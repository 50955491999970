import { Box, Link as MuiLink, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from '../Hooks/useTranslation';

interface ConsentMessageProps {}

//const TEXT = 'By sending your data, you declare your consent that we process it to the extent specified in our data protection information'
const ConsentMessage: React.FC<ConsentMessageProps> = (props) => {
    const { getTranslation } = useTranslation();

    return (
        <Box mt={1}>
            <Typography variant="body2">
                {/* {getTranslation('signUpForm.userAgreementText')} */}I agree to the&nbsp;
                <MuiLink target="_blank" href="https://www.microfocus.com/de-de/about/legal/#privacy">
                    {getTranslation('signUpForm.termsAndConditionText')}
                </MuiLink>
                &nbsp;and&nbsp;
                <MuiLink target="_blank" href="https://www.microfocus.com/de-de/about/legal/#privacy">
                    {getTranslation('signUpForm.privacyPolicyText')}
                </MuiLink>
            </Typography>
        </Box>
    );

    // return (
    //     <Typo variant="caption">
    //         By sending your data, you declare your consent that we process it to the extent specified in our&nbsp;
    //         <Link href={`${process.env.PUBLIC_URL}/privacy-policy.html`} target="_blank" rel="noopener">data protection information</Link>
    //     </Typo>
    // )
};

export default ConsentMessage;
