import { Box, Button, ListItemText, Menu, MenuItem, Slide } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import React, { FC, useContext, useEffect, useMemo } from 'react';
import { ContrastText } from '../../Components';
import Meta from '../../Components/Meta';
import Poppers from '../../Components/Poppers';
import { useGlobalStyle } from '../../Constants/globalStyle';
import { useSessionFeedbackDialog } from '../../Dialogs/SessionFeedback/useSessionFeedbackDialog';
import ChatBox from '../../Features/Chat/ChatBox';
import { SESSION_WINDOW_WIDTH } from '../../Features/Chat/useChat';
import { useSessionInfoDrawer } from '../../Features/Session/useSessionInfoDrawer';
import { useMediaQuery } from '../../Hooks/useMediaQuery';
import { getUpdatedSessions } from '../../Hooks/useScheduler';
import { useTranslation } from '../../Hooks/useTranslation';
import { TEventStatus, TSession } from '../../Models/Agenda/@types';
import { TEvent } from '../../Models/Events/@types';
import { useStoreState } from '../../Stores';
import { TranslationContext } from '../../Utils/TranslationProvider';
import { useFullScreenSession } from './useFullScreenSession';
import { useRunningSession } from './useRunningSession';
import { useSessionMedia } from './useSessionMedia';

export interface FullScreenSessionPageProps {
    sessionId: string;
}

const getFilteredSessionForPastEvent = (sessionId: string, eventStatus: TEventStatus, sessions: TSession[]) => {
    return eventStatus === 'Past'
        ? getUpdatedSessions(sessions).filter((i) => i.id !== sessionId)
        : getUpdatedSessions(sessions).filter((i) => i.status !== 'closed' && i.id !== sessionId);
};

const FullScreenSessionPage: FC<FullScreenSessionPageProps> = ({ sessionId }) => {
    const classes = useStyles();
    const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => setMenuAnchorEl(event.currentTarget);
    const handleClose = () => setMenuAnchorEl(null);
    const { getTranslation } = useTranslation();
    const { language } = useContext(TranslationContext);
    const { isDeviceSm } = useMediaQuery();
    const {
        eventDetails = {} as TEvent,
        sessions,
        eventStatus,
    } = useStoreState(({ Events: { eventDetails, allowedSessions: sessions, eventStatus } }) => ({ eventDetails, sessions, eventStatus }));
    const { currentSession } = useRunningSession(sessionId);
    const globalStyle = useGlobalStyle();
    const { popperNodes, onButtonClick, anchorEl, popperName, onPopperClose } = useFullScreenSession(currentSession, sessionId);
    const { toggleDialog, FeedbackDialogComponent, showDialog, initListner, toggleShowRejoin } = useSessionFeedbackDialog(sessionId);
    const {
        Component: MediaComponent,
        StreamButton,
        SwitchToSdkButton,
    } = useSessionMedia({ session: currentSession, onVideoEnd: toggleDialog, onMeetingLeft: toggleShowRejoin, eventId: eventDetails?.id });
    const { InfoDrawer, openDrawer } = useSessionInfoDrawer({ disableActions: true });
    useEffect(() => {
        initListner();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleUpcomingClick = () =>
        openDrawer(getTranslation('sessionDetails.upcomingSessionlabel'), getFilteredSessionForPastEvent(sessionId, eventStatus, sessions));

    const MENU: Array<{ label: string; onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void } | undefined> = [
        { label: getTranslation('speakerDetails.heading'), onClick: (e) => onButtonClick(e, 'session') },
        eventDetails._settings && eventDetails._settings.postSessionFeedback === true && !showDialog
            ? { label: 'Feedback', onClick: (e) => onButtonClick(e, 'feedback') }
            : undefined,
        !showDialog ? { label: /*'Requests'*/ getTranslation('agendaCard.request'), onClick: (e) => onButtonClick(e, 'request') } : undefined,
        { label: eventStatus === 'Past' ? 'All sessions' : getTranslation('sessionDetails.upcomingSessionlabel'), onClick: handleUpcomingClick },
    ];

    const isChatActive = useMemo(() => {
        return isDeviceSm === false && currentSession?.chat === true && currentSession?.hideChat !== true;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDeviceSm, currentSession?.chat, currentSession?.hideChat]);

    return (
        <Box className={classes.root} width={`calc(100vw - ${isChatActive ? SESSION_WINDOW_WIDTH : 0}px)`}>
            <Meta title={currentSession?.title} />
            <Box height="100%" width="100%" paddingBottom="80px">
                <Box className={classes.media}>{showDialog ? FeedbackDialogComponent : MediaComponent}</Box>
                <Box
                    width={`calc(100vw - ${isChatActive ? SESSION_WINDOW_WIDTH : 0}px)`}
                    className={clsx(classes.lowerMenuPanel, globalStyle.gradientBg)}
                >
                    {isDeviceSm ? (
                        <>
                            <Button onClick={handleClick}>
                                <ContrastText variant="body2" weight="medium">
                                    Options
                                </ContrastText>
                            </Button>
                            <Menu
                                id="user-menu"
                                anchorEl={menuAnchorEl}
                                getContentAnchorEl={null}
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                keepMounted
                                //@ts-ignore
                                TransitionComponent={Slide}
                                open={Boolean(menuAnchorEl)}
                                onClose={handleClose}
                            >
                                {MENU.map(
                                    (m, i) =>
                                        m && (
                                            <MenuItem
                                                key={i}
                                                onClick={(e) => {
                                                    m.onClick(e);
                                                    handleClose();
                                                }}
                                            >
                                                <ListItemText>{m.label}</ListItemText>
                                            </MenuItem>
                                        )
                                )}
                            </Menu>
                        </>
                    ) : (
                        MENU.filter((m) => m).map(
                            (m, i) =>
                                m && (
                                    <Box key={i} mx="5px">
                                        <Button variant="text" color="inherit" onClick={m.onClick}>
                                            <ContrastText variant="body2" weight="medium">
                                                {m.label}
                                            </ContrastText>
                                        </Button>
                                    </Box>
                                )
                        )
                    )}
                    {currentSession?.type === 'conferenceCall' && currentSession.status === 'live' && !showDialog && StreamButton}
                    {currentSession?.type === 'conferenceCall' && currentSession.status === 'live' && !showDialog && SwitchToSdkButton}
                </Box>
                <Poppers onClose={() => onPopperClose()} anchorEl={anchorEl}>
                    {popperNodes[popperName]}
                </Poppers>
                {InfoDrawer}
            </Box>
            {isChatActive && sessionId && (
                <div className={classes.chatBoxContainer}>
                    <ChatBox
                        config={{
                            eventId: eventDetails.id,
                            type: 'session',
                            sessionId,
                        }}
                        enableHeader={true}
                        headerProps={{
                            title: 'Session Chat',
                        }}
                    />
                </div>
            )}
        </Box>
    );
};

const useStyles = makeStyles<Theme>((theme) => {
    return createStyles({
        root: {
            //   width: `calc(100vw - 500px)`,
            maxHeight: `100vh`,
        },
        lowerMenuPanel: {
            height: 90,

            position: 'fixed',
            bottom: 0,
            display: 'flex',
            padding: 30,
            boxSizing: 'border-box',
        },
        media: {
            height: `calc(100vh - 90px)`,
        },
        chatBoxContainer: {
            position: 'fixed',
            top: 0,
            bottom: 0,
            right: 0,
            width: SESSION_WINDOW_WIDTH,
            transition: '200ms ease-in',
            // overscrollBehavior: 'contain'
        },
    });
});

export default FullScreenSessionPage;
