import qs from 'qs';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Campaign from './Campaign';


export interface CampaignData {
    utm_source?: string
    utm_medium?: string
    utm_campaign?: string
    utm_term?: string
    utm_content?: string
}
export default () => {
    const { search } = useLocation();
    const query = qs.parse(search, { ignoreQueryPrefix: true });

    useEffect(() => {
        const campaign = Campaign.fromQuery(query);
        campaign.setToCookie();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

}