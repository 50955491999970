import BannerHomeMobile from '../Assets/default/home_mobile.jpg';
import BannerHomeDesktop from '../Assets/default/home_desktop.jpg';
import BannerOtherPagesDesktop from '../Assets/default/other_pages_desktop.jpg';
import BannerOtherPagesMobile from '../Assets/default/other_pages_mobile.jpg';


export const DEFAULT_BANNERS = {
    homeMobile: BannerHomeMobile,
    homeDesktop: BannerHomeDesktop,
    otherMobile: BannerOtherPagesMobile,
    otherDesktop: BannerOtherPagesDesktop
}