import React from 'react'
import { Typography } from '@material-ui/core'

interface ErrorTextProps { }

const ErrorText: React.FC<ErrorTextProps> = (props) => {
    return <Typography variant='caption' color="error">{props.children}</Typography>
}



export default ErrorText