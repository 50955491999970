
import { TAgencyState } from ".";
import { TFilter } from "../../Models";
import { TAppConstants } from "../../Models/App/@types";
import { TTag } from "../../Models/Tag/@types";


export function buildSearchFilter(filter?: TAgencyState['appliedFilter']): { where: Record<string, any>, should: Record<string, any>[], sort: Record<string, any> } {
    if (!filter) return { where: {}, should: [], sort: {} };
    let _filter: Record<string, any> = {};
    let _should: Record<string, any>[] = [];
    let sort: Record<string, any> = {}
    // HANDLING OF EVENT TYPES TYPE
    if (filter.type && filter.type.includes('event')) {
        _filter['type'] = ['event', 'virtual', 'physical']
    } else {
        if (filter.type && filter.type.length)
            _filter['type'] = filter.type;
    }

    if (filter.status === 'past') {
        sort = {
            endTime: { order: 'desc' }
        }
    } else {
        sort = {
            startTime: { order: 'asc' }
        }
    }

    if (filter.focusAreaIds && filter.focusAreaIds.length) {
        _filter.bool = {
            should: [
                {
                    term: {
                        'focusAreas.parentIds': filter.focusAreaIds?.[0]
                    },

                },
                {
                    term: {
                        'focusAreaIds': filter.focusAreaIds?.[0]
                    },

                }
            ],
            "minimum_should_match": 1,
        }

    }
    if (filter.languages && filter.languages.length)
        _filter['languages'] = filter.languages;
    if (filter.regions)
        _filter['regions'] = filter.regions;

    return { where: _filter, should: _should, sort }
}

export function getTopLevelFocusAreaTags(appConstants: TAppConstants, facets: TAgencyState['facets'], focusAreas: TTag[]) {
    if (!appConstants) return [];
    const _focusAreas: TTag[] = [];
    const _focusAreaIds: string[] = [];
    facets.focusAreas?.forEach((f) => {
        const _focusArea = focusAreas.find(_f => f.id === _f.id);
        if (_focusArea) {
            _focusAreas.push(_focusArea);
            _focusAreaIds.push(f.id)
        } else {
            f.parentIds?.forEach(id => {
                const _focusArea = focusAreas.find(f => f.id === id);
                if (!_focusAreaIds.includes(id) && _focusArea) {
                    _focusAreas.push(_focusArea);
                    _focusAreaIds.push(id)
                }


            })
        }

    })
    return [{ label: 'All Focus Areas', value: 'all' }, ...(_focusAreas.map(l => ({ label: l.name, value: l.id })) || [])]
        .filter(i => i.label && i.label.trim().length) //handle emtpy focus area . In idead scenario there should not be emtpy focus area

}

export const getFieldFilter = (content: 'calendar' | 'resource') => {
    if (content === 'resource')
        return {
            Zine: {
                include: ['focusAreas'],
                "fields": ["updated", "languages", "focusAreas", "focusAreaIds", "id", "name", "description", "uploadStatus", "domainRestrictionType", "archived", "slug", "url", "restrictedStrategicPartnerIds", "restrictedEmailDomains", "_coverImage"]
            },
            AgencyAsset: {
                include: ['focusAreas'],
                "fields": ["updated", "languages", "id", "focusAreas", "focusAreaIds", "mimeType", "description", '_file', "name", "domainRestrictionType", "archived", "slug", "url", "restrictedStrategicPartnerIds", "restrictedEmailDomains", "_coverImage"]
            }
        }
    return {
        Event: {
            include: ['focusAreas'],
            "fields": ["languages", "timeZone", "focusAreas", "focusAreaIds", "id", "name", "startTime", "domainRestrictionType", "endTime", "archived", "campaignId", "slug", "_sessions", "type", "restrictedStrategicPartnerIds", "restrictedEmailDomains", "shortDescription", "_webImages"]
        },
        External: {
            include: ['focusAreas'],
            "fields": ["languages", "timezone", "focusAreas", "focusAreaIds", "id", "name", "startTime", "domainRestrictionType", "endTime", "archived", "campaignId", "slug", "url", "type", "restrictedStrategicPartnerIds", "restrictedEmailDomains", "_coverImage", "description"]
        },
    }
}

