
import DEFAULT_CONFIG from './config.json';
let envConfig: Record<string, any> = {};
let defaultConfig: Record<string, any> = DEFAULT_CONFIG;

if (process.env.REACT_APP_NODE_ENV) {
    const env = process.env.REACT_APP_NODE_ENV.trim();
    envConfig = require(`./config.${env}.json`);
}

const config = {
    get: (key: TConfigKey) => {
        return envConfig[key] || defaultConfig[key];
    }
}

export type TConfigKey =
    | 'BASE_URL'
    | 'API_URL'
    | 'EVENT_SLUG'
    | 'IMAGE_KIT_BASE_URL'
    | 'IMAGE_KIT_URL_PATH'
    | 'REDUX_LOGGER'
    | 'CONSOLE_LOGGING'
    | 'INTERCOM_APP_ID'


export type TReactAppNodeEnv =
    | 'development'
    | 'staging'
    | 'production'
    | 'ts'

export default config;