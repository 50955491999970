import { Box, Paper } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import React, { FC } from 'react';
import { ContrastText, RoundedButton } from '../../Components';
import Feedback from '../../Features/FullScreenSession/Feedback';
import SessionRequest from '../../Features/FullScreenSession/SessionRequest';
import { useMediaQuery } from '../../Hooks/useMediaQuery';
import { TFeedBack, TSessionRequest } from '../../Models/Agenda/@types';

export interface FeedbackDialogProps {
    onFeedbackSubmit?: (values: Partial<TFeedBack>) => Promise<void>
    onSessionRequestSubmit?: (values: TSessionRequest) => Promise<void>
    feedback?: TFeedBack
    sessionRequest?: TSessionRequest
    showRejoin?: boolean
    isSessionLive?: boolean
}

const FeedbackDialog: FC<FeedbackDialogProps> = (props) => {
    const classes = useStyles();
    const { showRejoin = false, isSessionLive = true } = props;
    const { isDeviceSm } = useMediaQuery();
    return (
        <Box width='100%' height='100%' overflow='auto' className={classes.background}>
            <Box width={'400px'} display='flex' flexDirection='column' margin='auto' paddingTop='60px'>
                <Box my={1}><ContrastText variant='h6' weight='medium' >{isSessionLive ? 'Thank you for attending!' : 'The meeting might not have started yet.'}</ContrastText></Box>
                {showRejoin && <Box my={1} display='flex' alignItems='center'><ContrastText variant='h6' weight='medium' >If wish you to rejoin , please </ContrastText><RoundedButton variant='contained' color='primary' onClick={() => { window.location.reload() }}>click here</RoundedButton></Box>}
                {(isSessionLive) && <><Paper className={!isDeviceSm ? classes.root : classes.mRoot}>
                    <SessionRequest sessionRequest={props.sessionRequest} onSubmit={props.onSessionRequestSubmit} />
                </Paper>
                    <Paper className={!isDeviceSm ? classes.root : classes.mRoot}>
                        <Feedback onSubmit={props.onFeedbackSubmit} feedback={props.feedback} />
                    </Paper></>}
            </Box>
        </Box>
    )
}

const useStyles = makeStyles<Theme>((theme) => {
    return (createStyles({
        background: {
            backgroundColor: '#000000'
        },
        root: {
            overflow: 'auto',
            width: 400,
            maxHeight: 500,
            borderRadius: 12,
            padding: '50px 25px',
            position: 'relative',
            boxSizing: 'border-box',
            marginBottom: 24
        },
        mRoot: {
            width: '100%',
            maxHeight: '100%',
            borderRadius: 12,
            padding: '50px 25px',
            position: 'relative',
            boxSizing: 'border-box',
            overflow: 'auto'
        },
    }))
})

export default FeedbackDialog