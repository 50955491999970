import { action, Action, thunk, Thunk } from "easy-peasy";
import { Injections } from "..";
import EventsBase from "../../BaseModels/Events";
import { TPartner } from "../../Models/Partner/@types";

export interface TPartnerState {
    partnerDetails?: TPartner
    setPartner: Action<TPartnerState, TPartner>
    fetchPartner: Thunk<TPartnerState, { eventId: string, partnerId: string }, Injections>
}
const State: TPartnerState = {
    setPartner: action((state, payload) => {
        state.partnerDetails = payload
    }),
    fetchPartner: thunk(async (actions, { partnerId, eventId },) => {


        try {
            const res = await EventsBase.get_Events_id_partners_partnerId<TPartner>(partnerId, eventId);
            actions.setPartner(res.data)
        } catch (er) {

        }
        // const {} = useStoreS
    })
}
export default State