import { useParams } from "react-router-dom";
import { TReactAppNodeEnv } from "../../Config";

export type useEventHandlerConfig = {}




export const useEventSlugHandler = (_config?: useEventHandlerConfig) => {
    const hostname = window.location.hostname
    const env = (process.env.REACT_APP_NODE_ENV || '').trim() as TReactAppNodeEnv | '';
    // const strs = hostname.split('-');
    let eventSlug = '';
    const params = useParams<{ eventId: string }>();

    const strs = /event.*.dev/ig.exec(hostname)
    const matched = strs ? strs[0] : '';


    switch (env) {
        case 'staging':
        case 'development': {
            if (!matched) break;
            const _temp = matched.split('-')
            const _temparr = _temp.slice(1, _temp.length - 1);
            if (_temparr.length > 0)
                eventSlug = _temparr.join('-')
            break;
        }

        case 'production': {
            eventSlug = hostname.split('.')[0]
            break;
        }
        default: {

        }
    }

    // if (!eventSlug)
    //     console.log("Event Slug not found. Using hardcoded event: ", TEST_EVENT_SLUG)

    return {
        eventSlug: params.eventId || eventSlug
        // eventSlug: (eventSlug === '__slug__' || !eventSlug) ? TEST_EVENT_SLUG : eventSlug
    }
}
