import { Box, BoxProps } from '@material-ui/core';
import React, { FC, useEffect } from 'react';
import ReactPlayer from 'react-player';
import { TAsset } from '../../Models/App/@types';
// import {sub} from '../../Assets/Subs/sub1.vtt'
export interface SessionVideoProps extends BoxProps {
    url?: string
    onVideoEnd?: () => void
    subtitles?: TAsset[]
    startTime?: number
    disableSeek?: boolean
}

const SessionVideo: FC<SessionVideoProps> = (props) => {
    const { url, onVideoEnd, subtitles = [], startTime = 0, disableSeek = false, ...boxProps } = props;
    useEffect(() => {
        if (disableSeek) {
            const video: HTMLVideoElement | null = document.getElementById('video') as HTMLVideoElement | null;
            if (video) {
                video.currentTime = startTime;
                console.log(video.currentTime)
            }
            var supposedCurrentTime = startTime;
            video?.addEventListener('timeupdate', function () {
                if (!video.seeking) {
                    supposedCurrentTime = video.currentTime;
                }
            });
            // prevent user from seeking
            video?.addEventListener('seeking', function () {
                // guard agains infinite recursion:
                // user seeks, seeking is fired, currentTime is modified, seeking is fired, current time is modified, ....
                var delta = video.currentTime - supposedCurrentTime;
                if (Math.abs(delta) > 0.01) {
                    video.currentTime = supposedCurrentTime;
                }
            });
        }
    }, [disableSeek, startTime])
    return (
        <Box {...boxProps}>
            <ReactPlayer
                pip={false}
                config={{
                    file: {

                        tracks: subtitles.map((item, index) => ({
                            kind: 'subtitles',
                            src: item.url,
                            label: item.name.replace('.vtt', '').replace('.srt', ''),
                            srcLang: 'en',
                            default: index === 0
                        })),
                        attributes: {
                            id: 'video',
                            crossOrigin: 'true',
                            controlsList: 'nodownload',
                            // disablepictureinpicture: 'true',
                        },

                        hlsOptions: {

                            xhrSetup: function (xhr: { withCredentials: boolean }, url: any) {
                                console.log(xhr);
                                xhr.withCredentials = true // send cookies
                            },

                        }
                    },

                }}
                width="100%" height="100%" url={(`${url}`)} controls playing onEnded={onVideoEnd} />
        </Box>
    )
}



export default SessionVideo