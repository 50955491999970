import React, { FC, useState, useEffect, createRef } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { TWebsiteBanner } from '../../Models/Picture/@types';
import LottieImage from '../../Components/LottieImage';
import { request, COOKIES_ACCESS_TOKEN } from '../../Utils';
import { useMediaQuery } from '../../Hooks/useMediaQuery';
import { DEFAULT_BANNERS } from '../../Constants/defaultBanners';
import { TEvent } from '../../Models/Events/@types';
import utilities from '../../Resources/Utils';

export interface ImageBannerProps {
    event?: TEvent
    type: 'home' | 'other'
    enableFallbackImage?: boolean
    width?: string
    height?: string
}

const ImageBanner: FC<ImageBannerProps> = (props) => {
    const { width = '100%', type, height = '100%', event, enableFallbackImage = true } = props;
    const classes = useStyles({});
    const [jsonData, setJsonData] = useState<any>(undefined);
    const videoRef = createRef<HTMLVideoElement>();
    const { isDeviceSm } = useMediaQuery();



    useEffect(() => {
        if (event)
            getJsonData(event);
    }, [event])


    useEffect(() => {
        if (videoRef.current)
            videoRef.current.play();
    }, [videoRef])


    const getJsonData = async (event: TEvent) => {
        const media = utilities.getBannerImage(type, event, isDeviceSm);
        if (media && media.fileType === 'json') {
            fetch(`${media.url}?access_token=${localStorage.getItem(COOKIES_ACCESS_TOKEN)}`)
                .then(res => res.json())
                .then(data => setJsonData(data))
        }
    }



    const renderImage = () => {
        const media = utilities.getBannerImage(type, event, isDeviceSm);
        const fallbackImageUrl = utilities.getFallbackImageUrl(type, event, isDeviceSm);
        if (media) {
            if (media.fileType === 'json') return (
                <LottieImage loop={true} lotteJSON={jsonData} dimension={{ height, width }} />
            )
            if (media.fileType === 'video') return (
                <video ref={videoRef} width={width} height={height} muted autoPlay loop>
                    <source src={media.url} type={media.mimetype} />
                            Your browser does not support the video tag.
                </video>
            )
            if (media.fileType === 'image') return (
                <img src={media.url} width={width} height={height} alt={media.caption} />
            );
        } else {
            if (enableFallbackImage)
                return <img src={fallbackImageUrl} width={width} height={height} alt={'home banner'} />
        }
        return <div />
    }


    return renderImage();
}

const useStyles = makeStyles<Theme, any>((theme) => ({

}));

export default ImageBanner;