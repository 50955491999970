import { action, Action, thunk, Thunk } from "easy-peasy";
import { Injections, TRootStore } from "..";
import { TFilter } from "../../Models";
import ZineModel from "../../Models/Zine";
import { TZine } from "../../Models/Zine/@types";
import { IAnalyticsCustomConfig, setAnalytics } from "analytics.config";

export interface TZineState {
    zineId?: string;
    setZineId: Action<TZineState, string | undefined>;

    zine?: TZine;
    setZine: Action<TZineState, TZine | undefined>;
    loadZine: Thunk<TZineState, { id: string; filter?: TFilter }, Injections, TRootStore, Promise<TZine>>;
}
const state: TZineState = {
    loadZine: thunk(async (_a, params, { getState }) => {
        const res = await ZineModel.get_Zines_id<TZine>(params.id, params.filter);
        const state = getState();
        const { tracking } = res.data;
        if (tracking) {
            let trackingConfig: IAnalyticsCustomConfig[] = [];
            if (tracking.linkedIn?.campaignId)
                trackingConfig.push({
                    appId: tracking.linkedIn?.campaignId ?? "",
                    type: "linkedIn",
                });
            if (tracking.googleAnalytics?.campaignId)
                trackingConfig.push({
                    appId: tracking.googleAnalytics?.campaignId ?? "",
                    type: "google",
                });
            setAnalytics(trackingConfig);
        }
        state.zine = res.data;
        state.zineId = res.data.id;
        return res.data;
    }),
    setZine: action((state, payload) => {
        state.zine = payload;
        state.zineId = payload?.id;
    }),
    setZineId: action((state, payload) => {
        state.zineId = payload;
    }),
};
export default state;
