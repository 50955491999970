import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { FormConfig, IFormActionProps } from "react-forms";
import * as Yup from "yup";
import { useTranslation } from "../../Hooks/useTranslation";
import { CheckboxProps, Typography } from "@material-ui/core";
import React from "react";
import HTMLText from "Components/HTMLText";
export const useFormConfig = (config?: { emailOptInText?: string }) => {
    const classes = useStyles();
    const { getTranslation } = useTranslation();
    const validationSchema = Yup.object({
        email: Yup.string().trim().email("Invalid Email").required("Email is required"),
        emailOptIn: config?.emailOptInText ? Yup.boolean().required("This is required").oneOf([true], "This is required") : Yup.boolean(),
    });
    const actionConfig: IFormActionProps = {
        submitButtonText: getTranslation("emailInputForm.nextButtonLabel"),
        submitButtonLayout: "fullWidth",
        submitButtonProps: {
            size: "large",
        },
        containerClassNames: classes.buttonContainer,
    };
    const inputConfig: Array<Array<FormConfig> | FormConfig> = [
        {
            type: "text",
            valueKey: "email",
            fieldProps: {
                label: getTranslation("emailInputForm.emailInputLabel"),
                fullWidth: true,
                autoFocus: true,
            },
        },
        ...(config?.emailOptInText
            ? [
                  {
                      type: "checkbox",
                      valueKey: "emailOptIn",
                      fieldProps: {
                          formControlLabelProps: {
                              style: {
                                  alignItems: "flex-start",
                                  gap: 4,
                              },
                              label: <HTMLText htmlText={config?.emailOptInText} variant="body2" />,
                          },
                      } as CheckboxProps,
                  },
              ]
            : []),
    ];
    return { actionConfig, inputConfig, validationSchema };
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonContainer: {},
    })
);
