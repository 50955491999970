import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React, { FC, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { AppHeader } from "../Components";
import FullScreenLoader from "../Components/FullScreenLoader";
import Config from "../Config";
import AppContext from "../Context/AppContext";
import { ThemeContext } from "../Context/ThemeContext";
import { useNavMenu } from "../Features/AppHeader/useNavMenu";
import MainBanner from "../Features/Banner/MainBanner";
import { useMainBanner } from "../Features/Banner/useMainBanner";
import usePathChangeListener from "../Hooks/usePathChangeListener";
import { intercomUtil } from "../Intercoms";
import EventNavigation from "../Routes/EventNavigation";
import { useStoreState } from "../Stores";
import { useFeedbackRemider } from "./Feedback/useFeedbackRemider";
import Page404 from "./Page404";

export interface EventLayoutProps {}

const EventLayout: FC<EventLayoutProps> = (props) => {
    const classes = useStyles();
    const { pathname } = useLocation();
    const { user, eventDetails } = useStoreState(({ User: { user }, Events: { eventDetails } }) => ({ user, eventDetails }));
    const { FULL_PATH } = useNavMenu();
    const { initialized, previewMode, invalidEvent, invalidAgency } = useContext(AppContext);
    const { theme } = useContext(ThemeContext);
    usePathChangeListener();
    useEffect(() => {
        window.scrollTo(0, 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    useEffect(() => {
        intercomUtil.shutIntercom();
        if (!pathname.includes("zoom-client") && eventDetails) {
            // console.log({eventDetails : eventDetails.id })
            intercomUtil.initIntercom(user, eventDetails, Config.get("INTERCOM_APP_ID"), FULL_PATH, theme);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user?.id, theme, eventDetails?.id]);

    useMainBanner();
    useFeedbackRemider();
    return invalidEvent || invalidAgency ? (
        <Page404 />
    ) : (
        <div className={classes.root}>
            {!initialized ? (
                <FullScreenLoader />
            ) : (
                <>
                    <AppHeader previewMode={previewMode} />
                    <MainBanner />
                    <EventNavigation />
                </>
            )}
        </div>
    );
};

const useStyles = makeStyles<Theme>((theme) => {
    return createStyles({
        root: {
            backgroundColor: theme.palette.background.default,
            minHeight: "100vh",
        },
    });
});

export default EventLayout;
