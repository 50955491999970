import { Box, BoxProps } from '@material-ui/core';
import EventModel from 'Models/Events';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { TrackEvent } from '../../../analytics.config';
import { LiveDot, RoundedButton } from '../../../Components';
import Typo from '../../../Components/Typo';
import { TBannerConfig } from '../../../Context/BannerContext/@types';
import { useAuthDialog } from '../../../Dialogs/Auth/useAuthDialog';
import { useMediaQuery } from '../../../Hooks/useMediaQuery';
import { useStoreState } from '../../../Stores/index';
import { useNavMenu } from '../../AppHeader/useNavMenu';
import { useAuth } from '../../Auth/useAuth';
import CheckIn from '../../CheckIn';
import useAutoRegister from '../../Registration/useAutoRegister';
import { getBannerMessages } from './GenericMessage';

export interface DuringEventBannerProps {
  loggedIn?: boolean;
  registered?: boolean;
  registrationOpen?: boolean;
  registrantionStatus?: 'cancelled' | 'closed' | 'unregistered' | 'approved' | 'rejected' | 'checkedIn';
}
const CANCELLED_REGISTERED_TEXT = 'Your registration has been cancelled for this event.';

const DuringEventBanner: React.FC<DuringEventBannerProps> = (props) => {
  const { loggedIn = false, registered = false, registrantionStatus = 'unregistered', registrationOpen = false } = props;
  const { isDeviceSm } = useMediaQuery();
  const { BASE_URL } = useNavMenu();
  const { eventSettings, eventDetails } = useStoreState(({ Events: { eventDetails, eventSettings } }) => ({ eventSettings, eventDetails,  }));

  const { RoundedRegisterButton } = useAutoRegister();
  const { autoRegister , eventType = 'default'} = eventDetails || {};
  const { userLogin, userSignup, isRegistered, forgotPassword } = useAuth();
  const { AuthComponent, openAuthDialog } = useAuthDialog({
    onLogin: userLogin,
    onSignup: userSignup,
    handleCheck: isRegistered,
    onForgotPassword: forgotPassword,
  });

  const trackRegisterClick = () => {
    TrackEvent.log('registerRequested');
  };

  const requiresApproval = eventSettings?.approvalRequired ?? false;

  if (!loggedIn)
    return (
      <Box display="flex" mt={isDeviceSm ? 1 : 0} alignItems={isDeviceSm ? 'flex-start' : 'center'} width={isDeviceSm ? '100%' : undefined} flexDirection={isDeviceSm ? 'column' : 'row'}>
        <EventLiveComponent subtext={getBannerMessages('notLoggedIn', eventType,autoRegister)} />
        {isDeviceSm ? (
          <Box width="100%" display="flex">
            <Box flex={1} />
            <RoundedButton onClick={openAuthDialog} variant="contained" color="secondary">
              SIGN IN
            </RoundedButton>
          </Box>
        ) : null}
        {AuthComponent}
      </Box>
    );

  return (
    <Box display="flex" alignItems={isDeviceSm ? 'flex-start' : 'center'} width={isDeviceSm ? '100%' : undefined} flexDirection={isDeviceSm ? 'column' : 'row'}>
      {!registered && !registrationOpen && (
                <EventLiveComponent subtext={getBannerMessages('registrationClosed', eventType,autoRegister)}/>
       
      )}

      {((!registered && registrantionStatus === 'unregistered' && registrationOpen) || (registered && registrantionStatus === 'cancelled')) && (
        <>
            {registrantionStatus === 'cancelled' ? (
                <Box display="flex" alignItems="center" mr={3}>
              <Typo variant={'subtitle2'} weight="medium">
                {getBannerMessages('cancelled', eventType,autoRegister)}
              </Typo>
                </Box>
            ) : (
                <EventLiveComponent subtext={getBannerMessages('notRegistered', eventType,autoRegister)} mr={2}/>
            )}

          {registrationOpen && (
            <Box width={isDeviceSm ? '100%' : undefined} display="flex">
              <Box flex={1} />
              {autoRegister ? (
                RoundedRegisterButton
              ) : (
                <Link rel="noopener" to={`${BASE_URL}/register`}>
                  <RoundedButton onClick={trackRegisterClick} borderColor="white" variant="contained" color="secondary">
                    REGISTER
                  </RoundedButton>
                </Link>
              )}
            </Box>
          )}
        </>
      )}

      {requiresApproval && registered && registrantionStatus !== 'approved' && registrantionStatus !== 'cancelled' && registrantionStatus !== 'rejected' && (
        <Typo variant={'subtitle2'} weight="medium">
          {getBannerMessages('notApproved', eventType,autoRegister)}
        </Typo>
      )}

      {requiresApproval && registered && registrantionStatus === 'rejected' && (
        <Typo variant={'subtitle2'} weight="medium">
          {getBannerMessages('cancelled', eventType,autoRegister)}
        </Typo>
      )}

      {registered && registrantionStatus === 'approved' && (
        <>
          <EventLiveComponent />
          <Box width={isDeviceSm ? '100%' : undefined} display="flex">
            <Box flex={1} />
            <Box ml={3}>
              <CheckIn />
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

interface IProps extends BoxProps {
  subtext?: string;
}
const EventLiveComponent: React.FC<IProps> = ({ subtext = '', ...boxProps }) => {
  const { eventDetails, currentSessions } = useStoreState(({ Events: { eventDetails, eventSettings, currentSessions } }) => ({ eventSettings, eventDetails, currentSessions }));
  
  const isEventLive = useMemo(() => {
    if (eventDetails?.eventType === 'webinar' && currentSessions.length) return true;
    if (eventDetails?.eventType === 'default') return true;
    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventDetails?.eventType, currentSessions.length]);
 
  if (isEventLive === false)
    return (
      <Box display="flex" alignItems="center" {...boxProps}>
        <Typo variant={'subtitle2'} weight="medium">
          {subtext}
        </Typo>
      </Box>
    );
  
    return (
      <Box display="flex" alignItems="center" {...boxProps}>
        <LiveDot mr={1} />
        <Typo variant={'subtitle2'} weight="medium">
          {`This ${EventModel.getEventLabel()} is now live! ${subtext}`}
        </Typo>
      </Box>
    );
};

export const getDuringEventBannerConfig = (config: DuringEventBannerProps): TBannerConfig => ({
  content: { right: <DuringEventBanner {...config} /> },
  size: 'large',
});

export default DuringEventBanner;
