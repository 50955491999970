import { Slide, Snackbar } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import React, { createContext, useState } from 'react';

export type AppNotificationContextProvider = {
    notify: (content: JSX.Element, action?: JSX.Element, duration?: number) => void;
    handleClose: () => void;
};
export const AppNotificationContext = createContext<AppNotificationContextProvider>({
    notify: () => {},
    handleClose: () => {},
});

const AppNotificationProvider: React.FC = (props) => {
    return (
        <_AppNotificationProvider>{props.children}</_AppNotificationProvider>
        // </SnackbarProvider>
    );
};

const _AppNotificationProvider: React.FC = (props) => {
    const [open, setOpen] = useState(false);
    const [action, setAction] = useState<JSX.Element>();
    const [content, setContent] = useState<JSX.Element>();
    const [duration, setDuration] = useState(10000);
    const classes = useStyles();
    // const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const handleClose = () => {
        setOpen(false);
        // closeSnackbar();
    };

    const notify = (_content: JSX.Element, _action?: JSX.Element, duration?: number) => {
        // enqueueSnackbar(_content, { autoHideDuration: duration || 10000, action: _action })
        // return;
        if (duration) setDuration(duration);
        setContent(_content);
        setAction(_action);
        setOpen(true);
    };

    const providerValue: AppNotificationContextProvider = {
        notify,
        handleClose,
    };

    return (
        <AppNotificationContext.Provider value={providerValue}>
            {props.children}
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                TransitionComponent={Slide}
                open={open}
                ContentProps={{ className: classes.root, classes: { message: classes.message } }}
                autoHideDuration={duration}
                onClose={handleClose}
                ClickAwayListenerProps={{ onClickAway: () => {} }}
                message={content}
                action={action}
            />
        </AppNotificationContext.Provider>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            background: theme.palette.common.white,
            color: theme.palette.text.primary,
            flexDirection: 'column',
            alignItems: 'flex-start',
            zIndex: theme.zIndex.appBar,
        },
        message: {
            width: '100%',
        },
    })
);

export default AppNotificationProvider;
