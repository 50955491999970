import qs from "query-string";
import { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useNavMenu } from "../../Features/AppHeader/useNavMenu";
import useCampaignUrl from "../../Features/Campaign/useCampaignUrl";
import { useScheduler } from "../../Hooks/useScheduler";
import { useSocketListener } from "../../Hooks/useSocketListener";
import AgencyModel from "../../Models/Agency";
import AppModel from "../../Models/App";
import EventModel from "../../Models/Events";
import { TEvent } from "../../Models/Events/@types";
import { TRegistrant, TRegistrationStatus } from "../../Models/Registrant/@types";
import UserModel from "../../Models/User";
import { TUser } from "../../Models/User/@types";
import { useStoreActions, useStoreState } from "../../Stores";
import utils from "../../Utils";
import { ToastContext } from "../../Utils/AppToast";
import { DEFAULT_STATUS_PERMISSIONS, TEventStatusAndPermissions, TInitializeKeys } from "./@types";
export type useAppInitConfig = {
    eventSlug: string;
};
const EXCLUDE_PATHS_PATTERNS = ["zoom-client", "empty-screen"];

export const useAppInit = (config: useAppInitConfig) => {
    const { eventSlug } = config;
    const [initialized, setInitialized] = useState<Record<TInitializeKeys, boolean>>({
        constants: false,
        event: false,
        registrant: true,
        agency: false,
        user: false,
    });
    const history = useHistory();
    const { pathname } = useNavMenu();
    const [invalidEvent, setInvalidEvent] = useState(false);
    const [invalidAgency, setInvalidAgency] = useState(false);
    const curEventSlug = useStoreState((state) => state.App.eventSlug);

    const { fetchSingleEvent, setEventSlug, setAppConstantsToRedux, setAllSession, setRegistrationAllowed } = useStoreActions(
        ({ Events: { fetchSingleEvent, setAllSession, setRegistrationAllowed }, App: { setEventSlug, setEventId, setAppConstants: setAppConstantsToRedux } }) => ({
            fetchSingleEvent,
            setAllSession,
            setEventSlug,
            setEventId,
            setAppConstantsToRedux,
            setRegistrationAllowed,
        })
    );

    const { showToast } = useContext(ToastContext);
    const { setUser, setIsAuthenticated, setRegistrant, setCredit, setAgency, setRegistrantFormData, fetchStrategicPartners } = useStoreActions(
        ({ Agency: { setAgency, fetchStrategicPartners }, User: { setUser, setIsAuthenticated }, Registrant: { setRegistrant, setRegistrantFormData, setCredit } }) => ({
            setUser,
            setIsAuthenticated,
            setRegistrant,
            setRegistrantFormData,
            setCredit,
            setAgency,
            fetchStrategicPartners,
        })
    );

    const { isAuthenticated, user } = useStoreState((state) => state.User);

    const { eventDetails, registrant, appConstants, eventSettings, agency, allSessions, strategicPartners } = useStoreState(
        ({ App: { appConstants }, Events: { eventDetails, eventSettings, allSessions }, Agency: { agency, strategicPartners }, Registrant: { registrant } }) => ({
            allSessions,
            appConstants,
            eventDetails,
            registrant,
            agency,
            eventSettings,
            strategicPartners,
        })
    );
    const [eventId, setEventId] = useState("");
    // const [appConstants, setAppConstants] = useState<TAppConstants>({});
    const previewMode = useStoreState((state) => state.App.previewMode);
    const tLocation = useLocation();
    const { socketInit, socket, SOCKET_EVENT_KEYS, connectEventSocket } = useSocketListener();

    const [statusAndPermissions, setStatusAndPermissions] = useState<TEventStatusAndPermissions>({
        ...DEFAULT_STATUS_PERMISSIONS,
    });

    useCampaignUrl();
    useScheduler();

    const APP_INITIALIZED = initialized.constants && initialized.registrant && initialized.user && initialized.agency;
    // const APP_INITIALIZED = initialized.constants && initialized.event && initialized.registrant && initialized.user && initialized.agency;

    useEffect(() => {
        fetchAppConstants();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let init = true;
        EXCLUDE_PATHS_PATTERNS.forEach((i) => {
            init = !pathname.includes(i);
        });
        init && socketInit();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);
    useEffect(() => {
        if (eventId) {
            connectEventSocket(eventId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user?.id, eventId]);

    useEffect(() => {
        if (eventSettings) {
            setStatusAndPermissions({
                ...statusAndPermissions,
                networking: eventSettings?.networking || false,
                networkingWall: eventSettings?.networkingWall || false,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventSettings?.networking, eventSettings?.networkingWall]);

    useEffect(() => {
        if (eventDetails?.id) {
            setAllSession(allSessions);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user?.email, eventDetails?.id, strategicPartners.length]);

    useEffect(() => {
        if (eventDetails?.id) {
            setRegistrationAllowed(user?.email);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user?.email, eventDetails?.id]);
    useEffect(() => {
        if (user && registrant) {
            socket.removeListener(SOCKET_EVENT_KEYS.REGISTRATION_CANCELLED);
            socket.on(SOCKET_EVENT_KEYS.REGISTRATION_CANCELLED, (data: { eventId: string; registrationId: string; registrationStatus: TRegistrationStatus }) => {
                if (eventDetails?.id === data.eventId && registrant?.id === data.registrationId) {
                    if (data.registrationStatus === "rejected" || data.registrationStatus === "canceled") {
                        window.location.reload();
                    }
                }
                //tLocation.reload();
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [registrant, user]);

    useEffect(() => {
        if (!isAuthenticated) return;
        if (eventId) getRegistrationDetail(eventId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, eventId]);
    useEffect(() => {
        if (curEventSlug !== eventSlug || eventSlug === "") initialize(eventSlug);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventSlug]);

    const getRegistrationDetail = async (eventId: string) => {
        setInitialized((prev) => ({ ...prev, registrant: false }));
        try {
            const _registrant = await EventModel.myRegistration(eventId);
            setRegistrant(_registrant);
            setCredit({ points: _registrant.credits_remaining || 0, rank: _registrant?.rank?.rank || 0 });
            setRegistrantFormData(_registrant);
        } catch (error) {
            if (user) {
                const registrant = getRegistrantInfoFromUserData(user);
                setRegistrant(registrant as TRegistrant);
                setRegistrantFormData(registrant as TRegistrant);
            }
            if (!eventDetails?.autoRegister) {
                if (APP_INITIALIZED) history.push(`/${eventSlug}/register`);
            }
        }
        // REGISTRANT INITIALIZED
        setInitialized((prev) => ({ ...prev, registrant: true }));
    };

    useEffect(() => {
        if (user?.id && agency?.id) {
            fetchStrategicPartners(agency.id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user?.id, agency?.id]);

    const fetchAppConstants = async () => {
        setInitialized((prev) => ({ ...prev, constants: false }));
        try {
            if (!appConstants) {
                const _appConstants = await AppModel.fetchAppConstants();
                // setAppConstants(_appConstants as any);
                setAppConstantsToRedux(_appConstants as any);
            }
        } catch (error) {}
        // APP CONSTANTS INITIALIZED
        setInitialized((prev) => ({ ...prev, constants: true }));
    };

    const initialize = async (eventId: string, token?: string) => {
        setInitialized((prev) => ({ ...prev, event: false }));
        const q = qs.parse(tLocation.search);
        const _token = q.token;
        if (_token && typeof _token === "string") utils.setAuthHeader(_token);
        if (eventId) setEventSlug(eventId);
        try {
            await fetchAgency(AppModel.extractAgencyId());
        } catch (err) {
            showToast("Agency not found", { variant: "error" });
            setInvalidAgency(true);
            return;
        }
        try {
            const access_token = utils.getCookie("access_token");
            if (access_token) {
                await setTokenAndUser(access_token);
            }
        } catch (error) {}

        let event: TEvent | undefined = undefined;
        if (eventId && !invalidAgency) {
            try {
                event = await fetchSingleEvent(eventId);
                // fetchAgency(event!);
                initStatusAndPermissions(event!);
                setEventId(event!.id);
                // getRegistrationDetail(event.id)
            } catch (error) {
                console.log(error);
                setInvalidEvent(true);
            }
        } else {
            setInvalidEvent(true);
        }
        if (_token) utils.setAuthHeader(undefined);

        // EVENT INITIALIZED
        setInitialized((prev) => ({ ...prev, event: true, user: true }));
    };

    const fetchAgency = async (agencyId: string) => {
        // try {
        if (!agency || (agency && agency.slug !== agencyId)) {
            const _agency = await AgencyModel.getAgency(agencyId);
            setAgency(_agency);
        }
        setInitialized((prev) => ({ ...prev, agency: true }));
        // } catch (err) {
        //     console.log('here we go')
        //     throw err
        // }
    };

    const initStatusAndPermissions = (event: TEvent) => {
        const _data: Partial<TEventStatusAndPermissions> = {};
        _data.networking = event._settings.networking || false;
        _data.networkingWall = event._settings.networkingWall;
        setStatusAndPermissions({ ...DEFAULT_STATUS_PERMISSIONS, ..._data });
    };

    const setTokenAndUser = async (access_token: string, event?: TEvent, user?: TUser) => {
        utils.setAuthHeader(access_token);
        utils.setCookie("access_token", access_token);
        try {
            if (!user) {
                const _user = await UserModel.me();
                // if (event && !event._settings?.emailVerificationRequired)
                //     _user.emailVerified = true;
                // if (_user.agencyId !== event?.agencyId) throw ''
                setUser(_user);
            } else {
                setUser(user);
            }
            setIsAuthenticated(true);
        } catch (error) {
            utils.clearCookies();
        }
        return;
    };

    return {
        initialized: APP_INITIALIZED,
        previewMode,
        eventSlug,
        initialize,
        eventId,
        invalidEvent,
        invalidAgency,
        statusAndPermissions,
        // appConstants,
        setTokenAndUser,
    };
};

export const getRegistrantInfoFromUserData = (user: TUser) => {
    return {
        email: user.email || "",
        firstName: user.firstName || "",
        lastName: user.lastName || "",
        designation: user.designation || "",
        company: user.company || "",
        department: user.department || "",
        street: user.street || "",
        zipCode: user.zipCode || "",
        city: user.city || "",
        country: user.country || "",
        phone: user.phone || "",
        industry: user.industry || "",
        eventRelation: user.eventRelation || "",
        salutation: user.salutation || "",
    };
};
