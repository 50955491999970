
import axios, {AxiosRequestConfig} from 'axios';
// THIS IS A GENERATED FILE; PLEASE DO NOT MAKE CHANGES HERE

class StrategicPartnersBase {

/** 
 * Find all instances of the model matched by filter from the data source.
 */ 
    static get_StrategicPartners<T = any>(filter?: any,config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/StrategicPartners`,
            method: 'get',
            params: {
                filter
            },
            
        })
    }

/** 
 * Create a new instance of the model and persist it into the data source.
 */ 
    static post_StrategicPartners<T = any>(data?: any,config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/StrategicPartners`,
            method: 'post',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Find a model instance by {{id}} from the data source.
 */ 
    static get_StrategicPartners_id<T = any>(id: string,filter?: any,config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/StrategicPartners/${id}`,
            method: 'get',
            params: {
                filter
            },
            
        })
    }

/** 
 * Patch attributes for a model instance and persist it into the data source.
 */ 
    static patch_StrategicPartners_id<T = any>(id: string,data?: any,config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/StrategicPartners/${id}`,
            method: 'patch',
            
            data: {
                ...data
            },
        })
    }

}

export default StrategicPartnersBase;
    