import ZinesBase from "../../BaseModels/Zines";
import { SOCKET_EVENT_KEYS } from "../../Hooks/useSocketListener";
import { COOKIES_ACCESS_TOKEN } from "../../Utils";

class ZineModel extends ZinesBase {
    static emitJoinZineEvent = (socket: SocketIOClient.Socket, zineId: string) => {
        /**
         * This is fired for leetign the backend know that the user has loaded a new zine.
         * @author Adnan Husain
         */
        socket.emit(SOCKET_EVENT_KEYS.JOIN_ZINE, {
            accessToken: localStorage.getItem(COOKIES_ACCESS_TOKEN),
            zineId,
        });
    };
    static emitExitJoinEvent = (socket: SocketIOClient.Socket, zineId: string) => {
        socket.emit(SOCKET_EVENT_KEYS.EXIT_ZINE, {
            accessToken: localStorage.getItem(COOKIES_ACCESS_TOKEN),
            zineId,
        });
    };
}
export default ZineModel;
