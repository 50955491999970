import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React, { FC } from "react";
import "react-quill/dist/quill.snow.css";
import { Redirect, Route, Switch } from "react-router-dom";
import "rf-autocomplete";
import ContextProviders from "../Context";
import { useClientTheme } from "../Features/Theme/useClientTheme";
import AgencyLayout from "./AgencyLayout";
import DialogRoot from "./DialogRoot";
import EventLayout from "./EventLayout";
import Page404 from "./Page404";

const RootLayout: FC = () => {
    useClientTheme();

    return (
        <>
            <Switch>
                <Route path="/" exact render={() => <Redirect to="/calendar" />} />
                <Route path="/calendar" component={AgencyRoot} />
                <Route path="/home" render={() => <Redirect to="/calendar" />} />
                <Route path="/resources" component={AgencyRoot} />
                <Route path="/sa" component={AgencyRoot} />
                <Route path="/zine/:id" component={AgencyRoot} />
                <Route path="/asset/:id" component={AgencyRoot} />
                <Route path="/reset-password" component={AgencyRoot} />
                <Route path="/:eventId" component={EventRoot} />
                <Route path="*" exact component={Page404} />
            </Switch>
            <DialogRoot />
        </>
    );
};

const EventRoot = () => {
    return (
        <ContextProviders>
            <EventLayout />
        </ContextProviders>
    );
};

const AgencyRoot = () => {
    return (
        <ContextProviders>
            <AgencyLayout />
        </ContextProviders>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: theme.palette.background.default,
            minHeight: "100vh",
        },
    })
);

export default RootLayout;

// id = 5e748c84b1971712aa161292
