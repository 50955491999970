import { AppBar, AppBarProps, Box, BoxProps, Paper } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import React, { FC } from 'react';
import CloseButton from '../CloseButton';
import Typo from '../Typo';

export interface PlainHeaderProps extends AppBarProps {
    title: string;
    onClose?: () => void;
    titleProps?: BoxProps;
}

const PlainHeader: FC<PlainHeaderProps> = (props) => {
    const classes = useStyles();
    const { title, onClose, className, titleProps = {}, ...appbarProps } = props;
    return (
        <AppBar className={clsx(classes.title, className)} {...appbarProps}>
            <Paper className={classes.paper}>
                <Box {...titleProps}>
                    <Typo weight="medium">{title}</Typo>
                </Box>
                <Box flex={1} />
                {onClose ? <CloseButton onClick={onClose} variant="filledLight" size="small" /> : null}
            </Paper>
        </AppBar>
    );
};

const useStyles = makeStyles<Theme>((theme) => {
    return createStyles({
        title: {
            height: 64,
            width: '100%',
            boxSizing: 'border-box',
            position: 'sticky',
            boxShadow: 'none',
        },
        paper: {
            width: '100%',
            height: '100%',
            boxSizing: 'border-box',
            borderRadius: 0,
            paddingLeft: 32,
            paddingRight: 16,
            display: 'flex',
            alignItems: 'center',
            boxShadow: '0px 1px 2px #00000029',
        },
    });
});

export default PlainHeader;
