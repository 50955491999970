import React from 'react'
import { Typography } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'

interface PageTitleProps {
    color?: 'contrast' | 'default'
    text: string
}

// const TITLE = 'ArcSight User Group 19/5/2020';

const PageTitle: React.FC<PageTitleProps> = (props) => {
    const { color = 'default', text = '' } = props;
    const theme = useTheme()

    const style = {
        color: color === 'default' ? undefined : theme.palette.common.white
    }

    return (
        <Typography variant="h2" color="textPrimary" style={style}>{text}</Typography>
    )
}


export default PageTitle