import { Fab } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import React, { FC } from 'react';
export interface ArrowButtonProps {
    role: 'next' | 'previous';
    onClick?: any;
    currentSlide?: number;
}

const ArrowButton: FC<ArrowButtonProps> = (props) => {
    const classes = useStyles();
    const { role, currentSlide } = props;
    let disabled = currentSlide === 0 && role === 'previous';
    return (
        <Fab
            disabled={disabled}
            className={clsx(role === 'next' ? clsx('slick-arrow', classes.next) : clsx('slick-arrow', classes.prev), classes.root)}
            onClick={props.onClick}
        >
            {role === 'previous' ? <ArrowBackIosIcon fontSize="default" style={{ marginLeft: 8 }} /> : <ArrowForwardIosIcon fontSize="default" />}
        </Fab>
    );
};

const useStyles = makeStyles<Theme>((theme) => {
    const {
        palette: {
            primary: { main },
        },
    } = theme;
    return createStyles({
        root: {
            position: 'absolute',
            zIndex: 100,
            transition: '300ms ease',
            backgroundColor: main,
            opacity: 1,
            // display: 'flex',
            // alignItems: 'center',
            // justifyContent: 'center',
            '&:hover': {
                backgroundColor: main,
            },
        },
        next: {
            right: 25,
            top: '30%',
        },
        prev: {
            left: 25,
            top: '30%',
        },
    });
});

export default ArrowButton;
