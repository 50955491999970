import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { StoreProvider } from 'easy-peasy';
import React from 'react';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import App from './App';
import ThemeContextProvider from './Context/ThemeContext';
import './index.css';
import store from './Stores';
import utils from './Utils';


dayjs.extend(isSameOrAfter)
dayjs.extend(customParseFormat)

export const IS_IN_IFRAME = utils.inIframe();

ReactDOM.render(
    <StoreProvider store={store}>
        <ThemeContextProvider>
            <App />
        </ThemeContextProvider>
    </StoreProvider>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
