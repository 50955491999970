import { Box, Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { FC } from 'react';
import NotFountJson from '../Assets/Lottie/404.json';
import LottieImage from '../Components/LottieImage';
import Meta from '../Components/Meta';
import Typo from '../Components/Typo';
import { useMediaQuery } from '../Hooks/useMediaQuery';
import { useTranslation } from '../Hooks/useTranslation'


const Page404: FC = () => {
    const classes = useStyles();
    const { isDeviceSm } = useMediaQuery();
    const { getTranslation } = useTranslation();
    return (
        <div>
            <Meta title={'404 Not Found'}></Meta>
            <Box className={classes.root}>
                <Box  >
                    <LottieImage dimension={{ width: isDeviceSm ? '350px' : '400px', height: '100%' }} lotteJSON={NotFountJson} />
                </Box>
                <Box mt={2}>
                    <Typo align="center" weight="medium" variant="h5">{getTranslation("pageNotFound.404")}</Typo>
                    <Typo align="center" variant="body2">{getTranslation("pageNotFound.linkBroken")}</Typo>
                </Box>
            </Box>
        </div>
    )
}



const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        maxWidth: 600,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: 20,
        margin: '0 auto',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 16,
            paddingRight: 16,
            // boxSizing: 'border-box'
        }
    }
}))

export default Page404;