import { TUser } from "../User/@types";
import { TBaseModel } from '..';
import { TPicture } from "../Picture/@types";
import { TOption } from "../App/@types";

export interface TRegistrant extends TBaseModel {
  salutation: string;
  firstName: string;
  lastName: string;
  email: string;
  eventId: string;
  status: TRegistrationStatus;
  referenceNumber: string;
  phone: string;
  agencyId: string;
  isSpeaker: boolean;
  reviewedEvent: boolean;
  network: boolean;
  joinedSessionIds: string[];
  postSessionRequestedIds: string[];
  eventCheckIn: boolean;
  level: number;
  focusAreaIds: string[];
  gdpr: Record<string, any>;
  termsAgreed?: boolean;
  extras: TExtras[];
  formExtras: TFormExtras;
  lookingFor: string[];
  canOffer: string[];
  designation: string;
  department: string;
  company: string;
  score: number;
  gdprSet: true;
  city: string;
  country?: string | null;
  eventRelation: string;
  receiveUpdates: boolean;
  agreedNDA: boolean;
  street?: string;
  eventCheckInDate: string;
  zipCode: string;
  industry?: string | null;
  user?: TUser;
  credits_remaining: number;
  credits_total: number;
  userId: string;
  _profileImage?: TPicture | null;
  xingUrl?: string;
  rank: { rank: number };
  twitterUrl?: string;
  linkedinUrl?: string;
  interestedSessionIds?: string[];
  agreedTnC: boolean;
  checkedInSessionIds?: string[];
  attend?: TRegistationAttendType;
  dietary?: string;
}

export type TRegistrationStatus = 'approved' | 'canceled' | 'registered' | 'canceled' | 'rejected' | 'checkedIn'

export const REGISTRATION_STATUS: string[] = ['rejected', 'canceled', 'registered', 'approved', 'checkedIn'];

export interface TExtras {
    key: string,
    label: string,
    value: any,
}
export interface TFormExtras {
    [key: string]: any
}

export type TRegistrationBulkTask = 'bulkApprove' | 'bulkReject' | 'changeLeadStatus' | 'sendEmail';

export type TRegistationAttendType = 'virtual' | 'physical';
export const RegistrantAttendOptions: TOption<TRegistationAttendType>[] = [
    { name: 'Virtually (only online)', value: 'virtual' },
    { name: 'Physically (on-site at the event location)', value: 'physical' },
]