import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Mustache from 'mustache';
import React from 'react';
import { useAgreementLink } from '../Features/Agreements/index';
import { useMediaQuery } from '../Hooks/useMediaQuery';
import { useTranslation } from '../Hooks/useTranslation';
import { TEvent } from '../Models/Events/@types';
import { useStoreState } from '../Stores/index';
import ContrastText from './ContrastText';

interface FooterProps {
    event?: TEvent;
}

const Footer: React.FC<FooterProps> = (props) => {
    const { event } = props;
    const classes = useStyles();
    const { agency } = useStoreState((state) => state.Agency);
    const { withLink } = useAgreementLink(event);
    const { isDeviceSm } = useMediaQuery();

    const agencyName = agency?.name || 'Venzi';
    const { getTranslation } = useTranslation();
    return (
        <div className={classes.root}>
            <ContrastText id="target" variant={isDeviceSm ? 'caption' : 'body1'}>
                {Mustache.render(`${getTranslation('footer.copyrightText')}` as string, { agencyName: agencyName, year: new Date().getFullYear() })}
            </ContrastText>
            <ContrastText variant={isDeviceSm ? 'caption' : 'body1'}>
                &nbsp;{withLink(getTranslation('footer.privacyPolicyLinkText'), 'privacy')}
            </ContrastText>
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: theme.palette.grey['200'],
            minHeight: 100,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '& a': {
                color: 'white',
                textDecoration: 'underline',
            },
        },
    })
);

export default Footer;
