import React from 'react';
import { Theme, IconButtonProps, Button, Icon } from '@material-ui/core';
import { createStyles, makeStyles, fade } from '@material-ui/core/styles';
import { useGlobalStyle } from '../Constants/globalStyle';
import clsx from 'clsx';

interface JoinButtonProps extends IconButtonProps {}

const JoinButton: React.FC<JoinButtonProps> = (props) => {
  const { children, size, className, ...iconButtonProps } = props;
  const gclasses = useGlobalStyle();
  const classes = useStyles();

  return (
    <Button className={classes.join} size={size} {...iconButtonProps}>
      JOIN
    </Button>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    join: {
      color: 'white',
      border: '1px solid white',
      width: '64px',
      height: '30px',
      borderRadius: '14px',
      margin: 'auto',
    },
  })
);

export default JoinButton;
