import { LinearProgress } from '@material-ui/core';
import React, { ReactNode, useCallback, useMemo, useState } from 'react';
import CurrentSessionCard from '../../Features/FullScreenSession/CurrentSessionCard';
import Feedback from '../../Features/FullScreenSession/Feedback';
import SessionQuestions from '../../Features/FullScreenSession/SessionQuestions';
import SessionRequest from '../../Features/FullScreenSession/SessionRequest';
import { TFeedBack, TSession, TSessionQuestions, TSessionRequest } from '../../Models/Agenda/@types';
import { TEvent } from '../../Models/Events/@types';
import { useStoreState } from '../../Stores';
import { useSessionFeedback } from './useSessionFeedback';

export const useFullScreenSession = (currentSession: TSession | undefined, sessionId: string) => {
    const { eventDetails = {} as TEvent } = useStoreState(({ Events: { eventDetails } }) => ({ eventDetails }));
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [popperName, setPopperName] = useState<string>('');
    const { handleFeedbackSubmit, feedback, handleRequestSubmit, sessionRequest, getSessionFeedback, getSessionRequest, loader, sessionQuestions, getSessionQuestions, handleSessionQuestionSubmit } = useSessionFeedback(sessionId);

    const handleSessionQuestion = async (values: Partial<TSessionQuestions>) => {
        await handleSessionQuestionSubmit(values);
        onPopperClose();
    };
    const handleFeedback = async (values: Partial<TFeedBack>) => {
        await handleFeedbackSubmit(values);
        onPopperClose();
    };
    const handleRequest = async (values: TSessionRequest) => {
        await handleRequestSubmit(values);
        onPopperClose();
    };
    const popperNodes: Record<string, ReactNode | null> = useMemo(
        () => ({
            '': null,
            session: <CurrentSessionCard session={currentSession} focusAreas={(eventDetails.focusAreas || []).filter((i) => (currentSession?.focusAreaIds || []).indexOf(i.id) > -1).map((i) => i.name)} timeZone={eventDetails?.timeZone} />,
            feedback: loader ? <LinearProgress /> : <Feedback onSubmit={handleFeedback} feedback={feedback} />,
            request: loader ? <LinearProgress /> : <SessionRequest onSubmit={handleRequest} sessionRequest={sessionRequest} />,
            questions: loader ? <LinearProgress /> : <SessionQuestions onSubmit={handleSessionQuestion} questions={sessionQuestions} />,
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [loader, currentSession?.focusAreaIds?.length, eventDetails?.timeZone, feedback, sessionRequest]
    );

    const makeRequest = useCallback(async (name: 'session' | 'feedback' | 'request' | 'questions') => {
        name === 'feedback' && (await getSessionFeedback());
        name === 'request' && (await getSessionRequest());
        name === 'questions' && (await getSessionQuestions());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const onButtonClick = useCallback((event: React.MouseEvent<HTMLElement>, name: 'session' | 'feedback' | 'request' | 'questions') => {
        if (anchorEl) {
            if (popperName === name) {
                setPopperName('');
                setAnchorEl(null);
            } else {
                setAnchorEl(event.currentTarget);
                setPopperName(name);
                makeRequest(name);
            }
        } else {
            setPopperName(name);
            setAnchorEl(event.currentTarget);
            makeRequest(name);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onPopperClose = useCallback(() => {
        setAnchorEl(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        popperNodes,
        onButtonClick,
        popperName,
        anchorEl,
        onPopperClose,
    };
};
