import { action, Action, Thunk, thunk } from "easy-peasy";
import { Injections, TRootStore } from "..";
import { TFilter } from "../../Models";
import AgencyAssetsModel from "../../Models/AgencyAssets";
import { TAgencyAsset } from "../../Models/AgencyAssets/@types";
import { IAnalyticsCustomConfig, setAnalytics } from "analytics.config";

export interface TAgencyAssetState {
    asset?: TAgencyAsset;
    assetId?: string;
    setAsset: Action<TAgencyAssetState, TAgencyAsset | undefined>;
    loadAsset: Thunk<TAgencyAssetState, { id: string; filter?: TFilter }, Injections, TRootStore, Promise<TAgencyAsset>>;
}
const state: TAgencyAssetState = {
    setAsset: action((state, payload) => {
        state.asset = payload;
        state.assetId = payload?.id;
    }),
    loadAsset: thunk(async (_a, params, { getState }) => {
        const res = await AgencyAssetsModel.get_AgencyAssets_id<TAgencyAsset>(params.id, params.filter);
        const state = getState();
        const { tracking } = res.data;
        if (tracking) {
            let trackingConfig: IAnalyticsCustomConfig[] = [];
            if (tracking.linkedIn?.campaignId)
                trackingConfig.push({
                    appId: tracking.linkedIn?.campaignId ?? "",
                    type: "linkedIn",
                });
            if (tracking.googleAnalytics?.campaignId)
                trackingConfig.push({
                    appId: tracking.googleAnalytics?.campaignId ?? "",
                    type: "google",
                });
            setAnalytics(trackingConfig);
        }
        state.asset = res.data;
        state.assetId = res.data.id;
        return res.data;
    }),
};
export default state;
