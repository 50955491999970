import { Box } from '@material-ui/core'
import React from 'react'
import { TSpeaker } from '../../Models/EventPerson/@types'
import SpeakerChip, { SpeakerChipProps } from './SpeakerChip'

interface SpeakerChiplistProps extends Omit<SpeakerChipProps, 'speaker'> {
    speakers: TSpeaker[]
}

const SpeakerChiplist: React.FC<SpeakerChiplistProps> = (props) => {
    const { speakers, ...speakerChipProps } = props;
    return (
        <>
            {speakers.map((sp, i) => (
                <Box mr={1} mb={1} key={i}>
                    <SpeakerChip speaker={sp} {...speakerChipProps} />
                </Box>
            ))}
        </>
    )
}



export default SpeakerChiplist