import AgenciesBase from "../../BaseModels/Agencies";
import { request } from "../../Utils";
import { TEvent, TFocusArea, TTimeZone } from "../Events/@types";
import { TExternalEvent } from "../ExternalEvent/@types";
import { TAgency, TAgencyContent } from "./@types";
import dayjs from 'dayjs';
import utilities from "../../Resources/Utils";
import { TZine } from "../Zine/@types";
import { TAgencyAsset } from "../AgencyAssets/@types";
import { TSpeaker } from "Models/EventPerson/@types";



class AgencyModel extends AgenciesBase {
  /**
   * Fetch agency details
   * @param id
   * @returns {TAgency}
   */
  static getAgency = (id: string) =>
    request<TAgency>({
      url: `agencies/${id}`,
      params: {
        filter: {
          include: ['tags'],
        },
      },
    });

  static getFocusAreas = (focusAreas: TFocusArea[]) => {
    return (focusAreas || []).map((i) => i.name).join(', ') || '';
  };
  static getLanguagesString(content: TAgencyContent, resolver: (val: string) => string) {
    // if(AgencyModel.isExternalEvent(content)) {
    return content.languages?.map((lang) => resolver(lang)).join(', ') || '';
    // }
    // else {
    // return
    // }
  }

  static getContentType(content: TAgencyContent) {
    if (content.doc_type === 'External' && AgencyModel.isExternalEvent(content)) return content.type;
    return 'Event';
  }

  static isExternalEvent(item: Omit<TAgencyContent, 'doc_type'>): item is TExternalEvent {
    return (item as TEvent)._sessions === undefined;
  }
  static isSpeaker(item: any): item is TSpeaker {
    return !!(item as TSpeaker).availability;
  }
  static isZine(item: Omit<TAgencyContent, 'doc_type'>): item is TZine {
    return !!(item as TZine).uploadStatus || (item as TZine).doc_type === 'Zine';
  }

  static isAsset(item: Omit<TAgencyContent, 'doc_type'>): item is TAgencyAsset {
    return !!(item as TAgencyAsset).mimeType;
  }

  static getCardDateString(date: string, timeZone?: TTimeZone) {
    return dayjs(date).format('ddd, MMM DD, hh:mm a') + ` ${utilities.getAbbreviation(timeZone)}`;
  }
}

export default AgencyModel;