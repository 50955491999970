
import axios, {AxiosRequestConfig} from 'axios';
// THIS IS A GENERATED FILE; PLEASE DO NOT MAKE CHANGES HERE

class EntityRegistrationsBase {

/** 
 * 
 */ 
    static post_EntityRegistrations_new<T = any>(entityRegistration: any,accessUserId?: any,config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/EntityRegistrations/new`,
            method: 'post',
            
            data: {
                ...entityRegistration, accessUserId
            },
        })
    }

/** 
 * 
 */ 
    static get_EntityRegistrations_search<T = any>(entityType: any,term?: any,filter?: any,accessUserId?: any,config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/EntityRegistrations/search`,
            method: 'get',
            params: {
                entityType, term, filter, accessUserId
            },
            
        })
    }

/** 
 * 
 */ 
    static post_EntityRegistrations_id_gdpr<T = any>(gdpr: any,id: string,accessUserId?: any,config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/EntityRegistrations/${id}/gdpr`,
            method: 'post',
            
            data: {
                gdpr, accessUserId
            },
        })
    }

/** 
 * get my registration
 */ 
    static get_EntityRegistrations_my_registration<T = any>(entityType: any,entityId: any,accessUserId?: any,config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/EntityRegistrations/my-registration`,
            method: 'get',
            params: {
                entityType, entityId, accessUserId
            },
            
        })
    }

}

export default EntityRegistrationsBase;
    