import { Box } from "@material-ui/core";
import { createStyles, fade, makeStyles, Theme } from "@material-ui/core/styles";
import React, { FC } from "react";
import Typo from "../../Components/Typo";
import { TEntity } from "../../Models/EntityRegistrations/@types";
import SignInCard from "./SignInCard";
import DetailInfoCard from "./DetailInfoCard";

export interface EntityPreviewProps {
    entity: TEntity;
}

const EntityPreview: FC<EntityPreviewProps> = (props) => {
    const classes = useStyles();
    const { entity } = props;
    return (
        <Box className={classes.root}>
            {entity._bannerImage?.url ? <img style={{ objectFit: "cover" }} width="100%" src={entity._bannerImage?.url} alt={`${entity.name} banner`} /> : null}
            <Box className={classes.container}>
                <Box id={"image-container"} flex={1} display="flex" flexDirection="column">
                    {entity._previewImages?.map((item, index) => {
                        return (
                            <Box mb={2} key={index}>
                                <img alt="previews-images" className={classes.imgContainer} width={"100%"} src={item.url} />
                            </Box>
                        );
                    })}
                </Box>
                <Box className={classes.info} display="flex" flexDirection="column">
                    <Box pb={3} mb={4} className={classes.border}>
                        <Typo variant="h6" weight="bold" color="secondary">
                            {entity.name}
                        </Typo>
                    </Box>
                    {entity.description && (
                        <Box pb={3} mb={4} className={classes.border}>
                            <Typo variant="body1">{entity.description}</Typo>
                        </Box>
                    )}
                    {entity.isDetailResource ? <DetailInfoCard entity={entity} /> : <SignInCard entity={entity} />}
                </Box>
            </Box>
        </Box>
    );
};

const useStyles = makeStyles<Theme>((theme) => {
    return createStyles({
        root: {
            width: "100%",
            paddingBottom: 100,
        },
        imgContainer: {},
        border: {
            borderBottom: `1px solid ${fade(theme.palette.grey["600"], 0.2)}`,
        },
        container: {
            display: "flex",
            width: 1200,
            margin: theme.spacing("50px", "auto"),
            alignItems: "stretch",
            gap: 50,
            [theme.breakpoints.down("sm")]: {
                width: "100%",
                margin: theme.spacing(6, 0),
                padding: theme.spacing(0, 2),
                flexDirection: "column",
                alignItems: "center",
                boxSizing: "border-box",
            },
        },
        info: {
            width: 420,
            display: "flex",
            flexDirection: "column",
            [theme.breakpoints.down("sm")]: {
                width: "100%",
            },
        },
    });
});

export default EntityPreview;
