import { darken, Theme } from '@material-ui/core/styles';

export const changePallete = (type: 'primary' | 'secondary', theme: Theme, newPalette: Partial<Theme['palette']['primary']>): Theme => {
  return {
    ...theme,
    palette: {
      ...theme.palette,
      [type]: {
        ...theme.palette[type],
        ...newPalette,
      },
    },
  };
};

export const getGradientFromTheme = (theme: Theme) => {
  const primaryColor = theme.palette.primary.main || '#0073E7';
  const secondaryColor = theme.palette.secondary.main || '#7426AD';
  return `transparent linear-gradient(320deg, ${primaryColor} 0%, ${secondaryColor} 100%) 0% 0% no-repeat padding-box`;
};
export const getRevGradientFromTheme = (theme: Theme) => {
  const primaryColor = theme.palette.primary.main || '#0073E7';
  const secondaryColor = theme.palette.secondary.main || '#7426AD';
  return `transparent linear-gradient(320deg, ${secondaryColor}  0%, ${primaryColor} 100%) 0% 0% no-repeat padding-box`;
};

export const overrrideComponentStylingFromTheme = (theme: Theme): Theme => {
  return {
    ...theme,
    overrides: {
      ...theme.overrides,
      MuiButton: {
        ...theme.overrides?.MuiButton,
        root: {
          ...theme.overrides?.MuiButton?.root,
          textTransform: 'unset',
        },
        containedPrimary: {
          ...theme.overrides?.MuiButton?.containedPrimary,
          '&:hover': {
            backgroundColor: darken(theme.palette.primary.main, 0.3),
          },
        },
        containedSecondary: {
          ...theme.overrides?.MuiButton?.containedSecondary,
          '&:hover': {
            backgroundColor: darken(theme.palette.secondary.main, 0.3),
          },
        },
      },
    },
  };
};
