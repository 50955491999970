import { Box, Button, Dialog } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Typo from '../Components/Typo';
import { useMediaQuery } from '../Hooks/useMediaQuery';

export interface DomainNoAccessDialogProps {
    redirectUrl?: string;
    titleText?: string;
}

const DomainNoAccessDialog: FC<DomainNoAccessDialogProps> = ({ titleText, redirectUrl }) => {
    const classes = useStyles();
    const [open, setOpen] = useState<boolean>(true);
    const { push } = useHistory();
    const onClose = () => {
        setOpen(false);
        push(redirectUrl || '/');
    };
    const { isDeviceSm } = useMediaQuery();
    return (
        <Dialog onClose={onClose} open={open} PaperProps={{ className: classes.paper }} fullScreen={isDeviceSm} disableBackdropClick scroll="body">
            <Box boxSizing="border-box" flexDirection={isDeviceSm ? 'column' : 'row'} alignItems="center" width={isDeviceSm ? '100%' : 'auto'} p={6} height={isDeviceSm ? '100%' : undefined} mx="auto" position="relative">
                <Typo color={'textPrimary'} gutterBottom variant="h6" weight="medium">
                    {titleText || `You do not have access to this.`}
                </Typo>
                <Box width={2} />
                <Button size="medium" fullWidth onClick={onClose} color="primary" variant="contained">
                    Go to Homepage
                </Button>
            </Box>
        </Dialog>
    );
};

const useStyles = makeStyles<Theme>((theme) => {
    return createStyles({
        paper: {
            boxShadow: 'none',
            [theme.breakpoints.down('sm')]: {
                height: '100%',
                // height: `calc(100vh - ${HEADER_HEIGHT_SMALL}px)`
            },
        },
    });
});

export default DomainNoAccessDialog;
