import { Link as MuiLink, Typography } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from '../../Hooks/useTranslation';
import { TEvent } from '../../Models/Events/@types';
import { useNavMenu } from '../AppHeader/useNavMenu';
interface AgreementsProps {
    event?: TEvent;
}

const Agreements: React.FC<AgreementsProps> = (props) => {
    const { getTranslation } = useTranslation();
    const { event } = props;
    const { withLink } = useAgreementLink(event);
    if (!event) return <div />;

    return (
        <Typography variant="body2">
            {getTranslation('signUpForm.userAgreementText')} {withLink(getTranslation('signUpForm.termsAndConditionText'), 'tnc')}
            &nbsp;and&nbsp;
            {withLink(getTranslation('signUpForm.privacyPolicyText'), 'privacy')}
        </Typography>
    );
};

export const useAgreementLink = (event?: TEvent) => {
    const { BASE_URL } = useNavMenu();

    const withLink = (content: string, type: 'tnc' | 'privacy') => {
        switch (type) {
            case 'privacy': {
                if (event && event.privacyPolicy && event.privacyPolicy.type === 'url')
                    return (
                        <MuiLink href={event.privacyPolicy.value} target="_blank">
                            {content}
                        </MuiLink>
                    );
                else
                    return (
                        <Link rel="noopener" to={`${BASE_URL}/privacy-policy`} target="_blank">
                            {content}
                        </Link>
                    );
            }
            case 'tnc': {
                if (event && event.termsConditions && event.termsConditions.type === 'url')
                    return (
                        <MuiLink href={event.termsConditions.value} target="_blank">
                            {content}
                        </MuiLink>
                    );
                else
                    return (
                        <Link rel="noopener" to={`${BASE_URL}/terms`} target="_blank">
                            {content}
                        </Link>
                    );
            }
        }
    };
    return { withLink };
};

export default Agreements;
