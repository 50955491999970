import { Box, Container } from "@material-ui/core";
import { createStyles, Theme } from "@material-ui/core/styles";
import { Pagination } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { IS_IN_IFRAME } from "../..";
import FullScreenLoader from "../../Components/FullScreenLoader";
import Typo from "../../Components/Typo";
import AgencyContentGridList from "../../Features/Agency/AgencyContentGridList";
import StrategicPartnerModel from "../../Models/StrategicPartner";
import { TStrategicPartner } from "../../Models/StrategicPartner/@types";
import { useStoreActions, useStoreState } from "../../Stores";
import { AGENCY_CONTENT_FETCH_SIZE } from "../../Stores/Agency";
import Banner from "./Banner";
import CalendarView from "./CalendarView";
import FilterBar, { FilterBarProps } from "./Filter/FilterBar";
import RecommendFilter from "./Filter/RecommendFilter";
import useInit from "./useInit";
import useSearchQuery from "./useSearchQuery";
import Meta from "Components/Meta";

interface IProps {
    isStrategicPartnerView?: boolean;
}

const Home: React.FC<IProps> = (props) => {
    const { isStrategicPartnerView = false } = props;
    const { setPageNumber, setStrategicPartner } = useStoreActions(({ Agency: { setPageNumber, setStrategicPartner } }) => ({ setPageNumber, setStrategicPartner }));
    const { strategicPartners, user, activeTab } = useStoreState(({ Agency: { strategicPartners, activeTab }, User: { user } }) => ({ user, strategicPartners, activeTab }));
    const strategicPartnerSlug = useRef<string>();
    const isStrategicPartnerUser = useRef<boolean>(false);
    const findIsUserFromStrategicPartner = async (strategicPartners: TStrategicPartner[], email?: string) => {
        if (!email) {
            strategicPartnerSlug.current = undefined;
            isStrategicPartnerUser.current = false;
            setStrategicPartner(undefined);
            return;
        }
        const res = await StrategicPartnerModel.isUserFromStrategicPartner(email, strategicPartners);
        strategicPartnerSlug.current = res.strategicPartnerSlug;
        isStrategicPartnerUser.current = res.isStrategicPartnerUser;
    };

    useEffect(() => {
        if (!isStrategicPartnerView) findIsUserFromStrategicPartner(strategicPartners, user?.email);
    }, [user?.email, isStrategicPartnerView, strategicPartners.length]);

    const showStrategicPartnerContent = isStrategicPartnerView || isStrategicPartnerUser.current;

    useInit(showStrategicPartnerContent, strategicPartnerSlug.current);
    useSearchQuery();
    const classes = useStyles();
    const { contentList, isFetching, pageNumber, contentCount, showRecommended } = useStoreState(({ Agency: { contentList, agency, isFetching, pageNumber, contentCount, showRecommended } }) => ({
        isFetching,
        contentList,
        agency,
        pageNumber,
        contentCount,
        showRecommended,
    }));
    const [listingType, setListingType] = useState<FilterBarProps["listingType"]>("grid");

    const pagesCount = useMemo(() => Math.ceil(contentCount / AGENCY_CONTENT_FETCH_SIZE), [contentCount]);

    useEffect(() => {
        /**
         * @author Adnan Husain
         * Calendar view is only for events.
         * Hence user switches from evetns to resource tab . and calendar view is active
         * so we reset it to grid view.
         */
        if (listingType === "calendar" && activeTab === "Resources") setListingType("grid");
    }, [activeTab, listingType]);

    const shouldShowPagination = useMemo(() => {
        if (isFetching || !contentCount) return false;
        return contentCount > AGENCY_CONTENT_FETCH_SIZE;
    }, [isFetching, contentCount]);

    const getView = () => {
        if (isFetching)
            return (
                <Box height="20vh">
                    <FullScreenLoader />
                </Box>
            );
        if (showRecommended && !contentList.length)
            return (
                <Typo align="center" weight="bold">
                    {EMPTY_STATE_TEXT}
                </Typo>
            );
        else if (!contentList.length)
            return (
                <Typo align="center" weight="bold">
                    {EMPTY_STATE_TEXT_2}
                </Typo>
            );

        if (listingType === "calendar") return <CalendarView />;
        return (
            <>
                <AgencyContentGridList loading={isFetching} variant={listingType === "grid" ? "grid" : "list"} items={contentList} />
                {shouldShowPagination ? (
                    <Box mt={2}>
                        <Pagination classes={{ ul: classes.pagination }} count={pagesCount} page={pageNumber} onChange={(_, page) => setPageNumber(page)} />
                    </Box>
                ) : null}
            </>
        );
    };

    return (
        <>
            <Meta />
            <Banner />
            <FilterBar onListingTypeChange={setListingType} listingType={listingType} isStrategicPartnerView={showStrategicPartnerContent} />
            <Container className={classes.listContainer}>
                {user || (showStrategicPartnerContent && IS_IN_IFRAME) ? <RecommendFilter /> : null}
                {getView()}
                {/* {listingType === 'calendar' ? <CalendarView /> : (
                    <>
                        <AgencyContentGridList loading={isFetching} variant={listingType === 'grid' ? 'grid' : 'list'} items={contentList} />
                        {isFetching || !contentList.length ? null : <Box mt={2}>
                            <Pagination classes={{ ul: classes.pagination }} count={pagesCount} page={pageNumber} onChange={(_, page) => setPageNumber(page)} />
                        </Box>}
                    </>
                )} */}
            </Container>
        </>
    );
};

const EMPTY_STATE_TEXT = "You do not have any recommendations. Please select 'Show All' to see all upcoming events.";
const EMPTY_STATE_TEXT_2 = "No results found. Please reset filters.";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            // minHeight: '100vh',
            overflowX: "hidden",
        },
        listContainer: {
            padding: theme.spacing(10, 0),

            [theme.breakpoints.up("md")]: {
                maxWidth: theme.breakpoints.width("md") - 32,
            },
            [theme.breakpoints.up("lg")]: {
                maxWidth: `calc(90vw - 32px)`,
            },
            [theme.breakpoints.down("sm")]: {
                width: "100vw",
                paddingLeft: 16,
                paddingRight: 16,
            },
        },
        pagination: {
            justifyContent: "center",
        },
    })
);

export default Home;
