import { Box, Typography } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import { FormikValues } from 'formik';
import React, { FC } from 'react';
import { getFieldError, IFieldProps } from 'react-forms';
import Rating from '../../Components/Rating';
import { useTranslation } from '../../Hooks/useTranslation';

export interface SessionRatingProps extends IFieldProps {}

const SessionRating: FC<SessionRatingProps> = (props) => {
    const classes = useStyles();
    const { formikProps = {} as FormikValues } = props;
    const { getTranslation } = useTranslation();
    const value = formikProps.values['rating'];
    const onChange = (value: number) => {
        formikProps.setFieldValue('rating', value);
    };
    const error = getFieldError('rating', formikProps);
    return (
        <Box mb={2} display="flex" flexDirection="column" alignItems="center">
            <Rating description={getTranslation('sessionFeedbackForm.formTitle')} onChange={onChange} rating={value - 1} />
            {error && <Typography className={classes.error}>{error}</Typography>}
        </Box>
    );
};

const useStyles = makeStyles<Theme>((theme) => {
    return createStyles({
        error: {
            color: '#B71840',
            fontSize: 12,
            textAlign: 'left',
            fontFamily: 'lato',
            fontWeight: 'bold',
            lineHeight: 1.66,
            marginTop: 10,
        },
    });
});

export default SessionRating;
