import { AppBar, Box, Theme, Toolbar } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import React, { useContext } from 'react'
import { HEADER_HEIGHT } from '../../Components'
import Typo from '../../Components/Typo'
import { BannerContext } from '../../Context/BannerContext'
import { TBannerPosition } from '../../Context/BannerContext/@types'
import { useMediaQuery } from '../../Hooks/useMediaQuery'
import { useNavMenu } from '../AppHeader/useNavMenu'

interface MainBannerProps { }

const MainBanner: React.FC<MainBannerProps> = (props) => {
    const { content, hidden } = useContext(BannerContext);

    const { isDeviceSm } = useMediaQuery()
    const classes = useStyles()
    const { pathname } = useNavMenu()


    const getContent = (pos: TBannerPosition) => {
        if (typeof content[pos] === 'string')
            return <Typo align={pos === 'center' ? "center" : 'inherit'} variant={ "subtitle2"} weight='medium' >
                {content[pos]}
            </Typo>
        else
            return content[pos]
    }

    if (hidden) return <div />

    return (
        // <WithTransition direction="bottom" delay={1000}>
        <AppBar elevation={1} position="sticky" className={clsx(classes.root, { [classes.absolute]: pathname === '/' })}>
            <Toolbar className={classes.toolbar}>
                <Box display="flex" flex={1} flexDirection={isDeviceSm ? 'column' : 'row'} alignItems={isDeviceSm ? 'flex-end' : 'center'} pr={isDeviceSm ? 1 : 0} pl={isDeviceSm ? 0 : 3}>
                    {content['left'] && <Box width={isDeviceSm ? '100%' : undefined} flex={1}>{getContent('left')}</Box>}
                    {content['center'] && <Box flex={2} width={isDeviceSm ? '100%' : undefined} justifyContent={isDeviceSm ? undefined : "center"}>{getContent('center')}</Box>}
                    {content['right'] && <Box flex={2} width={isDeviceSm ? '100%' : undefined} display="flex" justifyContent={isDeviceSm ? undefined : "flex-end"}>{getContent('right')}</Box>}
                </Box>
            </Toolbar>
        </AppBar>
        // </WithTransition>
    )
}




export const BANNER_LARGE = 50;
export const BANNER_SMALL = 50;
const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        display: 'flex',
        padding: '0px',
        boxSizing: 'border-box',
        zIndex: 100,
        width: '100%',
        backgroundColor: '#fff',
        [theme.breakpoints.down('sm')]: {
            padding: '16px 16px',
            height: 'unset'
        },
        top:  HEADER_HEIGHT ,
        [theme.breakpoints.down('sm')]: {
            top: HEADER_HEIGHT,
        },
        boxShadow: '0px 1px 2px #00000029',
        transition: '200ms ease-in',
    },
    toolbar: {
        height: BANNER_SMALL,
        minHeight: BANNER_SMALL,
        [theme.breakpoints.down('sm')]: {
            height: 'auto',
            paddingTop: 8,
            paddingBottom: 8,
            paddingRight: 4
        },
    },
    absolute: {
        position: 'absolute',
    },
    registrationPage: {
        backgroundColor: theme.palette.primary.dark,

    },
    typo: {
        color: theme.palette.grey[900]
    }
}))

export default MainBanner