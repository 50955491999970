import React, { FC } from 'react'
import Box, { BoxProps } from '@material-ui/core/Box';

export interface DotProps extends Pick<BoxProps, 'my' | 'mx' | 'm' | 'margin' | 'mt' | 'mb' | 'ml' | 'mr'> {
    color?: string,
    size?: number,
    borderRadius?: any
}

const Dot: FC<DotProps> = (props) => {

    const { color = '#505050', size = 5, borderRadius = '100%', ...boxProps } = props;
    return (
        <Box
            {...boxProps}
            style={{
                backgroundColor: color,
                height: size,
                width: size,
                borderRadius: borderRadius
            }}
        />
    )
}


export default Dot;