import { Box, Tab, Tabs } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { FC, useCallback, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Typo from '../../Components/Typo';
import { useStoreState } from '../../Stores';
import Query from '../../Stores/Agency/Query';
export interface ResourceTabsProps { }

const HomeTabs: FC<ResourceTabsProps> = (props) => {
    const classes = useStyles();
    const { appliedFilter, resourceFacets, activeTab } = useStoreState(({ Agency: { appliedFilter, resourceFacets, activeTab, } }) => ({ activeTab, appliedFilter, resourceFacets }))
    const { pathname } = useLocation();
    const getUrl = useCallback((tab: typeof activeTab = 'Calendar') => {
        let newPath = '';
        if (tab === 'Calendar') {
            newPath = pathname.replace('resources', 'calendar')
        }
        if (tab === 'Resources') {
            newPath = pathname.replace('calendar', 'resources')
        }
        const query = Query.getQueryFromObject({
            regions: appliedFilter.regions,
            focusAreaIds: [],
            languages: [],
            type: []
        });
        if (query)
            return `${newPath}?filter=${query}`;
        else
            return newPath
    }, [activeTab, appliedFilter.regions, pathname])
    const totalResource = useMemo(() => resourceFacets?.total || 0,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [resourceFacets.total])
    const value = useMemo(() => {
        return activeTab === 'Resources' ? (totalResource ? 1 : false) : 0;
    }, [activeTab, totalResource]);
    return (
        <Box width={180}>
            <Tabs value={value} classes={{ flexContainer: classes.flexContainer, indicator: classes.indicator }} aria-label="simple tabs example">
                <Tab color='primary' label={<Typo color={value === 0 ? 'primary' : 'inherit'} weight='bold' variant='body2'>Calendar</Typo>} component={Link} to={getUrl('Calendar')} />
                {totalResource && <Tab color='primary' label={<Typo color={value === 1 ? 'primary' : 'inherit'} weight='bold' variant='body2'>Resources</Typo>} component={Link} to={getUrl('Resources')} />}
            </Tabs>
        </Box>
    )
}

const useStyles = makeStyles<Theme>((theme) => {
    return (createStyles({
        flexContainer: {
            justifyContent: 'space-between'
        },
        indicator: {
            backgroundColor: theme.palette.primary['main']
        }
    }))
})

export default HomeTabs