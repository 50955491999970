import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, MenuItem, Select, SelectProps } from '@material-ui/core';
import clsx from 'clsx';


export interface SingleSelectProps extends SelectProps {
    options: { label: string, value: any }[]
}

const SingleSelect: FC<SingleSelectProps> = (props) => {
    const classes = useStyles({});
    const { options, ...selectProps } = props;
    return (
        <Select
            {...selectProps}
            inputProps={{ className: classes.menuItem, ...selectProps?.inputProps }}
            className={clsx(classes.select, selectProps.className)}
        >
            {/* <MenuItem value={'all'} className={classes.menuItem}>
            </MenuItem> */}
            {
                (options).map((item, index) => {
                    return <MenuItem key={index} value={item.value} className={classes.menuItem}>{item.label}</MenuItem>
                })
            }
        </Select>
    )
}

const useStyles = makeStyles<Theme, any>((theme) => ({
    select: {
        // maxWidth: '50%',
        // color: '#0073E7',
        color: theme.palette.primary.main,
        //flex: 1,
        marginLeft: '24px',
        '&:first-child': {
            marginLeft: 8,
            [theme.breakpoints.down('sm')]: {
                marginLeft: '0px !important'
            }
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '0px !important'
        }
    },
    menuItem: {
        fontWeight: 500,
        fontSize: 14,
        textAlign: 'center'
    }
}));

export default SingleSelect;