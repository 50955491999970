import { TSessionGroup } from "./@types";
import moment from 'moment-timezone'
import { TSession } from "../Agenda/@types";
const SessionGroupModel = {
    updateSingleSession: (sessionGroup: TSessionGroup[], session: TSession): TSessionGroup[] => {
        let index = sessionGroup.findIndex(i => i.day === moment(session.startTime).startOf('day').format());
        if (index === -1)
            return sessionGroup;
        else {
            sessionGroup[index].sessions = sessionGroup[index].sessions.map(i => i.id === session.id ? ({ ...session }) : i);
            return sessionGroup;
        }
    },
    removeSingleSession: (sessionGroup: TSessionGroup[], session: TSession): TSessionGroup[] => {
        let index = sessionGroup.findIndex(i => i.day === moment(session.startTime).startOf('day').format());
        if (index === -1)
            return sessionGroup;
        else {
            sessionGroup[index].sessions = sessionGroup[index].sessions.filter(i => i.id !== session.id);
            return sessionGroup;
        }
    },
    addSingleSession: (sessionGroup: TSessionGroup[], session: TSession): TSessionGroup[] => {
        let index = sessionGroup.findIndex(i => i.day === moment(session.startTime).startOf('day').format());
        if (index === -1)
            return [...sessionGroup, { day: moment(session.startTime).startOf('day').format(), sessions: [session] }];
        else {
            sessionGroup[index].sessions = [...sessionGroup[index].sessions, session];
            return sessionGroup;
        }
    },
    groupSessionByDay: (sessions: TSession[]) => {
        let group: TSessionGroup[] = []
        sessions.forEach((elem) => {
            let day = moment(elem.startTime).startOf('day').format();
            let index = group.findIndex(i => i.day === day);
            if (index === -1) {
                group.push({ day, sessions: [elem] })
            } else {
                group[index].sessions.push(elem);
            }
        })
        return group;
    },
    sortGroupedSessions:(group:TSessionGroup[]) =>{
        const currentDay = moment().startOf('day').format();
        let newGroup = [...group];
        group.forEach((item) => {
            const diff = moment(item.day).diff(moment(currentDay), 'd');
            if(diff < 0)
            {
                
                const poppedElem = newGroup.shift();
                if(poppedElem)
                newGroup.push(poppedElem);
            }
        })
        return newGroup;
    },
    getFilteredGroup(group: TSessionGroup[], speakerIds: string[], focusAreaIds: string[], day: string) {
        const newAgendas: TSessionGroup[] = [];
        group.forEach((item, index) => {
            const session: TSession[] = []
            item.sessions.forEach(element => {
                let speakerFlag = element.speakers.filter(i => -1 !== speakerIds.indexOf(i.id))
                let focusAreaFlag = element.focusAreaIds?.filter(i => -1 !== focusAreaIds.indexOf(i));
                let dayFlag = item.day === day;
                if ((focusAreaFlag.length > 0 || focusAreaIds.length === 0) && (speakerFlag.length > 0 || speakerIds.length === 0) && (dayFlag || day === 'all')) {
                    session.push(element)
                }
            });
            if (session.length > 0)
                newAgendas.push({ ...item, sessions: session })
        })
        return newAgendas;
    }
}
export default SessionGroupModel;