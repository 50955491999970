import { Box, Button } from '@material-ui/core';
import dayjs from 'dayjs';
import EventModel from 'Models/Events';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Typo from '../../Components/Typo';
import { useNavMenu } from '../../Features/AppHeader/useNavMenu';
import Permissions from '../../Features/Permissions';
import { useTranslation } from '../../Hooks/useTranslation';
import { useStoreState } from '../../Stores/index';
import { DialogContext } from '../../Utils/AppDialogProvider';
import utils from '../../Utils/index';

export type useFeedbackRemiderConfig = {};

export const useFeedbackRemider = (config?: useFeedbackRemiderConfig) => {
    const { showDialog, hideDialog } = useContext(DialogContext);
    const { pathname } = useLocation();
    // const { eventStatus, eventDetails } = useStoreState(state => state.Events)
    const { eventDetails, eventStatus, allSessions, user } = useStoreState(
        ({ Events: { eventDetails, eventStatus, allSessions }, User: { user } }) => ({ eventDetails, eventStatus, allSessions, user })
    );
    const { registrant } = useStoreState((state) => state.Registrant);
    const { BASE_URL } = useNavMenu();
    const history = useHistory();

    const getKey = () => {
        if (!eventDetails || !registrant) return '';
        const key = `eventFeedback-${eventDetails.id}-${registrant.id}`;
        return key;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const getFeedbackRecord = () => {
        return utils.getFeedbackRecord(getKey());
    };
    const disableFeedbackDialog = useMemo(() => {
        if (pathname.includes('/fullscreen') || pathname.includes('/feedback')) return true;
        return false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);
    const shouldShowDialog = useCallback(() => {
        const record = getFeedbackRecord();
        if (disableFeedbackDialog) return false;
        if (!record) return true;
        else {
            const prevShownDate = record;

            if (dayjs(prevShownDate).diff(new Date(), 'day') < 0) return true;
        }
        return false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [disableFeedbackDialog, getFeedbackRecord]);

    const handleAccept = () => {
        hideDialog();
        setFeedbackDate();
        history.push(`${BASE_URL}/feedback`);
    };

    const handleCancel = () => {
        hideDialog();
        setFeedbackDate();
    };

    const setFeedbackDate = () => {
        const key = getKey();
        utils.setFeedbackRecord(key, dayjs().toISOString());
    };
    const { getTranslation } = useTranslation();

    useEffect(() => {
        if (allSessions.length === 0) return;
        if (!registrant?.id || registrant.reviewedEvent) return;
        if (!user?.id) return;
        const lastSession = allSessions[allSessions.length - 1];
        if (eventStatus === 'Past' && lastSession.status === 'closed' && Permissions.eventFeedback(registrant)) {
            if (!shouldShowDialog()) return;
            showDialog(<Typo>{getTranslation('mainBanner.askForFeedback')}</Typo>, {
              headerProps: {
                isCloseButton: false,
              },
              isActionCloseButton: false,
              PaperProps: {
                style: {
                  width: 400,
                  padding: 16,
                },
              },
              title: `${EventModel.getEventLabel(true)} feedback`,
              actionsChildren: <ActionButton onAccept={handleAccept} onCancel={handleCancel} />,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventStatus, registrant?.id, registrant?.reviewedEvent, allSessions, user?.id]);
};

const ActionButton: React.FC<{ onAccept: () => void; onCancel: () => void }> = (props) => {
    const { getTranslation } = useTranslation();

    return (
        <Box mr={2} display="flex" alignItems="center">
            <Box mr={1}>
                <Button color="primary" onClick={props.onCancel}>
                    {getTranslation('eventFeedbackForm.laterButtonLabel')}
                </Button>
            </Box>
            <Button variant="contained" color="primary" onClick={props.onAccept}>
                {getTranslation('eventFeedbackForm.giveFeedbackButtonLabel')}
            </Button>
        </Box>
    );
};
