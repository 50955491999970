


export interface BannerContextProviderValue extends TBannerConfig {
    setBanner: (config: Partial<TBannerConfig>) => void
    hideBanner: () => void
    hidden: boolean
}

export interface TBannerConfig {
    content: Partial<Record<TBannerPosition, string | JSX.Element>>
    // position: TBannerPosition
    size: TBannerSize
}


export type TBannerPosition = 'right' | 'left' | 'center'
export type TBannerSize = 'small' | 'large'



export const defaultValue: BannerContextProviderValue = {
    content: {},
    // position: 'right',
    size: 'large',
    hidden: false,
    setBanner: () => { },
    hideBanner: () => { }
}


