import config, { TConfigKey } from "./Config";
import Utils from "./Utils";

const VALIDATE_CONFIG_PROPERTIES: TConfigKey[] = ["BASE_URL", "API_URL"];

const CDN_CSS_LIST: string[] = [
    // 'https://fonts.googleapis.com/icon?family=Material+Icons&display=swap',
    // '//cdn.quilljs.com/1.3.6/quill.snow.css'
];

const CDN_SCRIPT_LIST: string[] = [
    "https://use.fontawesome.com/ecbc17e5f9.js",
    // 'https://aframe.io/releases/1.0.4/aframe.min.js',
    // 'https://unpkg.com/aframe-event-set-component@5.0.0/dist/aframe-event-set-component.min.js',
    // 'https://unpkg.com/aframe-look-at-component@0.8.0/dist/aframe-look-at-component.min.js',
    "https://cdnjs.cloudflare.com/ajax/libs/jquery/3.4.1/jquery.min.js",
];

const validateConfig = () => {
    VALIDATE_CONFIG_PROPERTIES.forEach((key) => {
        const val = config.get(key);
        if (!val) throw new Error(`App config must define ${key}`);
    });
};

const setExternalLinks = () => {
    CDN_CSS_LIST.forEach((href) => {
        const el = document.createElement("link");
        el.href = href;
        el.rel = "stylesheet";
        document.head.appendChild(el);
    });

    CDN_SCRIPT_LIST.forEach((src) => {
        const el = document.createElement("script");
        el.src = src;
        document.head.appendChild(el);
    });
};

const ANCESTOR_ORIGIN = document.referrer;
// const ANCESTOR_ORIGIN = window.location.ancestorOrigins?.[0] || document.referrer;

const Boot = () => {
    return new Promise((resolve, reject) => {
        validateConfig();
        /** Override console.log as per environment file */
        if (config.get("CONSOLE_LOGGING") === false) {
            console.log = () => {};
        }

        Utils.setBaseAPI_URL(config.get("API_URL"));

        // console.log("base url set", ANCESTOR_ORIGIN)
        // if (ANCESTOR_ORIGIN && IS_IN_IFRAME) {
        //     Utils.setHeader('x-parent-frame', ANCESTOR_ORIGIN);
        // }

        setExternalLinks();
        resolve(true);
    });
};

export default Boot;
