import React, { FC } from 'react'
import { Box, Typography, Link } from '@material-ui/core'
import { createStyles, Theme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/styles';
import { TEvent } from '../../Models/Events/@types';

export interface ContactProps {
    event: TEvent
}

const Contact: FC<ContactProps> = (props) => {
    const classes = useStyles();
    const { event } = props;
    return (
        <Box className={classes['root']}>
            <Typography align='center' color='textPrimary' variant='subtitle1'>{'CONTACT'}</Typography>
            <Link rel="noopener" href={`mailto:${event.email}`} align='center' color='textPrimary' variant='body2'>{event.email}</Link>
            <Link rel="noopener" href={`tel:${event.phone}`} align='center' color='textPrimary' variant='body2'>{event.phone}</Link>
        </Box>
    )
}

const useStyles = makeStyles<Theme>((theme) => {
    return (createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            margin: 'auto'
        }
    }))
})

export default Contact