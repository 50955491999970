import { Box, IconButton, Link as MuiLink, Paper } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Apps, Event as EventIcon, FilterList as FilterIcon, Menu as MenuIcon } from '@material-ui/icons';
import List2 from '@material-ui/icons/FormatListBulleted';
import { intersection } from 'lodash';
import React, { FC, useMemo, useState } from 'react';
import { HEADER_HEIGHT, MainDialog } from '../../../Components';
import SingleSelect, { SingleSelectProps } from '../../../Components/Input/SingleSelect';
import Menu from '../../../Components/Menu';
import { useFocusArea } from '../../../Features/FocusAreas/useFocusArea';
import { useMediaQuery } from '../../../Hooks/useMediaQuery';
import { useStoreActions, useStoreState } from '../../../Stores';
import { getTopLevelFocusAreaTags } from '../../../Stores/Agency/helpers';
import HomeTabs from '../HomeTabs';

export interface FilterBarProps {
    listingType: 'grid' | 'list' | 'calendar';
    onListingTypeChange: (type: FilterBarProps['listingType']) => void;
    isStrategicPartnerView?: boolean;
}

type TEventStatus = 'upcoming' | 'past' | undefined;

const FilterBar: FC<FilterBarProps> = (props) => {
    const { isDeviceSm } = useMediaQuery();
    const classes = useStyles({ isDeviceSm });
    const { focusAreas } = useFocusArea();
    const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);
    const [filterDialogOpen, setFilterDialogOpen] = useState(false);

    const { listingType, onListingTypeChange } = props;
    const { setAppliedFilter } = useStoreActions(({ Agency: { setAppliedFilter } }) => ({ setAppliedFilter }));
    const {
      appConstants,
      appliedFilter,
      facets: eventFacets,
      resourceFacets,
      activeTab,
      agency,
    } = useStoreState(({ App: { appConstants }, Agency: { activeTab, appliedFilter, facets, resourceFacets, agency } }) => ({
      appConstants,
      appliedFilter,
      facets,
      resourceFacets,
      activeTab,
      agency,
    }));
    const isEventActive = useMemo(() => {
        return activeTab === 'Calendar';
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTab]);
    const facets = useMemo(() => {
        return isEventActive ? eventFacets : resourceFacets;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventFacets, resourceFacets, isEventActive]);

    const closeMenu = () => setMenuAnchorEl(null);
    const closeFilterDialog = () => setFilterDialogOpen(false);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const languageOptions: SingleSelectProps['options'] = useMemo(() => {
        if (!appConstants) return [];
        return [{ label: 'All Languages', value: 'all' }, ...(facets.languages?.map((l) => ({ label: l.name || l.key, value: l.key })) || [])];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [facets]);
    const focusAreaOptions: SingleSelectProps['options'] = useMemo(() => {
        if (!appConstants) return [];
        return getTopLevelFocusAreaTags(appConstants, facets, focusAreas);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [facets, focusAreas]);

    const statusOption: SingleSelectProps['options'] = useMemo(() => {
        return [
            { label: 'Upcoming events', value: 'upcoming' },
            { label: 'Past events', value: 'past' },
        ];
    }, []);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const regionOptions: SingleSelectProps['options'] = useMemo(() => {
        if (!appConstants) return [];

        const facetRegions = facets.regions?.map(i => i.key) || [];

        
        return [{ label: 'All Regions', value: 'all' }, ...(agency?.regionsMapping?.filter(i => facetRegions.includes(i.value)).map((l) => ({ label: l.name , value: l.value })) || [])];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [facets]);

    const typeOptions: SingleSelectProps['options'] = useMemo(() => {
        if (!appConstants) return [];
        let options = [
            { label: 'All Types', value: 'all' },
            ...(facets.type?.filter((l) => l.key !== 'physical' && l.key !== 'virtual' && l.key !== 'event').map((l) => ({ label: l.name || l.key, value: l.key })) || []),
            // ...(facets.type?.map(l => ({ label: l.name || l.key, value: l.key })) || [])
        ];
        if (
            intersection(
                facets.type?.map((f) => f.key),
                ['physical', 'virtual', 'event']
            )
        ) {
            options.push({ label: 'Event', value: 'event' });
        }
        return options;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [facets]);

    const handleChange = (
        e: React.ChangeEvent<{
            name?: string | undefined;
            value: unknown;
        }>
    ) => {
        const value = e.target.value as string;
        const name = e.target.name as string;
        /**
         * We need to reset filter on status change since there is a chance that some filters will have different values
         *  based on different status , hence could cause conflict.
         */
        switch (name) {
            case 'status': {
                setAppliedFilter({
                    ...appliedFilter,
                    status: value as TEventStatus,
                    focusAreaIds: [],
                    type: [],
                });
                break;
            }
            default: {
                if (value === 'all') setAppliedFilter({ ...appliedFilter, [name]: name === 'regions' ? undefined : [] });
                else setAppliedFilter({ ...appliedFilter, [name]: name === 'regions' || name === 'status' ? value : [value] });
            }
        }
    };

    const selectProps: Omit<SingleSelectProps, 'options'> = {
        onChange: (e) => {
            handleChange(e);
            if (filterDialogOpen) closeFilterDialog();
        },
        disableUnderline: true,
        defaultValue: 'all',
        className: classes.select,
    };

    const OPTIONS = [
        {
            onClick: () => {
                closeMenu();
                onListingTypeChange('grid');
            },
            label: 'Grid view',
        },
        {
            onClick: () => {
                closeMenu();
                onListingTypeChange('list');
            },
            label: 'List view',
        },
    ];

    const getFilters = () => (
      <>
        {regionOptions.length > 1 ? <SingleSelect {...selectProps} value={appliedFilter.regions || 'all'} name="regions" options={regionOptions} /> : null}
        {isEventActive ? <SingleSelect {...selectProps} multiple={false} value={appliedFilter.status || 'upcoming'} name="status" options={statusOption} /> : null}
        {facets.focusAreas && <SingleSelect {...selectProps} value={appliedFilter.focusAreaIds[0] || 'all'} name="focusAreaIds" options={focusAreaOptions} />}
        {isEventActive && facets.type ? <SingleSelect {...selectProps} value={appliedFilter.type[0] || 'all'} name="type" options={typeOptions} /> : null}
      </>
    );

    return (
        <Paper elevation={1} square className={classes.root}>
            <Box>
                <HomeTabs />
            </Box>
            <Box flex={1} />
            {isDeviceSm ? (
                <>
                    <IconButton onClick={(e) => setMenuAnchorEl(e.currentTarget)}>
                        <MenuIcon fontSize="small" />
                    </IconButton>
                    <IconButton onClick={(e) => setFilterDialogOpen(true)}>
                        <FilterIcon fontSize="small" />
                    </IconButton>
                    <Menu anchorEl={menuAnchorEl} handleClose={closeMenu} options={OPTIONS} id="calendar-filter-menu" />
                    <MainDialog dialogTitle="FILTER" open={filterDialogOpen} onClose={closeFilterDialog}>
                        <Box display="flex" flexDirection="column" p={3}>
                            {getFilters()}
                        </Box>
                    </MainDialog>
                </>
            ) : (
                <>
                    <Box display="flex" alignItems="center">
                        <Box mt={'-2px'}>
                            <MuiLink variant="body2" style={{ fontWeight: 500 }} target="_blank" href="https://www.microfocus.com/en-us/events#category=&language=&market=&region=&search=&solution=&type=">
                                Global Events
                            </MuiLink>
                        </Box>
                        {/* {isStrategicPartnerView ? null : <SingleSelect {...selectProps} value={appliedFilter.regions || 'all'} name='regions' options={regionOptions} />} */}
                        {/* <SingleSelect {...selectProps} value={appliedFilter.languages[0] || 'all'} name='languages' options={languageOptions} /> */}
                        {getFilters()}
                        {/* {isEventActive ? <SingleSelect {...selectProps} multiple={false} value={appliedFilter.status || 'upcoming'} name='status' options={statusOption} /> : null}
                        {facets.focusAreas && <SingleSelect {...selectProps} value={appliedFilter.focusAreaIds[0] || 'all'} name='focusAreaIds' options={focusAreaOptions} />}
                        {(isEventActive && facets.type) ? <SingleSelect {...selectProps} value={appliedFilter.type[0] || 'all'} name='type' options={typeOptions} /> : null} */}
                    </Box>
                    <Box ml={2} display="flex" alignItems="center">
                        <IconButton onClick={() => onListingTypeChange('grid')} color={listingType === 'grid' ? 'primary' : 'default'}>
                            <Apps fontSize="small" />
                        </IconButton>
                        <IconButton onClick={() => onListingTypeChange('list')} color={listingType === 'list' ? 'primary' : 'default'}>
                            <List2 fontSize="small" />
                        </IconButton>
                        {isEventActive ? (
                            <IconButton onClick={() => onListingTypeChange('calendar')} color={listingType === 'calendar' ? 'primary' : 'default'}>
                                <EventIcon fontSize="small" />
                            </IconButton>
                        ) : null}
                    </Box>
                </>
            )}
        </Paper>
    );
};

const useStyles = makeStyles<Theme, { isDeviceSm: boolean }>((theme) => ({
    root: {
        position: 'sticky',
        top: HEADER_HEIGHT,
        zIndex: theme.zIndex.appBar,
        padding: theme.spacing(0, 8),
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 24,
        },
    },
    select: {
        minWidth: 100,
        marginLeft: theme.spacing(2),
    },
}));

export default FilterBar;
