import qs from 'qs';
import { CampaignData } from './useCampaignUrl';

const keys: (keyof CampaignData)[] = ['utm_campaign', 'utm_content', 'utm_medium', 'utm_source', 'utm_term'];
class Campaign {
    private utm_source: string | undefined;
    private utm_medium: string | undefined;
    private utm_campaign: string | undefined;
    private utm_term: string | undefined;
    private utm_content: string | undefined;

    get campaignData() {
        let data: Record<string, string | undefined> = {};
        keys.forEach(k => {
            if (this[k])
                data[k] = this[k]
        })
        return data
    }

    constructor(campaignData: CampaignData) {
        this.utm_campaign = campaignData.utm_campaign;
        this.utm_content = campaignData.utm_content;
        this.utm_medium = campaignData.utm_medium;
        this.utm_source = campaignData.utm_source;
        this.utm_term = campaignData.utm_term;
    }

    setToCookie() {
        keys.forEach(k => {
            if (this[k])
                localStorage.setItem(k, this[k]!);
        })
    }

    get hasCampaignData() {
        return !!this.utm_campaign && !!this.utm_medium && !!this.utm_source
    }

    static clearCampaignCookie() {
        keys.forEach(k => {
            localStorage.removeItem(k);
        })
    }

    static fromQuery(query: qs.ParsedQs) {
        const campaignData = {
            utm_source: query['utm_source'] as string,
            utm_medium: query['utm_medium'] as string,
            utm_campaign: query['utm_campaign'] as string,
            utm_term: query['utm_term'] as string,
            utm_content: query['utm_content'] as string,
        }
        return new Campaign(campaignData);
    }

    static fromCookie() {
        const keys: (keyof CampaignData)[] = ['utm_campaign', 'utm_content', 'utm_medium', 'utm_source', 'utm_term'];
        let campaignData: Record<string, string> = {};

        keys.forEach(k => {
            const value = localStorage.getItem(k);
            if (value !== null)
                campaignData[k] = value
        })
        // const campaignData = {
        //     utm_source: localStorage.getItem('utm_source'),
        //     utm_medium: localStorage.getItem('utm_medium'),
        //     utm_campaign: query['utm_campaign'] as string,
        //     utm_term: query['utm_term'] as string,
        //     utm_content: query['utm_content'] as string,
        // }
        return new Campaign(campaignData);
    }

    getQuerySuffix() {
        let query = this.campaignData;
        return qs.stringify(query);
    }


}

export default Campaign