import dayjs from "dayjs";
import React from "react";
import utc from "dayjs/plugin/utc";
import { filter, indexOf, isString, map } from "lodash";
import moment from "moment";
import Cookies from "universal-cookie";
import * as Yup from "yup";
import { DEFAULT_BANNERS } from "../Constants/defaultBanners";
import { TEvent, TTimeZone } from "../Models/Events/@types";
import { linkRegExp, phoneRegExp } from "./AppConstants";
import { IAnalyticsCustomConfig } from "analytics.config";
const cookies = new Cookies();
dayjs.extend(utc);

let emailSchema = Yup.object({
    email: Yup.string().trim().email("Invalid Email").required("Email is required"),
});
let utilities = {
    getTextFromHTML: (htmlString: string) => {
        if (!htmlString) return "";
        var div = document.createElement("div");
        div.innerHTML = htmlString;
        var text = div.textContent || "";
        return text;
    },
    toggleItemFromList: (list: any[] = [], item: any, key: string = "id", comaprisonFunction?: (currentItem: any, item: any) => boolean) => {
        let updatedList: any[] = [...list];
        let index = list.findIndex((i) => (comaprisonFunction ? comaprisonFunction(i, item) : i[key] === item[key]));
        index === -1 ? updatedList.push(item) : updatedList.splice(index, 1);
        return updatedList;
    },
    getTransformedImageUrl: (url: string, width: number, height: number) => {
        if (!url.length) return undefined;
        const str = `mithya/tr:w-${width},h-${height}/`;
        return url.replace("mithya/", str);
    },
    clearItemFromCookies: (key: string) => cookies.remove(key, { path: "/" }),
    addItemInCookies: (key: string, value: any) => cookies.set(key, value, { path: "/", domain: "*", sameSite: "none" }),
    getDummyImageUrl: (w: number = 800, h: number = 800) => `https://source.unsplash.com/random/${w}x${h}`,
    getDateFromDayTime: (day: string, time: string, format: "dayjs" | "string" = "string") => {
        const _date = dayjs
            .utc(day ? new Date(day) : new Date())
            .set("hour", parseInt(time.split(":")[0]) || 0)
            .set("m", parseInt(time.split(":")[1]) || 0);
        if (format === "string") return _date.toISOString();
        return _date;
    },
    validateFileType: (acceptedType: string, typeToValidate: string) => {
        let accept = acceptedType.split(",").map((i) => i.replace(/ /g, ""));
        let isValid = false;
        accept.forEach((i) => {
            if (i.includes("*")) {
                if (typeToValidate.includes(i.split("/")[0])) isValid = true;
            } else if (i.includes(".")) {
                if (typeToValidate.includes(i.split(".")[0])) isValid = true;
            } else {
                if (typeToValidate.includes(i)) isValid = true;
            }
        });
        return isValid;
    },
    bytesToSize: (bytes: number) => {
        var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
        if (bytes === 0) return "0 Byte";
        var i = Math.floor(Math.log(bytes) / Math.log(1024));
        return (bytes / Math.pow(1024, i)).toFixed(2) + " " + sizes[i];
    },
    getFormattedDate: (date: string, format: string) => moment(date).clone().format(format),
    getFallbackImageUrl: (type: "home" | "other", event?: TEvent, smallDevice: boolean = false) => {
        const websiteImage = type === "home" ? DEFAULT_BANNERS.homeDesktop : DEFAULT_BANNERS.otherDesktop;
        const websiteImageSm = type === "home" ? DEFAULT_BANNERS.homeMobile : DEFAULT_BANNERS.otherMobile;
        return smallDevice ? websiteImageSm : websiteImage;
    },
    getBannerImage: (type: "home" | "other" | "calendar", event?: TEvent, smallDevice: boolean = false) => {
        const _webImages = event?._webImages || [];
        if (type === "calendar") {
            return _webImages.filter((im) => im.type === "calendarWebsite")[0];
        }
        const websiteImage = type === "home" ? _webImages.filter((im) => im.type === "homePageBannerDesktop")[0] : _webImages.filter((im) => im.type === "otherPagesBannerDesktop")[0];
        const websiteImageSm = type === "home" ? _webImages.filter((im) => im.type === "homePageBannerMobile")[0] : _webImages.filter((im) => im.type === "otherPagesBannerMobile")[0];

        return !smallDevice ? websiteImage : websiteImageSm;
    },

    ValidateEmail: (mail: string) => emailSchema.validate({ email: mail }),
    //(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)),
    getAbbreviation: (timeZone?: TTimeZone): string => {
        // if (!timeZone) return ''
        // if (typeof timeZone === 'string') return '';
        // const offset = moment().utcOffset(timeZone.offset.split(' ')[1]).utcOffset();
        return moment().zoneAbbr();
    },

    isListSimilar: (list1: Array<any>, list2: Array<any>) => {
        if (list1.length !== list2.length) return false;
        list1.forEach((elem) => {
            let index = list2.findIndex((i) => i.id === elem.id);
            if (index === -1) return false;
        });
        return true;
    },
    updateItemList: (list: Array<any>, item: any, action: "ADD" | "DELETE" | "UPDATE" | "PUT", key: any = "id"): typeof list => {
        list = list || [];
        let newList = list.slice();
        let itemIndex;
        if (action === "UPDATE") {
            itemIndex = newList.findIndex((listItem) => item[key] === listItem[key]);
            if (itemIndex !== -1) newList.splice(itemIndex, 1, item);
            return newList;
        } else if (action === "ADD") {
            newList.unshift(item);
            return newList;
        } else if (action === "DELETE") {
            return newList.filter((listItem) => item[key] !== listItem[key]);
        } else if (action === "PUT") {
            itemIndex = newList.findIndex((listItem) => item[key] === listItem[key]);
            if (itemIndex !== -1) newList.splice(itemIndex, 1, item);
            else {
                newList.push(item);
            }
            return newList;
        }
        return newList;
    },
    clipString: (text: string, allowedLength: number, clipText: string = "...") => {
        if (text === "") return text;
        if (text.length <= allowedLength) return text;
        else return text.substr(0, allowedLength - clipText.length) + clipText;
    },
    findValues: (superSet: Array<Object>, subSet: string | Array<string>, findKey: string = "value", mapKey: string = "name", isReturnItem: Boolean = false) => {
        // findKey = findKey || 'value';
        // mapKey = mapKey || 'name';
        if (isString(subSet)) subSet = [subSet];
        let filteredValues = filter(superSet, (item: any) => {
            return indexOf(subSet, item[findKey]) !== -1;
        });
        if (isReturnItem) return filteredValues;
        return map(filteredValues, mapKey);
    },
    hexToRgb: (hex: string, opacity = "0.1") => {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        if (result) return `rgb(${parseInt(result[1], 16)},${parseInt(result[2], 16)} ,${parseInt(result[3], 16)},${opacity})`;
        // r: parseInt(result[1], 16),
        // g: parseInt(result[2], 16),
        // b: parseInt(result[3], 16)
        else return "";
    },
    getLabelWithRequiredMark: (label: string = "") => {
        return (
            <>
                {label}
                <sup>*</sup>
            </>
        );
    },
    // getAddress: (GeoAddress: any) => {
    //     if (!GeoAddress) return {}
    //     const geoAddress = GeoAddress as google.maps.GeocoderResult;
    //     const full_address = get(geoAddress, 'formatted_address');
    //     const components: { long_name?: string, short_name?: string, types: string[] }[] = get(geoAddress, 'address_components') || [];
    //     var city = '';
    //     var country = '';
    //     var zipcode = '';
    //     var placeid = get(geoAddress, 'place_id') || '';
    //     components.forEach((i) => {
    //         i.types.forEach((type => {
    //             if (type === 'country')
    //                 country = i.long_name || i.short_name || '';
    //             if (type === 'postal_code')
    //                 zipcode = i.long_name || i.short_name || '';
    //             if (type === 'administrative_area_level_1' || type === 'administrative_area_level_2')
    //                 city = i.long_name || i.short_name || '';
    //         }))
    //     })
    //     return {
    //         city,
    //         country,
    //         placeid,
    //         zipcode,
    //         full_address
    //     }
    // }
    yupValidation: (validation: Array<any>): Record<string, any> => {
        let YupObj: any = Yup;

        validation.map((item) => {
            switch (item.type) {
                case "string":
                    YupObj = YupObj.string().trim();
                    break;
                case "number":
                    YupObj = YupObj.number();
                    break;
                case "boolean":
                    YupObj = YupObj.boolean();
                    break;
                case "array":
                    YupObj = YupObj.array();
                    break;
                case "email":
                    YupObj = YupObj.email(item.error);
                    break;
                case "required":
                    YupObj = YupObj.required(item.error);
                    break;
                case "notNull":
                    YupObj = YupObj.typeError(item.error).nullable(false); //.notOneOf([null], item.error)
                    break;
                case "oneOf":
                    YupObj = YupObj.oneOf(item.options, item.error);
                    break;
                case "min length":
                    YupObj = YupObj.test("len", item.error, (val: string[]) => val.length >= item.value);
                    break;
                case "minimum value":
                    YupObj = YupObj.min(item.value, item.error);
                    break;
                case "phone":
                    YupObj = YupObj.matches(phoneRegExp, item.error);
                    break;
                case "select as true":
                    YupObj = YupObj.oneOf([true], item.error);
                    break;
                case "link":
                    YupObj = YupObj.matches(linkRegExp, item.error);
                    break;
                case "matches":
                    YupObj = YupObj.matches(item.regex, item.error);
                    break;
            }
            return 0;
        });
        return YupObj;
    },
    downloadPdfFileResponse: (resp: any, fileName: string = "temp_file") => {
        var blob = new Blob([resp], { type: "application/pdf" });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
    },
};
export default utilities;
