import { Box, Theme, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React from 'react';
import LiveJson from '../../Assets/Lottie/live.json';
import { THEME_PALETTE } from '../../Theme/themeValues';
import LottieImage from '../LottieImage';


interface LiveBadgeProps {
    type?: 'square' | 'rounded'
}


const LiveBadge: React.FC<LiveBadgeProps> = (props) => {
    const { type = 'square' } = props;
    const classes = useStyles(type)()

    return (
        <Box className={classes.root} display="flex" alignItems="center">
            {/* <span /> */}
            <LottieImage dimension={{ width: '15px', height: '15px' }} lotteJSON={LiveJson} />
            <Box pr='4px' />
            <Typography>Live</Typography>
        </Box>
    )
}

const useStyles = (type: LiveBadgeProps['type']) => makeStyles((theme: Theme) => createStyles({
    root: {
        padding: type === 'rounded' ? '0px 8px 0px 4px' : '0px 8px 0px 0px',
        background: type === 'rounded' ? THEME_PALETTE.others.activeGreen : 'transparent',
        borderRadius: type === 'rounded' ? '0px 25px 25px 0px' : 0,
        '& > span': {
            backgroundColor: theme.palette.common.white,
            width: 8,
            height: 8,
            borderRadius: 4,
            marginRight: 4
        },
        '& > p': {
            color: theme.palette.common.white,
        }
    }
}))

export default LiveBadge