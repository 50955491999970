import { Box, Paper, Button } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { FC, useState } from 'react';
import { useStoreActions, useStoreState } from '../../../Stores';
import Typo from '../../../Components/Typo';
import { useAuth } from '../useAuth';
import { useAuthDialog } from '../../../Dialogs/Auth/useAuthDialog';
import { LoginFormData } from '../../../Forms/LoginForm';
export interface indexProps {
    onClick?: () => void
    onClose?: () => void
}

const AuthRecommender: FC<indexProps> = ({ }) => {
    const classes = useStyles();
    const { userLogin, userSignup, isRegistered, forgotPassword } = useAuth()
    const [open, setOpen] = useState<boolean>(true);
    const { isAuthenticated } = useStoreState(({ User: { isAuthenticated } }) => ({ isAuthenticated }));
    const handleClose = () => {
        setOpen(false)
    }
    const handleShow = () => {
        openAuthDialog();
        // handleClose();
    }
    const { AuthComponent, openAuthDialog, } = useAuthDialog({
        onLogin: async (formData: LoginFormData) => {
            await userLogin(formData)
        },
        onForgotPassword: forgotPassword,
        handleCheck: isRegistered,
        onSignup: userSignup,
        onClose: () => {
            if (isAuthenticated)
                handleClose()
        }
    });
    if (isAuthenticated)
        return <div />
    if (!open)
        return <div />
    return (
        <Paper elevation={2} className={classes.root} style={{ display: !!AuthComponent ? 'none' : undefined }}>
            <Box width='100%' display='flex' flexDirection='column' alignItems='center'>
                <Typo variant='body1' weight='regular'>Get a personalized experience</Typo>
                <Box height={12} />
                <Button fullWidth color='primary' variant='contained' onClick={handleShow}>Sign In</Button>
            </Box>
            {AuthComponent}
        </Paper>
    )
}

const useStyles = makeStyles<Theme>((theme) => {
    return (createStyles({
        root: {
            position: 'fixed',
            zIndex: theme.zIndex.snackbar + 1,
            bottom: 24,
            right: 24,
            width: 250,
            padding: theme.spacing(2)
        }
    }))
})

export default AuthRecommender