import React from 'react'
import { animated, config, useSpring } from 'react-spring'


interface WithTransitionProps {
    /** direction of transition */
    direction?: 'top' | 'left' | 'right' | 'bottom'
    /** delay in milliseconds */
    delay?: number

}

const WithTransition: React.FC<WithTransitionProps> = (props) => {
    const { direction = 'top', delay = 0 } = props;
    const spring = useSpring({ opacity: 1, pos: 0, delay, from: { opacity: 0, pos: 20 }, config: config.gentle })


    const getTransform = (pos: any) => {
        if (direction === 'bottom') return `translateY(-${pos}px)`
        if (direction === 'top') return `translateY(${pos}px)`
        if (direction === 'left') return `translateX(${pos}px)`
        if (direction === 'right') return `translateX(-${pos}px)`
    }

    return (
        <animated.div style={{ ...spring, transform: spring.pos.interpolate(getTransform) }}>
            {props.children}
        </animated.div>
    )
}



export default WithTransition