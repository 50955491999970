import { Button, Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React from 'react';
import AddToCalendars from 'react-add-to-calendar';
import './style.css';

export type TCalendarEvent = {
    title: string;
    start: string;
    end: string;
    description?: string;
    location?: string;
};

interface AddToCal2Props extends TCalendarEvent {}

const icon = { 'calendar-plus-o': 'left' };
// const items = [
//     { outlook: 'Outlook' },
//     { outlookcom: 'Outlook.com' },
//     { apple: 'Apple Calendar' },
//     { yahoo: 'Yahoo' },
//     { google: 'Google' }
// ];
const AddToCal2: React.FC<AddToCal2Props> = (props) => {
    const { title, description, start, end, location = 'Virtual' } = props;

    // const start = dayjs(day).format('MM/DD/YYYY') + ' ' + dayjs(open, 'HH:mm').format('hh:mm A')
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Button style={{ minWidth: 0 }} disableFocusRipple>
                <>
                    <AddToCalendars
                        buttonLabel=""
                        buttonTemplate={icon}
                        event={{
                            title: title,
                            startTime: start,
                            endTime: end,
                            description: description,
                            location: location,
                        }}
                        useFontAwesomeIcons={true}
                        displayItemIcons={true}
                    />
                </>
            </Button>
        </div>
    );
};
const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        root: {
            '& div > div > div': {
                backgroundColor: 'yellow',
                height: '50px',
            },
            '& .react-add-to-calendar': {
                fontFamily: `${theme.typography.fontFamily} !important`,
            },
            '& .react-add-to-calendar__button': {
                // color: `${theme.palette.primary.main} !important`,
                fontWeight: 500,
                fontSize: '13px',
                color: '#1D1D1D !important',
            },
            '& .react-add-to-calendar__icon--left': {
                paddingRight: '0 !important',
            },
        },
        icon: {},
    });
});

export default AddToCal2;
