import { CircularProgress } from '@material-ui/core';
import React, { useCallback } from 'react';
import { RoundedButton } from '../../Components';
import { useAuthDialog } from '../../Dialogs/Auth/useAuthDialog';
import useAsyncTask from '../../Hooks/useAsyncTask';
import { TRegistrant } from '../../Models/Registrant/@types';
import { useStoreState } from '../../Stores';
import { useAuth } from '../Auth/useAuth';
import { useRegistrationForm } from './useRegistrationForm';

const useAutoRegister = (onClick?: () => {}) => {
    const { eventDetails, user } = useStoreState(({ Events: { eventDetails }, User: { user } }) => ({ eventDetails, user }));
    const { handleSubmit } = useRegistrationForm(eventDetails?.id || '', eventDetails?.agencyId);
    const { userLogin, userSignup, isRegistered, forgotPassword } = useAuth();

    const register = useAsyncTask(async (values: Partial<TRegistrant>) => {
        try {
            if (!user) {
                openAuthDialog();
            } else {
                await handleSubmit.run({ ...values });
            }
        } catch (e) {}
    });
    const handleLogin = async (data: any) => {
        await userLogin(data);
        // await register.run({});
    };
    const { AuthComponent, openAuthDialog } = useAuthDialog({
        onLogin: handleLogin,
        onSignup: userSignup,
        handleCheck: isRegistered,
        onForgotPassword: forgotPassword,
    });

    const RoundedRegisterButton =
        handleSubmit.status === 'PROCESSING' ? (
            <CircularProgress />
        ) : (
            <React.Fragment>
                {AuthComponent}
                <RoundedButton borderColor="white" size="medium" variant="contained" color="secondary" onClick={() => register.run({})}>
                    Register
                </RoundedButton>
            </React.Fragment>
        );
    return {
        register,
        RoundedRegisterButton,
    };
};
export default useAutoRegister;
