import { Button, ButtonProps, CircularProgress } from '@material-ui/core';
import React from 'react';

interface SubmitButtonProps extends ButtonProps {
    loading: boolean
}

const SubmitButton: React.FC<SubmitButtonProps> = (props) => {
    const { children, loading, ...buttonProps } = props;

    return (
        <Button {...buttonProps}>
            {loading ? <CircularProgress style={{ color: 'white' }} /> : children}
        </Button>
    )
}




export default SubmitButton