import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Redirect, Route, RouteProps, useRouteMatch } from "react-router-dom";
import FullScreenLoader from "../../Components/FullScreenLoader";
import ResourceMeta from "../../Components/ResourceMeta";
import DomainNoAccessDialog from "../../Dialogs/DomainNoAccessDialog";
import useAsyncTask from "../../Hooks/useAsyncTask";
import { TAgencyAsset } from "../../Models/AgencyAssets/@types";
import DomainUtils from "../../Models/Domain";
import { useStoreActions, useStoreState } from "../../Stores";
import AuthRecommender from "../Auth/AuthRecommender";
import EntityPreview from "../EntityPreview";
import WithEntityRegistration from "../EntityRegistration/WithEntityRegistration";
import { useRegistrantHandler } from "../PrivateRoute/useRegistrantHandler";
// eslint-disable
export interface AssetRouteProps extends RouteProps {}

const AssetRoute: FC<AssetRouteProps> = (routeProps) => {
    const {
        params: { id: assetId },
    } = useRouteMatch<{ id: string }>();
    const {
        setAsset,
        loadAsset: getAsset,
        setAppliedFilter,
        setGuestInfo,
    } = useStoreActions(({ AgencyAsset: { setAsset, loadAsset }, Agency: { setAppliedFilter }, User: { setGuestInfo } }) => ({
        setAsset,
        loadAsset,
        setGuestInfo,
        setAppliedFilter,
    }));
    const { user, asset, guestInfo, agency } = useStoreState(({ User: { user, guestInfo }, AgencyAsset: { asset }, Agency: { agency } }) => ({
        agency,
        asset,
        user,
        guestInfo,
    }));
    const [isDomainAllowed, setIsDomainAllowed] = useState<boolean>(false);
    const { isAuthenticated } = useRegistrantHandler({ initRegistrant: true, baseUrl: "/resources" });
    const checkDomains = useCallback(
        (data: TAgencyAsset) => {
            return DomainUtils.checkEmailDomains(
                data.restrictedStrategicPartners || [],
                {
                    domainRestrictionType: data.domainRestrictionType,
                    restrictedEmailDomains: data.restrictedEmailDomains || [],
                    restrictedStrategicPartnerIds: data.restrictedStrategicPartnerIds || [],
                },
                user?.email
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [user?.email]
        // eslint-disable-next-line react-hooks/exhaustive-deps
    );
    const loadAsset = useAsyncTask(async (id: string) => {
        const data = await getAsset({ id, filter: { include: ["focusAreas", "restrictedStrategicPartners"] } });
        const agencyRegions = agency?.regionsMapping.map((i) => i.value) || [];
        const activeRegions = data.regions?.filter((i) => agencyRegions.includes(i));
        if (activeRegions.length) setAppliedFilter({ regions: activeRegions[0], focusAreaIds: [], languages: [], type: [] });
    });
    useEffect(() => {
        if (asset?.id) {
            const { allowed } = checkDomains(asset);
            setIsDomainAllowed(allowed);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user?.email, asset?.id]);
    useEffect(() => {
        if (assetId) {
            loadAsset.run(assetId);
            return () => {
                setAsset(undefined);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assetId]);
    useEffect(() => {
        if (guestInfo) {
            return () => {
                setGuestInfo(undefined);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [guestInfo]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const authNeeded = useMemo(() => !!asset?.isAuthRequired, [asset?.isAuthRequired]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const previewNeeded = useMemo(() => !!asset?.isPreviewResource, [asset?.isPreviewResource]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const basicDetailsNeeded = useMemo(() => !!asset?.isDetailResource, [asset?.isDetailResource]);

    const getComponent = useCallback(() => {
        if (!asset) return <div />;

        if (previewNeeded || basicDetailsNeeded) {
            if (isAuthenticated || guestInfo) {
                return <Route {...routeProps} />;
            } else {
                return <EntityPreview entity={asset} />;
            }
        } else if (authNeeded) {
            if (isAuthenticated) {
                if (!isDomainAllowed) {
                    return <DomainNoAccessDialog redirectUrl={"/resources"} />;
                }

                if (asset.isRegRequired) {
                    if (asset.registrationOpen) return <WithEntityRegistration entity={asset!} {...routeProps} />;
                    else return <DomainNoAccessDialog titleText={"Registrations are closed for this resource."} redirectUrl={"/resources"} />;
                } else return <Route {...routeProps} />;
            } else return <EntityPreview entity={asset} />;
        } else {
            return <Route {...routeProps} />;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, guestInfo, isDomainAllowed, authNeeded, asset?.isRegRequired, previewNeeded]);
    if (loadAsset.status === "ERROR") return <Redirect to={`/resources`} />;
    return (
        <>
            <ResourceMeta resource={asset} />
            {loadAsset.status === "PROCESSING" ? (
                <FullScreenLoader />
            ) : (
                <>
                    {/* {asset && <EntityPreview entity={asset} />} */}
                    {loadAsset.status === "SUCCESS" && getComponent()}
                    {loadAsset.status === "SUCCESS" && !authNeeded && !previewNeeded && !basicDetailsNeeded ? <AuthRecommender /> : null}
                </>
            )}
        </>
    );
};

export default AssetRoute;
