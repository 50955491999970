import React, { FC, useMemo } from 'react';
import { useRouteMatch } from 'react-router';
import DomainNoAccessDialog from '../../Dialogs/DomainNoAccessDialog';
import { useStoreState } from '../../Stores';
import FullScreenSessionPage from './FullScreenSessionPage';

export interface SessionDetailProps { }

const SessionDetail: FC<SessionDetailProps> = (props) => {
    const { params: { sessionId } } = useRouteMatch<{ sessionId: string }>();
    const { allowedSessions, eventSlug } = useStoreState(({ Events: { allowedSessions }, App: { eventSlug } }) => ({ allowedSessions, eventSlug }));
    const isAllowed = useMemo(() => {
        return !!allowedSessions.find(i => i.id === sessionId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allowedSessions.length, sessionId,])
    return (
        isAllowed ?
            <>
                <FullScreenSessionPage sessionId={sessionId} />
            </> :
            <DomainNoAccessDialog redirectUrl={`/${eventSlug}`} />
    )
}

export default SessionDetail