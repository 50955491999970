import { Box, Paper } from "@material-ui/core";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import DetailInfoForm, { DetailInfoFormData } from "Forms/DetailInfoForm";
import React, { FC } from "react";
import Typo from "../../Components/Typo";
import useAsyncTask from "../../Hooks/useAsyncTask";
import EntityRegistrationsModel from "../../Models/EntityRegistrations";
import { TEntity } from "../../Models/EntityRegistrations/@types";
import { useStoreActions } from "../../Stores";

export interface DetailInfoCardProps {
    entity: TEntity;
}

let link3 = "https://px.ads.linkedin.com/collect/?pid=5571921&conversionId=13986641&fmt=gif";

const appendElem = (src: string) => {
    let env = process.env.REACT_APP_NODE_ENV?.trim();
    if (env === "local") {
        let elem = document.createElement("img");
        elem.height = 1;
        elem.width = 1;
        elem.src = src;
        document.getElementById("root")?.appendChild(elem);
        // document.getElementById('root')?.removeChild(elem)
    }
};

const DetailInfoCard: FC<DetailInfoCardProps> = (props) => {
    const classes = useStyles();
    const { entity } = props;
    const { doGuestLogin } = useStoreActions(({ User: { doGuestLogin } }) => ({ doGuestLogin }));
    const type = EntityRegistrationsModel.getEntityType(entity);

    const handleCheck = useAsyncTask(async (data: DetailInfoFormData) => {
        // e.preventDefault();
        try {
            await doGuestLogin({
                userData: { ...data },
                agencyId: entity.agencyId,
                resourceType: type,
                resourceId: entity.id,
            });
            appendElem(link3);
        } catch (error) {}
    });

    return (
        <Paper elevation={2} className={classes.root}>
            <Box width="100%" display="flex" flexDirection="column">
                <Typo gutterBottom variant="h6" weight="medium">
                    {"Enter your basic details to view complete content"}
                </Typo>
                <DetailInfoForm entity={entity} onSubmit={handleCheck.run} isSubmitting={handleCheck.status === "PROCESSING"} initialData={{ email: "" }} />
            </Box>
        </Paper>
    );
};

const useStyles = makeStyles<Theme>((theme) => {
    return createStyles({
        root: {
            // zIndex: theme.zIndex.snackbar + 1,
            width: "100%",
            padding: theme.spacing(2),
            boxSizing: "border-box",
            position: "sticky",
            top: 100,
        },
    });
});

export default DetailInfoCard;
