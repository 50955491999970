import { Paper } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import React, { FC, useMemo } from 'react';
import Typo from '../../Components/Typo';
import { useGlobalStyle } from '../../Constants/globalStyle';
import utilities from '../../Resources/Utils';

export interface DescriptionProps {
    description: string;
}

const Description: FC<DescriptionProps> = (props) => {
    const classes = useStyles();
    const globalClasses = useGlobalStyle();

    const { description } = props;
    const hasDescription = useMemo(() => {
        if (!description) return false;
        return !!utilities.getTextFromHTML(description);
    }, [description]);
    if (!hasDescription) return <div />;
    return (
        <Paper className={classes.paper}>
            <Typo id="event-description" className={clsx(classes.description, globalClasses.description, 'ql-editor')} dangerouslySetInnerHTML={{ __html: description }}></Typo>
        </Paper>
    );
};

const useStyles = makeStyles<Theme>((theme) => {
    return createStyles({
        paper: {
            width: '800px',
            margin: '32px auto',
            height: 'auto',
            padding: 40,
            boxSizing: 'border-box',
            whiteSpace: 'pre-wrap',

            [theme.breakpoints.down('sm')]: {
                width: '100%',
                padding: 16,
            },
        },
        description: {
            '& img': {
                width: '100%',
            },
            '& p': {
                margin: 0,
            },
        },
    });
});

export default Description;
