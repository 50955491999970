import { useEffect, useState } from "react";
import TagsModel from "../../Models/Tag";
import { TTag } from "../../Models/Tag/@types";
import { useStoreState } from "../../Stores";

export const useFocusArea = (agencyId?: string) => {
    const [focusAreas, setFocusAreas] = useState<TTag[]>([]);
    const { agency: agencyDetails } = useStoreState(({ Agency: { agency } }) => ({ agency }));

    const currentAgencyId = agencyId || agencyDetails?.id;
    // const mapOrder(array, order, key) {

    //     array.sort(function (a, b) {
    //         var A = a[key], B = b[key];

    //         if (order.indexOf(A) > order.indexOf(B)) {
    //             return 1;
    //         } else {
    //             return -1;
    //         }

    //     });

    //     return array;
    // };

    // const parseTree = (childrens: TTag[]) => {
    //     let list: TTag[] = [];
    //     childrens.forEach((item) => {
    //         if (item.level === 2) {
    //             list.push(item);
    //         }
    //         else if (item.children) {
    //             parseTree(item.children);
    //         }
    //     });
    //     return list;

    // }

    useEffect(() => {
        getFocusAreas();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentAgencyId])

    const getFocusAreas = async () => {
        if (currentAgencyId) {
            try {
                const { data: focusAreaTags } = await TagsModel.get_Tags_findOne<TTag>({
                    include: ['children'],
                    where: {
                        agencyId: currentAgencyId,
                        value: 'areas_of_interest'
                    }
                })
                // const { data: resp } = await TagsModel.get_Tags_tree_search<any>('', {
                //     where: {
                //         parentIds: [focusAreaTag?.[0].id],
                //         agencyId: currentAgencyId,
                //         // level: 2
                //     },
                //     limit: 60
                // }
                // );
                // if (resp.children && resp.children?.[0]) {

                //     const list = parseTree(resp.children[0].children || []);
                //     setFocusAreas([...list]);
                // }
                let children: TTag[] = focusAreaTags?.children || [];
                let childrenId = focusAreaTags.childrenIds || [];
                children.sort((a, b) => {
                    var A = a['id'];
                    var B = b['id'];
                    if (childrenId.indexOf(A) > childrenId.indexOf(B)) {
                        return 1;
                    } else {
                        return -1;
                    }
                });
                setFocusAreas(children);
                // const res: TFocusArea[] = await AppModel.fetchAutocompleteResult('', { doc_type: 'Tag', where: { type: 'focusArea' }, limit: 100 });
                // setFocusAreas([...res]);
            } catch (err) {
                setFocusAreas([])
            }
        }

    }

    return {
        getFocusAreas,
        focusAreas
    }
}