import React, { FC, useEffect, useState } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import EntityRegistration from '.';
import FullScreenLoader from '../../Components/FullScreenLoader';
import { getRegistrantInfoFromUserData } from '../../Context/AppContext/useAppInit';
import useAsyncTask from '../../Hooks/useAsyncTask';
import EntityRegistrationsModel from '../../Models/EntityRegistrations';
import { TEntity } from '../../Models/EntityRegistrations/@types';
import { TRegistrant } from '../../Models/Registrant/@types';
import { useStoreActions, useStoreState } from '../../Stores';

export interface WithEntityRegistrationProps extends RouteProps {
    entity: TEntity;
}

const WithEntityRegistration: FC<WithEntityRegistrationProps> = (props) => {
    const { entity, ...routeProps } = props;
    const { user, formData } = useStoreState(({ User: { user }, Registrant: { formData } }) => ({ user, formData }));
    const { setRegistrant, setRegistrantFormData } = useStoreActions(({ Registrant: { setRegistrant, setRegistrantFormData } }) => ({ setRegistrant, setRegistrantFormData }));
    const [registrationCompleted, setRegistrationCompleted] = useState<boolean>(false);
    const isRegistered = useAsyncTask(async () => {
        try {
            const res = await EntityRegistrationsModel.get_EntityRegistrations_my_registration(EntityRegistrationsModel.getEntityType(entity), entity.id);
            if (res) {
                setRegistrationCompleted(true);
            }
        } catch (err) {
            if (user) {
                const registrant = getRegistrantInfoFromUserData(user);
                setRegistrant(registrant as TRegistrant);
                setRegistrantFormData(registrant as TRegistrant);
            }
            setRegistrationCompleted(false);
        }
    });
    const onRegistrationCompleted = () => {
        setRegistrationCompleted(true);
    };
    useEffect(() => {
        if (user?.id) isRegistered.run({});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user?.id]);
    return (
        <>
            {isRegistered.status === 'PROCESSING' || isRegistered.status === 'IDLE' ? (
                <FullScreenLoader />
            ) : (
                <>{registrationCompleted ? <Route {...routeProps} /> : <EntityRegistration entity={entity} onRegistrationCompleted={onRegistrationCompleted} />}</>
            )}
        </>
    );
};
export default WithEntityRegistration;
