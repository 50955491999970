import App from './App';
import Agency from './Agency'
import Events from './Event'
import User from './User';
import Registrant from './Registrant'
import Chat from './Chat'
import { createStore, createTypedHooks, State } from 'easy-peasy';
import EventModel from '../Models/Events';
import RegistrantModel from '../Models/Registrant'
import UserModel from '../Models/User/index';
import NetworkModel from '../Models/Network/index'
import AgencyModel from '../Models/Agency/index';
import ChatModel from '../Models/Chat';
import SpeakerModel from '../Models/EventPerson';
import Partner from './Partner'
import Dialogs from './Dialogs'
import Zine from './Zine'
import AgencyAsset from './AgencyAsset'
import _isEqual from 'lodash/isEqual'
export type TRootStore = typeof RootStore;

export interface Injections {
    AgencyModel: typeof AgencyModel;
    EventModel: typeof EventModel;
    RegistrantModel: typeof RegistrantModel;
    UserModel: typeof UserModel;
    NetworkModel: typeof NetworkModel;
    ChatModel: typeof ChatModel;
    EventPersonModel: typeof SpeakerModel
}
const RootStore = {
    Agency,
    App,
    Events,
    Registrant,
    User,
    Chat,
    Partner,
    Dialogs,
    Zine,
    AgencyAsset
}

const typedHooks = createTypedHooks<TRootStore>();

export const useStoreActions = typedHooks.useStoreActions;
export const useStoreDispatch = typedHooks.useStoreDispatch;
// export const useStoreState = typedHooks.useStoreState;
export const useStoreState = <Result>(a: (state: State<TRootStore>) => Result, b?: any) => typedHooks.useStoreState(a, _isEqual);
export default createStore(RootStore, {
    injections: {
        AgencyModel,
        EventModel,
        RegistrantModel,
        UserModel,
        NetworkModel,
        ChatModel,
        EventPersonModel: SpeakerModel
    }
});