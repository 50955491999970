import { Action, action, thunk, Thunk } from "easy-peasy";
import AppModel from "../../Models/App";
import { TAppConstants } from "../../Models/App/@types";

export interface TState {
    // isAuthenticated: boolean
    eventSlug: string
    eventId: string
    previewMode: boolean
    setEventId: Action<TState, string>
    setEventSlug: Action<TState, string>
    setPreviewMode: Action<TState, boolean>
    // setIsAuthenticated: Action<TState, boolean>

    fetchAppConstants: Thunk<TState>;
    setAppConstants: Action<TState, TAppConstants>
    appConstants?: TAppConstants;
}

const State: TState = {
    // isAuthenticated: false,
    eventSlug: '',
    eventId: '',
    previewMode: false,

    // setIsAuthenticated: action((state, payload) => {
    //     state.isAuthenticated = payload
    // }),

    setEventId: action((state, payload) => {
        state.eventId = payload
    }),
    setEventSlug: action((state, payload) => {
        state.eventSlug = payload
    }),
    setPreviewMode: action((state, payload) => {
        state.previewMode = payload
    }),

    fetchAppConstants: thunk(async (actions) => {
        const appConstants = await AppModel.fetchAppConstants().catch(err => { throw err });
        actions.setAppConstants(appConstants);
    }),
    setAppConstants: action((state, payload) => { state.appConstants = payload }),

};

export default State;