
import axios, {AxiosRequestConfig} from 'axios';
// THIS IS A GENERATED FILE; PLEASE DO NOT MAKE CHANGES HERE

class AgenciesBase {

/** 
 * Find a related item by id for events.
 */ 
    static get_Agencies_id_events_fk<T = any>(fk: string,id: string,config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/Agencies/${id}/events/${fk}`,
            method: 'get',
            
            
        })
    }

/** 
 * Queries events of Agency.
 */ 
    static get_Agencies_id_events<T = any>(id: string,filter?: any,config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/Agencies/${id}/events`,
            method: 'get',
            params: {
                filter
            },
            
        })
    }

/** 
 * Creates a new instance in events of this model.
 */ 
    static post_Agencies_id_events<T = any>(id: string,data?: any,config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/Agencies/${id}/events`,
            method: 'post',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Counts events of Agency.
 */ 
    static get_Agencies_id_events_count<T = any>(id: string,where?: any,config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/Agencies/${id}/events/count`,
            method: 'get',
            params: {
                where
            },
            
        })
    }

/** 
 * Queries tags of Agency.
 */ 
    static get_Agencies_id_tags<T = any>(id: string,filter?: any,config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/Agencies/${id}/tags`,
            method: 'get',
            params: {
                filter
            },
            
        })
    }

/** 
 * Find all instances of the model matched by filter from the data source.
 */ 
    static get_Agencies<T = any>(filter?: any,config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/Agencies`,
            method: 'get',
            params: {
                filter
            },
            
        })
    }

/** 
 * Create a new instance of the model and persist it into the data source.
 */ 
    static post_Agencies<T = any>(data?: any,config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/Agencies`,
            method: 'post',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Find a model instance by {{id}} from the data source.
 */ 
    static get_Agencies_id<T = any>(id: string,filter?: any,config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/Agencies/${id}`,
            method: 'get',
            params: {
                filter
            },
            
        })
    }

/** 
 * Patch attributes for a model instance and persist it into the data source.
 */ 
    static patch_Agencies_id<T = any>(id: string,data?: any,config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/Agencies/${id}`,
            method: 'patch',
            
            data: {
                ...data
            },
        })
    }

/** 
 * moveToTrash
 */ 
    static delete_Agencies_trash<T = any>(ids: any,config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/Agencies/trash`,
            method: 'delete',
            
            data: {
                ids
            },
        })
    }

/** 
 * Get session's user is in.
 */ 
    static get_Agencies_id_user_userId_sessions<T = any>(userId: any,id: string,accessUserId?: any,config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/Agencies/${id}/user/${userId}/sessions`,
            method: 'get',
            params: {
                accessUserId
            },
            
        })
    }

/** 
 * Requests a demo for venzi platform
 */ 
    static post_Agencies_requestDemo<T = any>(form: any,accessUserId?: any,config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/Agencies/requestDemo`,
            method: 'post',
            
            data: {
                ...form, accessUserId
            },
        })
    }

/** 
 * calender
 */ 
    static get_Agencies_calender<T = any>(term?: any,filter?: any,recommended?: any,domain?: any,accessUserId?: any,config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/Agencies/calender`,
            method: 'get',
            params: {
                term, filter, recommended, domain, accessUserId
            },
            
        })
    }

}

export default AgenciesBase;
    