import { Typography } from '@material-ui/core';
import React from 'react';
import { IReactFormProps } from 'react-forms';
import 'rf-autocomplete';
import 'rf-country-picker';
import * as Yup from 'yup';
import { SALUTATIONS } from '../../Constants/Salutations';
import { TAgency } from '../../Models/Agency/@types';
import { TEntity } from '../../Models/EntityRegistrations/@types';
import { TFocusArea } from '../../Models/Events/@types';
import { phoneRegExp, postalCodeRegExp } from '../../Resources/AppConstants';
import utilities from '../../Resources/Utils';
import Terms from './Terms';

const MARKETING_LABEL =
    "I would like to receive further information with expert information from our marketing and engineering insiders on the topics of Application Delivery Management, Application Modernization & Connectivity, Information Management & Governance, IT Operations Management, Security, Vertica, TechBeacon, Micro Focus Online Community, Technology Transfer Partners ( TTP) community and be informed about future events in these subject areas. I hereby declare that I can manage and unsubscribe from this information at <a href='https://www.microfocus.com/company/unsubscribe/' target='_blank'>https://www.microfocus.com/company/unsubscribe/</a> at any time";
// const MARKETING_LABEL =
//   "I would like to receive Micro Focus Marketing communications that contain expert views from our marketing and engineering insiders, plus best practices that will help you get the best value out of your Micro Focus products.";
const NDA_LABEL = 'I Agree to NDA';

export const getFormConfig = (event: TEntity, disabledKeys: string[] = [], mobile: boolean = false, agency: TAgency) => {
    const { fields } = getFields(event, disabledKeys, mobile, agency);
    const relation = () => {
        if (agency.registrantsSettings.relation?.length)
            return [
                {
                    valueKey: 'entityRelation',
                    type: 'radio',
                    styles: {
                        width: '100%',
                    },
                    fieldProps: {
                        options: agency.registrantsSettings.relation || ['Other'],
                        header: `Relation with ${agency.name}`,
                    },
                    validation: [
                        {
                            type: 'string',
                        },
                        {
                            type: 'required',
                            error: 'Required',
                        },
                    ],
                },
            ];
        else return [];
    };
    const newConfig = /* (config.length && config) || */ [
        [fields.salutation, fields.firstName],
        [fields.lastName, fields.designation],
        [fields.company, fields.department],
        // [fields.street, fields.zipCode],
        // [fields.city, fields.country],
        // [fields.email, fields.phone],
        // [fields.industry],
        [fields.country, fields.email],
        [fields.phone, fields.industry],
        [...relation()],
        // [...fields.focusAreas(focusAreas)],
        [fields.agreedTnC],
        [fields.receiveUpdates],
    ];
    const mobileConfig = [
        fields.salutation,
        fields.firstName,
        fields.lastName,
        fields.designation,
        fields.company,
        // fields.department,
        // fields.street,
        // fields.zipCode,
        fields.city,
        fields.country,
        fields.email,
        fields.phone,
        ...relation(),
        // ...fields.focusAreas(focusAreas),
        fields.agreedTnC,
        fields.receiveUpdates,
    ];

    return mobile ? mobileConfig : newConfig;
};

export const formActionConfig: IReactFormProps['actionConfig'] = {
    displayActions: true,
    submitButtonProps: {
        variant: 'contained',
        color: 'primary',
    },
    submitButtonLayout: 'right',
    submitButtonText: 'NEXT',
};

export const getFormValidator = (event: TEntity, disabledKeys: string[] = [], mobile: boolean = false, agency: TAgency) => {
    /**
     * THESE ARE STATIC FIELDS WHICH WILL ALWAYS BE IN THE FORM FOR NOW.
     */
    let formValidator: { [key: string]: any } = {};
    let configWithValidation = getFormConfig(event, disabledKeys, mobile, agency);

    configWithValidation.forEach((item: any) => {
        if (Array.isArray(item)) {
            item.forEach((data: any) => {
                if (data.validation?.length) {
                    const res = utilities.yupValidation(data.validation);
                    formValidator = {
                        ...formValidator,
                        [data.valueKey]: res,
                    };
                }
            });
        } else {
            if (item.validation?.length) {
                const res = utilities.yupValidation(item.validation);

                formValidator = {
                    ...formValidator,
                    [item.valueKey]: res,
                };
            }
        }
    });
    return Yup.object({
        ...formValidator,
    });
};
const getFields = (event: TEntity, disabledKeys: string[] = [], mobile: boolean = false, agency: TAgency) => {
    const getFocusAreas = (focusAreas: TFocusArea[]) => {
        if (!focusAreas.length) {
            return [];
        }
        return [
            {
                valueKey: 'focusAreaIds',
                type: 'checkbox',
                fieldProps: {
                    header: 'Area of Interest',
                    color: 'primary',
                    options: focusAreas.map((i) => {
                        return { name: i.name, value: i.id };
                    }),
                },
                validation: [
                    { type: 'array' },
                    {
                        type: 'min length',
                        value: 1,
                        error: 'Required',
                    },
                ],
            },
        ];
    };
    const getNDA = (isEnabled: boolean) => {
        if (!isEnabled) return [];
        return [
            {
                valueKey: 'agreedNDA',
                styles: {
                    marginTop: -40,
                },
                type: 'checkbox',
                fieldProps: {
                    label: NDA_LABEL,
                    formControlLabelProps: {
                        label: <Typography variant="body2"> {NDA_LABEL}</Typography>,
                        style: { alignItems: 'flex-start' },
                    },
                    color: 'primary',
                },
                validation: [{ type: 'boolean' }],
            },
        ];
    };

    return {
        fields: {
            focusAreas: getFocusAreas,
            salutation: {
                valueKey: 'salutation',
                type: 'select',
                styles: {
                    width: '100%',
                },
                fieldProps: {
                    options: SALUTATIONS,
                    label: 'Salutation',
                    formControlProps: {
                        style: {
                            width: '100%',
                        },
                    },
                    style: {
                        minWidth: '100%',
                    },
                },
                validation: [
                    {
                        type: 'string',
                    },
                    {
                        type: 'required',
                        error: 'Required',
                    },
                ],
            },
            firstName: {
                valueKey: 'firstName',
                type: 'text',
                fieldProps: {
                    label: 'First Name',
                },
                validation: [
                    {
                        type: 'string',
                    },
                    {
                        type: 'required',
                        error: 'Required',
                    },
                ],
            },
            lastName: {
                valueKey: 'lastName',
                type: 'text',
                fieldProps: {
                    label: 'Last Name',
                },
                validation: [
                    {
                        type: 'string',
                    },
                    {
                        type: 'required',
                        error: 'Required',
                    },
                ],
            },
            designation: {
                valueKey: 'designation',
                type: 'text',
                fieldProps: {
                    label: 'Job Title',
                },
                validation: [
                    {
                        type: 'string',
                    },
                    {
                        type: 'required',
                        error: 'Required',
                    },
                ],
            },
            company: {
                valueKey: 'company',
                type: 'text',
                fieldProps: {
                    label: 'Company',
                },
                validation: [
                    {
                        type: 'string',
                    },
                    {
                        type: 'required',
                        error: 'Required',
                    },
                ],
            },
            department: {
                valueKey: 'department',
                type: 'text',
                fieldProps: {
                    label: 'Department',
                },
                validation: [
                    {
                        type: 'string',
                    },
                    {
                        type: 'required',
                        error: 'Required',
                    },
                ],
            },
            street: {
                valueKey: 'street',
                type: 'text',
                fieldProps: {
                    label: 'Street',
                },
                validation: [
                    {
                        type: 'string',
                    },
                    {
                        type: 'required',
                        error: 'Required',
                    },
                ],
            },
            zipCode: {
                valueKey: 'zipCode',
                type: 'text',
                fieldProps: {
                    label: 'Postal Code',
                },
                validation: [
                    {
                        type: 'string',
                    },
                    {
                        type: 'required',
                        error: 'Required',
                    },
                    {
                        type: 'matches',
                        error: 'Minimum 4 characters required.',
                        regex: postalCodeRegExp,
                    },
                ],
            },
            city: {
                valueKey: 'city',
                type: 'text',
                fieldProps: {
                    label: 'City',
                },
                validation: [
                    {
                        type: 'string',
                    },
                    {
                        type: 'required',
                        error: 'Required',
                    },
                ],
            },
            country: {
                valueKey: 'country',
                type: 'country',
                fieldProps: {
                    renderInputProps: {
                        label: 'Country',
                        autoComplete: 'nope',
                    },
                },
                validation: [
                    {
                        type: 'string',
                    },
                    {
                        type: 'required',
                        error: 'Required',
                    },
                    {
                        type: 'notNull',
                        error: 'Required',
                    },
                ],
            },
            email: {
                valueKey: 'email',
                type: 'text',
                fieldProps: {
                    disabled: disabledKeys.indexOf('email') > -1,
                    label: 'Email',
                },
                validation: [
                    {
                        type: 'string',
                    },
                    {
                        type: 'email',
                        error: 'Email is not Valid',
                    },
                    {
                        type: 'required',
                        error: 'Required',
                    },
                ],
            },
            phone: {
                valueKey: 'phone',
                type: 'phone',
                fieldProps: {
                    label: 'Phone',
                    countryCodeContainerProps: {
                        width: '50%',
                    },
                    phoneContainerProps: {
                        width: '50%',
                    },
                    inputLabelProps: {
                        shrink: true,
                    },
                    displayEmpty: true,
                    emptyItem: true,
                    emptyItemText: 'Country code',
                },
                validation: [
                    {
                        type: 'string',
                    },
                    {
                        type: 'required',
                        error: 'Required',
                    },
                    {
                        type: 'matches',
                        error: 'Phone number is not valid',
                        regex: phoneRegExp,
                    },
                ],
            },
            industry: {
                valueKey: 'industry',
                type: 'autocomplete',
                // styles: {
                //     width: !mobile ? "50%" : undefined,
                // },
                fieldProps: {
                    renderInputProps: {
                        placeholder: 'Type in your industry',
                        label: 'Industry',
                    },
                    // style: {
                    //     width: !mobile ? "50%" : undefined,
                    // },
                    autoHighlight: true,
                    freeSolo: true,
                    getOptionLabel: (o: any) => o,
                    options: agency.registrantsSettings.industry || ['Unclassified'],
                },
                validation: [
                    {
                        type: 'string',
                    },
                    {
                        type: 'required',
                        error: 'Required',
                    },
                ],
            },
            eventRelation: {
                valueKey: 'eventRelation',
                type: 'radio',
                styles: {
                    width: '100%',
                },
                fieldProps: {
                    options: agency.registrantsSettings.relation || ['Other'],
                    header: `Relation with ${agency.name}`,
                },
                validation: [
                    {
                        type: 'string',
                    },
                    {
                        type: 'required',
                        error: 'Required',
                    },
                ],
            },
            agreedTnC: {
                valueKey: 'agreedTnC',
                type: 'checkbox',

                fieldProps: {
                    formControlLabelProps: {
                        label: <Terms />, //'Agreed TNC',// <Agreements event={event} />,
                        style: { alignItems: 'flex-start' },
                    },
                    color: 'primary',
                    label: 'I agree to the Terms & Conditions and Privacy Policy',
                },
                validation: [
                    {
                        type: 'boolean',
                    },
                    {
                        type: 'required',
                        error: 'Required',
                    },
                    {
                        type: 'oneOf',
                        options: [true],
                        error: 'Required',
                    },
                ],
            },
            receiveUpdates: {
                valueKey: 'receiveUpdates',
                styles: {
                    marginTop: -30,
                },
                type: 'checkbox',
                fieldProps: {
                    label: MARKETING_LABEL,
                    color: 'primary',
                    formControlLabelProps: {
                        label: <Typography dangerouslySetInnerHTML={{ __html: MARKETING_LABEL }} variant="body2"></Typography>,
                        style: { alignItems: 'flex-start', '& :span': { paddingTop: 0 } },
                    },
                },
            },
            agreedNDA: getNDA,
        },
    };
};
