import { Box, Dialog, Fab } from '@material-ui/core';
import React, { FC, useState } from 'react';
import SessionVideo from '../../Features/Session/SessionVideo';
import { TAsset } from '../../Models/App/@types';
import { useStoreActions } from '../../Stores';

export interface VideoDialogProps {
    open: boolean;
    videos: TAsset[];
    afterClose?: Function;
}

const VideoDialog: FC<VideoDialogProps> = (props) => {
    const { open, videos = [] } = props;
    const [currentIndex, setIndex] = useState(0);
    const handleVideoEnd = (ind: number) => {
        if (ind < videos.length - 1) setIndex((i) => i + 1);
    };
    const { clodeVideoDialog } = useStoreActions(({ Dialogs: { closeVideoDialog: clodeVideoDialog } }) => ({ clodeVideoDialog }));
    const onClose = () => {
        props.afterClose?.();
        clodeVideoDialog();
    };
    if (!videos.length) return <div />;
    return (
        <Dialog
            fullScreen
            open={open}
            onClose={() => onClose()}
            PaperProps={{
                style: {
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                    position: 'fixed',
                },
            }}
        >
            <Fab style={{ color: 'white', boxShadow: 'none', zIndex: 12, top: 20, right: 20, position: 'absolute' }} size={'small'} onClick={() => onClose()}>
                <i className="material-icons">close</i>
            </Fab>
            <Box width={'100vw'} height={'100vh'} margin="auto">
                <SessionVideo onVideoEnd={() => handleVideoEnd(currentIndex)} url={videos[currentIndex].url} style={{ height: '100%', width: '100%', paddingTop: 0 }} />
            </Box>
        </Dialog>
    );
};

export default VideoDialog;
