import { Box, Drawer, DrawerProps, Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React from 'react';
import PlainHeader from '../Headers/PlainHeader';

export interface InfoDrawerProps extends Omit<DrawerProps, 'onClose'> {
    title: string
    onClose: () => void
}

const InfoDrawer: React.FC<InfoDrawerProps> = (props) => {
    const { title, ...drawerProps } = props;
    const classes = useStyles()

    return (
        <Drawer PaperProps={{ className: classes.paperRoot }} {...drawerProps} onClose={props.onClose}>
            <Box className={classes.root} position='relative'>
                <PlainHeader title={title} onClose={props.onClose} />
                {/* <Box px={0} py={2} display="flex" className={classes.root} flexDirection="column" alignItems="flex-start">
                    {/* <Box display="flex" className={classes.title} alignItems="center" >
                    <Typography >{title}</Typography>
                    <Box flex={1} />
                    {/* <IconButton className={classes.iconButton} onClick={props.onClose}>
                        <Icon>close</Icon>
                    </IconButton> }

            </Box> */}
                <Box width="100%">
                    {props.children}
                </Box>
            </Box>
        </Drawer >
    )
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    paperRoot: {
        backgroundColor: theme.palette.background.default,
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    root: {
        height: 'auto',
        [theme.breakpoints.up('sm')]: {
            width: 500
        }
    },
    title: {
        height: 64,
        width: '100%',
        boxSizing: 'border-box',
        position: 'sticky',
        boxShadow: 'none'
    },
    paper: {
        width: '100%',
        height: '100%',
        boxSizing: 'border-box',
        borderRadius: 0,
        paddingLeft: 32,
        paddingRight: 16,
        display: 'flex',
        alignItems: 'center',
        boxShadow: '0px 1px 2px #00000029',

    },
    iconButton: {
        backgroundColor: theme.palette.common.white
    }
}))

export default InfoDrawer