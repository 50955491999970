import { Theme } from "@material-ui/core/styles";
import { get } from "lodash";
import { useContext, useEffect } from "react";
import { ThemeContext } from "../../Context/ThemeContext";
import { TAgency } from "../../Models/Agency/@types";
import { useStoreState } from "../../Stores/index";
import { changePallete, overrrideComponentStylingFromTheme } from "./themeUtils";

export type useClientThemeConfig = {};

// const DEFAULT_FONT_MEDIUM = 'https://eventtool-content.s3.amazonaws.com/aktiv_ttf_6567.ttf'
// const DEFAULT_FONT_REGULAR = 'https://eventtool-content.s3.amazonaws.com/aktiv_ttf_8613.ttf'

export const useClientTheme = (_config?: useClientThemeConfig) => {
    const { agency } = useStoreState((state) => state.Agency);
    const { theme, setTheme } = useContext(ThemeContext);
    useEffect(() => {
        if (agency) {
            setFonts(agency);
            setColorPalette(agency);
            setOtherStylingsFromTheme(agency);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [agency]);

    /**
     *
     * @param agency agency
     * set theme - color palette
     */
    const setColorPalette = (agency: TAgency) => {
        const clientTheme = agency.theme;
        if (!clientTheme) return;
        let newTheme: Theme = changePallete("primary", theme, clientTheme.primary);
        newTheme = changePallete("secondary", newTheme, clientTheme.secondary);
        newTheme = overrrideComponentStylingFromTheme(newTheme);
        setTheme(newTheme);
    };

    const setOtherStylingsFromTheme = (agency: TAgency) => {
        const color = agency.theme?.primary?.main || "#0073E7";
        let css = `

        .icon {
            height: 24px;
            width: 24px;
            border-radius: 2px;
            fill : #363535;
            transition: 200ms linear,
        }

        .icon:hover{
            fill : ${color};

        }
        `;
        let head = document.head || document.getElementsByTagName("head")[0];
        let style: HTMLStyleElement = document.createElement("style");
        head.appendChild(style);
        style.type = "text/css";
        style.appendChild(document.createTextNode(css));
    };

    /**
     *
     * @param agency agency
     * sets font based on configuration from agency
     */
    const setFonts = (agency: TAgency) => {
        const medium = agency._fonts?.filter((f) => f.weight === "medium")[0];
        const regular = agency._fonts?.filter((f) => f.weight === "regular")[0];

        const fontMedium = medium?.url;
        const fontRegular = regular?.url;

        const re = /(?:\.([^.]+))?$/;
        const mediumFontType: FontType = get(re.exec(medium?.fileName || ""), "[1]") || "ttf";
        const regularFontType: FontType = get(re.exec(regular?.fileName || ""), "[1]") || "ttf";

        let fontMediumCss = fontMedium
            ? `@font-face {
                font-family: "Custom";
                font-weight: 500;
                src: url(${fontMedium}) format("${fontFormatMapping[mediumFontType]}");
              }`
            : "";
        let fontRegularCss = fontRegular
            ? `@font-face {
                font-family: "Custom";
                font-weight: 400;
                src: url(${fontRegular}) format("${fontFormatMapping[regularFontType]}");
              }`
            : "";
        let css = fontMediumCss + fontRegularCss;
        let head = document.head || document.getElementsByTagName("head")[0];
        let style: HTMLStyleElement = document.createElement("style");
        head.appendChild(style);
        style.type = "text/css";
        style.appendChild(document.createTextNode(css));
    };

    useEffect(() => {
        if (agency?.id) {
            const favIconUrl = agency?._favicon?.url || process.env.PUBLIC_URL + "/favicon.ico";
            const el = document.createElement("link");
            el.rel = "icon";
            el.href = favIconUrl;
            document.head.appendChild(el);
        }
    }, [agency]);
};

export type FontType = "ttf" | "otf";
const fontFormatMapping: Record<FontType, string> = {
    otf: "opentype",
    ttf: "truetype",
};
