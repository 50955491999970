import React from "react";
import { ReactForm, attachField } from "react-forms";
import DomainAllowMessage from "./DomainAllowMessage";
import { useFormConfig } from "./config";

export interface EmailVerificationFormData {
    email: string;
}

//@ts-ignore
attachField("domainAllowMessage", <DomainAllowMessage />);

interface EmailVerificationFormProps {
    onSubmit: Function;
    initialData: Partial<EmailVerificationFormData>;
    isSubmitting: boolean;
    emailOptInText?: string;
}

const EmailVerificationForm: React.FC<EmailVerificationFormProps> = (props) => {
    const { actionConfig, inputConfig, validationSchema } = useFormConfig({ emailOptInText: props.emailOptInText });
    return (
        <ReactForm
            initialValues={props.initialData}
            formId="email-verification"
            config={inputConfig}
            actionConfig={actionConfig}
            onSubmit={props.onSubmit}
            validationSchema={validationSchema}
            formSettings={{
                verticalSpacing: 20,
            }}
            isInProgress={props.isSubmitting}
        />
    );
};

export default EmailVerificationForm;
