import { Box, ClickAwayListener, Paper, Popper } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import React, { FC } from 'react';
import CloseButton from '../CloseButton';
export interface PopperProps {
    onClose?: () => void
    anchorEl: null | HTMLElement
}

const Poppers: FC<PopperProps> = (props) => {
    const classes = useStyles();
    const {
        anchorEl,
        onClose,
        children
    } = props;

    if (!anchorEl)
        return <div />
    return (
        <Box >
            <Popper anchorEl={anchorEl} open={Boolean(anchorEl)} style={{ zIndex: 1400 }}>
                <ClickAwayListener onClickAway={() => onClose?.()}>
                    <Paper elevation={2} className={classes.root}>
                        <CloseButton size='small' onClick={() => onClose?.()} variant='filledLight' className={classes.closeIcon} ></CloseButton>
                        {children}

                    </Paper>
                </ClickAwayListener>
            </Popper>
        </Box>
    )
}

const useStyles = makeStyles<Theme>((theme) => {
    return (createStyles({
        root: {
            width: 400,
            maxHeight: 500,
            borderRadius: 8,
            padding: 24,
            paddingTop: 15,
            position: 'relative',
            boxSizing: 'border-box',
            overflow: 'auto',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                maxHeight: '100%',
            }
        },

        closeIcon: {
            position: 'fixed',
            top: 12,
            right: 12,
            // background: '#FFFFFF 0% 0% no-repeat padding-box',
            // border: '1px solid #707070'
        }
    }))
})

export default Poppers