import { Box, IconButton, Paper } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Autolinker from 'autolinker';
import clsx from 'clsx';
import moment from 'moment';
import React, { FC } from 'react';
import Typo from '../../Components/Typo';
import { TMessage } from '../../Models/Chat/@types';
import UserModel from '../../Models/User';
import { TUser } from '../../Models/User/@types';
import { AttachmentContent } from './MessageCard';
export interface ChatReplyCardProps {
    msg: TMessage;
    onCancelPress: () => void;
    isCurrentUser?: boolean;
}

const ChatReplyCard: FC<ChatReplyCardProps> = (props) => {
    const classes = useStyles();
    const { msg: message, onCancelPress, isCurrentUser } = props;

    const textWithLink = Autolinker.link(message.text?.replace(/<\/?[^>]+(>|$)/g, '') || '');
    return (
        <Box>
            <div style={{ maxWidth: '100%', minWidth: 50 }}>
                <Paper elevation={1} className={clsx({ [classes.attachmentPaper]: Boolean(message.attachment) }, classes.messageBody)}>
                    <IconButton size={'small'} onClick={onCancelPress} className={classes.closeIcon}>
                        <CloseIcon fontSize={'small'} />
                    </IconButton>
                    <Box className={classes.messageMetaContainer} mb={0.5}>
                        {
                            <Box>
                                <Typo variant={'caption'} weight="medium" color="inherit" style={{ marginRight: 4 }}>
                                    {isCurrentUser ? 'You' : `${UserModel.getUserName(message.user as TUser)}`}
                                </Typo>
                            </Box>
                        }
                    </Box>
                    {message.attachment ? (
                        <>
                            <AttachmentContent asset={message.attachment} />
                        </>
                    ) : (
                        <Typo variant="body2" dangerouslySetInnerHTML={{ __html: `<p style='display:contents'>${textWithLink}</p>` }} />
                    )}
                    <Box className={classes.timeMeta} mb={'2px'}>
                        {' '}
                        <Typo className={clsx({ [classes.time]: !Boolean(message.attachment) })} variant={'overline'} color="inherit">
                            {moment(message.created).format('hh:mm a')}
                        </Typo>
                    </Box>
                </Paper>
            </div>
        </Box>
    );
};

const useStyles = makeStyles<Theme>((theme) => {
    return createStyles({
        attachmentPaper: {
            backgroundColor: '#fff',
        },
        messageBody: {
            padding: theme.spacing(1.5),
            minWidth: 50,
            position: 'relative',
            marginLeft: 0,
            '& span': {
                fontWeight: 400,
            },
            overflow: 'hidden',
            whiteSpace: 'break-spaces',
            transition: '200ms',
        },
        messageMetaContainer: {
            display: 'flex',
            alignItems: 'center',
        },
        timeMeta: {
            position: 'absolute',
            bottom: 2,
            right: 4,
        },
        closeIcon: {
            position: 'absolute',
            top: 5,
            right: 5,
        },
    });
});

export default ChatReplyCard;
