import React, { FC, useMemo, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, Fade } from '@material-ui/core';
import FullCalendar, { CalendarOptions, EventClickArg, EventInput } from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import { useStoreState } from '../../Stores';
import AgencyModel from '../../Models/Agency';
import AgencyContentCard from '../../Features/Agency/AgencyContentCard';
import { TAgencyContent } from '../../Models/Agency/@types';
import Popover from '../../Features/Popover/FlowPopover';
import Typo from '../../Components/Typo';

export interface CalendarViewProps {

}

const CalendarView: FC<CalendarViewProps> = (props) => {
    const classes = useStyles({});
    const [item, setItem] = useState<TAgencyContent | undefined>();
    const [cardDisplayOption, setCardDisplayOption] = useState<{ item?: TAgencyContent, el: HTMLElement | null }>({
        el: null
    })
    const { contentList } = useStoreState(({ Agency: { contentList } }) => ({ contentList }))
    const close = () => setCardDisplayOption({ el: null })


    const events: EventInput[] = useMemo(() => {
        return contentList.map<EventInput>(c => {
            if (AgencyModel.isExternalEvent(c)) {
                return {
                    title: c.name,
                    id: c.id,
                    className: classes.externalEvent,
                    start: c.startTime,
                    end: c.endTime,
                }
            }
            if (AgencyModel.isZine(c) || AgencyModel.isAsset(c))
                return {}
            return {
                title: c.name,
                className: classes.internalEvent,
                start: c.startTime,
                end: c.endTime,
                id: c.id
            }
        })
    }, [contentList])


    const handleEventClick = (e: EventClickArg) => {
        const _event = e.event.toPlainObject();
        const item = contentList.find(item => item.id === _event.id);
        if (!item) return;
        setCardDisplayOption({
            item,
            el: e.el
        })
        // showCard(item)
    }

    const showCard = (item: TAgencyContent) => {
        setItem(item);
    }

    const handleClose = () => setItem(undefined);


    // const renderCards: CalendarOptions['eventContent'] = (info) => {
    //     const _event = contentList.find(e => e.id === info.event.id);

    //     return _event ? (
    //         <Box position='relative'>
    //             <Typo variant='caption' contrast>{info.event.title}</Typo>
    //             <div className='event-card'>
    //                 <AgencyContentCard item={_event} />
    //             </div>
    //         </Box>
    //     ) : <div />
    // }


    return (
        <Box className={classes.root}>
            <FullCalendar
                plugins={[dayGridPlugin]}
                initialView="dayGridMonth"
                events={events}
                stickyHeaderDates

                // eventDidMount={handleEventMount}
                // eventContent={renderCards}
                eventClassNames={classes.event}
                eventClick={handleEventClick}
            />

            {/* <Fade enter={Boolean(item)}>
                {item ? (
                    <Box className={classes.agencyCard} p={1} width={300}>
                        <AgencyContentCard item={item} />
                    </Box>
                ) : <div />}
            </Fade> */}

            {Boolean(cardDisplayOption.el) && cardDisplayOption.item ? (
                <Popover
                    PaperProps={{ style: { boxShadow: 'none', backgroundColor: 'transparent' } }}
                    onClose={close}

                    id={cardDisplayOption.item.id}
                    anchorEl={cardDisplayOption.el}
                >
                    <Box p={1} className={classes.agencyCard} width={600}>
                        <AgencyContentCard variant='list' item={cardDisplayOption.item} />
                    </Box>
                </Popover>

            ) : null}
        </Box>
    )
}

const useStyles = makeStyles<Theme, any>((theme) => ({
    root: {
        maxWidth: 940,
        paddingBottom: 300,
        margin: '0 auto',
        '& .fc-daygrid-day-number': {
            color: theme.palette.text.primary
        },
        '& .fc-col-header-cell-cushion ': {
            color: theme.palette.primary.main
        },
        '& .fc .fc-button-primary': {
            backgroundColor: theme.palette.primary.main,
            boxShadow: theme.shadows[1],
            border: 'none'
        },
        '& .fc-day': {
            // position: 'relative'
        },
        '& .fc-event-time': {
            display: 'none'
        },
        '& .fc-daygrid-event-dot': {
            display: 'none'
        },
        '& .fc-event': {
            padding: 2,
            backgroundColor: theme.palette.primary.main
        },
        '& .fc-event-title': {
            color: 'white',
            fontSize: 12,
            fontWeight: theme.typography.fontWeightRegular
        }
    },
    internalEvent: {
        // backgroundColor: 'blue'
    },
    externalEvent: {
        // backgroundColor: 'red'
    },
    agencyCard: {
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    event: {
        // '&:hover': {
        //     '& .event-card': {
        //         zIndex: 999,
        //         opacity: 1,
        //     }
        // }
    }
}));

export default CalendarView;