import React, { FC } from 'react';
import MetaTags from 'react-meta-tags';
import { useTranslation } from '../Hooks/useTranslation';
import { useStoreState } from '../Stores/index';

export interface PageTitleProps {
    title?: string
    // description?: string
    // ogTitle?: string
    // ogImage?: string
    // ogDescription?: string
}




const TempElem: FC<{ desc?: string }> = (props) => {
    return <div id={'event-desc'} dangerouslySetInnerHTML={{ __html: props.desc || '' }} style={{ display: 'none' }} />
}
const Meta: FC<PageTitleProps> = (props) => {
    const { getTranslation } = useTranslation();
    const label = getTranslation("navbar.home");
    const { title = /*'Home'*/ label } = props;
    const event = useStoreState(state => state.Events.eventDetails);
    const PREFIX = `${event?.name || 'venzi'} | `
    const elem = document.getElementById("event-desc");
    const text = elem?.innerText;
    const META = {
        description: event?.ogSubTitle || text,
        title: event?.ogTitle || event?.name,
    }

    const OGImage = event?._webImages?.find(i => i.type === 'ogImage')?.url || (process.env.PUBLIC_URL + "/assets/OG.jpg")

    return (
        <>
            <MetaTags>
                <title>{PREFIX + title}</title>
                <meta name="description" content={META.description} />
                <meta property="og:title" content={META.title} />
                <meta property="og:image" content={OGImage} />
                <meta property="og:description" content={META.description} />

            </MetaTags>
            <TempElem desc={event?.description} />
        </>
    )
}



export default Meta