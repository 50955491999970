import { Box } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import React, { FC } from 'react';
import LiveJson from '../../Assets/Lottie/live.json';
import { ContrastText } from '../../Components';
import LottieImage from '../../Components/LottieImage';

export interface LiveChipProps {
    count: number,
    top?: number
}

const LiveChip: FC<LiveChipProps> = (props) => {
    const classes = useStyles();
    const { count, top = 20 } = props;
    return (
        <Box className={classes.root} style={{ top }}>
            {/* <Dot size={8} color={'#fff'} mr={1} /> */}
            <LottieImage dimension={{ width: '15px', height: '15px' }} lotteJSON={LiveJson} />
            <Box mr={1} />
            <ContrastText variant='overline'>{`${count} Live`}</ContrastText>
        </Box>
    )
}

const useStyles = makeStyles<Theme>((theme) => {
    return (createStyles({
        root: {
            backgroundColor: '#38B468',
            display: 'flex',
            alignItems: 'center',
            padding: '5px 8px',
            borderRadius: 14,
            position: 'absolute',
            // top: 20,
            right: 15,
            zIndex: 1101
        }
    }))
})

export default LiveChip