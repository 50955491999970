import { Button, ButtonProps } from '@material-ui/core';
import React, { FC } from 'react';
import { useTranslation } from '../../Hooks/useTranslation';
import { TSession } from '../../Models/Agenda/@types';

export interface PlayVideoProps extends ButtonProps {
    session: TSession;
    label?: string;
}

const PlayVideo: FC<PlayVideoProps> = (props) => {
    const { getTranslation } = useTranslation();
    const { session, ...buttonProps } = props;

    const isVideo = session.type === 'video' && session._video;

    const isExternalVideo = (session?.type === 'externalConferenceCall' || session?.type === 'noStream' || session.type === 'streamingUrl') && session.allowPlayback && session._conferenceRecording;
    const isRecordedVideo = session.type === 'conferenceCall' && session.allowPlayback === true && (session._conferenceRecording || session.recordingCompleted);

    return isVideo || isExternalVideo || isRecordedVideo ? (
        <Button color="primary" variant="text" {...buttonProps}>
            {/* {label} */}
            {getTranslation('agendaCard.playVideoButton')}
        </Button>
    ) : (
        <div />
    );
};

export default PlayVideo;
