import { Box, Button, Link as MUILink } from '@material-ui/core';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { capitalize } from 'lodash';
import React, { FC, useCallback, useMemo } from 'react';
import AddToCal2 from '../../Components/AddToCalendar/AddToCal2';
import BadgeTag from '../../Components/Badge/BadgeTag';
import Typo from '../../Components/Typo';
import useLayoutQuery from '../../Hooks/useLayoutQuery';
import { useMediaQuery } from '../../Hooks/useMediaQuery';
import AgencyModel from '../../Models/Agency';
import { TAgencyContent } from '../../Models/Agency/@types';
import utilities from '../../Resources/Utils';
import { useStoreState } from '../../Stores';
import { THEME_PALETTE } from '../../Theme/themeValues';
import { getGradientFromTheme } from '../Theme/themeUtils';

export interface AgencyContentCardProps {
    item: TAgencyContent;
    variant?: 'grid' | 'list';
}

const DATE_FORMAT = 'ddd, MMM DD, hh:mm a';

const AgencyContentCard: FC<AgencyContentCardProps> = (props) => {
    const { item, variant = 'grid' } = props;
    const { appConstants } = useStoreState(({ App: { appConstants } }) => ({ appConstants }));
    const { ref, width } = useLayoutQuery();
    const classes = useStyles({ width, variant });
    const theme = useTheme();
    const { isDeviceSm } = useMediaQuery();

    const languageResolver = (val: string) => {
        if (appConstants) {
            const options = appConstants.AppModel?.languages || [];
            return options.find((o) => o.value === val)?.name || val;
        }
        return val;
    };

    const getExternalUrl = (item: TAgencyContent) => {
        if (AgencyModel.isZine(item)) return window.location.origin + '/zine/' + (item.slug || item.id);
        if (AgencyModel.isAsset(item) && item._file) return window.location.origin + '/asset/' + (item.slug || item.id);
        if (AgencyModel.isExternalEvent(item)) return item.url;

        return window.location.origin + '/' + item.slug;
    };
    const isResource = useMemo(() => {
        return AgencyModel.isZine(item) || AgencyModel.isAsset(item);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item]);
    const data = useMemo(() => {
        if (AgencyModel.isZine(item) || AgencyModel.isAsset(item)) {
            return {
                date: '',
                title: item.name,
                target: '_blank',
                type: AgencyModel.isAsset(item) ? capitalize(item.mimeType) : 'Zine',
                focusAreas: AgencyModel.getFocusAreas(item.focusAreas || []),
                description: item.description || '',
                languages: AgencyModel.getLanguagesString(item, languageResolver),
                isRecommended: item.recommended,
                image: item._coverImage?.url,
                externalUrl: getExternalUrl(item),
            };
        }
        if (AgencyModel.isExternalEvent(item)) {
            const _timezone = typeof item.timezone === 'string' ? item.timezone : item.timezone;
            return {
                endDate: item.endTime ? AgencyModel.getCardDateString(item.endTime, _timezone) : '',
                date: item.startTime ? AgencyModel.getCardDateString(item.startTime, _timezone) : '',
                title: item.name,
                type: AgencyModel.getContentType(item)?.toUpperCase(),
                isRecommended: item.recommended,
                focusAreas: AgencyModel.getFocusAreas(item.focusAreas || []),
                languages: AgencyModel.getLanguagesString(item, languageResolver),
                image: item._coverImage?.url,
                // redirectUrl: getRedirectUrl(item),
                externalUrl: getExternalUrl(item),
                description: item.description || '',
                shortDescription: item.description || '',
                target: '_blank',
            };
        }

        const _timezone = typeof item.timeZone === 'string' ? item.timeZone : item.timeZone;
        return {
            date: item.startTime ? AgencyModel.getCardDateString(item.startTime, _timezone) : '',
            endDate: item.endTime ? AgencyModel.getCardDateString(item.endTime, _timezone) : '',
            title: item.name,
            languages: AgencyModel.getLanguagesString(item, languageResolver),
            type: AgencyModel.getContentType(item)?.toUpperCase(),
            isRecommended: item.recommended,
            focusAreas: AgencyModel.getFocusAreas(item.focusAreas || []),
            shortDescription: item.shortDescription,
            image: utilities.getBannerImage('calendar', item, isDeviceSm)?.url,
            // redirectUrl: getRedirectUrl(item),
            externalUrl: getExternalUrl(item),
            description: item.description || '',
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item]);

    const DateBadge = useMemo(() => <BadgeTag className={classes.date} bgColor="#000" text={data.date} textColor="#fff" />, [data]);

    const withLink = useCallback(
        (content: JSX.Element, style?: object) => {
            if (data.externalUrl)
                return (
                    <MUILink target={data.target} href={data.externalUrl} style={style || {}}>
                        {content}
                    </MUILink>
                );
            return content;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [data]
    );

    if (isResource)
        return (
            <div ref={ref} className={classes.root}>
                {withLink(
                    <div className={classes.imageContainer}>
                        <img className={classes.img + ' img'} src={data.image} />
                    </div>,
                    { width: '100%' }
                )}
                <Box display="flex" flex={1} flexDirection={variant === 'grid' ? 'row' : 'column'}>
                    {withLink(
                        <Box className={classes.typeContainer} display="flex" alignItems="center">
                            <BadgeTag bgColor={theme.palette.secondary.main} text={data.type || ''} textColor="#fff" />
                            {data.isRecommended ? (
                                <BadgeTag bgColor={THEME_PALETTE.others.recommendedGreen} text="Recommended" textColor="#fff" />
                            ) : null}
                        </Box>
                    )}
                    <div className={clsx(classes.content)}>
                        {withLink(
                            <>
                                {data.date && variant == 'grid' ? DateBadge : null}
                                <Typo className={classes.title} variant={variant == 'grid' ? 'body1' : 'h6'} weight={'bold'}>
                                    {data.title}
                                </Typo>
                                {data.languages ? <Typo variant="body2">Languages: {data.languages}</Typo> : null}
                                {data.focusAreas ? <Typo variant="body2">Focus areas: {data.focusAreas}</Typo> : null}
                                {variant === 'list' && data.description ? <Typo variant="body2">{data.description}</Typo> : null}
                                <Box flex={1} />
                            </>,
                            data.externalUrl ? { flex: 1 } : {}
                        )}
                        <Box display="flex" justifyContent="flex-end" alignItems="center" mb={variant == 'grid' ? 1 : 0}>
                            {data.externalUrl ? (
                                <MUILink target="_blank" className={classes.actionButton} href={data.externalUrl}>
                                    DETAILS
                                </MUILink>
                            ) : (
                                <Button disabled className={classes.actionButton}>
                                    COMING SOON
                                </Button>
                            )}
                        </Box>
                    </div>
                </Box>
            </div>
        );

    return (
        <div ref={ref} className={classes.root}>
            {withLink(
                <div className={classes.imageContainer}>
                    <img className={classes.img + ' img'} src={data.image} />
                </div>,
                { width: '100%' }
            )}
            <Box display="flex" flex={1} flexDirection={variant === 'grid' ? 'row' : 'column'}>
                {withLink(
                    <Box className={classes.typeContainer} display="flex" alignItems="center">
                        {variant === 'list' && data.date && !isResource ? DateBadge : null}
                        <BadgeTag bgColor={theme.palette.secondary.main} text={data.type || ''} textColor="#fff" />
                        {data.isRecommended ? <BadgeTag bgColor={THEME_PALETTE.others.recommendedGreen} text="Recommended" textColor="#fff" /> : null}
                    </Box>
                )}
                <div className={clsx(classes.content)}>
                    {withLink(
                        <>
                            {data.date && variant == 'grid' ? DateBadge : null}
                            <Typo className={classes.title} variant={variant == 'grid' ? 'body1' : 'h6'} weight={'bold'}>
                                {data.title}
                            </Typo>
                            {data.languages ? <Typo variant="body2">Languages: {data.languages}</Typo> : null}
                            {data.focusAreas ? <Typo variant="body2">Focus areas: {data.focusAreas}</Typo> : null}
                            {variant === 'list' && data.shortDescription ? <Typo variant="body2">{data.shortDescription}</Typo> : null}
                            <Box flex={1} />
                        </>,
                        data.externalUrl ? { flex: 1 } : {}
                    )}
                    <Box className={classes.actionButtonContainer} display="flex" alignItems="center">
                        <AddToCal2 title={data.title} start={data.date || ''} end={data.endDate || ''} />
                        {data.externalUrl ? (
                            <MUILink className={classes.actionButton} target={data.target} href={data.externalUrl}>
                                DETAILS
                            </MUILink>
                        ) : (
                            <Button disabled className={classes.actionButton}>
                                COMING SOON
                            </Button>
                        )}
                    </Box>
                </div>
            </Box>
        </div>
    );
};

const LIST_CARD_IMAGE_SIZE = 124;

const useStyles = makeStyles<Theme, { width: number; variant: 'grid' | 'list' }>((theme) => ({
    root: {
        backgroundColor: 'white',
        position: 'relative',
        display: 'flex',
        padding: (props) => (props.variant === 'grid' ? 0 : theme.spacing(2)),
        flexDirection: (props) => (props.variant === 'grid' ? 'column' : 'row'),
        boxShadow: theme.shadows[1],
        borderRadius: theme.shape.borderRadius,
        alignItems: 'flex-start',
    },
    imageContainer: {
        borderTopLeftRadius: (props) => (props.variant === 'grid' ? theme.shape.borderRadius : 0),
        borderTopRightRadius: (props) => (props.variant === 'grid' ? theme.shape.borderRadius : 0),
        background: getGradientFromTheme(theme),
        width: (props) => (props.variant === 'grid' ? '100%' : LIST_CARD_IMAGE_SIZE),
        height: (props) => (props.variant === 'grid' ? props.width : LIST_CARD_IMAGE_SIZE),
    },
    img: {
        borderTopLeftRadius: (props) => (props.variant === 'grid' ? theme.shape.borderRadius : 0),
        borderTopRightRadius: (props) => (props.variant === 'grid' ? theme.shape.borderRadius : 0),
        // background: getGradientFromTheme(theme),
        width: (props) => (props.variant === 'grid' ? '100%' : LIST_CARD_IMAGE_SIZE),
        objectFit: 'cover',
        height: (props) => (props.variant === 'grid' ? props.width : LIST_CARD_IMAGE_SIZE),
    },
    content: {
        width: (props) => props.width - 8 - (props.variant === 'grid' ? 0 : LIST_CARD_IMAGE_SIZE + 20),
        position: 'relative',
        minHeight: (props) => (props.variant === 'list' ? LIST_CARD_IMAGE_SIZE : 80),
        padding: (props) => (props.variant === 'grid' ? theme.spacing(1) : theme.spacing(0, 1, 1, 1)),
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: (props) => (props.variant === 'list' ? 'column' : 'column'),
    },
    date: {
        position: (props) => (props.variant === 'grid' ? 'absolute' : 'relative'),
        left: (props) => (props.variant === 'grid' ? 0 : 0),
        top: (props) => (props.variant === 'grid' ? -14 : 0),
    },
    typeContainer: {
        width: 'fit-content',
        boxShadow: theme.shadows[2],
        position: 'absolute',
        // position: props => props.variant === 'grid' ? 'absolute' : 'relative',
        top: (props) => (props.variant === 'grid' ? 10 : -12),
        left: (props) => (props.variant === 'grid' ? -10 : 16),
    },
    actionButtonContainer: {
        // marginTop: props => props.variant === 'list' ? '8px' : undefined,
        // position: props => props.variant === 'list' ? 'absolute' : 'relative',
        // right: 0,
        // bottom: 0,
        justifyContent: 'flex-end',
    },
    title: {
        marginTop: (props) => (props.variant === 'grid' ? theme.spacing(1) : 0),
        // minHeight: props => props.variant === 'grid' ? 60 : 0
    },
    actionButton: {
        fontFamily: theme.typography.body1.fontFamily,
        fontSize: theme.typography.body2.fontSize,
        fontWeight: theme.typography.fontWeightBold,
    },
}));

export default AgencyContentCard;
