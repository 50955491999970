import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import Typo, { TypoProps } from '../Typo';
import clsx from 'clsx';

export interface BadgeTagProps {
    bgColor?: string
    textColor?: string
    text: string
    className?: string
    elevated?: boolean
    typoProps?: TypoProps
}

const BadgeTag: FC<BadgeTagProps> = (props) => {
    const { className, elevated = false, bgColor = '#fff', textColor = '#000', text, typoProps = {} } = props;
    const classes = useStyles({ bgColor, textColor });

    return (
        <div className={clsx(classes.root, className, { [classes.elevated]: elevated })}>
            <Typo color='inherit' variant='body2' weight='medium' {...typoProps} >{text}</Typo>
        </div>
    )
}

const useStyles = makeStyles<Theme, { bgColor: string, textColor: string }>((theme) => ({
    root: {
        display: 'inline-block',
        padding: '1px 8px',
        backgroundColor: props => props.bgColor,
        color: props => props.textColor
    },
    elevated: {
        boxShadow: theme.shadows[2]
    }
}));

export default BadgeTag;