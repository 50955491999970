import { Avatar, Box, IconButton, Paper, Typography } from '@material-ui/core';
import { createStyles, fade, makeStyles, Theme } from '@material-ui/core/styles';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import { Autolinker } from 'autolinker';
import clsx from 'clsx';
import moment from 'moment';
import React, { FC, useEffect, useRef, useState } from 'react';
import { ContrastText } from '../../Components';
import Typo from '../../Components/Typo';
import { TAsset } from '../../Models/App/@types';
import { TMessage } from '../../Models/Chat/@types';
import { TPicture } from '../../Models/Picture/@types';
import UserModel from '../../Models/User';
import { TUser } from '../../Models/User/@types';
import utilities from '../../Resources/Utils';
import MessageCardMenu from './MessageCardMenu';
import { ChatUtils } from './useChat';

export interface MessageCardProps {
    isCurrentUser: boolean;
    isLastUserSame?: boolean;
    message: TMessage;
    isStaff?: boolean;
    onReply: (msg: TMessage) => void;
    disabled?: boolean;
}
const MessageCard: FC<MessageCardProps> = (props) => {
    const { isCurrentUser, message, isLastUserSame = false, isStaff = false, onReply: handleReply, disabled } = props;
    const classes = useStyles({ isCurrentUser, isLastUserSame });
    const [isInViewport, setisInViewport] = useState(false);
    const textWithLink = Autolinker.link(message.text?.replace(/<\/?[^>]+(>|$)/g, '') || '');
    const [menuAnchor, setMenuAnchor] = useState<HTMLElement>();
    const node = useRef<HTMLDivElement>(null);
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => setMenuAnchor(e.currentTarget);
    const onReply = () => {
        handleReply(message);
        setMenuAnchor(undefined);
    };
    let options = {
        root: null,
        rootMargin: '0px',
        threshold: 1,
    };
    const parentMessage = message.parentMessage;
    const onClick = () => {
        let msgToReach = document.getElementById(parentMessage!.id!);
        let root = document.getElementById('messages-container');
        if (root && msgToReach) {
            root.scrollTo({ behavior: 'smooth', top: msgToReach.offsetTop });
        }
    };
    useEffect(() => {
        if (node.current?.id && message.id) {
            let observer = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting) {
                    setisInViewport(true);
                }
            }, options);
            observer.observe(node.current);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [node.current?.id, message.id]);
    const isAttachment = Boolean(message.attachment);
    return (
        <div className={classes.root} id="message-card" ref={node}>
            {isCurrentUser || isLastUserSame ? null : (
                <Avatar className={classes.avatar} src={isInViewport ? utilities.getTransformedImageUrl(message.user?._profileImage?.url || '', 45, 45) : ''}>
                    {message.user?.firstName?.slice(0, 1)}
                </Avatar>
            )}
            <div style={{ maxWidth: '100%', minWidth: 150 }}>
                <Paper className={clsx({ [classes.attachmentPaper]: isAttachment }, classes.messageBody)}>
                    {message.hidden ? null : (
                        <IconButton disabled={disabled} size={'small'} onClick={handleClick} className={classes.messageOptions}>
                            <ExpandMoreRoundedIcon fontSize={'small'} />
                        </IconButton>
                    )}

                    <Box className={classes.messageMetaContainer} mb={isLastUserSame ? 0 : 0.5}>
                        {isLastUserSame ? null : (
                            <Box>
                                <Typo variant={'caption'} weight="medium" color="inherit" style={{ marginRight: 4 }}>
                                    {isCurrentUser ? 'You' : `${message.user?.firstName} ${message.user?.lastName}`}
                                </Typo>
                            </Box>
                        )}
                        {!isLastUserSame && isStaff ? (
                            <Box className={classes.staff} p={0.5} pb={'3px'} mr={0.5}>
                                <ContrastText variant={'overline'} weight="medium">
                                    STAFF
                                </ContrastText>
                            </Box>
                        ) : null}
                    </Box>

                    {message.deleted === true || message.hidden === true ? (
                        <Typography variant="caption" style={{ fontStyle: 'italic' }}>
                            {'This message has been deleted'}
                        </Typography>
                    ) : (
                        <>
                            {parentMessage ? (
                                <div onClick={onClick} className={clsx(classes.repliedMsg, { [classes.replyDark]: true })}>
                                    <Typo variant={'caption'} weight="medium">
                                        {UserModel.getUserName(parentMessage?.user as TUser)}
                                    </Typo>
                                    {parentMessage.attachment ? <AttachmentContent mini asset={parentMessage.attachment} loadImage={isInViewport} /> : <Typo variant="body2">{parentMessage.text?.substr(0, 120)}</Typo>}
                                </div>
                            ) : null}
                            {
                                message.attachment ? (
                                    <>
                                        <AttachmentContent asset={message.attachment} />
                                        <div className={classes.buttonsContainer}>
                                            <Typography download={message.attachment.name} target="_blank" component={'a'} href={message.attachment.url} variant={'caption'}>
                                                DOWNLOAD
                                            </Typography>
                                        </div>
                                    </>
                                ) : (
                                    <Typography variant="body2" dangerouslySetInnerHTML={{ __html: `<p style='display:contents'>${textWithLink}</p>` }} />
                                )
                                // <Typography variant='body2' >{message.text}</Typography>
                            }
                        </>
                    )}
                    <Box className={classes.timeMeta} mb={'2px'}>
                        {' '}
                        <Typography className={clsx({ [classes.time]: !Boolean(message.attachment) })} variant={'overline'} color="inherit">
                            {moment(message.created).format('hh:mm a')}
                        </Typography>
                    </Box>
                    <MessageCardMenu anchorEl={menuAnchor} open={Boolean(menuAnchor)} onClose={() => setMenuAnchor(undefined)} menuConfig={message.id ? [{ id: 'Reply-to-message', label: 'Reply', onClick: onReply }] : []} />
                </Paper>
            </div>
        </div>
    );
};
export const AttachmentContent: FC<{ asset: TAsset & TPicture; mini?: boolean; loadImage?: boolean }> = ({ asset, mini, loadImage = true }) => {
    const classes = useStyles({ isCurrentUser: false, isLastUserSame: false, mini });
    const type = asset?.type || asset?.mimetype || '';
    const Icon = ChatUtils.getAttachmentIcon(type);
    const assetName = mini ? asset.name.substr(0, 75) : asset.name;
    return (
        <div className={classes.attachmentContent}>
            <div className={classes.preview}>
                {asset ? (
                    /image/g.test(asset.type || asset.fileType || '') ? (
                        <img src={loadImage ? asset.thumbnailUrl : ''} alt={asset.name || ''} width={'100%'} height={'auto'} />
                    ) : (
                        <Box py={1}>
                            <Icon fontSize={mini ? 'small' : 'large'} color="inherit" />
                        </Box>
                    )
                ) : null}
            </div>
            <div className={classes.metaContainer}>
                <Typo variant={'caption'} weight="medium" component="span" color="inherit">
                    {assetName || ''}
                </Typo>
                {mini ? null : (
                    <Typo variant={'overline'} color="inherit">
                        {asset?.size ? utilities.bytesToSize(asset?.size) : ''}
                    </Typo>
                )}
            </div>
        </div>
    );
};
const useStyles = makeStyles<Theme, { isCurrentUser: boolean; isLastUserSame: boolean; mini?: boolean }>((theme) => {
    const {
        palette: { grey, secondary },
    } = theme;
    return createStyles({
        root: {
            boxSizing: 'border-box',
            width: '100%',

            paddingRight: ({ isCurrentUser }) => (isCurrentUser ? 15 : 60),
            paddingLeft: ({ isCurrentUser }) => (isCurrentUser ? 80 : 15),
            //     marginLeft: ({ isLastUserSame, isCurrentUser }) => isLastUserSame && !isCurrentUser ? 41 : 0,
            display: 'flex',
            justifyContent: ({ isCurrentUser }) => (isCurrentUser ? 'flex-end' : 'flex-start'),
        },
        avatar: {
            height: 45,
            width: 45,
            backgroundColor: theme.palette.secondary.main,
            borderRadius: '100%',
            marginRight: 5,
        },
        messageMetaContainer: {
            display: 'flex',
            alignItems: 'flex-start',
        },
        messageBody: {
            borderRadius: ({ isCurrentUser }) => (isCurrentUser ? '8px 0px 8px 8px' : '0px 8px 8px 8px'),
            padding: ({ isLastUserSame }) => (isLastUserSame ? '8px 8px 16px 8px' : '8px 8px 16px 8px'),
            minWidth: 50,
            boxShadow: '0px 1px 2px #00000029',
            position: 'relative',
            marginLeft: ({ isLastUserSame, isCurrentUser }) => (isLastUserSame && !isCurrentUser ? 50 : 0),
            // border: ({ isCurrentUser }) => isCurrentUser ? `1px solid ${theme.palette.secondary.main}` : 'none',
            '& span': {
                fontWeight: 400,
            },
            overflow: 'hidden',
            whiteSpace: 'break-spaces',
            transition: '200ms',
            '& > button:first-child': {
                transform: 'translateX(8px)',
                transition: '200ms',
                opacity: 0,
                backgroundColor: '#fff',
            },
            '&:hover > button:first-child': {
                // visibility: 'visible',
                transition: '200ms',
                transform: 'translateX(0px)',
                opacity: 1,
            },
        },
        margin: {
            marginLeft: 'auto',
        },
        time: {
            color: grey['200'],
        },
        attachmentPaper: {
            // backgroundColor: grey['800'],
            // color: '#fff'
        },
        buttonsContainer: {
            display: 'flex',
            justifyContent: 'flex-end',
            '& a': {
                fontWeight: 700,
                marginLeft: 8,
                // color: '#fff'
            },
        },
        attachmentContent: {
            alignItems: 'flex-start',
            display: 'flex',
            marginTop: 8,
            marginBottom: 8,
        },
        preview: {
            width: ({ mini }) => (mini ? 32 : 64),
            height: 'auto',
            backgroundColor: secondary.main,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#fff',
        },
        metaContainer: {
            marginLeft: 10,
            // color: '#fff'
        },
        staff: {
            display: 'flex',
            background: `linear-gradient(143deg, ${utilities.hexToRgb(theme.palette.primary.main, '1')} 0%, ${utilities.hexToRgb(theme.palette.secondary.main, '1')} 100%) 0% 0% no-repeat padding-box`,
        },
        timeMeta: {
            position: 'absolute',
            bottom: 2,
            right: 4,
        },
        messageOptions: {
            position: 'absolute',
            top: 0,
            right: 0,
        },
        repliedMsg: {
            margin: `2px 0px`,
            padding: theme.spacing(1, 0.5),
            borderTopRightRadius: 4,
            borderBottomRightRadius: 4,
            cursor: 'pointer',
        },
        replyDark: {
            borderLeft: `1px solid ${fade(grey['600'], 0.5)}`,
            background: '#EAEAEA', //`transparent linear-gradient(143deg, ${utilities.hexToRgb(theme.palette.primary.main, '0.15')} 0%, ${utilities.hexToRgb(theme.palette.secondary.main, '0.15')} 100%) 0% 0% no-repeat padding-box`,
        },
    });
});

export default React.memo(MessageCard);
