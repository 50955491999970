import { FormControl, Icon, InputAdornment, TextField, Theme } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { get } from "lodash";
import React from "react";
import { IFieldProps } from "react-forms";
import { useTranslation } from "../Hooks/useTranslation";

interface EmailFieldProps extends IFieldProps {
    handleBack?: () => void;
    disabled?: true;
}

const EmailField: React.FC<EmailFieldProps> = (props) => {
    const classes = useStyles();

    const value = props.formikProps?.values[props.fieldConfig?.valueKey || ""];
    const onBack = get(props, "fieldProps.onBack") || ((email: string) => {});

    const error = !!props.formikProps?.errors["email"];
    const errorText = props.formikProps?.errors["email"] || "";
    const { getTranslation } = useTranslation();

    return (
        <div>
            <FormControl className={classes.formControl}>
                <TextField
                    value={value || ""}
                    label={getTranslation("emailInputForm.emailInputLabel")}
                    // onChange={handleChange}
                    error={error}
                    name="email"
                    helperText={errorText}
                    InputProps={{
                        disabled: props.disabled,
                        endAdornment: (
                            <InputAdornment className={classes.adornment} onClick={() => onBack(value)} position="end">
                                <Icon color="primary">edit</Icon>
                            </InputAdornment>
                        ),
                    }}
                />
            </FormControl>
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            display: "flex",
        },
        adornment: {
            cursor: "pointer",
        },
    })
);

export default EmailField;
