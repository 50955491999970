import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuthDialog, useAuthDialogConfig } from '../../Dialogs/Auth/useAuthDialog';
import { LoginFormData } from '../../Forms/LoginForm';
import { useStoreActions, useStoreState } from '../../Stores/index';
import { useNavMenu } from '../AppHeader/useNavMenu';
import { useAuth } from '../Auth/useAuth';
import { TUser } from '../../Models/User/@types';

export type useRegistrantHandlerConfig = {
    initRegistrant: boolean
    baseUrl?: string
}

export const useRegistrantHandler = (config: useRegistrantHandlerConfig & Partial<useAuthDialogConfig>) => {
    const { initRegistrant } = config
    const history = useHistory()
    const { BASE_URL } = useNavMenu()
    const setRegstrant = useStoreActions(state => state.Registrant.setRegistrantFormData)

    const { isAuthenticated, user } = useStoreState(({ User: { isAuthenticated, user }, }) => ({ isAuthenticated, user, }))
    const { userLogin, userSignup, isRegistered, forgotPassword } = useAuth()
    const { AuthComponent, openAuthDialog, openGuestDialog, openLoginDialog, openSignupDialog } = useAuthDialog({
        onLogin: async (formData: LoginFormData) => {
            const user = await userLogin(formData)
            if (initRegistrant) {
                setRegstrant({ ...getRegistrantInfoFromUserData(user) })
            }

        },
        onForgotPassword: forgotPassword,
        handleCheck: isRegistered,
        onSignup: userSignup,
        onClose: () => {
            setTimeout(() => {
                // history.goBack()
                history.push(config.baseUrl || BASE_URL)
            }, 260);
        },
        ...config
    })
    const getRegistrantInfoFromUserData = (user: TUser) => {
        return {
            email: user.email || '',
            firstName: user.firstName || '',
            lastName: user.lastName || '',
            designation: user.designation || '',
            company: user.company || '',
            department: user.department || '',
            street: user.street || '',
            zipCode: user.zipCode || '',
            city: user.city || '',
            country: user.country || null,
            phone: user.phone || '',
            industry: user.industry || null,
            eventRelation: user.eventRelation || '',
            salutation: user.salutation || ''
        }
    }

    useEffect(() => {
        if (!isAuthenticated)
            openAuthDialog()
        else if (user && initRegistrant) {
            console.log({ user })
            setRegstrant({
                ...getRegistrantInfoFromUserData(user)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])



    return {
        isAuthenticated,
        AuthComponent,
        openAuthDialog,
        openLoginDialog,
        openGuestDialog,
        openSignupDialog
    }
}