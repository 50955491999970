import { Box } from '@material-ui/core';
import EventModel from 'Models/Events';
import React from 'react';
import { Checkpoint } from 'react-compound-timer/build';
import { Link } from 'react-router-dom';
import { TrackEvent } from '../../../analytics.config';
import { LiveDot, RoundedButton } from '../../../Components';
import CountdownTimer from '../../../Components/CountdownTimer';
import Typo from '../../../Components/Typo';
import { TBannerConfig } from '../../../Context/BannerContext/@types';
import { useAuthDialog } from '../../../Dialogs/Auth/useAuthDialog';
import { useMediaQuery } from '../../../Hooks/useMediaQuery';
import { useTranslation } from '../../../Hooks/useTranslation';
import { useStoreState } from '../../../Stores';
import { useNavMenu } from '../../AppHeader/useNavMenu';
import { useAuth } from '../../Auth/useAuth';
import CheckIn from '../../CheckIn';
import useAutoRegister from '../../Registration/useAutoRegister';
import { getBannerMessages } from './GenericMessage';

interface PreEventBannerProps {
    loggedIn?: boolean;
    registered?: boolean;
    timeRemaining: number;
    registrantionStatus?: 'cancelled' | 'closed' | 'unregistered' | 'approved' | 'rejected' | 'checkedIn';
    checkPoints?: Checkpoint[];
    registrationOpen?: boolean;
    showCheckIn?: boolean;
}
const PreEventBanner: React.FC<PreEventBannerProps> = (props) => {
    const { getTranslation } = useTranslation();
    const { loggedIn = false, registered = false, registrantionStatus = 'unregistered', registrationOpen, showCheckIn = false } = props;
    const { isDeviceSm } = useMediaQuery();
    const { BASE_URL } = useNavMenu();
    const { eventSettings, eventDetails } = useStoreState(({ Events: { eventDetails, eventSettings } }) => ({ eventSettings, eventDetails }));
    const { RoundedRegisterButton } = useAutoRegister();
    const { userLogin, userSignup, isRegistered, forgotPassword } = useAuth();
    const { AuthComponent, openAuthDialog } = useAuthDialog({
        onLogin: userLogin,
        onSignup: userSignup,
        handleCheck: isRegistered,
        onForgotPassword: forgotPassword,
    });

    const trackRegisterClick = () => {
        TrackEvent.log('registerRequested');
    };

    const requiresApproval = eventSettings?.approvalRequired ?? false;
    const { autoRegister , eventType = 'default'} = eventDetails || {};
    if (!loggedIn)
        return (
            <Box display="flex" mt={isDeviceSm ? 1 : 0} alignItems={isDeviceSm ? 'flex-start' : 'center'} width={isDeviceSm ? '100%' : undefined} flexDirection={isDeviceSm ? 'column' : 'row'}>
                <Box mt={isDeviceSm ? 1 : 0}>
                    <Typo variant={'subtitle2'} weight="medium">
                        {getBannerMessages('notLoggedIn', eventType,autoRegister)}
                    </Typo>
                </Box>
                {isDeviceSm ? (
                    <Box width="100%" display="flex">
                        <Box flex={1} />
                        <RoundedButton onClick={openAuthDialog} variant="contained" color="secondary">
                            {getTranslation('signUpWarningDialogBox.signInButton')}
                        </RoundedButton>
                    </Box>
                ) : null}
                {AuthComponent}
            </Box>
        );
    if (registered && registrantionStatus === 'approved' && showCheckIn)
        return (
            <Box display="flex" mt={isDeviceSm ? 1 : 0} alignItems={isDeviceSm ? 'flex-start' : 'center'} width={isDeviceSm ? '100%' : undefined} flexDirection={isDeviceSm ? 'column' : 'row'}>
                <>
                    <Box display="flex" alignItems="center">
                        <LiveDot mr={1} />
                        <Typo variant={'subtitle2'} weight="medium">
                            {`You can now check-in to this ${EventModel.getEventLabel()}.`}
                        </Typo>
                    </Box>
                    <Box width={isDeviceSm ? '100%' : undefined} display="flex">
                        <Box flex={1} />
                        <Box ml={3}>
                            <CheckIn />
                        </Box>
                    </Box>
                </>
            </Box>
        );
    const getBannerContent = () => {
        if (!registered && !registrationOpen)
            return (
                <Box>
                    <Typo variant={'subtitle2'} weight="medium">
                        {getBannerMessages('registrationClosed', eventType,autoRegister)}
                    </Typo>
                </Box>
            );
        if ((!registered && registrantionStatus === 'unregistered') || (registered && registrantionStatus === 'cancelled'))
            return (
                <>
                    <Box mx={isDeviceSm ? 0 : 3}>
                        <Typo variant={'subtitle2'} weight="medium">
                            {registrantionStatus === 'cancelled' ? getBannerMessages('cancelled', eventType,autoRegister) : getBannerMessages('notRegistered', eventType,autoRegister)}
                        </Typo>
                    </Box>

                    {registrationOpen && (
                        <Box width={isDeviceSm ? '100%' : undefined} display="flex">
                            <Box flex={1} />
                            {autoRegister ? (
                                RoundedRegisterButton
                            ) : (
                                <Link rel="noopener" to={`${BASE_URL}/register`}>
                                    <RoundedButton onClick={trackRegisterClick} borderColor="white" variant="contained" color="secondary">
                                        {getTranslation('mainBanner.registerButton')}
                                    </RoundedButton>
                                </Link>
                            )}
                        </Box>
                    )}
                </>
            );
        if (registered && registrantionStatus === 'rejected')
            return (
                <Box>
                    <Typo variant={'subtitle2'} weight="medium">
                        {getBannerMessages('cancelled', eventType,autoRegister)}
                    </Typo>
                </Box>
            );
        if (registered && registrantionStatus !== 'cancelled' && registrantionStatus !== 'rejected')
            return (
                <Box>
                    <Typo variant={'subtitle2'} weight="medium">
                        {!requiresApproval || registrantionStatus === 'approved' || registrantionStatus === 'checkedIn' ? getBannerMessages('registered', eventType,autoRegister) : getBannerMessages('notApproved', eventType,autoRegister)}
                    </Typo>
                </Box>
            );
        return null;
    };
    return (
        <Box display="flex" mt={isDeviceSm ? 1 : 0} alignItems={isDeviceSm ? 'flex-start' : 'center'} width={isDeviceSm ? '100%' : undefined} flexDirection={isDeviceSm ? 'column' : 'row'}>
            {getBannerContent()}
        </Box>
    );
};

export const getPreEventBannerConfig = (config: PreEventBannerProps): TBannerConfig => ({
    content: {
        left: <CountdownTimer initialTimer={config.timeRemaining} checkpoints={config.checkPoints || []} />,
        right: <PreEventBanner {...config} />,
    },
    size: 'large',
});

export default PreEventBanner;
