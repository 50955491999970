import { useState, useEffect } from 'react';
import { useStoreState } from '../../Stores';
import AgendaModel from '../../Models/Agenda';
import { TSession } from '../../Models/Agenda/@types';


export const useRunningSession = (sessionId: string) => {
    const { sessions } = useStoreState(({ Events: { eventDetails, allowedSessions: sessions } }) => ({ eventDetails, sessions }))
    const upcoming = AgendaModel.getUpcomingSessions(sessions);
    const [currentSession, setCurrentSession] = useState<TSession>()
    let tSession = sessions.find(i => i.id === sessionId)
    useEffect(() => {
        if (sessionId) {

            let ss = sessions.find(j => j.id === sessionId);
            if (ss) {
                setCurrentSession(ss);
            }

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sessionId, tSession])

    const curIndex = upcoming.findIndex(s => s.id === sessionId)
    const isLive = currentSession && AgendaModel.isSessionLive(currentSession.startTime, currentSession.endTime)

    return {
        currentSession,
        nextSession: upcoming[curIndex + 1],
        isLive
    }
}