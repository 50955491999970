import { AppBar, Box, Icon, IconButton, Theme, Toolbar } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LOGO from "../../Assets/Logo/default_logo.svg";
import { useMediaQuery } from "../../Hooks/useMediaQuery";
import { useStoreState } from "../../Stores/index";
import MenuDrawer from "./MenuDrawer";
import NavBar from "./NavBar";
import { useNavMenu } from "./useNavMenu";
import UserMenu from "./UserMenu";

interface AppHeaderProps {
    previewMode?: boolean;
}

/**
 * List of paths where the app-bar should not be shown
 */
const EXCLUDE_PATHS_PATTERNS = ["zoom-client", "fullscreen", "empty-screen"];

const shouldDisableHeader = (pathname: string) => {
    let disable = false;
    EXCLUDE_PATHS_PATTERNS.forEach((p) => {
        if (new RegExp(p).test(pathname)) disable = true;
    });
    return disable;
};

const AppHeader: React.FC<AppHeaderProps> = (props) => {
    const { previewMode = false } = props;
    const { user, agency } = useStoreState(({ User: { user }, Agency: { agency } }) => ({ user, agency }));
    const classes = useStyles();
    const { isDeviceSm } = useMediaQuery();

    const { BASE_URL, pathname } = useNavMenu();
    const [drawerOpen, setDrawerOpen] = useState(false);

    const openDrawer = () => setDrawerOpen(true);
    const closeDrawer = () => setDrawerOpen(false);

    const disableHeader = shouldDisableHeader(pathname);

    const logo = agency?._logo?.url || LOGO;

    if (disableHeader) return <div />;

    return (
        <AppBar elevation={1} position="sticky" className={classes.appbar}>
            {previewMode && <div className={classes.previewBanner}>PREVIEW</div>}
            <Toolbar classes={{ gutters: classes.gutter }} className={classes.shrunk}>
                {!isDeviceSm && <Box width={"20px"} />}
                <Link rel="noopener" to={`${BASE_URL}`}>
                    <img src={logo} height="50px" alt="logo" />
                </Link>
                <Box flex={1} />
                {!isDeviceSm && <NavBar />}

                {!isDeviceSm && <UserMenu />}

                {isDeviceSm && (
                    <IconButton color="primary" onClick={openDrawer}>
                        <Icon>menu</Icon>
                    </IconButton>
                )}
            </Toolbar>

            <MenuDrawer user={user} open={drawerOpen} onClose={closeDrawer} />
        </AppBar>
    );
};

export const HEADER_HEIGHT = 64;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        appbar: {
            top: 0,
            backgroundColor: theme.palette.common.white,
            transition: "200ms ease-in",
            boxShadow: "0px 1px 2px #00000029",
            "& img": {
                transition: "200ms ease-out",
            },
        },
        shrunk: {
            height: HEADER_HEIGHT,
            transition: "200ms ease-in",
            "& img": {
                height: 36,
            },
        },
        previewBanner: {
            position: "absolute",
            width: 100,
            height: 20,
            textAlign: "center",
            transform: "rotate(-45deg) translateX(-28px)",
            backgroundColor: "#ff9800",
        },
        gutter: {
            [theme.breakpoints.down("sm")]: {
                paddingRight: 4,
            },
        },
    })
);

export default AppHeader;
