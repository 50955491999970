import { createStyles, Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import omit from 'lodash/omit';
import React, { FC } from 'react';
import { attachField, FormConfig, IFormActionProps, ReactForm } from 'react-forms';
import * as Yup from 'yup';
import ConsentMessage from '../../Components/ConsentMessage';
import { useTranslation } from '../../Hooks/useTranslation';
import EmailField from '../EmailField';
import usePasswordField from '../usePasswordField';

attachField('emailField', <EmailField />);
attachField('consent', <ConsentMessage />);

export interface SignupFormData {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
}

interface IProps {
    onSubmit: Function;
    isSubmitting?: boolean;
    onBack?: (email: string) => void;
    initialData?: Partial<SignupFormData>;
}

const useFormActionConfig = () => {
    const { getTranslation } = useTranslation();
    const classes = useStyles();
    const config: IFormActionProps = {
        submitButtonText: getTranslation('emailInputForm.nextButtonLabel'),
        submitButtonLayout: 'right',
        submitButtonProps: {
            size: 'large',
        },
        containerClassNames: classes.buttonContainer,
    };
    return config;
};

export const SignupForm: FC<IProps> = (props) => {
    const { getTranslation } = useTranslation();
    const passwordField = usePasswordField('password', getTranslation('signUpForm.passwordInputLabel'));
    const CONFIG: Array<Array<FormConfig> | FormConfig> = [
        {
            type: 'emailField',
            valueKey: 'email',
            fieldProps: {
                onBack: props.onBack,
            },
        },
        {
            type: 'text',
            valueKey: 'firstName',
            fieldProps: {
                label: getTranslation('signUpForm.firstNameInputLabel'),
                fullWidth: true,
            },
        },
        {
            type: 'text',
            valueKey: 'lastName',
            fieldProps: {
                label: getTranslation('signUpForm.lastNameInputLabel'),
                fullWidth: true,
            },
        },
        passwordField,
        {
            type: 'checkbox',
            valueKey: 'consent',
            fieldProps: {
                label: <ConsentMessage />,
                formControlLabelProps: {
                    style: {
                        alignItems: 'flex-end',
                    },
                },
                style: {
                    padding: 0,
                },
            },
        },
    ];
    const formValidation = Yup.object({
        firstName: Yup.string().trim().required('Name is required'),
        lastName: Yup.string().trim().required('Name is required'),
        email: Yup.string().email(getTranslation('loginForm.invalidEmailLabel')).required(getTranslation('loginForm.emailRequiredLabel')),
        password: Yup.string().min(6, 'Password must be at least 6 characters').required(getTranslation('loginForm.passwordRequiredLabel')),
        consent: Yup.boolean().oneOf([true], 'This is required').required('This is required'),
        // repeatPassword: Yup.string().required('Required')
        //     .oneOf([Yup.ref('password'), null], 'Password must match')
    });

    const formActionConfig = useFormActionConfig();
    const handleSubmit = (values: any) => props.onSubmit(omit(values, 'consent'));
    return (
        <ReactForm
            formId="login-form"
            config={CONFIG}
            actionConfig={formActionConfig}
            initialValues={props.initialData}
            onSubmit={handleSubmit}
            validationSchema={formValidation}
            formSettings={{
                verticalSpacing: 20,
            }}
            isInProgress={props.isSubmitting}
        ></ReactForm>
    );
};

export default SignupForm;

const useStyles = makeStyles<Theme>((theme) =>
    createStyles({
        buttonContainer: {
            marginTop: 40,
        },
    })
);
