import Box, { BoxProps } from '@material-ui/core/Box';
import React from 'react';
import LiveJson from '../../Assets/Lottie/live.json';
import LottieImage from '../LottieImage';

interface LiveDotProps extends BoxProps {}

const LiveDot: React.FC<LiveDotProps> = (props) => {
    return (
        <Box {...props}>
            <LottieImage dimension={{ width: '20px', height: '20px' }} lotteJSON={LiveJson} />
        </Box>
    );
};

export default LiveDot;
