
import axios, {AxiosRequestConfig} from 'axios';
// THIS IS A GENERATED FILE; PLEASE DO NOT MAKE CHANGES HERE

class EventsBase {

/** 
 * Queries scenes of Event.
 */ 
    static get_Events_id_scenes<T = any>(id: string,filter?: any,config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/Events/${id}/scenes`,
            method: 'get',
            params: {
                filter
            },
            
        })
    }

/** 
 * Creates a new instance in scenes of this model.
 */ 
    static post_Events_id_scenes<T = any>(id: string,data?: any,config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/Events/${id}/scenes`,
            method: 'post',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Queries presentations of Event.
 */ 
    static get_Events_id_presentations<T = any>(id: string,filter?: any,config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/Events/${id}/presentations`,
            method: 'get',
            params: {
                filter
            },
            
        })
    }

/** 
 * Queries eventPersons of Event.
 */ 
    static get_Events_id_eventPersons<T = any>(id: string,filter?: any,config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/Events/${id}/eventPersons`,
            method: 'get',
            params: {
                filter
            },
            
        })
    }

/** 
 * Upsert many EventPerson
 */ 
    static put_Events_id_eventPersons<T = any>(eventPersons: any,id: string,merge?: any,accessUserId?: any,config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/Events/${id}/eventPersons`,
            method: 'put',
            
            data: {
                eventPersons, merge, accessUserId
            },
        })
    }

/** 
 * Find a model instance by {{id}} from the data source.
 */ 
    static get_Events_id<T = any>(id: string,filter?: any,config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/Events/${id}`,
            method: 'get',
            params: {
                filter
            },
            
        })
    }

/** 
 * Find all instances of the model matched by filter from the data source.
 */ 
    static get_Events<T = any>(filter?: any,config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/Events`,
            method: 'get',
            params: {
                filter
            },
            
        })
    }

/** 
 * 
 */ 
    static get_Events_feedback_csv<T = any>(id: string,config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/Events/feedback-csv`,
            method: 'get',
            params: {
                id
            },
            
        })
    }

/** 
 * 
 */ 
    static get_Events_id_registrations_csv<T = any>(id: string,term?: any,filter?: any,config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/Events/${id}/registrations-csv`,
            method: 'get',
            params: {
                term, filter
            },
            
        })
    }

/** 
 * moveToTrash
 */ 
    static delete_Events_trash<T = any>(ids: any,config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/Events/trash`,
            method: 'delete',
            
            data: {
                ids
            },
        })
    }

/** 
 * Publish entity.
 */ 
    static post_Events_id_publish<T = any>(publish: any,id: string,config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/Events/${id}/publish`,
            method: 'post',
            
            data: {
                publish
            },
        })
    }

/** 
 * 
 */ 
    static get_Events_id_partners_partnerId<T = any>(partnerId: any,id: string,accessUserId?: any,config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/Events/${id}/partners/${partnerId}`,
            method: 'get',
            params: {
                accessUserId
            },
            
        })
    }

/** 
 * 
 */ 
    static patch_Events_id_partners_partnerId<T = any>(partnerId: any,updates: any,id: string,accessUserId?: any,config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/Events/${id}/partners/${partnerId}`,
            method: 'patch',
            
            data: {
                ...updates, accessUserId
            },
        })
    }

/** 
 * 
 */ 
    static post_Events_id_partners<T = any>(updates: any,id: string,partnerId?: any,accessUserId?: any,config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/Events/${id}/partners`,
            method: 'post',
            
            data: {
                ...updates, partnerId, accessUserId
            },
        })
    }

/** 
 * Get session by eventId
 */ 
    static get_Events_id_sessions_sessionId<T = any>(sessionId: any,id: string,accessUserId?: any,config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/Events/${id}/sessions/${sessionId}`,
            method: 'get',
            params: {
                accessUserId
            },
            
        })
    }

/** 
 * Update Session
 */ 
    static patch_Events_id_sessions_sessionId<T = any>(sessionId: any,session: any,id: string,accessUserId?: any,config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/Events/${id}/sessions/${sessionId}`,
            method: 'patch',
            
            data: {
                ...session, accessUserId
            },
        })
    }

/** 
 * Delete Session
 */ 
    static delete_Events_id_sessions_sessionId<T = any>(sessionId: any,id: string,accessUserId?: any,config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/Events/${id}/sessions/${sessionId}`,
            method: 'delete',
            
            data: {
                accessUserId
            },
        })
    }

/** 
 * Create new Session
 */ 
    static post_Events_id_sessions<T = any>(session: any,id: string,sessionId?: any,accessUserId?: any,config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/Events/${id}/sessions`,
            method: 'post',
            
            data: {
                ...session, sessionId, accessUserId
            },
        })
    }

/** 
 * joinByStaff
 */ 
    static get_Events_id_sessions_sessionId_join_staff<T = any>(sessionId: any,id: string,accessUserId?: any,config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/Events/${id}/sessions/${sessionId}/join-staff`,
            method: 'get',
            params: {
                accessUserId
            },
            
        })
    }

/** 
 * downloadAssets
 */ 
    static get_Events_id_sessions_sessionId_download_assets<T = any>(contentId: any,sessionId: any,id: string,accessUserId?: any,config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/Events/${id}/sessions/${sessionId}/download-assets`,
            method: 'get',
            params: {
                contentId, accessUserId
            },
            
        })
    }

/** 
 * 
 */ 
    static get_Events_id_sessions_sessionId_recording<T = any>(sessionId: any,id: string,accessUserId?: any,config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/Events/${id}/sessions/${sessionId}/recording`,
            method: 'get',
            params: {
                accessUserId
            },
            
        })
    }

/** 
 * 
 */ 
    static get_Events_id_sessions_sessionId_meeting_creds<T = any>(sessionId: any,id: string,accessUserId?: any,config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/Events/${id}/sessions/${sessionId}/meeting-creds`,
            method: 'get',
            params: {
                accessUserId
            },
            
        })
    }

/** 
 * start
 */ 
    static get_Events_id_sessions_sessionId_start<T = any>(sessionId: any,id: string,accessUserId?: any,config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/Events/${id}/sessions/${sessionId}/start`,
            method: 'get',
            params: {
                accessUserId
            },
            
        })
    }

/** 
 * start
 */ 
    static get_Events_id_sessions_sessionId_start_stream<T = any>(sessionId: any,id: string,accessUserId?: any,config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/Events/${id}/sessions/${sessionId}/start-stream`,
            method: 'get',
            params: {
                accessUserId
            },
            
        })
    }

/** 
 * getMeetingInfo
 */ 
    static get_Events_id_sessions_sessionId_meeting_info<T = any>(sessionId: any,id: string,accessUserId?: any,config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/Events/${id}/sessions/${sessionId}/meeting-info`,
            method: 'get',
            params: {
                accessUserId
            },
            
        })
    }

/** 
 * getSessionJoinedPercentages
 */ 
    static get_Events_id_analytics_joinedSessionPercentages<T = any>(id: string,accessUserId?: any,config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/Events/${id}/analytics/joinedSessionPercentages`,
            method: 'get',
            params: {
                accessUserId
            },
            
        })
    }

/** 
 * stats
 */ 
    static get_Events_id_analytics_stats<T = any>(id: string,accessUserId?: any,config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/Events/${id}/analytics/stats`,
            method: 'get',
            params: {
                accessUserId
            },
            
        })
    }

/** 
 * stats
 */ 
    static get_Events_id_analytics_joinedSessionUserIdsByPercentage_sessionId<T = any>(sessionId: any,id: string,low?: any,high?: any,filterByLive?: any,live?: any,accessUserId?: any,config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/Events/${id}/analytics/joinedSessionUserIdsByPercentage/${sessionId}`,
            method: 'get',
            params: {
                low, high, filterByLive, live, accessUserId
            },
            
        })
    }

/** 
 * sessionStats
 */ 
    static get_Events_id_analytics_user_stats_registrationId<T = any>(registrationId: any,id: string,accessUserId?: any,config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/Events/${id}/analytics/user-stats/${registrationId}`,
            method: 'get',
            params: {
                accessUserId
            },
            
        })
    }

/** 
 * Get EventPerson by Id
 */ 
    static get_Events_id_eventPersons_eventPersonId<T = any>(eventPersonId: any,id: string,accessUserId?: any,config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/Events/${id}/eventPersons/${eventPersonId}`,
            method: 'get',
            params: {
                accessUserId
            },
            
        })
    }

/** 
 * Delete EventPerson
 */ 
    static delete_Events_id_eventPersons_eventPersonId<T = any>(eventPersonId: any,id: string,accessUserId?: any,config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/Events/${id}/eventPersons/${eventPersonId}`,
            method: 'delete',
            
            data: {
                accessUserId
            },
        })
    }

/** 
 * Re order event persons.
 */ 
    static patch_Events_id_eventPersons_reorder<T = any>(eventPersonEmails: any,id: string,accessUserId?: any,config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/Events/${id}/eventPersons/reorder`,
            method: 'patch',
            
            data: {
                eventPersonEmails, accessUserId
            },
        })
    }

/** 
 * Change Event Person Email
 */ 
    static patch_Events_id_eventPersons_eventPersonId_changeEmail<T = any>(email: any,eventPersonId: any,id: string,accessUserId?: any,config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/Events/${id}/eventPersons/${eventPersonId}/changeEmail`,
            method: 'patch',
            
            data: {
                email, accessUserId
            },
        })
    }

/** 
 * checkIn
 */ 
    static post_Events_id_checkIn<T = any>(id: string,accessUserId?: any,config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/Events/${id}/checkIn`,
            method: 'post',
            
            data: {
                accessUserId
            },
        })
    }

/** 
 * Event EventFeedback
 */ 
    static post_Events_id_feedback<T = any>(feedback: any,id: string,accessUserId?: any,config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/Events/${id}/feedback`,
            method: 'post',
            
            data: {
                feedback, accessUserId
            },
        })
    }

/** 
 * get my registration
 */ 
    static get_Events_id_my_registration<T = any>(id: string,accessUserId?: any,config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/Events/${id}/my-registration`,
            method: 'get',
            params: {
                accessUserId
            },
            
        })
    }

/** 
 * Updates an event.
 */ 
    static patch_Events_id_update<T = any>(updates: any,id: string,accessUserId?: any,config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/Events/${id}/update`,
            method: 'patch',
            
            data: {
                ...updates, accessUserId
            },
        })
    }

/** 
 * Updates settings of an event.
 */ 
    static patch_Events_id_settings<T = any>(settings: any,id: string,accessUserId?: any,config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/Events/${id}/settings`,
            method: 'patch',
            
            data: {
                ...settings, accessUserId
            },
        })
    }

/** 
 * gets checkIn count for an event.
 */ 
    static get_Events_id_checkInCount<T = any>(id: string,accessUserId?: any,config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/Events/${id}/checkInCount`,
            method: 'get',
            params: {
                accessUserId
            },
            
        })
    }

/** 
 * Checks if event slug is valid.
 */ 
    static get_Events_id_isSlugValid<T = any>(slug: any,id: string,accessUserId?: any,config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/Events/${id}/isSlugValid`,
            method: 'get',
            params: {
                slug, accessUserId
            },
            
        })
    }

/** 
 * 
 */ 
    static get_Events_search<T = any>(term?: any,filter?: any,accessUserId?: any,config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/Events/search`,
            method: 'get',
            params: {
                term, filter, accessUserId
            },
            
        })
    }

/** 
 * 
 */ 
    static post_Events_id_duplicate<T = any>(id: string,updates?: any,accessUserId?: any,config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/Events/${id}/duplicate`,
            method: 'post',
            
            data: {
                updates, accessUserId
            },
        })
    }

/** 
 * 
 */ 
    static get_Events_id_meetings<T = any>(id: string,accessUserId?: any,config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/Events/${id}/meetings`,
            method: 'get',
            params: {
                accessUserId
            },
            
        })
    }

/** 
 * Invalidate redis cache
 */ 
    static patch_Events_id_invalidateCache<T = any>(id: string,accessUserId?: any,config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/Events/${id}/invalidateCache`,
            method: 'patch',
            
            data: {
                accessUserId
            },
        })
    }

/** 
 * Start All Chats
 */ 
    static post_Events_id_start_chats<T = any>(id: string,accessUserId?: any,config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/Events/${id}/start-chats`,
            method: 'post',
            
            data: {
                accessUserId
            },
        })
    }

}

export default EventsBase;
    