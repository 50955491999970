import axios, { AxiosError, AxiosRequestConfig } from 'axios';

export type TCookieKey = 'UUID' | 'access_token' | 'infoPopover';

export const COOKIES_ACCESS_TOKEN: TCookieKey = 'access_token';
export const COOKIES_UUID: TCookieKey = 'UUID';

export const request = async<T>(config: AxiosRequestConfig, log = true): Promise<T> => {
    if (!axios.defaults.baseURL) {
        throw new Error('Error: Base Url is not provided');
    }
    const resp = await axios.request<T>(config);

    return resp.data;
};

export const loadScript = (filePath: string, fileId: string): Promise<void> => {
    const scriptTag = document.getElementById(fileId);
    if (scriptTag)
        return Promise.resolve();
    return new Promise((resolve, reject) => {
        const el = document.createElement('script');
        el.src = filePath;
        el.id = fileId;
        el.async = true;
        document.head.appendChild(el);
        el.onload = () => {
            resolve();
        };
        el.onerror = () => {
            reject();
        }
    })
}


const utils = {
    getDomain: () => {
        const x = /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n?]+)/img.exec(document.referrer);
        return x?.[1]

    },
    inIframe: () => {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    },
    setCookie: (key: TCookieKey, data: string) => localStorage.setItem(key, data),
    getCookie: (key: TCookieKey) => localStorage.getItem(key),
    clearCookies: () => {
        localStorage.removeItem(COOKIES_UUID)
        localStorage.removeItem(COOKIES_ACCESS_TOKEN)
    },

    camelToTitleCase: (str: string) => {
        return str
            .replace(/[0-9]{2,}/g, match => ` ${match} `)
            .replace(/[^A-Z0-9][A-Z]/g, match => `${match[0]} ${match[1]}`)
            .replace(/[A-Z][A-Z][^A-Z0-9]/g, match => `${match[0]} ${match[1]}${match[2]}`)
            .replace(/[ ]{2,}/g, match => ' ')
            .replace(/\s./g, match => match.toUpperCase())
            .replace(/^./, match => match.toUpperCase())
            .trim();
    },
    dismissInfoMessage: (key: string) => localStorage.setItem(key, 'dismissed'),
    isInfoMessageDismissed: (key: string) => !!localStorage.getItem(key),

    getFeedbackRecord: (key: string) => localStorage.getItem(key) || '',

    setFeedbackRecord: (key: string, val: string) => localStorage.setItem(key, val),

    setBaseAPI_URL: (url: string) => axios.defaults.baseURL = url,

    setContentType: (type = 'Content-Type', value = 'application/json') => axios.defaults.headers.post[type] = value,
    setHeader: (type: string, value: string) => axios.defaults.headers.common[type] = value,


    setAuthHeader: (access_token?: string) => axios.defaults.headers.common['Authorization'] = access_token,

    throwError: (error: AxiosError) => { console.log('Error', error.response); throw error }

}

export default utils;

