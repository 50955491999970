import { useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useStoreActions, useStoreState } from '../../Stores';
import Query from '../../Stores/Agency/Query';

function useSearchQuery() {
    const { pathname, search } = useLocation();
    const { query, showRecommended, activeTab } = useStoreState(({ Agency: { query, showRecommended, activeTab } }) => ({ query, activeTab, showRecommended }));
    const { fetchAgencyContents, setAppliedFilter, setActiveTab } = useStoreActions(({ Agency: { fetchAgencyContents, setAppliedFilter, setActiveTab } }) => ({ setActiveTab, fetchAgencyContents, setAppliedFilter }))
    const history = useHistory();
    useEffect(() => {
        const activeTab = pathname.includes('/calendar') ? 'Calendar' : pathname.includes('/resources') ? ('Resources') : undefined
        setActiveTab(activeTab)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname])
    const _query = useMemo(() => {
        return activeTab ? Query.fromQueryString(search) : undefined;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, activeTab])
    useEffect(() => {
        /**
         * @author Adnan Husain
         * this is temperory fix.
        */
        // const _query = activeTab ? Query.fromQueryString(search) : undefined;
        // if (query && activeTab) {

        // }
        if (_query && activeTab) {
            console.log(search, activeTab, _query,);
            fetchAgencyContents({ filter: _query?.filter, recommended: showRecommended })
            setAppliedFilter(_query.filter);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(JSON.stringify(_query?.filter)), activeTab])

    // useEffect(() => {
    //     const _query = Query.fromQueryString(search);
    //     fetchAgencyContents({ filter: _query.filter, recommended: showRecommended, activeTab })
    //     if (_query)
    //         setAppliedFilter(_query.filter)
    // }, [search, activeTab])

    useEffect(() => {
        history.push(pathname + '?' + query.toQueryString)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query])
}

export default useSearchQuery;