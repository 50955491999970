import React, { useState } from 'react';
import { InfoDrawer, InfoDrawerProps } from '../../Components';
import SessionList from './SessionList';
import { TSession } from '../../Models/Agenda/@types';
import { useStoreState } from '../../Stores';
import { SessionCardProps } from './SessionCard';


export const useSessionInfoDrawer = (sessionCardProps?: Partial<SessionCardProps>) => {
    const [open, setOpen] = useState(false);
    const { currentSessions } = useStoreState(state => state.Events);
    const [sessions, setSessions] = useState<TSession[]>([]);
    const [title, setTitle] = useState('');
    const closeDrawer = () => setOpen(false);

    const openDrawer = (title: string, _sessions: TSession[]) => {
        setTitle(title)
        setSessions(_sessions);
        setOpen(true)
    }

    return {
        openDrawer,
        closeDrawer,
        InfoDrawer: <DrawerContent currentSessions={currentSessions} title={title} open={open} sessions={sessions} onClose={closeDrawer} sessionCardProps={sessionCardProps} />
    }
}

interface DrawerContentProps extends InfoDrawerProps {
    sessions: TSession[]
    sessionCardProps?: Partial<SessionCardProps>
    currentSessions: TSession[]
}
const DrawerContent: React.FC<DrawerContentProps> = (props) => {
    const { sessions, sessionCardProps = {}, currentSessions, ...infoDrawerProps } = props;
    return (
        <InfoDrawer anchor="right" {...infoDrawerProps}>
            <SessionList withDrawer={true} sessions={sessions} {...sessionCardProps} currentSessions={currentSessions} />
        </InfoDrawer>
    )
}