
import axios, { AxiosRequestConfig } from 'axios';
// THIS IS A GENERATED FILE; PLEASE DO NOT MAKE CHANGES HERE

class ZinesBase {

    /** 
     * Find all instances of the model matched by filter from the data source.
     */
    static get_Zines<T = any>(filter?: any, config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/Zines`,
            method: 'get',
            params: {
                filter
            },

        })
    }

    /** 
     * Create a new instance of the model and persist it into the data source.
     */
    static post_Zines<T = any>(data?: any, config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/Zines`,
            method: 'post',

            data: {
                ...data
            },
        })
    }

    /** 
     * Find a model instance by {{id}} from the data source.
     */
    static get_Zines_id<T = any>(id: string, filter?: any, config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/Zines/${id}`,
            method: 'get',
            params: {
                filter
            },

        })
    }

    /** 
     * Patch attributes for a model instance and persist it into the data source.
     */
    static patch_Zines_id<T = any>(id: string, data?: any, config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/Zines/${id}`,
            method: 'patch',

            data: {
                ...data
            },
        })
    }

    /** 
     * moveToTrash
     */
    static delete_Zines_trash<T = any>(ids: any, config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/Zines/trash`,
            method: 'delete',

            data: {
                ids
            },
        })
    }

    /** 
     * Publish entity.
     */
    static post_Zines_id_publish<T = any>(publish: any, id: string, config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/Zines/${id}/publish`,
            method: 'post',

            data: {
                publish
            },
        })
    }

    /** 
     * stats
     */
    static get_Zines_id_analytics_stats<T = any>(id: string, accessUserId?: any, config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/Zines/${id}/analytics/stats`,
            method: 'get',
            params: {
                accessUserId
            },

        })
    }

    /** 
     * upload zip
     */
    static post_Zines_id_upload<T = any>(id: string, accessUserId?: any, config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/Zines/${id}/upload`,
            method: 'post',

            data: {
                accessUserId
            },
        })
    }

    /** 
     * Fetch zine file
     */
    static get_Zines_id_fetch<T = any>(id: string, accessUserId?: any, config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/Zines/${id}/fetch/*`,
            method: 'get',
            params: {
                accessUserId
            },

        })
    }

}

export default ZinesBase;
