import { request } from '../../Utils/index';

export class ZoomService {
    static getMeetingCreds = (sessionId: string, eventId: string) => request<any>({
        url: `Events/${eventId}/Sessions/${sessionId}/meeting-creds`,
    })
}

export const ZoomConfig = {
    ZOOM_LEAVE_URL: window.location.origin
}

export const getZoomConfig = (baseUrl: string) => {
    const ZoomConfig = {
        ZOOM_LEAVE_URL: baseUrl + '/empty-screen'
    };
    return { ZoomConfig }
}