import { Box, Button, Drawer, DrawerProps, Icon, IconButton, Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';
import { Link } from 'react-router-dom';
import Typo from '../../Components/Typo';
import { TUser } from '../../Models/User/@types';
import { useNavMenu } from './useNavMenu';
import UserMenu, { useUserMenu } from './UserMenu';


interface MenuDrawerProps extends Omit<DrawerProps, 'onClose'> {
    user?: TUser
    onClose: () => void
    registerComponent?: JSX.Element
}

const MenuDrawer: React.FC<MenuDrawerProps> = (props) => {
    const { user, registerComponent, ...drawerProps } = props;
    const { navMenu, pathname, classes: navClasses } = useNavMenu();
    const classes = useStyles()
    const isLoggedIn = !!user;

    const USER_MENU = useUserMenu()

    return (
        <Drawer anchor="right" PaperProps={{ className: clsx(classes.paperRoot) }}  {...drawerProps} onClose={props.onClose}>
            <Box pl={3} pr={1.2} display="flex" className={classes.root} flexDirection="column" alignItems="flex-start">
                <Box display="flex" className={classes.title} alignItems="center" >
                    {/* <Typography variant="caption">{user?.name || ''}</Typography> */}

                    <Box flex={1} />
                    <IconButton className={classes.iconButton} onClick={props.onClose}>
                        <Icon>close</Icon>
                    </IconButton>
                </Box>
                <Box width="100%" display="flex" flexDirection="column">
                    <Box mb={3}>
                        <UserMenu />
                    </Box>
                    {navMenu.map(menu => (
                        <Box my={1.5} key={menu.url}>
                            <Link rel="noopener" onClick={props.onClose} to={menu.url} className={clsx(classes.navItem, { [navClasses.activeNavItem]: menu.url === pathname })}>
                                <Typo variant="body2" weight="medium">{menu.label}</Typo>
                            </Link>
                        </Box>
                    ))}



                    {isLoggedIn ? USER_MENU.map(item => (
                        <Box my={1.5} key={item.label}>
                            <Button className={clsx(classes.signout, { [navClasses.activeNavItem]: item.label === 'Profile' && pathname.includes('profile') })} onClick={() => {
                                item.onClick();
                                props.onClose();
                            }}>
                                <Typo className={item.label === 'Profile' && pathname.includes('profile') ? classes.active : ''} variant="body2" weight="medium">{item.label}</Typo>
                            </Button>
                        </Box>
                    )) : registerComponent}

                </Box>
            </Box>
        </Drawer>
    )
}


const useStyles = makeStyles((theme: Theme) => createStyles({
    paperRoot: {
        // borderBottomLeftRadius: 160,
        // background:
    },
    root: {
        width: 160,
        height: '100%'
    },
    active: {
        width: '100%',
        textAlign: 'left',
        borderBottom: `3px solid ${theme.palette.primary.main}`,
    },
    title: {
        height: 64,
        width: '100%',
    },
    iconButton: {
        color: theme.palette.text.secondary,
        padding: 4
    },
    navItem: {
        fontWeight: 600,
        textDecoration: 'none',
        color: theme.palette.text.primary
    },
    signout: {
        // marginTop: 16,
        padding: 0,
        width: '100%',
        justifyContent: 'flex-start'
    }
}))


export default MenuDrawer