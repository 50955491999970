import { TFilter } from '..';
import { request } from '../../Utils';
import { TPartner } from '../Partner/@types';
import { TEvent } from './@types';
import { TMarkerMedia } from '../Exhibition';
import { TRegistrant } from '../Registrant/@types';
import _capitalize from 'lodash/capitalize';
export default class EventModel {
  id: string = '';
  name: string = '';
  email: string = '';
  phone: string = '';
  campaignName: string = '';
  campaignId: string = '';
  speakerIds: string[];
  _partners: TPartner[];
  type: string = '';
  agencyId: string = '';
  focusAreaIds: string[];
  partnerIds: string[];
  constructor(event: TEvent) {
    this.id = event.id;
    this.name = event.name;
    this.email = event.email;
    this.phone = event.phone;
    this.campaignId = event.campaignId;
    this.campaignName = event.campaignName;
    this.speakerIds = [];
    this._partners = [];
    this.type = '';
    this.agencyId = '';
    this.focusAreaIds = [];
    this.partnerIds = [];
  }

  static getSpeakersFromEvent = (event: TEvent) => (event._eventPersons || []).filter((ep) => ep.isSpeaker);

  static fetchEvents = async (filter?: TFilter) => {
    const events = await request<TEvent[]>({
      url: `Events`,
      params: {
        filter,
      },
      method: 'GET',
    });
    return events;
  };

  static fetchSingleEvent = async (eventId: string, filter?: TFilter) => {
    const event = await request<TEvent>({
      url: `Events/${eventId}`,
      params: { filter: { include: ['eventPersons', 'partners', 'focusAreas', 'scenes', 'restrictedStrategicPartners'] } as TFilter },
    }).catch((err) => {
      throw err;
    });
    return event;
  };
  static getMarkerMedia = async (id: string) => {
    let media = await request<TMarkerMedia>({
      url: `ExhibitionAssets/${id}`,
      method: 'GET',
    });
    return media;
  };

  static sendFeedback = (id: string, feedback: any) =>
    request<any>({
      url: `/Events/${id}/feedback`,
      method: 'POST',
      data: {
        feedback,
      },
    });

  static myRegistration = (id: string) =>
    request<TRegistrant>({
      url: `/Events/${id}/my-registration`,
    });

  static checkin = async (id: string) =>
    request({
      url: `events/${id}/checkin`,
      method: 'post',
    });

  static getEventLabel = (capitalize: boolean = false) => {
    const eventType = localStorage.getItem('eventType') || 'default';
    let model = 'event';
    if (eventType === 'webinar') model = 'webinar';

    if (capitalize) return _capitalize(model);
    return model;
  };
}
