import { LoginFormData } from '../../Forms/LoginForm';
import { SignupFormData } from '../../Forms/SignupForm';
import UserModel from '../../Models/User';
import { useStoreActions, useStoreState } from '../../Stores/index';
import { useNavMenu } from '../AppHeader/useNavMenu';


export const useAuth = () => {
    const { agency } = useStoreState(({ Agency: { agency } }) => ({ agency }));
    const { login, signup } = useStoreActions(state => state.User)
    const { origin, BASE_URL, eventSlug } = useNavMenu()

    const isRegistered = (email: string) => UserModel.isRegistered(email, agency?.id || '');

    const userLogin = async (formData: LoginFormData) => {
        // if (!eventDetails) return;
        if (!agency) return;
        return (await login({ ...formData, agencyId: agency.id }))
    }

    const forgotPassword = async (email: string) => {
        if (!agency) return;
        const baseUrl =eventSlug && eventSlug !== agency.slug ? `${origin}/${eventSlug}/reset-password` : `${origin}/reset-password`;
        return await UserModel.forgotPassword(email, baseUrl, agency.id || '');
    }

    const userSignup = async (formData: SignupFormData) => {
        if (!agency) return;
        const { ...form } = formData
        return (await signup({ ...form, agencyId: agency.id }))
        // return (await signup({ ...form, agencyId: eventDetails.agencyId, eventId: eventDetails.id }))
    }


    return {
        isRegistered,
        userLogin,
        userSignup,
        forgotPassword
    }
}