import moment from 'moment-timezone';
import React, { useEffect } from 'react';
import { useNavMenu } from '../Features/AppHeader/useNavMenu';
import { useNotification } from '../Features/Notification/useNotification';
import Join from '../Features/Session/Join';
import AgendaModel from '../Models/Agenda';
import { TSession } from '../Models/Agenda/@types';
import utilities from '../Resources/Utils';
import { useStoreActions, useStoreState } from '../Stores/index';
//let cur: TSession | undefined = undefined;

export const VIDEO_BUFFER_TIME = 0; //Pre-recorded session shows live at (startTime - BUFFER_TIME (format minutes)).
export const BUFFER_TIME = 2; //Session shows live at (startTime - BUFFER_TIME (format minutes)).
export const UPCOMING_TIME = 10 + BUFFER_TIME; //Notification for session going live shows at (startTime - UPCOMING_TIME (format minutes)).

let curs: Array<TSession> = [];
let sess: Array<TSession> = [];

export const useScheduler = () => {
    const { setCurrentSessions, setEventStatus } = useStoreActions((state) => state.Events);
    const { sessions, currentSessions, eventDetails, allSessions } = useStoreState(
        ({ Events: { allSessions, currentSession, allowedSessions: sessions, currentSessions, eventDetails } }) => ({
            currentSession,
            sessions,
            currentSessions,
            eventDetails,
            allSessions,
        })
    );
    const { notify } = useNotification();
    const { pathname } = useNavMenu();

    curs = currentSessions;
    sess = sessions;
    const timeZone = eventDetails?.timeZone;

    useEffect(() => {
        if (eventDetails) startScheduler();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventDetails]);

    useEffect(() => {
        if (sessions.length) {
            handleEventStatus();
            setLiveMethod();
        }
    }, [sessions.length]);

    useEffect(() => {
        if (eventDetails) {
            if (!pathname.includes('zoom-client') && !pathname.includes('fullscreen')) {
                startNotificationScheduler();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventDetails]);

    const startNotificationScheduler = () => {
        sessionNotifications();
        setInterval(() => {
            sessionNotifications();
        }, 60000);
    };
    const startScheduler = () => {
        handleEventStatus();
        setLiveMethod();
        setInterval(() => {
            //(cur && curs.length > 0) && setPastMethod(cur , curs);
            handleEventStatus();
            setLiveMethod();
        }, 30000);
    };

    const handleEventStatus = () => {
        if ((allSessions || []).length === 0) return;
        const lastIndex = (allSessions.length || 1) - 1;
        if (!allSessions[0].startTime || !allSessions[lastIndex].endTime) return;
        const start = allSessions[0].startTime;
        const end = allSessions[lastIndex].endTime;
        const status = AgendaModel.getStatus(start, end);
        if (status) {
            setEventStatus(status);
        }
    };

    const sessionNotifications = () => {
        sessions.forEach((elem) => {
            let currentTime = moment();
            let currentTimeInterval = moment().add(1, 'minute');
            let toCheckUpcoming = moment(elem.startTime)
                .clone()
                .subtract(elem.type === 'video' ? VIDEO_BUFFER_TIME : UPCOMING_TIME, 'minute');
            let toCheckLive = moment(elem.startTime)
                .clone()
                .subtract(elem.type === 'video' ? VIDEO_BUFFER_TIME : BUFFER_TIME - 1, 'minute');
            let caption = `${moment(elem.startTime).format('DD/MM/YYYY')} ${moment(elem.startTime).format('HH:mm')} - ${moment(elem.endTime).format(
                'HH:mm'
            )} ${utilities.getAbbreviation(timeZone)} (${timeZone?.offset || 'UTC 00:00'})`;

            if (toCheckUpcoming.isBetween(currentTime, currentTimeInterval)) {
                elem.type !== 'break' && notify({ title: 'UPCOMING IN 10 MINS', message: elem.title, subtitle: elem.subtitle, caption });
            }

            if (toCheckLive.isBetween(currentTime, currentTimeInterval)) {
                elem.type !== 'break' &&
                    notify({ title: 'LIVE', message: elem.title, subtitle: elem.subtitle, caption }, <Join session={elem} />, 20000);
            }
        });
    };

    const setLiveMethod = () => {
        const updatedSessions = getUpdatedSessions(sess);
        if (updatedSessions.length === 0) return;
        let cursTemp = [...curs];
        updatedSessions.forEach((element) => {
            if (element.status === 'closed') {
                curs = utilities.updateItemList(curs, element, 'DELETE');
            } else {
                const startDate = moment(element.startTime).subtract(element.type === 'video' ? VIDEO_BUFFER_TIME : BUFFER_TIME, 'minute');
                //const endDate = utilities.getDateFromDayTime(element.day || '', element.close, 'dayjs') as dayjs.Dayjs;

                if (element.status === 'live' && moment().isSameOrAfter(startDate) && element.type !== 'break') {
                    curs = utilities.updateItemList(curs, { ...element, status: 'live' }, 'PUT');
                }
            }
        });

        if (!utilities.isListSimilar(curs, cursTemp)) setCurrentSessions([...curs]);
    };
};
export const getUpdatedSessions = (sessions: TSession[]): Array<TSession> => {
    let updatedSessions: TSession[] = [];
    sessions.forEach((element) => {
        const bufferTime = element.type === 'video' ? VIDEO_BUFFER_TIME : BUFFER_TIME;
        const startDate = moment(element.startTime).subtract(bufferTime, 'minute');
        const endDate = moment(element.endTime);
        if (element.type === 'conferenceCall') {
            if (element.status === 'closed') {
                updatedSessions.push(element);
            } else {
                if (element.status === 'live' || (moment().isSameOrAfter(startDate) && moment().isBefore(endDate))) {
                    updatedSessions.push({ ...element, status: 'live' });
                } else {
                    if (moment().isAfter(endDate)) updatedSessions.push({ ...element, status: 'closed' });
                    else updatedSessions.push({ ...element, status: 'upcoming' });
                }
            }
        } else {
            if (moment().isBefore(startDate)) updatedSessions.push({ ...element, status: 'upcoming' });
            else if (moment().isAfter(endDate)) updatedSessions.push({ ...element, status: 'closed' });
            else updatedSessions.push({ ...element, status: 'live' });
        }
    });
    return updatedSessions;
};
