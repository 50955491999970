import { createStyles, Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import { FormConfig, IFormActionProps } from 'react-forms';
import * as Yup from 'yup';
import { IProps } from '.';
import { useTranslation } from '../../Hooks/useTranslation';
import usePasswordField from '../usePasswordField';




export const useFormConfig = (props: IProps) => {
    const classes = useStyles();
    const { getTranslation } = useTranslation();
    const actionConfig: IFormActionProps = {
        submitButtonText: getTranslation("loginForm.submitButtonLabel"),
        submitButtonLayout: 'fullWidth',
        submitButtonProps: {
            size: 'large'
        },
        containerClassNames: classes.buttonContainer
    };

    const passwordField = usePasswordField("password", getTranslation("signUpForm.passwordInputLabel"));
    const inputConfig: Array<Array<FormConfig> | FormConfig> = [
        {
            type: 'emailField',
            valueKey: 'email',
            fieldProps: {
                onBack: props.onBack
            }
        },
        passwordField,
    ];
    const validationSchema = Yup.object({
        email: Yup.string().trim().email(getTranslation("loginForm.invalidEmailLabel")).required(getTranslation("loginForm.emailRequiredLabel")),
        password: Yup.string().trim().required(getTranslation("loginForm.passwordRequiredLabel"))
    })

    return {
        actionConfig,
        inputConfig,
        validationSchema,
    }
}


const useStyles = makeStyles<Theme>((theme) => createStyles({
    buttonContainer: {
        marginTop: 40
    },
    adornmentIcon: {
        cursor: 'pointer'
    }
}))