import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import moment from 'moment-timezone';
import { BUFFER_TIME } from '../../Hooks/useScheduler';
import utilities from '../../Resources/Utils';
import { request } from "../../Utils";
import { TEventStatus, TFeedBack, TSession, TSessionQuestions, TSessionRequest } from "./@types";
dayjs.extend(utc);
class AgendaModel {
    static downloadAssets = async ({ eventId, sessionId, contentId }: { eventId: string, sessionId: string, contentId: string }) => {
        return await request({
            url: `Events/${eventId}/Sessions/${sessionId}/download-assets`,
            params: { contentId }
        });
    }

    static getStatus = (startTime?: string, endTime?: string): TEventStatus | '' => {
        if (!startTime || !endTime) return ''
        let today = moment.utc(new Date());
        let startDate = moment.utc(startTime).subtract(BUFFER_TIME + 1, 'minutes');
        let endDate = moment.utc(endTime);
        if (today.isBefore(startDate))
            return 'Upcoming'
        else if (today.isAfter(endDate))
            return 'Past'
        else if ((today.isAfter(startDate) || today.isSame(startDate)) && (today.isBefore(endDate) || today.isSame(endDate)))
            return 'Live'
        return ''
    }
    static isSessionLive(startTime: string, endTime: string): boolean {
        let date = moment();
        return date.isSameOrAfter(startTime) && date.isSameOrBefore(endTime)
    }

    static handleSessionJoin = async (id: string) => {
        return await request<any>({
            url: `Sessions/${id}/join`,
        });
    }

    static handleSessionExit = async (id: string) => {
        await request({
            url: `Sessions/${id}/exit`,
        });
    }
    static isSessionUpcoming(open: string, close: string, day?: string): boolean {
        const sessionDate = utilities.getDateFromDayTime(day || '', close, 'dayjs') as Dayjs
        //const isBefore = sessionDate.toDate().getTime() <= (new Date().getTime())
        const isBefore = dayjs.utc().isSameOrAfter(sessionDate);
        return !isBefore
    }


    static isPastSession(allSessions: TSession[], session: TSession, currentSession?: TSession) {
        if (!currentSession) {

            let day = session.startTime;

            if (moment(day).isAfter(moment()))
                return false;
            return true
        }
        const sIndex = allSessions.map(s => s.id).indexOf(session.id)
        const cIndex = allSessions.map(s => s.id).indexOf(currentSession.id)
        return sIndex < cIndex
    }


    static getSessionFeedback = async (sessionId: string): Promise<TFeedBack | undefined> => {
        try {
            let resp = await request<TFeedBack>({
                url: `SessionFeedbacks/my-feedback/${sessionId}`,
                method: 'GET'
            });
            return resp;
        } catch (err) {
            return undefined;
        }
    }

    static getSessionRequest = async (sessionId: string): Promise<TSessionRequest | undefined> => {
        try {
            let resp = await request<TSessionRequest>({
                url: `PostSessionRequests/my-postSessionRequest/${sessionId}`,
                method: 'GET'
            });
            return resp;
        } catch (err) {
            return undefined
        }
    }
    static getSessionQuestions = async (sessionId: string): Promise<TSessionQuestions | undefined> => {
        try {
            let resp = await request<TSessionQuestions>({
                url: `SessionQuestions/my-sessionQuestion/${sessionId}`
            });
            return resp;
        } catch (err) {
            return undefined;
        }
    }
    static submitSessionFeedback = async (sessionId: string, values: Partial<TFeedBack>) => {
        let res = await request<TFeedBack>({
            url: `/SessionFeedbacks/submit/${sessionId}`,
            data: values,
            method: 'POST',
        });
        return res;
    }
    static submitSessionQuestions = async (sessionId: string, values: Partial<TSessionQuestions>) => {
        let res = await request<TSessionQuestions>({
            url: `SessionQuestions/submit/${sessionId}`,
            data: values,
            method: 'POST',
        });
        return res;
    }
    static submitSessionRequest = async (data: TSessionRequest) => {
        return await request<TSessionRequest>({
            url: `/PostSessionRequests/submit/${data.sessionId}`,
            data,
            method: 'POST'
        })
    }

    static getUpcomingSessions = (payload: TSession[], todayOnly: boolean = false) => {
        let sessions: TSession[] = [];
        payload.forEach((elem) => {
            if (dayjs(elem.startTime).isAfter(dayjs())) {
                sessions.push(elem)
            }
        })
        return sessions;
    }


}

export default AgendaModel;