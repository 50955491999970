import dayjs from 'dayjs';
import { useContext, useEffect, useState } from 'react';
import { BannerContext } from '../../Context/BannerContext';
import { TEventStatus } from '../../Models/Agenda/@types';
import AgendaModel from '../../Models/Agenda/index';
import { TEvent } from '../../Models/Events/@types';
import { TRegistrant } from '../../Models/Registrant/@types';
import { useStoreState } from '../../Stores';
import { useNavMenu } from '../AppHeader/useNavMenu';
import { DuringEventBannerProps, getDuringEventBannerConfig } from './Configs/DuringEvent';
import { getPostEventBannerConfig } from './Configs/PostEvent';
import { getPreEventBannerConfig } from './Configs/PreEvent';

export type useMainBannerConfig = {};
const timeToShowCheckIn = 24 * 60 * 60 * 1000;
export const useMainBanner = (_bannerCnfig?: useMainBannerConfig) => {
    const { hideBanner, setBanner } = useContext(BannerContext);
    const [showCheckIn, setShowCheckIn] = useState(false);
    const { pathname, BASE_URL } = useNavMenu();
    const { eventDetails, eventStatus } = useStoreState((state) => state.Events);
    const { isAuthenticated } = useStoreState((state) => state.User);
    const { registrant } = useStoreState((state) => state.Registrant);
    const isRegistrationProcess = pathname === `${BASE_URL}/register`;
    const [status, setStatus] = useState<TEventStatus | ''>('');
    const updateEventStatus = (status: TEventStatus | '') => {
        setStatus(status);
    };
    useEffect(() => {
        if (eventStatus === 'Past') setStatus(eventStatus);
    }, [eventStatus]);
    useEffect(() => {
        if (eventDetails?.startTime && !registrant?.eventCheckIn) {
            const diff = dayjs.utc(eventDetails.startTime).diff(dayjs.utc());
            if (Math.abs(diff) <= timeToShowCheckIn) {
                setShowCheckIn(true);
            }
        }
    }, [eventDetails, registrant]);
    useEffect(() => {
        console.log(status);
        if (pathname === `${BASE_URL}/register`) {
            return;
        }
        if (pathname.includes('fullscreen')) {
            hideBanner();
            return;
        }
        if (pathname.includes('zoom-client')) {
            hideBanner();
            return;
        }
        if (pathname.includes('emtpy-screen')) {
            hideBanner();
            return;
        }
        configureBanner();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, registrant, isRegistrationProcess, pathname, eventDetails, status]);

    const configureBanner = () => {
        if (!eventDetails) return;
        const config = getBannerConfig(eventDetails, updateEventStatus, registrant);

        if (status === 'Upcoming')
            setBanner(
                getPreEventBannerConfig({
                    timeRemaining: config.timeRemaining,
                    loggedIn: isAuthenticated,
                    registered: config.registered,
                    registrantionStatus: config.registrationStatus,
                    checkPoints: [
                        {
                            time: 0,
                            callback: () => {
                                updateEventStatus('Live');
                            },
                        },
                        {
                            time: timeToShowCheckIn, //24 hours
                            callback: () => {
                                if (!registrant?.eventCheckIn) setShowCheckIn(true);
                            },
                        },
                    ],
                    showCheckIn,
                    registrationOpen: config.registerationOpen,
                })
            );
        else if (status === 'Live') {
            if (registrant?.status === 'checkedIn') hideBanner();
            else
                setBanner(
                    getDuringEventBannerConfig({
                        loggedIn: isAuthenticated,
                        registered: config.registered,
                        registrantionStatus: config.registrationStatus,
                        registrationOpen: config.registerationOpen,
                    })
                );
        } else if (status === 'Past') {
            // if (eventDetails.registrationOpen)
            setBanner(
                getPostEventBannerConfig({
                    loggedIn: isAuthenticated,
                    registered: config.registered,
                    registrantionStatus: config.registrationStatus,
                    registrationOpen: config.registerationOpen,
                })
            );
        } else hideBanner();
    };
};

const getBannerConfig = (event: TEvent, setStatus: (status: TEventStatus | '') => void, registrant?: TRegistrant) => {
    const startDate = event.startTime ? dayjs.utc(event.startTime).subtract(0, 'minute') : undefined;
    const timeRemaining = startDate ? startDate.diff(dayjs.utc()) : 0;

    setStatus(AgendaModel.getStatus(event.startTime, event.endTime));

    let registrationStatus: DuringEventBannerProps['registrantionStatus'] = undefined;
    if (!registrant) {
        if (!event.registrationOpen) registrationStatus = 'closed';
        else registrationStatus = 'unregistered';
    } else {
        // if (!event.registrationOpen && !(registrant.status === 'canceled') && !(registrant.status === 'rejected')) registrationStatus = 'closed'
        // else
        if (!registrant.status) registrationStatus = 'unregistered';
        else if (registrant.status === 'canceled') registrationStatus = 'cancelled';
        else if (registrant.status === 'approved') registrationStatus = 'approved';
        else if (registrant.status === 'rejected') registrationStatus = 'rejected';
        else if (registrant.status === 'checkedIn') registrationStatus = 'checkedIn';
    }
    return {
        registered: !!registrant?.status && !!registrant,
        status: AgendaModel.getStatus(event.startTime, event.endTime),
        registrationStatus,
        timeRemaining,
        registerationOpen: event.registrationOpen,
    };
};

export type TBanerConponent = 'registrationFormInput' | 'registrationReview';
