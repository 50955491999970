import { TFilter } from "..";
import { request } from "../../Utils";
import { TChat, TChatType, TMessage } from "./@types";

export interface TChatConfig {
    eventId: string,
    userId?: string,
    type: TChatType,
    sessionId?: string,
    name?: string
}
class ChatModel {
    static startChat = async (data: TChatConfig) => {
        return await request<TChat>({
            url: 'Chats/start',
            method: 'POST',
            data
        })
    };
    static getMessages = async (params: { id: string, filter?: TFilter }) => {
        const { id, filter } = params;
        return await request<Array<TMessage>>({
            url: `Chats/${id}/get-messages`,
            method: 'GET',
            params: {
                filter
            }
        })
    }
};
export default ChatModel;