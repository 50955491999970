import NewLiveBanner from 'Features/Session/NewLiveBanner';
import React from 'react';
import { useStoreState } from '../../Stores/index';
import ImageBanner, { ImageBannerProps } from '../SiteMediaContent/ImageBanner';

interface PageBannerProps {
    isChatOn?:boolean
    disableSessionBanner?:boolean
    imageBannerProps?:Partial<ImageBannerProps>
}

const PageBanner: React.FC<PageBannerProps> = ({ disableSessionBanner = false, isChatOn = false, imageBannerProps ={}}) => {
  const { currentSessions = [], eventDetails: event, previewMode } = useStoreState(({ App: { previewMode }, Events: { currentSessions, eventDetails } }) => ({ currentSessions, eventDetails, previewMode }));

  if (!event) return null;
  if (currentSessions.length && !disableSessionBanner) return <NewLiveBanner isChatOn={isChatOn} />;
  return <ImageBanner event={event} enableFallbackImage={previewMode} type="other" {...imageBannerProps} />;
};

export default PageBanner;
