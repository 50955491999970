import { Box } from '@material-ui/core';
import { Formik } from 'formik';
import React, { FC, useContext, useMemo } from 'react';
import { attachField, IReactFormProps, MLFormContent } from 'react-forms';
import * as Yup from 'yup';
import SubmitButton from '../../Components/SubmitButton';
import useAsyncTask from '../../Hooks/useAsyncTask';
import { useTranslation } from '../../Hooks/useTranslation';
import { TFeedBack } from '../../Models/Agenda/@types';
import { TranslationContext } from '../../Utils/TranslationProvider';
import SessionRating from '../Session/SessionRating';

export interface FeedbackProps {
    onSubmit?: (feedback: Partial<TFeedBack>) => Promise<void>;
    disabled?: boolean;
    feedback?: TFeedBack;
}
attachField('rating', <SessionRating />);
const formValidation = Yup.object({
    rating: Yup.number().required('Rating is Required.').positive('Rating is Required.'),
    text: Yup.string()
        .trim()
        .test({
            name: 'texttest',
            message: 'Empty text is not allowed',
            test: function (value: string) {
                if (value && value.trim().length === 0) return false;
                return true;
            },
        }),
});

const Feedback: FC<FeedbackProps> = (props) => {
    const { getTranslation } = useTranslation();
    const { language } = useContext(TranslationContext);
    const config: IReactFormProps['config'] = [
        {
            valueKey: 'rating',
            type: 'rating',
        },
        {
            type: 'text',
            valueKey: 'text',
            fieldProps: {
                label: getTranslation('sessionFeedbackForm.commentInputLabel'),
                multiline: true,
                rows: 7,
                fullWidth: true,
                variant: 'outlined',
            },
        },
    ];
    const { feedback } = props;
    const onSubmit = useAsyncTask(async (values: Partial<TFeedBack>) => {
        await props.onSubmit?.(values);
    });

    const allowSubmit = (values: Partial<TFeedBack>) => {
        return (values.rating && values.rating > 0) || values.text;
    };

    const initialValues = useMemo(() => {
        return {
            text: feedback?.text || '',
            rating: feedback?.rating ? feedback.rating : 0,
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [feedback?.text, feedback?.rating]);
    return (
        <Box width="100%">
            <Formik initialValues={{ ...initialValues }} onSubmit={onSubmit.run} validationSchema={formValidation} enableReinitialize>
                {(formikProps) => (
                    <>
                        <MLFormContent schema={config} formikProps={formikProps} formId="session-feedback" />
                        <SubmitButton
                            fullWidth
                            disabled={!allowSubmit(formikProps.values)}
                            loading={onSubmit.status === 'PROCESSING'}
                            color="primary"
                            type={'submit'}
                            variant="contained"
                            onClick={() => formikProps.handleSubmit()}
                        >
                            {getTranslation('sessionFeedbackForm.submitButton')}
                        </SubmitButton>
                    </>
                )}
            </Formik>
        </Box>
    );
};

export default Feedback;
