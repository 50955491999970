import { Box } from '@material-ui/core';
import React, { FC } from 'react';
import Footer from '../../Components/Footer';
import Meta from '../../Components/Meta';
import PageBanner from '../../Features/Banner/PageBanner';
import VerificationStatus from '../../Features/ContactVerification/VerificationStatus';
import Contact from '../../Features/Landing/Contact';
import Description from '../../Features/Landing/Description';
import { useMediaQuery } from '../../Hooks/useMediaQuery';
import { TEvent } from '../../Models/Events/@types';
import { useStoreState } from '../../Stores/index';

const CovidGuideline: FC = () => {
    const { eventDetails = {} as TEvent, } = useStoreState((state) => state.Events);

    const { isDeviceSm } = useMediaQuery();

    if (!eventDetails) return <div />;

    return (
        <>
            <PageBanner />
            <VerificationStatus />
            <Meta title={'Covid Safety Standard'}></Meta>

            <Box p={isDeviceSm ? 2 : 0}>{<Description description={eventDetails.covidGuideline || ''} />}</Box>
            <Box
                style={{
                    padding: isDeviceSm ? 16 : eventDetails.email || eventDetails.phone ? `60px 0px` : 0,
                }}
            >
                {(eventDetails.email || eventDetails.phone) && <Contact event={eventDetails} />}
            </Box>
            <Footer event={eventDetails} />
        </>
    );
};

export default CovidGuideline;
