import EventModel from 'Models/Events';
import { TEventType } from 'Models/Events/@types';
import { TBannerConfig, TBannerPosition } from '../../../Context/BannerContext/@types';

export const getGenericMessageBannerConfig = (message: string, pos: TBannerPosition): TBannerConfig => ({
    content: { [pos]: message },
    size: 'large',
});

export const BANNER_MESSAGES = {
  registrationFormInput: 'Please enter your details below to complete registration.',
  registrationReview: 'Below is summary of your registration. Please review before submitting.',
  postEventRegistrationClosed: `${EventModel.getEventLabel(true)} is over. Registrations are closed.`,
  postEventRegistrationOpen: `${EventModel.getEventLabel(true)} is over. Registrations are open.`,
  domainRestricted: 'Registrations for this domain is restricted.',
};

/**
      * @author Adnan Husain
      * @param type 
      * @param eventType
      * @param _autoRegister  I am not using the passed flag as we are using the registration texts for rsvp flows as well.
                              But the flag is still kept in parameter so that if plan changes in the future , only fewer code change will be needed.
      * @returns string based on the flow.
      */
export const getBannerMessages = (type: string, eventType:TEventType, _autoRegister: boolean = false ,): string => {
    const autoRegister = false;
 
    const model = EventModel.getEventLabel()
    switch (type) {
        case 'notLoggedIn':
            return autoRegister ? 'Please sign in to RSVP or to access your account.' : 'Please sign in to start registration or to access your account.';
        case 'registrationOpen':
            return autoRegister ? 'RSVPs are open.' : 'Registrations are open.';
        case 'registrationClosed':
            return autoRegister ? 'RSVPs are closed.' : 'Registrations are closed.';
        case 'registered':
            return autoRegister ? `You have RSVP'd for this ${model}!` : `You are registered for this ${model}!`;
        case 'notApproved':
            return autoRegister ? 'Your RSVP has not been approved yet.' : 'Your registration has not been approved yet.';
        case 'cancelled':
            return autoRegister ? `Your RSVP has been cancelled for this ${model}.` : `Your registration has been cancelled for this ${model}.`;
        case 'notRegistered':
            return autoRegister ? `You have not RSVP'd for this ${model} yet.` : `You have not registered for this ${model} yet.`;
        default:
            return '';
    }
};
