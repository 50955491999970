import React from 'react'
import { Theme, IconButtonProps, IconButton, Icon } from '@material-ui/core'
import { createStyles, makeStyles, fade } from '@material-ui/core/styles'
import { useGlobalStyle } from '../Constants/globalStyle';
import clsx from 'clsx';


interface CloseButtonProps extends IconButtonProps {
    variant?: 'outlined' | 'filledLight' | 'filledDark'
}

const CloseButton: React.FC<CloseButtonProps> = (props) => {
    const { children, variant = 'outlined', size, className, ...iconButtonProps } = props;
    const gclasses = useGlobalStyle()
    const classes = useStyles();

    return (
        <IconButton classes={{ sizeSmall: classes.sizeSmall }} size={size} className={clsx(classes.root, gclasses.outlinedIconButton, classes[variant], className)} {...iconButtonProps}>
            <Icon className={size === 'small' ? classes.smallIcon : ''} >close</Icon>
        </IconButton>
    )
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        transition: '200ms linear',
    },
    sizeSmall: {
        padding: 6,
        height: 30,
        width: 30
    },
    smallIcon: {
        fontSize: 20
    },
    filledLight: {
        backgroundColor: theme.palette.common.white,
        border: 'none',
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white
        }
    },
    filledDark: {
        border: 'none',
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        '&:hover': {
            backgroundColor: fade(theme.palette.common.black, 0.7),
        }
    },
    outlined: {

    }
}))

export default CloseButton