import { Box } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { FC, useMemo } from 'react';
import Typo from '../../Components/Typo';
import { getGradientFromTheme } from '../../Features/Theme/themeUtils';
import { useMediaQuery } from '../../Hooks/useMediaQuery';
import { TAgency, TAgencyBanner } from '../../Models/Agency/@types';
import { TStrategicPartner } from '../../Models/StrategicPartner/@types';
import { useStoreState } from '../../Stores';

export interface BannerProps {}

const Banner: FC<BannerProps> = (props) => {
  const { agency, user, appliedFilter, strategicPartner, hasRegionFilter } = useStoreState(({ Agency: { agency, strategicPartner, appliedFilter, hasRegionFilter }, User: { user } }) => ({
    agency,
    appliedFilter,
    user,
    strategicPartner,
    hasRegionFilter,
  }));
  const { isDeviceSm } = useMediaQuery();

  const bannerImageUrl = useMemo(() => {
    const defaultBanner = getAgencyBanners(agency?._webImages || [], isDeviceSm);
    const regionImages = hasRegionFilter ? getRegionBanners(agency?._banners || [], appliedFilter.regions || '-', isDeviceSm) : defaultBanner;
    if (strategicPartner) return getBannerImage(strategicPartner?._webImages || [], isDeviceSm) || regionImages;
    if (hasRegionFilter) return regionImages;
    return defaultBanner;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agency, strategicPartner, appliedFilter, hasRegionFilter, isDeviceSm]);

  const classes = useStyles({ bg: bannerImageUrl });
  // const classes = useStyles({ bg: regionImages ? regionImages[0]?.url : getBannerImage(strategicPartner?._webImages || [], isDeviceSm) });

  

  return (
    <Box className={clsx(classes.root)}>
      {bannerImageUrl ? <img className={classes.image} src={bannerImageUrl} width="100%" alt="banner" /> : <Box width="100%" className={classes.image} />}
    </Box>
  );
};

function getAgencyBanners(images: TAgency['_webImages'], isDeviceSm: boolean = false) {
  if (!images) return undefined;
  if (isDeviceSm) {
    return images?.find((i) => i.type === 'mobileBanner')?.url;
  }
  return images.find((i) => i.type === 'banner')?.url;
}
function getRegionBanners(images: TAgencyBanner[], region: string, isDeviceSm: boolean = false) {
  if (isDeviceSm) {
    return images?.find((i) => i.type === 'mobileBanner' && i.region === region)?.url;
  }
  return images?.find((i) => i.type === 'desktopBanner' && i.region === region)?.url;
}
// function getBanner(images: TAgency['_banners'], isDeviceSm: boolean = false) {
//     if (isDeviceSm) {
//         return images?.find((i) => i.type === 'mobileBanner')?.url;
//     }
//     return images?.find((i) => i.type === 'desktopBanner')?.url;
// }

function getBannerImage(images: TStrategicPartner['_webImages'], isDeviceSm: boolean = false) {
  if (isDeviceSm) {
    return images?.find((i) => i.type === 'mobileBanner')?.url;
  }
  return images?.find((i) => i.type === 'banner')?.url;
}

const useStyles = makeStyles<Theme, { bg?: string }>((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: (props) => (props.bg ? undefined : getGradientFromTheme(theme)),
    // backgroundImage: props => props.bg ? `url(${props.bg})` : undefined,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    width: '100vw',
  },
  bg: {
    backgroundImage: (props) => (props.bg ? `url(${props.bg})` : undefined),
  },
  image: {
    width: '100%',
    objectFit: 'cover',
  },
  bannerTextContainer: {
    position: 'absolute',
    bottom: 8,
  },
}));

export default Banner;
