import React, { FC } from 'react';
import MetaTags from 'react-meta-tags';
import { useTranslation } from '../Hooks/useTranslation';
import { TEntity } from '../Models/EntityRegistrations/@types';
import { useStoreState } from '../Stores/index';

export interface PageTitleProps {
    resource?: TEntity;
}

const TempElem: FC<{ desc?: string }> = (props) => {
    return <div id={'entity-desc'} dangerouslySetInnerHTML={{ __html: props.desc || '' }} style={{ display: 'none' }} />;
};
const ResourceMeta: FC<PageTitleProps> = (props) => {
    const { resource } = props;
    const PREFIX = `${resource?.name || 'venzi'} | `;
    const elem = document.getElementById('entity-desc');
    const text = elem?.innerText;
    const META = {
        description: resource?.description?.substr(0, 100) || '',
        title: resource?.name,
    };

    const OGImage = resource?._seoImage?.url || process.env.PUBLIC_URL + '/assets/OG.jpg';

    return (
        <>
            <MetaTags>
                <title>{PREFIX}</title>
                <meta name="description" content={META.description} />
                <meta property="og:title" content={META.title} />
                <meta property="og:image" content={OGImage} />
                <meta property="og:description" content={META.description} />
            </MetaTags>
            <TempElem desc={META?.description} />
        </>
    );
};

export default ResourceMeta;
