import React from 'react';
import Typo, { TypoProps } from './Typo';

interface ContrastTextProps extends Omit<TypoProps, 'color'> { }

const ContrastText: React.FC<ContrastTextProps> = (props) => {
    const { children, ...typoProps } = props;
    return (
        <Typo {...typoProps} contrast>
            {children}
        </Typo>
    )
}


export default ContrastText