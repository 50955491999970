import { Button, Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React from 'react';
import AddToCalendars from 'react-add-to-calendar';
import './style.css';
export type TCalendarEvent = {
    title: string
    open: string
    close: string
    description?: string
    location?: string
}

interface AddToCalendarProps extends TCalendarEvent {

}


const icon = { 'calendar-plus-o': 'left' };
// const items = [
//     { outlook: 'Outlook' },
//     { outlookcom: 'Outlook.com' },
//     { apple: 'Apple Calendar' },
//     { yahoo: 'Yahoo' },
//     { google: 'Google' }
// ];
const AddToCalendar: React.FC<AddToCalendarProps> = (props) => {
    const { title, description, open, close, location = 'Virtual' } = props;

    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Button
                disableFocusRipple
            >
                <>
                    {/* <ReactSVG
                    src={Calender}
                    className={classes.icon}
                /> */}
                    <AddToCalendars
                        buttonLabel='Add to Calender'
                        buttonTemplate={icon}

                        event={{
                            title: title,
                            startTime: open,
                            endTime: close,
                            description: description,
                            location: location,
                        }}

                        useFontAwesomeIcons={true}
                        displayItemIcons={true}
                    /></>
            </Button>
        </div>
    )
}
const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        root: {
            '& div > div > div': {
                backgroundColor: 'yellow',
                height: '50px'
            },
            '& .react-add-to-calendar': {
                fontFamily: `${theme.typography.fontFamily} !important`,

            },
            '& .react-add-to-calendar__button': {
                color: `${theme.palette.primary.main} !important`,
                fontWeight: theme.typography.fontWeightMedium,
                fontSize: '13px'
            }
        },
        icon: {
            color: theme.palette.primary.main,

        }
    })
})




export default AddToCalendar