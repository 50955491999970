import { request } from "../../Utils";
import { TUser, TUserLoginData, TUserSignupData } from './@types';


interface TVerificationResponse extends TUser {
    accessToken: { id: string }
}
export default class UserModel {
    static sendVerificationMail = (email: string, eventSlug: string, agencyId: string) => request<TUser>({
        url: 'users/sendVerificationEmail',
        method: 'POST',
        data: {
            email, eventSlug, agencyId
        }
    })
    static verifyEmailWithOtp = (email: string, otp: string, agencyId: string) => request<TVerificationResponse>({
        url: 'users/verifyEmailWithOtp',
        method: 'POST',
        data: {
            email, otp, agencyId
        },
    })

    static signup = (data: TUserSignupData) => request<TUser>({
        url: 'users',
        method: 'post',
        data
    })

    static login = (data: TUserLoginData) => request<{ id: string, userId: string }>({
        url: 'users/login',
        method: 'post',
        data
    })

    static logout = () => request({
        url: 'users/logout',
        method: 'post'
    })


    static isRegistered = (email: string, agencyId: string) => request<boolean>({
        url: `/users/is-registered`,
        params: { email, agencyId }
    })


    static me = async () => await request<TUser>({
        url: 'users/me'
    })

    static updateUser = (data: Partial<TUser>, headers?: Record<string, any>) => request<TUser>({
        url: '/users/update-profile',
        method: 'PATCH',
        data,
        headers,
    })
    static resetPassword = (data: { password: string, otp: string, agencyId: string }, headers?: Record<string, any>) => request({
        url: '/users/updatePasswordWithToken',
        method: 'POST',
        data: {
            newPassword: data.password,
            token: data.otp,
            agencyId: data.agencyId
        },
        headers
    })

    static forgotPassword = (email: string, redirectUrl: string, agencyId: string) => request<any>({
        url: `/users/forget-password`,
        method: 'POST',
        params: {
            email,
            redirectUrl,
            agencyId

        }
    })
    static getUserName = (user?: TUser) => {
        if (!user)
            return 'Venzi User';
        if (user.firstName || user.lastName) {
            if (user.lastName)
                return `${user.firstName || ''} ${user.lastName || ''}`;
            else
                return `${user.firstName || ''}`;
        }
        return user.name || 'Venzi User'
    }
}