import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useMemo } from 'react';
import { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import AppContext from '../../Context/AppContext';
import { useTranslation } from '../../Hooks/useTranslation';
import { TEvent } from '../../Models/Events/@types';
import { useStoreState } from '../../Stores';
import { TEmptyField } from '../../Stores/Event';

export type TNavMenuItem = {
    label: string;
    id: string;
    url: string;
    dependencyKey?: TEmptyField;
};

const eventPartners = (event?: TEvent) => {
    return (event?._partners || []).filter((partner) => !partner.isSponsor);
};
export const eventSponsors = (event?: TEvent) => {
    return (event?._partners || []).filter((partner) => partner.isSponsor);
};

/**
 * A utility hook for navigation
 *
 */

export const useNavMenu = () => {
    const { pathname } = useLocation();
    const { statusAndPermissions, initialized } = useContext(AppContext);
    const [tabValue, setTabValue] = useState(0);
    const history = useHistory();
    const { getTranslation } = useTranslation();
    const { emptyFields, eventSettings, eventDetails, isAuthenticated, registrant, eventSlug } = useStoreState(({ Events: { emptyFields, eventSettings, eventDetails }, User: { isAuthenticated }, Registrant: { registrant }, App: { eventSlug } }) => ({
        isAuthenticated,
        emptyFields,
        eventSettings,
        eventDetails,
        registrant,
        eventSlug,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const BASE_URL = useMemo(() => (eventSlug ? `/${eventSlug}` : ''), [eventSlug]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const FULL_PATH = useMemo(() => `${window.location.origin}${pathname}`, [pathname, window.location.origin]);
    const navMenu: TNavMenuItem[] = [
        { label: getTranslation('navbar.home'), url: BASE_URL, id: 'home' },
        { label: getTranslation('navbar.agenda'), url: `${BASE_URL}/agenda`, dependencyKey: '_sessions', id: 'agenda' },
        { label: getTranslation('navbar.speakers'), url: `${BASE_URL}/speakers`, dependencyKey: '_speakers', id: 'speakers' },
        { label: getTranslation('navbar.meeting'), url: `${BASE_URL}/meetings`, dependencyKey: 'meetings', id: 'meetings' },
        { label: getTranslation('navbar.sponsors'), url: `${BASE_URL}/sponsors`, dependencyKey: 'sponsors', id: 'sponsors' },
        { label: getTranslation('navbar.partners'), url: `${BASE_URL}/partners`, dependencyKey: '_partners', id: 'partners' },
        { label: getTranslation('navbar.networking'), url: `${BASE_URL}/network`, dependencyKey: 'registrant', id: 'networking' },
        { label: getTranslation('navbar.exhibition'), url: `${BASE_URL}/exhibition`, dependencyKey: 'scenes', id: 'exhibition' },
        { label: getTranslation('navbar.rewards'), url: `${BASE_URL}/rewards`, dependencyKey: 'rewards', id: 'rewards' },
        { label: 'Corona Safety', url: `${BASE_URL}/covid-guidelines`, dependencyKey: 'covidGuideline', id: 'covidGuideline' },
        { label: 'Good To Know', url: `${BASE_URL}/good-to-know`, dependencyKey: 'goodToKnow', id: 'goodToKnow' },
        // { label: 'Feedback', url: `${BASE_URL}/feedback`, dependencyKey: 'feedback', id: 'feedback' },
    ];

    const filteredNavMenu = useMemo(() => {
        return navMenu.filter((menu) => {
            if (menu.dependencyKey === 'registrant')
                return !(!registrant?.network || !(registrant.status === 'approved' || registrant.status === 'checkedIn') || !(statusAndPermissions.networkingWall && statusAndPermissions.networking) || !isAuthenticated);
            if (menu.id === 'sponsors' && menu.dependencyKey) return eventSettings?.sponsers && (eventSponsors(eventDetails).length || (eventSettings?.displaySponsorshipPackage && eventDetails?._sponsorshipPackage));
            if (menu.id === 'partners' && menu.dependencyKey) return eventSettings?.partners && eventPartners(eventDetails).length;
            //((emptyFields.indexOf(menu.dependencyKey) < 0) || eventPartners(eventDetails).length || (eventSettings?.displaySponsorshipPackage && eventDetails?._sponsorshipPackage))
            else if (menu.dependencyKey) return emptyFields.indexOf(menu.dependencyKey) < 0;
            return true;
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navMenu.length, JSON.stringify(eventSettings), statusAndPermissions, eventDetails, registrant]);

    useEffect(() => {
        const newTabVal = filteredNavMenu.findIndex((menu) => menu.url === pathname);
        // setTabValue(newTabVal);
        setTabValue(newTabVal > -1 ? newTabVal : 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname, eventSlug]);

    useEffect(() => {
        if (!initialized) return; //this check allows us to redirect for invalid url once all the initialization is done
        const newTabVal = filteredNavMenu.findIndex((menu) => pathname === menu.url);
        const navIndex = navMenu.findIndex((menu) => pathname === menu.url);
        //by checking nav index we are just assuring that this check is only for those paths which are accessed through the nav bar.

        if (navIndex > -1 && newTabVal === -1 && filteredNavMenu.length && BASE_URL) {
            history.push(BASE_URL);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname, filteredNavMenu.length]);

    return {
        origin: window.location.origin,
        navMenu: filteredNavMenu,
        BASE_URL,
        currentTab: tabValue,
        pathname,
        classes: useStyles(),
        FULL_PATH,
        eventSlug,
    };
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        navItem: {
            fontWeight: theme.typography.fontWeightBold,
            textDecoration: 'none',
            color: theme.palette.text.secondary,
            transition: `color 200ms linear`,
            '& > p': {
                padding: '5px 0px',
            },
        },
        activeNavItem: {
            color: `${theme.palette.primary.main} !important`,

            '& > p': {
                borderBottom: `3px solid ${theme.palette.primary.main}`,
                padding: '5px 0px 2px 0px',
            },
        },
        tab: {
            minWidth: 0,
            margin: '0px 12px',
        },
        formControl: {
            minWidth: 120,
        },
    })
);
