import { Box, Collapse, Paper } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { FC, useEffect, useState } from 'react';
import Typo from '../../Components/Typo';
import EntityRegistrationForm from '../../Forms/EntityRegistrationForm';
import useAsyncTask from '../../Hooks/useAsyncTask';
import { useMediaQuery } from '../../Hooks/useMediaQuery';
import useToastMessage from '../../Hooks/useToastMessage';
import EntityRegistrationsModel from '../../Models/EntityRegistrations';
import { TEntity, TEntityRegistration } from '../../Models/EntityRegistrations/@types';
import { TUser } from '../../Models/User/@types';
import { useStoreActions, useStoreState } from '../../Stores';
import Gdpr from '../Agreements/Gdpr';
export interface EntityRegistrationProps {
    entity: TEntity;
    onRegistrationCompleted: () => void;
}
export type TRegistrationSteps = 'register' | 'gdpr';
const EntityRegistration: FC<EntityRegistrationProps> = ({ entity, onRegistrationCompleted }) => {
    const classes = useStyles();
    const { agency, formData, user } = useStoreState(({ Agency: { agency }, Registrant: { formData }, User: { user } }) => ({
        agency,
        formData,
        user,
    }));
    const { setRegistrantFormData, setUser } = useStoreActions(({ Registrant: { setRegistrantFormData }, User: { setUser } }) => ({ setRegistrantFormData, setUser }));
    const [step, setStep] = useState<TRegistrationSteps>('register');
    const { isDeviceSm } = useMediaQuery();
    const withToast = useToastMessage();
    const registerUser = useAsyncTask(async ({ values, gdprValues }: { values: Partial<TEntityRegistration>; gdprValues?: any }) => {
        try {
            await withToast(
                async () => {
                    const res = await EntityRegistrationsModel.post_EntityRegistrations_new({
                        ...values,
                        agencyId: agency?.id,
                        userId: user?.id,
                        entityId: entity.id,
                        entityType: EntityRegistrationsModel.getEntityType(entity),
                    });

                    if (gdprValues) {
                        await EntityRegistrationsModel.post_EntityRegistrations_id_gdpr(gdprValues, res.data.id);
                    }
                    setUser({ ...(user || {}), ...(values as TUser) });
                    onRegistrationCompleted();
                },
                {
                    successToastMessage: 'Registration successful.',
                }
            );
        } catch (err) {}
    });
    useEffect(() => {
        window.scrollTo(0, 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [step]);
    const handleRegister = async (values: Partial<TEntityRegistration> = {}) => {
        if (entity.onsiteGDPR) {
            setStep('gdpr');
            setRegistrantFormData({ ...values });
        } else {
            await registerUser.run({ values });
        }
    };
    const handleGDPR = async (data: any) => {
        registerUser.run({ values: formData, gdprValues: data });
    };
    return (
        <>
            {agency ? (
                <Box width={isDeviceSm ? '100%' : '800px'} margin="0 auto" py={10}>
                    <Collapse in={step === 'register'} timeout={1000}>
                        <Paper className={classes.paper}>
                            <Box mb={1}>
                                <Typo variant="h5">Please enter your details below to complete registration.</Typo>
                            </Box>
                            <EntityRegistrationForm agency={agency} entity={entity} formData={formData as Partial<TEntityRegistration>} onSubmit={handleRegister} disabledKeys={['email']} />
                        </Paper>
                    </Collapse>
                    {/* <Collapse in={step === 'review'}>
                            <Paper className={classes.paper}>
                                <FormPreview
                                    entity={entity}
                                    registrant={formData as Partial<TEntityRegistration>}
                                    onSubmit={handleNext}
                                    onEdit={async () => setStep('register')}
                                    focusAreas={entity.focusAreas || []}
                                />
                            </Paper>
                        </Collapse> */}
                    <Collapse in={step === 'gdpr'} timeout={1000}>
                        {/* <Paper className={classes.paper} > */}
                        <Gdpr containerClass={classes.paper} rootClass={classes.root} onSubmit={handleGDPR} registrant={formData} loading={registerUser.status === 'PROCESSING'} />
                        {/* </Paper> */}
                    </Collapse>
                </Box>
            ) : null}
        </>
    );
};

const useStyles = makeStyles<Theme>((theme) => {
    return createStyles({
        root: {
            paddingTop: 0,
        },
        paper: {
            width: '100%',
            height: '100%',
            boxSizing: 'border-box',
            padding: '40px',
            display: 'flex',
            boxShadow: '0px 3px 6px #00000029',
            flexDirection: 'column',
            borderRadius: '12px',
            [theme.breakpoints.down('sm')]: {
                borderRadius: 0,
                padding: '30px 15px',
            },
        },
    });
});

export default EntityRegistration;
