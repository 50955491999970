import { LinearProgress } from '@material-ui/core';
import React, { FC, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import AssetRoute from '../Features/AgencyAssets/AssetRoute';
import AgencyHeader from '../Features/AgencyHeader';
import ZineRoute from '../Features/Zine/ZineRoute';
import lazyImport from '../lazy-import';
import AgencyAsset from '../Screens/AgencyAssets';
import Home from '../Screens/Home';
import Page404 from '../Screens/Page404';
import Zine from '../Screens/Zine';

const ResetPassword = lazyImport('../Screens/ResetPassword');

const AgencyNavigation: FC = () => {
    return (
        <>
            <AgencyHeader />
            <Suspense fallback={<LinearProgress />}>
                <Switch>
                    <Route path="/calendar" exact component={Home} />
                    <Route path="/resources" exact component={Home} />
                    {/* <Route path='/home/:partnerId' exact render={() => <Home isStrategicPartnerView={true} />} /> */}
                    <Route path="/sa/:id/calendar" exact render={() => <Home isStrategicPartnerView />} />
                    <Route path="/sa/:id/resources" exact render={() => <Home isStrategicPartnerView />} />
                    <Route path="/sa/:id" exact render={({ match }) => <Redirect to={`/sa/${match.params.id}/calendar`} />} />
                    <ZineRoute path="/zine/:id" exact component={Zine} />
                    <AssetRoute path="/asset/:id" exact component={AgencyAsset} />
                    <Route exact path="/reset-password" component={ResetPassword} />
                    <Route path="*" exact component={Page404} />
                    {/* <Route render={() => <Redirect to={`/`} />} /> */}
                </Switch>
            </Suspense>
        </>
    );
};

export default AgencyNavigation;
