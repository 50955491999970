import { Box } from '@material-ui/core';
import React from 'react';
import { memo } from 'react';
import { NavLink } from 'react-router-dom';
import Typo from '../../Components/Typo';
import WithTransition from '../../Components/WithTransition';
import { useNavMenu } from './useNavMenu';

interface NavBarProps {}
export interface ILanguages {
    name: string;
    value: string;
}

const NavBar: React.FC<NavBarProps> = (props) => {
    const { navMenu, classes, BASE_URL } = useNavMenu();

    return (
        <WithTransition direction="left">
            <Box display="flex" alignItems="center">
                {navMenu.map((menu) => (
                    <Box mx={2} key={menu.id}>
                        <NavLink
                            to={menu.url}
                            exact={BASE_URL === menu.url}
                            key={menu.id}
                            className={classes.navItem}
                            activeClassName={classes.activeNavItem}
                        >
                            <Typo color="inherit" variant="body2" weight="medium">
                                {menu.label}
                            </Typo>
                        </NavLink>
                    </Box>
                ))}
            </Box>
        </WithTransition>
    );
};

export default memo(NavBar);
