import { TEventStatus } from "../../Models/Agenda/@types";
import { TEvent } from "../../Models/Events/@types";
import { TUser } from "../../Models/User/@types";

export const DEFAULT_STATUS_PERMISSIONS: TEventStatusAndPermissions = {
    contentDownloadEnabled: false,
    eventStatus: 'Past',
    registrationEnabled: false,
    sessionJoinEnabled: false,
    networking: true,
    networkingWall: true
}


export interface AppContextProviderValue {
    previewMode: boolean
    initialized: boolean
    invalidEvent: boolean
    invalidAgency: boolean
    eventId: string
    eventSlug: string
    // appConstants: TAppConstants
    setTokenAndUser: (token: string, event?: TEvent, user?: TUser) => Promise<void>
    statusAndPermissions: TEventStatusAndPermissions
}


export type TInitializeKeys = 'constants' | 'event' | 'registrant' | 'user' | 'agency'
export interface TEventStatusAndPermissions {
    eventStatus: TEventStatus
    registrationEnabled: boolean
    contentDownloadEnabled: boolean
    sessionJoinEnabled: boolean
    networking: boolean
    networkingWall: boolean
}

export const defaultValue: AppContextProviderValue = {
    previewMode: false,
    initialized: false,
    invalidEvent: false,
    invalidAgency: false,
    eventId: '',
    eventSlug: '',
    // appConstants: {},
    setTokenAndUser: async (token: string) => { },
    statusAndPermissions: { ...DEFAULT_STATUS_PERMISSIONS }
}


