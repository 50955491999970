import { Button } from '@material-ui/core';
import React from 'react';
import { useJoinSession } from '../../Hooks/useJoinSession';
import { TSession } from '../../Models/Agenda/@types';
import { useStoreState } from '../../Stores';
import { useFlowPopover } from '../Popover/useFlowPopover';

interface JoinProps {
    session: TSession;
}

const Join: React.FC<JoinProps> = (props) => {
    const { handleJoin } = useJoinSession();
    const { registrant } = useStoreState(({ Registrant: { registrant } }) => ({ registrant }));
    const { Component, withPopover } = useFlowPopover({
        id: 'popper-join',
        registrant,
    });

    const handleSessionJoin = (item: TSession) => (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        // if (props.handleJoin)
        //     props.handleJoin(item);
        withPopover(e.currentTarget, 'join', () => {
            handleJoin(item);
        });
    };
    if (props.session.type === 'noStream') return <div />;
    return (
        <>
            <Button color="primary" variant="contained" onClick={handleSessionJoin(props.session)}>
                Join
            </Button>
            {Component}
        </>
    );
};

export default Join;
