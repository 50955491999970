import { Box, Divider, Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useGlobalStyle } from 'Constants/globalStyle';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { ContrastText } from '../../Components';
import { useJoinSession } from '../../Hooks/useJoinSession';
import { TSession } from '../../Models/Agenda/@types';
import { useStoreState } from '../../Stores';
import '../../Styles/live-slider.css';

import { useFlowPopover } from '../Popover/useFlowPopover';

import Dot from 'Components/Dot';
import JoinButton from 'Components/JoinButton';
import Marquee from 'react-fast-marquee';

interface LiveBannerProps {
  isChatOn?: boolean;
  parentRef?: React.MutableRefObject<HTMLDivElement | null>;
}


const liveBoxWidth = 74
const NewLiveBanner: React.FC<LiveBannerProps> = (props) => {
  const { isChatOn, parentRef } = props;
  const { currentSessions = [], registrant } = useStoreState(({ Events: { eventDetails, currentSessions }, Registrant: { registrant } }) => ({ eventDetails, currentSessions, registrant }));
  const globalStyle = useGlobalStyle();
  const ref = useRef<HTMLDivElement|null>(null);
  const { handleJoin } = useJoinSession();

  const [showMarquee , setShowMarquee] = useState(false);


  const { withPopover, Component, isOpen } = useFlowPopover({
    id: 'popper',
    registrant,
  });

  const handleSessionJoin = (item: TSession) => (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    withPopover(e.currentTarget, 'join', () => {
      handleJoin(item);
    });
  };
  

  useEffect(()=>{
     const parentWidth = parentRef?.current?.offsetWidth || window.innerWidth; 
     const availableWidth = parentWidth - (isChatOn ? 500 : 0) - liveBoxWidth;
     
     const itemWidth = (ref.current?.offsetWidth || 0) -1 ;
    setShowMarquee(itemWidth > availableWidth);

  },[isChatOn, parentRef])

  const classes = useStyles();
  if (currentSessions.length === 0) return <div />;

  return (
    <Box className={clsx(classes.root, globalStyle.revGradientBg)}>
      <Box className={classes.liveBox}>
        <Dot color="#ffffff" size={10} />
        <ContrastText weight="medium">{'LIVE'}</ContrastText>
      </Box>
      <Divider orientation="vertical" className={classes.divider} />
      <MarqueeWrapper play={!isOpen} showMarquee={showMarquee}>
        <div ref={ref} className={classes.sessionList}>
          {currentSessions.map((item, i) => {
            return (
              <React.Fragment key={item.id}>
                <Box className={classes.sessionItem}>
                  <ContrastText weight="medium">{item.title}</ContrastText>
                  <JoinButton onClick={handleSessionJoin(item)} />
                  {i < currentSessions.length - 1 || showMarquee ? <Divider orientation="vertical" className={classes.divider} /> : null}
                </Box>
              </React.Fragment>
            );
          })}
        </div>
      </MarqueeWrapper>
      {Component}
    </Box>
  );
};

interface MarqueeWrapperProps {
  showMarquee?:boolean
  play?:boolean
}

const MarqueeWrapper: React.FC<MarqueeWrapperProps> = ({play = true , showMarquee = true , children = []}) => {
  if(showMarquee)
  return (
    <Marquee play={play} pauseOnHover pauseOnClick gradient={false}>
      {children}
    </Marquee>
  );
  return (
    <>
      {children}
    </>
  )
};

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
      root: {
        minWidth: '100%',
        height: 45,
        minHeight : 45,
        display: 'flex',
      },
      liveBox: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
        marginLeft: theme.spacing(1),
      },
      divider: {
        marginLeft: theme.spacing(1.5),
        background: '#fff',
      },
      sessionList: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        minWidth : 'max-content'
      },
      sessionItem: {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        gap: theme.spacing(1),
        flexShrink:0,
        marginLeft : theme.spacing(1.5)
      },
    });
  });

export default NewLiveBanner;
