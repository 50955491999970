import { request } from "../../Utils"
import { TAppConstants as AppConstants } from "./@types"

export interface TUploadProgress {
    loaded: number,
    total: number
}


// interface TExternal {
//     type: TAppConstantField[];
//     domainRestrictionType: TAppConstantField[];
// }

// export type TAppConstants = Record<string, Record<string, (string | number | TAppConstantField)[]>>
//     & { Translations?: TAppConstantTranslations[] }
//     & { External: TExternal }

export type TAppConstantField = {
    name: string
    value: string
}
export interface TAppConstantTranslations {
    name: string,
    locale: "en" | "fr" | "de",
};
export type TGamificationScore = {
    credits_remaining: number
    eventId: string
    agencyId: string
    id: string
    properties: {
        email: string
        throughInvitation: boolean
    },
    registrationId: string
    scored: boolean
    type: string
    score: number
    rank: number
    userId: string
}

export interface TAsset {
    id: string
    name: string
    url: string
    userId: string
    key: string
    allowDownload?: boolean
    showContent?: boolean
    isVODCreated?: boolean
    size: number
    type?: string
    mimetype?: string
    base64?: string;
}
export default class AppModel {

    static fetchAutocompleteResult = async (term: string, doc_type?: string | string[], type?: string | string[]) => {

        const result = await request<{ hit: { name: string, id: string } }[]>({
            url: '/appmodels/search',
            params: {
                term,
                filter: {
                    doc_type: doc_type,
                    where: { type: type }
                }
            },
            method: "GET"
        })
        return result.map(i => { return i.hit })
    }

    static postSingleAsset = async (data: any, setProgress?: (progress: number) => any) => {
        let formData = new FormData();
        formData.append('', data);
        try {
            const result = request<TAsset[]>(
                {
                    url: 'Assets/upload',
                    data: formData,
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    method: 'POST',
                    onUploadProgress: (progressEvent: TUploadProgress) => {
                        const progress = (progressEvent.loaded / progressEvent.total) * 100;
                        setProgress && setProgress(progress);

                    }
                }
            );
            return result;
        } catch (err) {
            throw err
        }
    }
    static fetchAppConstants = async () => await request<AppConstants>({ url: 'appmodels/constants', });

    static extractAgencyId = (): string => {
        // return 'https://event-microfocus-dev.mithyalabs.com/'.split('.')?.[0].split('-dev')?.[0].split('event-')[1] || 'microfocus'

        // if (!process.env.REACT_APP_NODE_ENV) return '';
        let env = process.env.REACT_APP_NODE_ENV?.trim();

        if (window.location.host.split(':')?.[0] === 'localhost')
            return env === 'ts' ? "qa-5" : 'microfocus';
        switch (env) {
            case 'production': return window.location.hostname?.split?.('.')?.[0] || 'microfocus';
            case 'development': return window.location.hostname?.split('.')?.[0].split('-dev')?.[0].split('event-')[1] || 'microfocus'
            case 'staging': return window.location.hostname?.split?.('.')?.[0]?.split('-stag')?.[0].split('event-')[1] || '';
            case 'ts': return window.location.hostname?.split?.('.')?.[0]?.split('-ts')?.[0].split('event-')[1] || '';
            default: return 'microfocus';
        }
    }
}