import { request } from "../../Utils";
import { TExtras, TRegistrant, REGISTRATION_STATUS, TRegistrationStatus } from "./@types";

export default class RegistrantModel {
  static registerUser = async (data: { eventId: string; utm?: Record<string, any>; registrant: Partial<TRegistrant> }) => {
    const extras: TExtras[] = [];
    if (data.registrant.termsAgreed) delete data.registrant.termsAgreed;
    if (data.registrant.formExtras) {
      const keys: string[] = Object.keys(data.registrant.formExtras);
      const values = Object.values(data.registrant.formExtras);
      keys.forEach((element, index) => {
        extras.push({ key: element, label: RegistrantModel.camelToTitleCase(element), value: values[index] });
      });
      extras.push({ key: 'phone', label: 'Phone', value: data.registrant.phone || '' });
      delete data.registrant.formExtras;
      delete data.registrant.phone;
    }

    try {
      const newRegistrant = await request<TRegistrant>({
        url: 'Registrations/new',
        method: 'POST',
        data: {
          ...data.registrant,
          utm: data.utm,
          eventId: data.eventId,
        },
      });
      return newRegistrant;
    } catch (err) {
      throw err;
    }
  };

  static submitGDPR = (id: string, gdpr: Record<string, any>): Promise<TRegistrant> =>
    request({
      url: `/Registrations/${id}/gdpr`,
      method: 'POST',
      data: {
        gdpr,
      },
    });

  static cancelRegistration = (id: string) =>
    request({
      url: `/Registrations/cancel`,
      method: 'DELETE',
      params: { id },
    });

  static updateRegistrant = (eventId: string, registrant: Partial<TRegistrant>) =>
    request<TRegistrant>({
      url: `/Registrations/update/${eventId}`,
      method: 'PATCH',
      data: registrant,
    });

  static getRegistrationStep = (status?: TRegistrationStatus): number => {
    if (!status) return -1;
    return REGISTRATION_STATUS.indexOf(status);
  };

  static camelToTitleCase(str: string) {
    return str
      .replace(/[0-9]{2,}/g, (match) => ` ${match} `)
      .replace(/[^A-Z0-9][A-Z]/g, (match) => `${match[0]} ${match[1]}`)
      .replace(/[A-Z][A-Z][^A-Z0-9]/g, (match) => `${match[0]} ${match[1]}${match[2]}`)
      .replace(/[ ]{2,}/g, (match) => ' ')
      .replace(/\s./g, (match) => match.toUpperCase())
      .replace(/^./, (match) => match.toUpperCase())
      .trim();
  }
  static downloadQrPdf = (id: string) =>
    request<Blob>({
      url: `Registrations/qr-code-badge.pdf`,
      params : {
        ids : id
      },
      // headers: {
      //     responseType: 'blob'
      // },
      responseType: 'blob',
    });
}