import DayjsUtils from '@date-io/dayjs';
import { Fab, Typography } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { Component, useContext, useEffect, useState } from 'react';
import { setDefaultProps } from 'react-forms';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import Boot from './Boot';
import FullScreenLoader from './Components/FullScreenLoader';
import { ThemeContext } from './Context/ThemeContext/index';
import { useMediaQuery } from './Hooks/useMediaQuery';
import RootLayout from './Screens/RootLayout';

const App: React.FC = () => {
    const { theme } = useContext(ThemeContext);
    const [bootComplete, setBootComplete] = useState(false);

    useEffect(() => {
        Boot().then(() => {
            setDefaultProps('text', { autoComplete: 'off', inputProps: { autoComplete: 'nope' } });
            setDefaultProps('autocomplete', { autoComplete: false });
            setBootComplete(true);
        });
    }, []);

    const { isIE } = useMediaQuery();

    return bootComplete ? (
        <BrowserRouter>
            <MuiThemeProvider theme={theme}>
                <MuiPickersUtilsProvider utils={DayjsUtils}>
                    <>
                        <RootLayout />
                        {isIE ? <BannerForIE /> : null}
                    </>
                </MuiPickersUtilsProvider>
            </MuiThemeProvider>
        </BrowserRouter>
    ) : (
        <FullScreenLoader />
    );
};

class BannerForIE extends Component {
    state = {
        open: true,
    };

    render() {
        return this.state.open ? (
            <div
                className="flex-row align-center space-between"
                style={{
                    background: '#000',
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    height: 40,
                    color: '#fff',
                    zIndex: 1610,
                }}
            >
                <Typography className="mx-4" align="center" color="inherit" variant="h6">
                    Please use Google Chrome, Mozilla Firefox or Microsoft Edge for optimal functionality
                </Typography>
                <Fab size="small" onClick={(e) => this.setState({ open: false })}>
                    <i className="material-icons">close</i>
                </Fab>
            </div>
        ) : null;
    }
}

export default App;
