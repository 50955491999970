import React from "react";
import { ReactForm } from "react-forms";
import { useFormConfig } from "./config";
import { TEntity } from "Models/EntityRegistrations/@types";

export interface DetailInfoFormData {
    email: string;
    firstName: string;
    lastName: string;
    phone?: string;
}

interface DetailInfoFormProps {
    entity: TEntity;
    onSubmit: Function;
    initialData: Partial<DetailInfoFormData>;
    isSubmitting: boolean;
}

const DetailInfoForm: React.FC<DetailInfoFormProps> = (props) => {
    const { actionConfig, inputConfig, validationSchema } = useFormConfig(props.entity);
    return (
        <ReactForm
            initialData={props.initialData}
            formId="detail-info-verification"
            config={inputConfig}
            actionConfig={actionConfig}
            onSubmit={props.onSubmit}
            validationSchema={validationSchema}
            formSettings={{
                verticalSpacing: 20,
            }}
            isInProgress={props.isSubmitting}
        />
    );
};

export default DetailInfoForm;
