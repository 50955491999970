import { Menu, MenuItem, MenuProps } from '@material-ui/core';
import React, { FC } from 'react';

export interface MenuItemConfig {
    id: string;
    onClick?: () => void;
    label: string;
}

export interface MessageCardMenuProps extends MenuProps {
    loading?: boolean;
    menuConfig: MenuItemConfig[]
}

const MessageCardMenu: FC<MessageCardMenuProps> = (props) => {
    const { menuConfig = [], anchorOrigin, transformOrigin, getContentAnchorEl, ...menuProps } = props;
    return (
        <Menu
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left', }}
            {...menuProps}
        >
            {
                menuConfig.map((item) => {
                    return (
                        <MenuItem key={item.id} onClick={item.onClick} >{item.label}</MenuItem>
                    )
                })
            }
        </Menu>
    )
}

export default MessageCardMenu