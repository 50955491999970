import { get } from 'lodash';
import { useContext } from "react";
import { useTranslation } from '../Hooks/useTranslation';
import { ToastContext } from '../Utils/AppToast';




/**
 * A hook for toast handling
 * @returns a function
 * @example
 * const withToast = useToastMessage(); // import useToastMessage first
 * const submitForm = async (formData: any) =>  await withToast(async () => {
 *      // do your async function and throw the error
 *  }, 
 *  {
 *      showApiErrorMsg: false, // without this flag, error message from api is displayed in toast
 *      successToastMessage: 'Form submitted successfully',
 *      errorToastMessage: 'Error submitting form',
 *  });
 */
export default () => {
    const { getTranslation } = useTranslation();
    const { showToast } = useContext(ToastContext);
    const SUCCESS_TOAST_MESSAGE = getTranslation("feedbackMessages.generalSuccessMessage")
    const ERROR_TOAST_MESSAGE = getTranslation("feedbackMessages.generalErrorMessage")


    const withToast = async (action: () => any, toastConfig: WithToastConfig = {}) => {
        try {
            const res = await action();
            showToast(toastConfig.successToastMessage || SUCCESS_TOAST_MESSAGE, { variant: 'success', autoHideDuration: toastConfig.toastDuration })
            return res;
        } catch (error) {
            const errortext = (typeof toastConfig.errorToastMessage === 'string') ? toastConfig.errorToastMessage : toastConfig.errorToastMessage?.(error)
            const msg = toastConfig.showApiErrorMsg === false ? (errortext || ERROR_TOAST_MESSAGE) : get(error, 'response.data.error.message') ?? error.message;
            showToast(msg, { variant: 'error', autoHideDuration: toastConfig.toastDuration })
            throw error;
        }
    }


    return withToast
}





export interface WithToastConfig {
    successToastMessage?: string
    errorToastMessage?: string | ((error: any) => string)
    showApiErrorMsg?: boolean
    toastDuration?: number
}


