import React from 'react';
import DialogProvider from '../Utils/AppDialogProvider';
import NotificationProvider from '../Utils/AppNotification';
import ToastProvider from '../Utils/AppToast';
import { LanguageProvider } from '../Utils/TranslationProvider';
import { AppContextProvider } from './AppContext';
import BannerContextProvider from './BannerContext';

const ContextProviders: React.FC = (props) => {
    return (
        <NotificationProvider>
            <ToastProvider>
                <DialogProvider>
                    <AppContextProvider>
                        <BannerContextProvider>
                            <LanguageProvider>{props.children}</LanguageProvider>
                        </BannerContextProvider>
                    </AppContextProvider>
                </DialogProvider>
            </ToastProvider>
        </NotificationProvider>
    );
};

export default ContextProviders;
