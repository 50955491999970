import { useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import AgencyModel from '../../Models/Agency';
import { TAgencyCalendarResponse } from '../../Models/Agency/@types';
import { useStoreActions, useStoreState } from '../../Stores';
import { AGENCY_EVENT_FILTER, AGENCY_RESOURCE_FILTER } from '../../Stores/Agency';
import Query from '../../Stores/Agency/Query';


function useInit(strategicPartnerView?: boolean, strategicPartnerId?: string) {
    const { agency, strategicPartners, } = useStoreState(({ Agency: { agency, strategicPartners } }) => ({ strategicPartners, agency, }));
    const { resetState: resetEventPage, setFacets, setStrategicPartner, fetchStrategicPartner, setResourceFacets } = useStoreActions(({ Agency: { setShowRecommended, setStrategicPartner, setFacets, setResourceFacets, fetchStrategicPartner, }, Events: { resetState } }) => ({ setResourceFacets, setShowRecommended, resetState, fetchStrategicPartner, setStrategicPartner, setFacets, }))
    const { id: strategicPartnerSlug } = useParams<{ id: string }>();
    const history = useHistory();
    const { search } = useLocation()
    useEffect(() => {
        resetEventPage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const status = Query.fromQueryString(search).filter.status || 'upcoming'
    useEffect(() => {
        // FETCH FACETS
        // right now its a hack
        if (agency && status) {
            setupFacets(agency.id, status);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, agency]);

    useEffect(() => {
        initStrategicPartner(strategicPartnerView, strategicPartnerId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [strategicPartnerView, strategicPartnerId])


    const initStrategicPartner = async (_strategicPartnerView: boolean | undefined, id: string | undefined) => {
        if (_strategicPartnerView) {
            try {
                if (id) {
                    const partner = strategicPartners.find(i => i.slug === id || i.id === id);
                    setStrategicPartner(partner);
                } else if (strategicPartnerSlug)
                    await fetchStrategicPartner(strategicPartnerSlug)
            } catch (error) {
                history.push('/resources')
            }
        } else {
            setStrategicPartner(undefined);
        }
    }




    const setupFacets = async (agencyId: string, status?: "upcoming" | "past") => {
        const { data } = await AgencyModel.get_Agencies_calender<TAgencyCalendarResponse>(undefined, {
            limit: 1,
            where: {
                agencyId,
                isPublished: true,
                ...AGENCY_EVENT_FILTER(status)
            }
        });
        setFacets(data.facets);
        const { data: res } = await AgencyModel.get_Agencies_calender<TAgencyCalendarResponse>(undefined, {
            limit: 1,
            where: {
                agencyId,
                isPublished: true,
                ...AGENCY_RESOURCE_FILTER
            },

        })
        setResourceFacets({ ...res.facets, total: res.total })
    }



}

export default useInit;