import { Avatar, Chip, Theme } from '@material-ui/core';
import React from 'react';
import { TSpeaker } from '../../Models/EventPerson/@types';
import { createStyles, makeStyles } from '@material-ui/core/styles';

export interface SpeakerChipProps {
    speaker: TSpeaker
    onClick?: (speaker: TSpeaker) => void
}

const SpeakerChip: React.FC<SpeakerChipProps> = (props) => {
    const { speaker, onClick = () => { } } = props;
    const classes = useStyles();

    return (
        <Chip clickable className={classes.root} variant="default" label={speaker.firstName + " " + speaker.lastName}
            avatar={<Avatar className={classes.img} alt={speaker.firstName + " " + speaker.lastName} src={speaker._profileImage?.thumbnailUrl || ''} />}
            onClick={() => onClick(speaker)}
        />
    )
}


const useStyles = makeStyles((theme: Theme) => {
    const { secondary, common, background, grey } = theme.palette;
    return createStyles({
        root: {
            height: 40,
            borderRadius: 20,
            backgroundColor: background.default,
            color: grey['800'],
            border: `1px solid #e6e6e6`,
            '&:hover': {
                backgroundColor: `${secondary.main} !important`,
                color: common.white
            },
            '&:focus': {
                backgroundColor: background.default,
            }

        },
        img: {
            height: '34px !important',
            width: '34px !important',
            margin: `5px 2px !important`,
            marginRight: '-6px !important'
        }
    })
})
export default SpeakerChip