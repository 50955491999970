import _ from 'lodash';
import { StaticTranslationNode, TLeaf, TranslationLocale } from "./@types";

class TranslationModel {

    static mergeTrees = (translationJson: StaticTranslationNode, agencyTranslation?: StaticTranslationNode) => {

        /**
         * If there's no agencyTranslation right now in the agency, 
         * then the translationJson will become the default translation tree.
         */
        if (!agencyTranslation) return translationJson;

        let node1 = { ...translationJson };
        let node2 = { ...agencyTranslation };
        const keys = _.uniq(Object.keys(node1).concat(Object.keys(node2)));
        for (let key of keys) {
            // If the current key is locale key (en, fr, de etc.)
            if (Object.keys(node1).includes(key) && !Object.keys(node2).includes(key))
                node2[key] = node1[key];

            if (typeof node1[key] === 'object') {
                node2[key] = TranslationModel.mergeTrees(node1[key] as StaticTranslationNode, node2[key] as StaticTranslationNode)
            }
        }
        return node2;

    }

    static getLeaves(data: StaticTranslationNode, pathFromRoot: string = ''): TLeaf[] {
        let leaves: TLeaf[] = [];
        const node = pathFromRoot ? _.get(data, pathFromRoot) as StaticTranslationNode : data;
        let leaf: TLeaf = { pathFromRoot, translations: [] };
        for (let key in node) {
            if (node[key] && typeof node[key] === 'string' && key !== 'label') {
                leaf.translations.push({ lang: key as TranslationLocale, value: node[key] as string });
            }
            if (node[key] && typeof node[key] === 'object') {
                leaves = leaves.concat(TranslationModel.getLeaves(data as StaticTranslationNode, pathFromRoot ? (pathFromRoot + `.${key}`) : key))
            }
        }
        leaf.translations.length > 0 && leaves.push(leaf);
        return leaves;
    }

    static getUsedLanguages(node: StaticTranslationNode): TranslationLocale[] {
        let locales: TranslationLocale[] = [];
        for (let key in node) {
            if (node[key] && typeof node[key] === 'string' && key !== 'label') {
                locales.push(key as TranslationLocale);
            }
            if (node[key] && typeof node[key] === 'object') {
                locales = locales.concat(TranslationModel.getUsedLanguages(node[key] as StaticTranslationNode))
            }
        }
        return _.uniq(locales);
    }
}

export default TranslationModel;