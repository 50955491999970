import { LinearProgress } from '@material-ui/core';
import React, { FC, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useStoreState } from 'Stores';
import AssetRoute from '../Features/AgencyAssets/AssetRoute';
import Gdpr from '../Features/Agreements/Gdpr';
import PrivateRoute from '../Features/PrivateRoute';
import ZineRoute from '../Features/Zine/ZineRoute';
import lazyImport from '../lazy-import';
import AgencyAsset from '../Screens/AgencyAssets';
import BlackScreen from '../Screens/BlackScreen';
import CovidGuideline from '../Screens/CovidGuideline';
import GoodToKnow from '../Screens/GoodToKnow';
import Page404 from '../Screens/Page404';
import SessionDetail from '../Screens/SessionDetail/index';
import Zine from '../Screens/Zine';

const Home = lazyImport('../Screens/Home');

const MeetingsScreen = lazyImport('../Screens/Meetings');
const VerifyEmail = lazyImport('../Screens/VerifyEmail');
const RewardScreen = lazyImport('../Screens/Rewards');
const FeedbackScreen = lazyImport('../Screens/Feedback');
const CancelRegistration = lazyImport('../Screens/CancelRegistration');
const ResetPassword = lazyImport('../Screens/ResetPassword');
const ProfileScreen = lazyImport('../Screens/Profile');
const Preview = lazyImport('../Screens/Preview');
const InviteScreen = lazyImport('../Screens/Invite');
const ZoomClient = lazyImport('../Screens/ZoomClient');
const TermsConditions = lazyImport('../Screens/StaticPages/TermsConditions');
const PrivacyPolicy = lazyImport('../Screens/StaticPages/PrivacyPolicy');
// const Gdpr = lazyImport('../Screens/StaticPages/Gdpr');
const Checkin = lazyImport('../Screens/Checkin');
const Register = lazyImport('../Screens/Register');
const Landing = lazyImport('../Screens/Landing');
const WebinarLanding = lazyImport('../Screens/Landing/WebinarLanding');
const AgendaScreen = lazyImport('../Screens/Agenda');
const SpeakersScreen = lazyImport('../Screens/Speakers');
const PartnerScreen = lazyImport('../Screens/Partners');
const PartnerDetails = lazyImport('../Screens/Partners/Details');
const Exhibition = lazyImport('../Screens/Exhibition');
const Networking = lazyImport('../Screens/Networking');
// const FullScreenSessionDetails = lazyImport('../Screens/SessionDetail/FullScreenSessionPage');
const TestForms = lazyImport('../Screens/TestForms');

const EventNavigation: FC = () => {
    const { eventDetails } = useStoreState(({  Events: { eventDetails } }) => ({ eventDetails }));
    return (
        <>
            <Suspense fallback={<LinearProgress />}>
                <Switch>
                    <Route path="/" exact component={Home} />
                    <ZineRoute path="/zine/:id" component={Zine} />
                    <AssetRoute path="/asset/:id" exact component={AgencyAsset} />
                    {/* <Route path='/partner' component={AgencyNavigation} /> */}

                    <Route path="/:eventId" exact component={eventDetails?.eventType === 'webinar' ? WebinarLanding : Landing} />
                    <Route path="/:eventId/verify-email" exact component={VerifyEmail} />
                    {/* <Route path='/:eventId/g' exact component={Gdpr} /> */}
                    <Route path="/:eventId/rewards" exact component={RewardScreen} />
                    <Route path="/:eventId/covid-guidelines" exact component={CovidGuideline} />
                    <Route path="/:eventId/good-to-know" exact component={GoodToKnow} />
                    <PrivateRoute path="/:eventId/checkin" exact component={Checkin} allowUnregisteredUser={false} />
                    <Route path="/:eventId/agenda" exact component={AgendaScreen} />
                    <PrivateRoute path="/:eventId/agenda/fullscreen/:sessionId" exact component={SessionDetail} allowUnregisteredUser={false} />
                    <Route path="/:eventId/speakers" exact component={SpeakersScreen} />
                    <Route path="/:eventId/meetings" exact component={MeetingsScreen} />
                    <Route path="/:eventId/sponsors" exact component={PartnerScreen} />
                    <Route path="/:eventId/partners" exact component={PartnerScreen} />
                    <Route path="/:eventId/sponsors/:partnerId" exact component={PartnerDetails} />
                    <Route path="/:eventId/partners/:partnerId" exact component={PartnerDetails} />
                    <Route path="/:eventId/terms" exact component={TermsConditions} />
                    <Route path="/:eventId/gdpr" exact component={Gdpr} />
                    <Route path="/:eventId/zoom-client" component={ZoomClient} />
                    <Route path="/:eventId/exhibition" component={Exhibition} />
                    <Route path="/:eventId/privacy-policy" exact component={PrivacyPolicy} />
                    <Route path="/:eventId/preview" component={Preview} />
                    <PrivateRoute exact path="/:eventId/profile" component={ProfileScreen} />
                    <PrivateRoute exact path="/:eventId/invite/:id" component={InviteScreen} />
                    <Route exact path="/:eventId/reset-password" component={ResetPassword} />
                    <Route exact path="/:eventId/empty-screen" component={BlackScreen} />
                    <PrivateRoute path="/:eventId/cancel-registration" exact component={CancelRegistration} />
                    <PrivateRoute path="/:eventId/feedback" exact component={FeedbackScreen} allowUnregisteredUser={false} />
                    <PrivateRoute path="/:eventId/register" exact component={Register} initRegistrant />
                    <PrivateRoute path="/:eventId/network" component={Networking} />
                    <Route path="/:eventId/test-forms" component={TestForms} />
                    <Route path="/" exact component={Home} />
                    <Route path="*" exact component={Page404} />

                    {/* <Route render={() => <Redirect to={`/`} />} /> */}
                </Switch>
            </Suspense>
        </>
    );
};

export default EventNavigation;
