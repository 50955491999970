import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { FormConfig, IFormActionProps } from "react-forms";
import * as Yup from "yup";
import { useTranslation } from "../../Hooks/useTranslation";
import utilities from "Resources/Utils";
import { phoneRegExp } from "Resources/AppConstants";
import { TEntity } from "Models/EntityRegistrations/@types";
import HTMLText from "Components/HTMLText";
import { CheckboxProps } from "@material-ui/core";
import React from "react";

export const useFormConfig = (entity?: TEntity) => {
    const classes = useStyles();
    const { getTranslation } = useTranslation();
    const validationSchema = Yup.object({
        email: Yup.string().trim().email("Invalid Email").required("This is required"),
        firstName: Yup.string().trim().required("Email is required"),
        lastName: Yup.string().trim().required("This is required"),
        phone: Yup.string().trim().matches(phoneRegExp, "Phone number is not valid"),
        emailOptIn: entity?.emailOptInText ? Yup.boolean().required("This is required").oneOf([true], "This is required") : Yup.boolean(),
    });

    const actionConfig: IFormActionProps = {
        submitButtonText: "Submit",
        submitButtonLayout: "fullWidth",
        submitButtonProps: {
            size: "large",
        },
        containerClassNames: classes.buttonContainer,
    };
    const inputConfig: Array<Array<FormConfig> | FormConfig> = [
        {
            type: "text",
            valueKey: "email",
            fieldProps: {
                label: utilities.getLabelWithRequiredMark(getTranslation("emailInputForm.emailInputLabel")),
                fullWidth: true,
            },
        },
        ...(entity?.emailOptInText
            ? [
                  {
                      type: "checkbox",
                      valueKey: "emailOptIn",
                      fieldProps: {
                          formControlLabelProps: {
                              style: {
                                  alignItems: "flex-start",
                                  gap: 4,
                              },
                              label: <HTMLText htmlText={entity?.emailOptInText} variant="body2" />,
                          },
                      } as CheckboxProps,

                      styles: {
                          marginBottom: -20,
                      },
                  },
              ]
            : []),
        {
            type: "text",
            valueKey: "firstName",
            fieldProps: {
                label: utilities.getLabelWithRequiredMark("First Name"),
                fullWidth: true,
            },
        },
        {
            type: "text",
            valueKey: "lastName",
            fieldProps: {
                label: utilities.getLabelWithRequiredMark("Last Name"),
                fullWidth: true,
            },
        },
        {
            valueKey: "phone",
            type: "phone",
            fieldProps: {
                label: utilities.getLabelWithRequiredMark("Phone"),
                countryCodeContainerProps: {
                    width: "50%",
                },
                phoneContainerProps: {
                    width: "50%",
                },
                inputLabelProps: {
                    shrink: true,
                },
                displayEmpty: true,
                emptyItem: true,
                emptyItemText: "Country code",
            },
        },
    ];
    return { actionConfig, inputConfig, validationSchema };
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonContainer: {},
    })
);
