import { useState } from "react";
import useToastMessage from "../../Hooks/useToastMessage";
import AgendaModel from "../../Models/Agenda";
import { TFeedBack, TSessionQuestions, TSessionRequest } from "../../Models/Agenda/@types";
import { useStoreActions, useStoreState } from "../../Stores";
import { useTranslation } from '../../Hooks/useTranslation'
export const useSessionFeedback = (sessionId: string) => {
    const [loader, setLoader] = useState<boolean>(false);
    const { feedback, registrant, sessionRequest, sessionQuestions } = useStoreState(({ Registrant: { feedback, registrant, sessionRequest, sessionQuestions } }) => ({ feedback, registrant, sessionRequest, sessionQuestions }))
    const { setFeedback, setSessionRequest, setSessionQuestions } = useStoreActions(({ Registrant: { setFeedback, setSessionRequest, setSessionQuestions } }) => ({ setFeedback, setSessionQuestions, setSessionRequest }))
    const { getTranslation } = useTranslation();
    const toastMessage = useToastMessage();
    const getSessionFeedback = async () => {
        setLoader(true);
        if (feedback?.sessionId !== sessionId) {
            let res: TFeedBack | undefined = await AgendaModel.getSessionFeedback(sessionId);
            setFeedback(res);
        }
        setLoader(false);
    }

    const getSessionRequest = async () => {
        setLoader(true);
        if (sessionRequest?.['sessionId'] === sessionId) {

        } else {
            let res: TSessionRequest | undefined = await AgendaModel.getSessionRequest(sessionId);
            setSessionRequest(res);
        }
        setLoader(false);
    }
    const getSessionQuestions = async () => {
        setLoader(true);
        if (sessionQuestions?.sessionId !== sessionId) {
            let res: TSessionQuestions | undefined = await AgendaModel.getSessionQuestions(sessionId);
            setSessionQuestions(res);
        }
        setLoader(false)

    }
    const handleSessionQuestionSubmit = async (values: Partial<TSessionQuestions>) => {
        await toastMessage(async () => {
            if (registrant)
                try {
                    let res = await AgendaModel.submitSessionQuestions(sessionId, {
                        eventId: registrant.eventId || '',
                        agencyId: registrant.agencyId || '',
                        registrationId: registrant.id || '',
                        sessionId,
                        ...values
                    });
                    setSessionQuestions(res);
                } catch (err) {
                    setSessionQuestions(undefined);
                    throw err;
                }
        },
            {
                successToastMessage: 'Questions submitted successfully.'
            })
    }
    const handleFeedbackSubmit = async (values: Partial<TFeedBack>) => {
        await toastMessage(async () => {
            try {
                let res = await AgendaModel.submitSessionFeedback(sessionId, { ...values });
                setFeedback(res);
            } catch (err) {
                setFeedback(undefined);
                throw err;
            }
        }, {
            successToastMessage: getTranslation("sessionFeedbackForm.feedbackSubmitHeading"),
            // errorToastMessage: getTranslation("sessionFeedbackForm.rateSubmitUnsuccessful")

        })

    }

    const handleRequestSubmit = async (values: TSessionRequest) => {
        if (Object.keys(values).length < 0 || !registrant)
            return;
        await toastMessage(async () => {
            const res = await AgendaModel.submitSessionRequest(
                {
                    ...values,
                    eventId: registrant.eventId || '',
                    userId: registrant.userId || '',
                    agencyId: registrant.agencyId || '',
                    registrationId: registrant.id || '',
                    sessionId,
                });
            setSessionRequest(res);
        },
            {
                successToastMessage: getTranslation("sessionFeedbackForm.requestSaveMessage")
            })
    }
    return {
        handleFeedbackSubmit,
        feedback,
        handleRequestSubmit,
        sessionRequest,
        getSessionFeedback,
        getSessionRequest,
        getSessionQuestions,
        sessionQuestions,
        handleSessionQuestionSubmit,
        loader,
    }
}