import React, { FC } from 'react';
import PdfDialog from '../Components/MultimediaDialogs/PdfDialog';
import VideoDialog from '../Components/MultimediaDialogs/VideoDialog';
import { useStoreState } from '../Stores';

export interface DialogRootProps {}

const DialogRoot: FC<DialogRootProps> = (props) => {
    const { videoDialogProps, pdfDialogProps } = useStoreState(({ Dialogs: { videoDialogProps, pdfDialogProps } }) => ({
        videoDialogProps,
        pdfDialogProps,
    }));
    return (
        <>
            {videoDialogProps?.open ? <VideoDialog {...videoDialogProps} /> : null}
            {pdfDialogProps?.open ? <PdfDialog {...pdfDialogProps} /> : null}
        </>
    );
};

export default DialogRoot;
