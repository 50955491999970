import { Box, Button, Icon, ListItemIcon, ListItemText, Menu, MenuItem, Slide } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import { get } from 'lodash';
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { RoundedButton } from '../../Components';
import Typo from '../../Components/Typo';
import { useAuthDialog } from '../../Dialogs/Auth/useAuthDialog';
import { useMediaQuery } from '../../Hooks/useMediaQuery';
import useToastMessage from '../../Hooks/useToastMessage';
import { useTranslation } from '../../Hooks/useTranslation';
import UserModel from '../../Models/User';
import { useStoreActions, useStoreState } from '../../Stores/index';
import { useAuth } from '../Auth/useAuth';
import useAutoRegister from '../Registration/useAutoRegister';
import { useNavMenu } from './useNavMenu';

interface UserMenuProps {}

const SlideTransition: React.FC<
    TransitionProps & {
        children?: React.ReactElement<any, any> | undefined;
    }
> = (props) => {
    return (
        <Slide direction="down" {...props}>
            {props.children}
        </Slide>
    );
};
// export type TranstionType = TransitionProps & { children?: React.ReactElement<any, any> | undefined; }
// const SlideT = React.forwardRef((props: TranstionType, ref) => {
//     return <Slide ref={ref} direction="down" {...props} ><div>{props.children}</div></Slide >
// })

const UserMenu: React.FC<UserMenuProps> = (props) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const user = useStoreState((state) => state.User.user);
    const { isDeviceSm } = useMediaQuery();
    const { getTranslation } = useTranslation();
    const { BASE_URL } = useNavMenu();
    const { eventDetails } = useStoreState(({ Events: { eventDetails } }) => ({ eventDetails }));
    const { RoundedRegisterButton } = useAutoRegister();
    const handleClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);
    const { userLogin, userSignup, isRegistered, forgotPassword } = useAuth();

    const { AuthComponent, openAuthDialog } = useAuthDialog({
        onLogin: userLogin,
        onSignup: userSignup,
        handleCheck: isRegistered,
        onForgotPassword: forgotPassword,
    });

    const LABEL = UserModel.getUserName(user);
    const shouldShowRegisterButton = !!eventDetails?.registrationOpen;

    const MENU_ITEMS = useUserMenu();

    return (
        <Box position="relative">
            {!user ? (
                <Box alignItems={isDeviceSm ? 'flex-start' : 'center'} display="flex" flexDirection={isDeviceSm ? 'column' : 'row'}>
                    <RoundedButton size="medium" variant="outlined" color="secondary" onClick={openAuthDialog}>
                        {/* SIGN IN */}
                        {getTranslation('navbar.signIn')}
                    </RoundedButton>
                    {shouldShowRegisterButton ? (
                        <Box ml={isDeviceSm ? 0 : 1} mt={isDeviceSm ? 1 : 0}>
                            {eventDetails?.autoRegister ? (
                                RoundedRegisterButton
                            ) : (
                                <RoundedButton borderColor="white" size="medium" variant="contained" color="secondary">
                                    <Link to={`${BASE_URL}/register`} style={{ color: 'inherit' }}>
                                        {/* REGISTER */}
                                        {getTranslation('navbar.register')}
                                    </Link>
                                </RoundedButton>
                            )}
                        </Box>
                    ) : null}
                </Box>
            ) : isDeviceSm ? (
                <Typo variant="body2" weight="medium">
                    {LABEL}
                </Typo>
            ) : (
                <>
                    <Button onClick={handleClick}>
                        {LABEL}
                        <Icon>expand_more</Icon>
                    </Button>
                    <Menu
                        id="user-menu"
                        anchorEl={anchorEl}
                        // transformOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        getContentAnchorEl={null}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                        keepMounted
                        TransitionComponent={Slide}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        {MENU_ITEMS.map((m, i) => (
                            <MenuItem
                                key={i}
                                onClick={() => {
                                    m.onClick();
                                    handleClose();
                                }}
                            >
                                <ListItemIcon>
                                    <Icon fontSize="small">{m.icon}</Icon>
                                </ListItemIcon>
                                <ListItemText>{m.label}</ListItemText>
                            </MenuItem>
                        ))}
                    </Menu>
                </>
            )}

            {AuthComponent}
        </Box>
    );
};

export const useUserMenu = () => {
    const { BASE_URL } = useNavMenu();
    const withToast = useToastMessage();
    const logout = useStoreActions((state) => state.User.logout);
    const { registrant } = useStoreState((state) => state.Registrant);
    const isRegistered = !!registrant?.status;
    const { getTranslation } = useTranslation();
    const history = useHistory();
    const handleLogout = () => {
        withToast(
            async () => {
                await logout();
                // history.push(BASE_URL);
            },
            {
                successToastMessage: getTranslation('feedbackMessages.logoutSuccess'),
                showApiErrorMsg: false,
            }
        );
    };
    const MENU_ITEMS = [
        { onClick: () => history.push(`${BASE_URL}/profile`), label: getTranslation('navbar.profile'), icon: 'account_box' },
        { onClick: handleLogout, label: getTranslation('navbar.logout'), icon: 'exit_to_app' },
    ].filter((item) => (isRegistered ? true : item.label !== 'Profile'));
    return MENU_ITEMS;
};

export default UserMenu;
