import React, { FC } from 'react';
import { attachField, ReactForm } from 'react-forms';
import EmailField from '../EmailField';
import { useFormConfig } from './config';

export interface LoginFormData {
    email: string
    password: string
}

attachField('emailField', <EmailField />)

export interface IProps {
    onSubmit: Function,
    initialData?: Partial<LoginFormData>
    isSubmitting?: boolean
    onBack?: (email: string) => void
}


export const LoginForm: FC<IProps> = (props) => {
    const { actionConfig, validationSchema, inputConfig } = useFormConfig(props);
    return (
        <ReactForm formId="login-form"
            initialValues={props.initialData}
            config={inputConfig}
            actionConfig={actionConfig}
            onSubmit={props.onSubmit}
            validationSchema={validationSchema}
            formSettings={{
                verticalSpacing: 20,
            }}
            isInProgress={props.isSubmitting}
        >
        </ReactForm>
    );
}

export default LoginForm;

