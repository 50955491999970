import { action, Action } from "easy-peasy";
import { PdfDialogProps } from "../../Components/MultimediaDialogs/PdfDialog";
import { VideoDialogProps } from "../../Components/MultimediaDialogs/VideoDialog";

interface TDialogs {
    videoDialogProps?: VideoDialogProps
    openVideoDialog: Action<TDialogs, Omit<VideoDialogProps, 'open'>>
    closeVideoDialog: Action<TDialogs>
    pdfDialogProps?: PdfDialogProps
    openPdfDialog: Action<TDialogs, Omit<PdfDialogProps, 'open'>>
    closePdfDialog: Action<TDialogs>
}
const state: TDialogs = {
    openVideoDialog: action((state, payload) => {
        state.videoDialogProps = { ...payload, open: true }
    }),
    closeVideoDialog: action((state) => {
        state.videoDialogProps = undefined
    }),
    openPdfDialog: action((state, payload) => {
        state.pdfDialogProps = { ...payload, open: true }
    }),
    closePdfDialog: action((state) => {
        state.pdfDialogProps = undefined
    })

}
export default state;