import React, { useState, createContext, FC } from 'react';
import { TranslationLocale } from '../Models/Translations/@types';

interface TranslationProps {
    language: TranslationLocale
    handleLanguage: (language: TranslationLocale) => void
}


export const TranslationContext = createContext<TranslationProps>({
    language: "en",
    handleLanguage: (language: TranslationLocale) => { }
});


export const LanguageProvider: FC = (props) => {

    const [language, handleLanguage] = useState<TranslationLocale>("en");
    // const { language, handleLanguage } = getLanguage();
    return (
        <TranslationContext.Provider value={{
            language,
            handleLanguage
        }}>
            {props.children}
        </TranslationContext.Provider>
    )
}
export default LanguageProvider;

