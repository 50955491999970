import { useMediaQuery as muiUseMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles'
import { get } from 'lodash';


export const useMediaQuery = () => {
    const theme = useTheme();
    const isDeviceSm = muiUseMediaQuery(theme.breakpoints.down('sm'));
    var isIE = !!get(document, 'documentMode');
    return { isDeviceSm, isIE }
}