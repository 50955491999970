import { Action, action, actionOn, ActionOn, Thunk, thunk, ThunkOn, thunkOn } from "easy-peasy";
import { Injections, TRootStore } from '..';
import { identifyUser, resetUser } from "../../Features/Analytics/events";
import { TUser, TUserLoginData, TUserSignupData } from "../../Models/User/@types";
import utils, { request } from '../../Utils/index';


export interface TUserState {
    isAuthenticated: boolean
    user?: TUser
    referenceNumber?: string
    login: Thunk<TUserState, TUserLoginData, Injections>
    signup: Thunk<TUserState, TUserSignupData, Injections>
    logout: Thunk<TUserState, void, Injections>
    updateUser: Thunk<TUserState, Partial<TUser>, Injections>
    setIsAuthenticated: Action<TUserState, boolean>
    setUser: Action<TUserState, TUser | undefined>
    setReferenceNumber: Action<TUserState, string>
    onLogout: ThunkOn<TUserState, Injections, TRootStore>
    onUserLogin: ActionOn<TUserState, TRootStore>
    guestInfo?: Record<string, any>
    setGuestInfo: Action<TUserState, TUserState['guestInfo']>
    doGuestLogin: Thunk<TUserState, TUserState['guestInfo']>
}


const UserState: TUserState = {
    isAuthenticated: false,
    setGuestInfo: action((state, payload) => {
        state.guestInfo = payload;
    }),
    doGuestLogin: thunk(async (_actions, data, { getState }) => {

        try {
            const res = await request<{ id: string }>({
                url: '/AssetPreviews/request-preview',
                data,
                method: 'POST'
            });
            getState().guestInfo = { ...data, id: res.id };
        } catch (err) {

        }
    }),
    setIsAuthenticated: action((state, payload) => {
        state.isAuthenticated = payload;
    }),
    setReferenceNumber: action((state, payload) => {
        state.referenceNumber = payload;
    }),
    setUser: action((state, payload) => {
        state.user = payload;
    }),
    updateUser: thunk(async (actions, payload, { injections }) => {
        const user = await injections.UserModel.updateUser(payload)
        actions.setUser(user);
        return user;

    }),
    login: thunk(async (actions, payload, { injections }) => {
        try {
            const res = await injections.UserModel.login(payload);
            utils.setCookie('access_token', res.id);
            utils.setAuthHeader(res.id);
            const user = await injections.UserModel.me();
            actions.setUser(user);
            actions.setIsAuthenticated(true);
            return user
        } catch (error) {
            actions.logout();
            throw error
        }
    }),
    signup: thunk(async (actions, payload, { injections }) => {
        return await injections.UserModel.signup(payload);
    }),

    logout: thunk(async (actions, payload, { injections, getState }) => {
        try {
            await injections.UserModel.logout();

        } catch (err) {
            // if()

            // throw err;
        }
        actions.setIsAuthenticated(false);
        getState().user = undefined
        utils.clearCookies();
    }),

    onLogout: thunkOn(
        action => action.logout,
        (state, target, { getStoreActions, getStoreState }) => {
            const actions = getStoreActions();
            actions.Registrant.setRegistrant(undefined);
            actions.Registrant.setRegistrantFormData({});
            const eventActions = getStoreActions().Events;
            const eventState = getStoreState().Events;
            //remove those current session which are not allowed to logged out view
            eventActions.setCurrentSessions(eventState.currentSessions.filter(i => eventState.allowedSessions.findIndex(x => x.id === i.id) > -1))
        }
    ),
    onUserLogin: actionOn(
        action => action.setUser,
        (state, { payload }) => {
            if (payload)
                identifyUser(payload)
            else
                resetUser()

        }
    )
}


export default UserState;