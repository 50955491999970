import { Box, BoxProps, Button, TypographyProps } from '@material-ui/core';
import React, { useState } from 'react';
import 'read-more-less-react/dist/index.css';
import Typo from './Typo';

type TVariant = Omit<Pick<TypographyProps, 'variant'>, 'inherit'>;
interface ReadMoreProps {
    lines?: number;
    variant?: 'body1' | 'body2' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'subtitle1' | 'subtitle2';
    text: string;
    onExpand?: () => void;
    key?: string;
    boxProps?: BoxProps;
}

const CHAR_LIMIT = 280;

const ReadMore: React.FC<ReadMoreProps> = (props) => {
    const { text = '', variant = 'body2', boxProps = {} } = props;
    const [expanded, setExpanded] = useState(false);

    const overflowing = text.length > CHAR_LIMIT;

    const getText = () => {
        let textToShow = text;
        if (overflowing) {
            if (!expanded) textToShow = textToShow.substr(0, CHAR_LIMIT) + '...';
        }
        return textToShow;
    };

    const BUTTON_TEXT = expanded ? 'Less' : 'More';

    return (
        <Box {...boxProps}>
            <Typo variant={variant}>{getText()}</Typo>
            {overflowing ? (
                <Box ml={-2}>
                    <Button color="primary" onClick={() => setExpanded(!expanded)}>
                        {BUTTON_TEXT}
                    </Button>
                </Box>
            ) : null}
        </Box>
    );

    // return (
    //     <div className={classes.root} style={style}>
    //         {isIE ? (
    //             <Typo variant={variant}>{text}</Typo>
    //         ) : (
    //                 <ReadMoreText
    //                     key={uniqueId()}
    //                     onAction={props.onExpand}
    //                     lines={lines}
    //                     text={text}
    //                 />
    //             )}
    //     </div>
    // )
};

export default ReadMore;
