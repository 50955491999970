import React, { createContext, useState } from 'react'
import { BannerContextProviderValue, defaultValue, TBannerConfig } from './@types'


export const BannerContext = createContext({ ...defaultValue })

const BannerContextProvider: React.FC = (props) => {


    const [banner, _setBanner] = useState<TBannerConfig>({ ...defaultValue })
    const [hidden, setHidden] = useState(false);

    const setBanner = (config: Partial<TBannerConfig>) => {
        setHidden(false);
        const { content = {}, size = 'large' } = config;
        _setBanner({ content, size })
    }

    const hideBanner = () => {
        setHidden(true)
    }





    const providerValue: BannerContextProviderValue = {
        content: banner.content,
        size: banner.size,
        setBanner,
        hideBanner,
        hidden
    }


    return (
        <BannerContext.Provider value={providerValue}>
            {props.children}
        </BannerContext.Provider>
    )
}



export default BannerContextProvider