import { get } from 'lodash';
import Mustache from 'mustache';
import React, { useState } from 'react';
import AuthDialog, { AuthType } from '.';
import { LoginFormData } from '../../Forms/LoginForm/index';
import { SignupFormData } from '../../Forms/SignupForm/index';
import useToastMessage from '../../Hooks/useToastMessage';
import { useTranslation } from '../../Hooks/useTranslation';
import utilities from '../../Resources/Utils';

export type useAuthDialogConfig = {
    onLogin: (form: LoginFormData) => Promise<void>;
    onSignup: (form: SignupFormData) => Promise<void>;
    onClose?: () => void;
    onForgotPassword?: (email: string) => Promise<void>;
    handleCheck?: (val: string) => Promise<boolean>;
    handleGuestSignUp?: (data: Record<string, any>) => Promise<void>;
};

export const useAuthDialog = (config: useAuthDialogConfig) => {
    const { getTranslation } = useTranslation();
    const EMAIL_EXISTS_ERROR = getTranslation('feedbackMessages.emailExist');
    const FORM_ERROR = getTranslation('feedbackMessages.loginCredentialError');
    const [open, setOpen] = useState(false);
    const [type, setType] = useState<AuthType>('login');
    const [errorMessage, setErrorMessage] = useState('');
    const withToast = useToastMessage();
    const [loading, setLoading] = useState(false);
    const [guestEmail, setGuestEmail] = useState<string>();
    const openAuthDialog = () => {
        setErrorMessage('');
        setType('check');
        setOpen(true);
    };
    const openGuestDialog = (guestEmail: string) => {
        setErrorMessage('');
        setType('guest');
        setOpen(true);
        setGuestEmail(guestEmail);
    };
    const openLoginDialog = (guestEmail?: string) => {
        setErrorMessage('');
        setType('login');
        guestEmail && setGuestEmail(guestEmail);
        setOpen(true);
    };

    const openSignupDialog = (guestEmail?: string) => {
        setErrorMessage('');
        setType('signup');
        guestEmail && setGuestEmail(guestEmail);
        setOpen(true);
    };

    const closeDialog = () => setOpen(false);

    const handleClose = () => {
        closeDialog();
        if (config.onClose) config.onClose();
    };

    const handleTypeChange = (type: AuthType) => {
        setErrorMessage('');
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            setType(type);
        }, 500);
    };

    // console.log(type)

    // const handleEmailCheck = (email: string) => new Promise<boolean>((resolve, reject) => {
    //     setLoading(true)
    //     setTimeout(() => {
    //         setLoading(false)
    //         _handleEmailCheck(email).then(
    //             res => resolve(res),
    //             err => reject(err)
    //         )
    //     }, (1000));
    // })

    const handleEmailCheck = async (email: string) => {
        setErrorMessage('');
        if (!utilities.ValidateEmail(email)) {
            setErrorMessage(getTranslation('feedbackMessages.invalidEmail'));
            throw new Error('');
        }

        try {
            setLoading(true);
            if (config.handleCheck) {
                setLoading(false);
                return await config.handleCheck(email);
            } else {
                setLoading(false);
                return false;
            }
        } catch (error) {
            setLoading(false);
            throw error;
        }
    };

    const handleForgotPassword = async (email: string) => {
        if (!config.onForgotPassword) return;
        withToast(async () => await config.onForgotPassword!(email), {
            successToastMessage: Mustache.render(getTranslation('feedbackMessages.resetPasswordLinkSent') as string, { email: email }),
        });
    };

    const handleSubmit = async (formData: any) => {
        setLoading(true);
        setErrorMessage('');
        try {
            if (type === 'login') {
                await config.onLogin(formData);
            } else if (type === 'guest') {
                await config.handleGuestSignUp?.(formData);
            } else {
                await config.onSignup(formData);
                await config.onLogin({ email: formData.email, password: formData.password });
                // openLoginDialog()
            }
            closeDialog();
        } catch (error) {
            if (get(error, 'response.data.error.statusCode') === 422) setErrorMessage(EMAIL_EXISTS_ERROR);
            else if (get(error, 'response.data.error.statusCode') === 401) setErrorMessage(FORM_ERROR);
            else setErrorMessage(get(error, 'response.data.error.message'));
        }
        setLoading(false);
    };

    return {
        openAuthDialog,
        openLoginDialog,
        openSignupDialog,
        closeDialog,
        authType: type,
        openGuestDialog,
        AuthComponent: open ? (
            <AuthDialog
                loading={loading}
                onForgotPassword={handleForgotPassword}
                handleCheck={handleEmailCheck}
                onTypeChange={handleTypeChange}
                errorMessage={errorMessage}
                authType={type}
                open={open}
                guestEmail={guestEmail}
                onSubmit={handleSubmit}
                onClose={handleClose}
            />
        ) : null,
    };
};
