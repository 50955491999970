import { Box, CircularProgress, IconButton, TextField } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import SendRoundedIcon from '@material-ui/icons/SendRounded';
import 'emoji-mart/css/emoji-mart.css';
import moment from 'moment';
import React, { FC, useEffect, useState } from 'react';
import Dot from '../../Components/Dot';
import PlainHeader, { PlainHeaderProps } from '../../Components/Headers/PlainHeader';
import Typo from '../../Components/Typo';
import { intercomUtil } from '../../Intercoms';
import { TChatConfig } from '../../Models/Chat';
import { TMessage } from '../../Models/Chat/@types';
import utilities from '../../Resources/Utils';
import { useStoreState } from '../../Stores';
import ChatReplyCard from './ChatReplyCard';
import LiveChip from './LiveChip';
import MessageCard from './MessageCard';
import useChat, { ChatUtils } from './useChat';

export interface ContainerProps {
    config: TChatConfig;
    enableHeader?: boolean;
    headerProps?: PlainHeaderProps;
}

const ChatBox: FC<ContainerProps> = (props) => {
    const { config, enableHeader = false, headerProps = {} } = props;
    const classes = useStyles();
    const [replyMsg, setReplyMsg] = useState<TMessage | null>(null);
    const { user } = useStoreState(({ User: { user } }) => ({ user }));
    const [currMessage, setCurrMessage] = useState('');
    const { eventDetails } = useStoreState(({ Events: { eventDetails } }) => ({ eventDetails }));
    const { sendMessage, messages, loading, liveCount, isChatActive } = useChat(config);
    const handleSubmit = () => {
        let val = ``.concat(currMessage);
        if (currMessage && val.replace(/\s/g, '')) {
            sendMessage({ text: currMessage, parentMessageId: replyMsg?.id, parentMessage: replyMsg || undefined });
            setReplyMsg(null);
        }
        setCurrMessage('');
    };
    useEffect(() => {
        //setting intercom styling
        intercomUtil.updateIntercomPadding(config.type === 'session' ? 420 : 520);
        return () => {
            intercomUtil.updateIntercomPadding();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!loading) {
            const elem = document.getElementById('messages-container');
            elem!.scrollTop = elem!.scrollHeight;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, messages.length]);

    const handleReplyMessage = (msg: TMessage) => {
        setReplyMsg(msg);
        // ref.current!.focus();
    };
    const onCancelPress = () => setReplyMsg(null);
    return (
        <div className={classes.root} id="root-container">
            {loading ? (
                <Box margin="auto">
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    {enableHeader && <PlainHeader title={'Session Chat'} titleProps={{ ml: '-16px' }} {...headerProps} />}
                    {config.type === 'session' ? null : <LiveChip count={liveCount} top={20} />}
                    <div id="messages-container" className={classes.messageContainer}>
                        {messages.map((a, i) => {
                            let isCurrentUser = user?.id === a.userId;
                            let isLastUserSame = i > 0 && messages[i - 1].userId === a.userId;
                            let showDate = i === 0 || !ChatUtils.isSameDay(a.created, messages[i - 1].created);
                            return (
                                <Box id={a.id} mt={isLastUserSame ? 0.5 : 2} key={a.id || `${Math.random() * 1000}`}>
                                    {showDate ? (
                                        <Box display="flex" ml={0} alignItems="center" my={1.5}>
                                            <Dot size={6} borderRadius={0} color={'#333333'} mr={'10px'} />
                                            <Typo variant="body2" weight="bold">
                                                {moment(a.created).format('DD/MM/YYYY, ddd')}
                                            </Typo>
                                        </Box>
                                    ) : null}
                                    <MessageCard
                                        isCurrentUser={isCurrentUser}
                                        message={a}
                                        disabled={!isChatActive}
                                        isLastUserSame={isLastUserSame && !showDate}
                                        isStaff={ChatUtils.isStaff(a.user, eventDetails)}
                                        onReply={handleReplyMessage}
                                    />
                                </Box>
                            );
                        })}
                    </div>
                    <div className={classes.inputContainer}>
                        <div className={classes.replyCardContainer}>
                            {replyMsg ? <ChatReplyCard msg={replyMsg!} onCancelPress={onCancelPress} /> : null}
                        </div>
                        {isChatActive === true ? (
                            <>
                                <TextField
                                
                                    className={classes.input}
                                    value={currMessage || ''}
                                    onChange={(e) => setCurrMessage(e.target.value)}
                                    // autoFocus
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault();
                                            handleSubmit();
                                        }
                                    }}
                                    // focused={!!replyMsg}
                                    multiline
                                    InputProps={{
                                        classes: {
                                            multiline: classes.multiline,
                                            underline: classes.underline,
                                        },
                                    }}
                                    rowsMax={10}
                                    placeholder="Type a message"
                                    id="textarea"
                                />
                                <IconButton className={classes.sendButton} onClick={handleSubmit}>
                                    <SendRoundedIcon />
                                </IconButton>
                            </>
                        ) : (
                            <Box display="flex" boxSizing="border-box" height="100%" width="100%" alignItems="center" pl="35px">
                                <Typo variant="subtitle2" weight="medium">{`Chat has been turned off`}</Typo>
                            </Box>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) => {
    const {
        typography: { subtitle2 },
        palette: { primary },
    } = theme;
    return createStyles({
        root: {
            height: `100%`,
            width: '100%',
            borderLeft: `1px solid #fff`,
            transition: '200ms ease-in',
            background: `transparent linear-gradient(143deg, ${utilities.hexToRgb(theme.palette.primary.main, '0.15')} 0%, ${utilities.hexToRgb(
                theme.palette.secondary.main,
                '0.15'
            )} 100%) 0% 0% no-repeat padding-box`,
            display: 'flex',
            flexDirection: 'column',
            boxSizing: 'border-box',
            position: 'relative',
            // scrollBehavior: 'smooth'
        },
        messageContainer: {
            flex: 1,
            height: `100%`,
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'column',
            padding: '18px 0',
            overflow: 'auto',
            position: 'relative',
            scrollBehavior: 'smooth',
        },
        inputContainer: {
            boxSizing: 'border-box',
            minHeight: 60 + 30,
            width: '100%',
            borderTop: `1px solid #fff`,
            padding: 15,
            position: 'relative',
            overscrollBehavior: 'contain',
        },
        input: {
            boxSizing: 'border-box',
            width: '100%',
            height: 'auto',
            maxHeight: '240px !important',
            borderRadius: 37,
            outline: 'none',
            overflow: 'auto',
            // border: 0,
            padding: '22px 20px',
            paddingRight: '68px',
            ...subtitle2,
            color: '#333333',
            resize: 'none',
            border: 'none !important',
            backgroundColor: '#fff',
        },
        sendButton: {
            position: 'absolute',
            height: 60,
            width: 60,
            bottom: 17,
            // border: `1px solid ${text.primary}`,
            backgroundColor: '#f5f5f5',
            color: `${primary.main}`,
            borderRadius: '100%',
            boxShadow: '0px 1px 2px #00000029',
            right: '16px',
            '&:hover': {
                backgroundColor: `${primary.main}`,
                color: 'white',
            },
        },
        picker: {
            position: 'absolute',
            bottom: 95,
            right: 74,
        },
        button: {
            backgroundColor: '#FFCE00',
            color: '#000',
            '&:hover': {
                backgroundColor: '#FFCE00',
                color: '#000',
            },
            height: 34,
            width: 34,
        },
        multiline: {
            padding: 0,
        },
        underline: {
            border: 'none !important',
            '&::before': {
                borderBottom: 'none !important',
            },
            '&::after': {
                borderBottom: 'none !important',
            },
        },
        replyCardContainer: {
            position: 'absolute',
            left: 0,
            right: 0,
            bottom: '85px',
            padding: theme.spacing(0, 2),
        },
    });
});

export default React.memo(ChatBox);

// export const EmojiSettings: PickerProps = {
//     i18n: {
//         categories: {
//             search: 'Search Results',
//             recent: 'Frequently Used',

//             // smileys: 'Smileys & Emotion',
//             people: 'People & Body',
//             // nature: 'Animals & Nature',
//             // foods: 'Food & Drink',
//             // activity: 'Activity',
//             // places: 'Travel & Places',
//             // objects: 'Objects',
//             // symbols: 'Symbols',
//             // flags: 'Flags',
//             // custom: 'Custom',
//         },

//     },
//     include: ['search', 'recent', 'people'],
//     // custom: [{
//     //     imageUrl: 'https://images.unsplash.com/photo-1503023345310-bd7c1de61c7d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1000&q=80',
//     //     name: 'custom_image',
//     //     short_names: ['c_i'],

//     // }],
//     showPreview: false,
//     showSkinTones: false,

// }
