import _ from 'lodash'
import { TStrategicPartner, } from '../StrategicPartner/@types';
import { TDomainRestriction, TDomainRestrictionType } from './@types';
const DomainUtils = {
    getRestrictedEmailDomains: (restrictedEmailDomains: string[], strategicPartners: TStrategicPartner[]) => {
        let emailDomains = restrictedEmailDomains;
        strategicPartners.forEach((elem) => {
            emailDomains = [...emailDomains, ...elem.emailDomains];
        })
        return emailDomains
    },
    checkEmailDomains: (strategicPartners: TStrategicPartner[], config: TDomainRestriction, email?: string,) => {
        let emailToCheck = config.restrictedEmailDomains;
        (config.restrictedStrategicPartnerIds || []).forEach((j) => {
            let emails = strategicPartners.find(i => i.id === j)?.emailDomains || [];
            emailToCheck = [...emailToCheck, ...emails]
        });
        return DomainUtils.isDomainAllowed(config.domainRestrictionType, emailToCheck, email);
    },
    isDomainAllowed: (type: TDomainRestrictionType = 'open', restrictedEmailDomains: string[], email?: string,) => {
        if (!type) {
            return {
                allowed: false,
                recommended: false,
            };
        }

        if (type === 'open') {
            return {
                allowed: true,
                recommended: false,
            };
        }
        if (!email) {
            return {
                allowed: false,
                recommended: false
            }
        }
        const domain = email.split('@')[1];
        const isInRestrictedEmails = !!_.find(
            restrictedEmailDomains,
            (d) => d === domain
        );
        if (type === 'allow') {
            return {
                allowed: isInRestrictedEmails,
                recommended: isInRestrictedEmails,
            };
        } else {
            return {
                allowed: !isInRestrictedEmails,
                recommended: false,
            };
        }
    }
};
export default DomainUtils;