import { Box, IconButton, Theme, AppBar } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { HEADER_HEIGHT } from '..';
import LOGO from '../../Assets/Logo/default_logo.svg';
import { useMediaQuery } from '../../Hooks/useMediaQuery';
import { useStoreState } from '../../Stores';
import Typo from '../Typo';

interface DialogToolbarProps {
    onClose?: () => void
    mini?: boolean
    title?: string
}

const DialogToolbar: React.FC<DialogToolbarProps> = (props) => {
    const { title } = props;
    const classes = useStyles({ mini: props.mini || false });
    const { isDeviceSm } = useMediaQuery()
    const { agency } = useStoreState(({ Agency: { agency } }) => ({ agency }))
    const logo = agency?._logo?.url || LOGO;
    return (
        <AppBar elevation={1} position="sticky" className={classes.appbar}>
            <Box display='flex' alignItems="center" className={classes.toolbar}>
                {!isDeviceSm && <Box width={"20px"} />}
                {title ? (
                    <Typo variant={isDeviceSm ? 'body2' : 'body1'}>{title}</Typo>
                ) : (
                    <img src={logo} width={props.mini ? "148px" : "180px"} style={{ maxHeight: props.mini ? 64 : 90, objectFit: 'contain' }} alt='logo' />
                )}
                <Box flex={1} />
                <IconButton className={classes.closeButton} onClick={props.onClose}>
                    <i className="material-icons">close</i>
                </IconButton>
            </Box>
        </AppBar>
    )
}

const useStyles = makeStyles<Theme, { mini: boolean }>((theme: Theme) => createStyles({
    toolbar: {
        padding: '0px 24px',
        [theme.breakpoints.down('sm')]: {
            height: 64,
            padding: '0px 8px 0px 16px',
            '& img': {
                width: 120
            }
        },
        [theme.breakpoints.up('sm')]: {
            height: props => props.mini ? 64 : HEADER_HEIGHT,
        }
    },
    appbar: {
        background: 'white'
    },
    closeButton: {
        background: theme.palette.common.white
    }
}))

export default DialogToolbar