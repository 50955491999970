import { TRegistrant } from "../../Models/Registrant/@types";
import UserModel from "../../Models/User";
import { TUser } from "../../Models/User/@types";

export function identifyUser(user: TUser) {
    const analytics = window.analytics;
    console.log("analytics", analytics)
    if (!analytics) return;
    analytics.identify?.(user.id, {
        name: UserModel.getUserName(user),
        email: user.email,
    });
}

export function resetUser() {
    const analytics = window.analytics;
    if (!analytics) return;
    analytics.reset?.();
}


export function userEventRegister(registrant: TRegistrant, utm: Record<string, any>) {
    const analytics = window.analytics;
    if (!analytics) return;
    const { firstName, lastName, email, eventId, agencyId } = registrant;
    analytics?.track('Registration', {
        registrant: {
            firstName, lastName, email, eventId, agencyId
        },
        utm
    })
}