import { Box } from '@material-ui/core';
import React, { useContext } from 'react';
import CloseButton from '../../Components/CloseButton';
import Typo from '../../Components/Typo';
import { AppNotificationContext } from '../../Utils/AppNotification';

export type useNotificationConfig = {};

export interface NotificationConfig {
    title: string | JSX.Element;
    caption?: string;
    message: string;
    subtitle?: string;
}

export type NotificationAction = {
    label: string;
    onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};
export const useNotification = (config?: useNotificationConfig) => {
    const { handleClose, notify: sendNotification } = useContext(AppNotificationContext);

    const notify = (config: NotificationConfig, action?: JSX.Element, duration?: number) => {
        sendNotification(
            // <NotificationCard
            //     {...config}
            //     handleClose={handleClose}
            // />
            <Box maxWidth="400px" width="100%">
                <Box flex={1} alignItems="center" display="flex">
                    <Box flex={1}>
                        {typeof config.title === 'string' ? (
                            <Typo variant="body2" weight="medium">
                                {config.title}
                            </Typo>
                        ) : (
                            config.title
                        )}
                    </Box>
                    <CloseButton size="small" onClick={handleClose} variant="filledLight" />
                </Box>
                <Box mt={2}>
                    <Typo gutterBottom variant="body2">
                        {config.caption}
                    </Typo>
                    <Typo variant="h6" weight="medium">
                        {config.message}
                    </Typo>
                    <Typo variant="body2" weight="medium">
                        {config.subtitle}
                    </Typo>
                </Box>
            </Box>,
            action,
            duration
        );
    };
    return { notify };
};

// interface NotificationCardProps extends NotificationConfig {
//     handleClose: () => void
// }

// const NotificationCard: React.FC<NotificationCardProps> = (props) => {
//     return (
//         <Box maxWidth='400px'>
//             <Box flex={1} alignItems="center" display="flex" >
//                 <Box flex={1}>
//                     {typeof props.title === 'string' ? <Typo variant="body2" weight="medium">{props.title}</Typo> : props.title}
//                 </Box>
//                 <CloseButton size="small" onClick={props.handleClose} variant='filledLight' />
//             </Box>
//             <Box mt={2}>
//                 <Typo gutterBottom variant="body2">{props.caption}</Typo>
//                 <Typo variant="h6" weight="medium">{props.message}</Typo>
//                 <Typo variant="body2" weight="medium">{props.subtitle}</Typo>
//             </Box>
//         </Box>
//     )
// }
