import React from 'react';
import { Theme, TypographyProps, Typography, Link } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

export interface TypoProps extends TypographyProps {
    weight?: 'regular' | 'medium' | 'bold';
    underline?: boolean;
    contrast?: boolean;
    limitLines?: number;
    disableUrl?: boolean;
    whiteSpace?: React.CSSProperties['whiteSpace'];
    component?: string;
}
const Typo = React.forwardRef<HTMLElement, TypoProps>((props, ref) => {
    const { children, limitLines, weight = 'regular', whiteSpace, underline = false, contrast = false, ...typographyProps } = props;
    const theme = useTheme();
    const style: TypographyProps['style'] = {
        ...typographyProps.style,
        textDecoration: underline ? 'underline' : undefined,
        fontWeight: getFontWeight(theme, weight),
        whiteSpace: whiteSpace,
        color: contrast ? 'white' : undefined,
        overflow: limitLines ? 'hidden' : undefined,
        textOverflow: limitLines ? 'ellipsis' : undefined,
        lineClamp: limitLines,
        WebkitLineClamp: limitLines,
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        boxOrient: 'vertical',
    };

    if (typeof props.children === 'string' && isUrl(props.children)) {
        return (
            <Link ref={ref} rel="noopener" href={props.children} target="_blank" {...typographyProps} style={{ ...style, cursor: 'pointer' }}>
                {props.children}
            </Link>
        );
    }

    return (
        <Typography ref={ref} color="textPrimary" {...typographyProps} style={style}>
            {children}
        </Typography>
    );
});

const getFontWeight = (theme: Theme, weight: TypoProps['weight']) => {
    if (weight === 'bold') return theme.typography.fontWeightBold;
    if (weight === 'medium') return theme.typography.fontWeightMedium;
    if (weight === 'regular') return theme.typography.fontWeightRegular;
    return theme.typography.fontWeightRegular;
};

const isUrl = (text: string) => {
    return /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gi.test(text);
};

export default Typo;
