import { Box, Dialog, Paper } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { CloseOutlined } from "@material-ui/icons";
import React, { FC, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import LOGO from "../../Assets/Logo/default_logo.svg";
import { HEADER_HEIGHT } from "../../Components";
import config from "../../Config";
import { useSocketListener } from "../../Hooks/useSocketListener";
import { useStoreState } from "../../Stores";

export interface ZineProps {}

const Zine: FC<ZineProps> = (props) => {
    const classes = useStyles();
    const [open, setOpen] = useState<boolean>(true);
    const { push } = useHistory();
    const { TrackCustomEvent } = useSocketListener();
    const { agency, zineId, guestInfo } = useStoreState(({ Agency: { agency }, Zine: { zineId }, User: { guestInfo } }) => ({
        agency,
        zineId,
        guestInfo,
    }));
    const logo = agency?._logo?.url || LOGO;
    const onClose = () => {
        setOpen(false);
        push("/resources");
    };
    const emitJoinZineEvent = (zineId: string) => {
        TrackCustomEvent.emit("JOIN_ZINE", { zineId, agencyId: agency?.id, assetPreviewId: guestInfo?.id, email: guestInfo?.userData?.email });
    };
    const emitExitJoinEvent = (zineId: string) => {
        TrackCustomEvent.emit("EXIT_ZINE", { zineId, agencyId: agency?.id, assetPreviewId: guestInfo?.id, email: guestInfo?.userData?.email });
    };
    useEffect(() => {
        if (zineId) {
            emitJoinZineEvent(zineId);
            return () => {
                emitExitJoinEvent(zineId);
            };
        }
    }, [zineId]);
    return (
        <Dialog open={open} fullScreen id="parent">
            <Box className={classes.root}>
                <Paper elevation={1} className={classes.appBar}>
                    <img src={logo} height="36px" alt="logo" />
                    <CloseOutlined onClick={onClose} style={{ cursor: "pointer" }} fontSize="default" />
                </Paper>
                <Box flex={1}>{zineId ? <iframe id={"iframe"} src={`${config.get("API_URL")}Zines/${zineId}/fetch/`} title="venzi-zine" frameBorder="0" width="100%" height="100%" className={classes.background} /> : null}</Box>
            </Box>
        </Dialog>
    );
};

const useStyles = makeStyles<Theme>((theme) => {
    return createStyles({
        root: {
            width: "100vw",
            height: "100vh",
            display: "flex",
            flexDirection: "column",
        },
        background: {
            backgroundColor: theme.palette.background["default"],
            flex: 1,
            "& body": {
                backgroundColor: theme.palette.background["default"],
            },
        },
        appBar: {
            height: HEADER_HEIGHT,
            backgroundColor: "#fff",
            // boxShadow: '0px 1px 2px #00000029',
            padding: theme.spacing(0, 3),
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            zIndex: 1,
            borderRadius: 0,
        },
    });
});

export default Zine;
