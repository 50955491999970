import React, { useState } from 'react'
import { InputAdornment, Icon } from '@material-ui/core';
import { FormConfig } from 'react-forms';


export default (valueKey: string, label: string) => {
    const [passType, setPassType] = useState<'text' | 'password'>('password')
    const toggleType = () => {
        setPassType(type => type === 'password' ? 'text' : 'password');
    }

    return {
        type: passType,
        valueKey: valueKey,
        fieldProps: {
            label,
            fullWidth: true,
            InputProps: {
                endAdornment: <InputAdornment style={{ cursor: 'pointer' }} position="end" onClick={toggleType}><Icon>remove_red_eye</Icon></InputAdornment>
            }
        }
    } as FormConfig
}