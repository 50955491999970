import { TRegistrant } from "../Registrant/@types";
import { request } from "../../Utils";

export interface TNetwork {
    fromUserId: string
    toUserId: string
    fromRegistrationId: string
    toRegistrationId: string
    id: string
    eventId: string
    agencyId: string
}

export interface TRecommendNetworkResponse {
    hits: TRegistrant[]
    sentConnections: TNetwork[]
    receivedConnections: TNetwork[]
}

export default class NetworkModel {
    static getNetworkingUser = async (id: string) => {
        let res = await request<TRecommendNetworkResponse>({
            url: `/Networks/recommend`,
            params: { registrationId: id }
        });
        return res;
    }

    static connectToUser = (data: { eventId: string, network: { toUserId: string, text?: string } }) =>
        request<TNetwork>({
            url: `Networks/connect/${data.eventId}`,
            data: { ...data.network },
            method: 'POST'
        })
}