import { useTranslation } from '../../Hooks/useTranslation';
import { TAsset } from "../App";
import { TDomainRestriction } from '../Domain/@types';
import { TSpeaker } from "../EventPerson/@types";
import { TFocusArea } from "../Events/@types";
import { TExtras } from "../Registrant/@types";
export type TEventStatus = 'Upcoming' | 'Live' | 'Past';
export interface TAgenda {
    id: string
    day: string;
    open: string;
    close: string;
    _sessions: TSession[]
}
export interface TSession extends TDomainRestriction {
  id: string;
  startTime: string;
  endTime: string;
  title: string;
  subtitle: string;
  type: TSessionType;
  description: string;
  status: TSessionStatus;
  isHighlighted: boolean;
  speakerIds: string[];
  speakers: TSpeaker[];
  _content?: TAsset | TAsset[];
  _video?: TAsset;
  focusAreas?: TFocusArea[];
  focusAreaIds: string[];
  externalConferenceUrl?: string;
  recordingCompleted?: boolean;
  _conferenceRecording?: TAsset;
  isSessionLive?: boolean;
  allowPlayback?: boolean;
  allowContentDownload?: boolean;
  eventPersonIds: string[];
  chatIsActive?: boolean;
  venue?: string;
  chat?: boolean;
  _subtitles: TAsset[];
  duration?: number;
  partnerIds?: string[];
  recommended?: boolean;
  allowed?: boolean;
  streamProvider?: 'vimeo' | 'antmedia';
  hideChat?: boolean;
  meetingId?: string;
  meeting?: {
    streamId: string;
  };
  streamingUrl?:string
}

export interface TFeedBack {
    formExtras?: string[]
    extras?: TExtras[]
    rating?: number
    text?: string
    sessionId?: string
}
export interface TSessionQuestions {
    registrationId: string;
    eventId: string;
    sessionId: string;
    agencyId: string;
    questions: string[];
}
export interface TSessionRequest {
    [key: string]: boolean | string
}

export type TSessionType = 'break' | 'video' | 'conferenceCall' | 'externalConferenceCall' | 'noStream' | 'streamingUrl';
export type TSessionStatus = 'closed' | 'live' | 'upcoming'



export const FeedbackValue = () => {
    const { getTranslation } = useTranslation();
    const getFeedbackValues = () => {
        const FEEDBACK_VALUES = [
            { name: getTranslation("meetingRequestForSessionForm.meetingRequestInputLabel"), value: 'requestedOneToOneMeet' },
            { name: getTranslation("meetingRequestForSessionForm.workshopRequestInputLabel"), value: 'requestedWorkshop' },
            // { name: 'Request for a Micro Focus representative to call me regarding this topic', value: 'requestedCall' },
            { name: getTranslation("meetingRequestForSessionForm.demoSessionInputLabel"), value: 'requestedDemo' }
        ]
        return FEEDBACK_VALUES
    }
    return { getFeedbackValues }
}
