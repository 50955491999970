import AttachFileRoundedIcon from "@material-ui/icons/AttachFileRounded";
import FileCopyRoundedIcon from "@material-ui/icons/FileCopyRounded";
import TextFieldsRoundedIcon from "@material-ui/icons/TextFieldsRounded";
import TheatersRoundedIcon from "@material-ui/icons/TheatersRounded";
import moment from "moment";
import { useEffect } from "react";
import useAsyncTask from "../../Hooks/useAsyncTask";
import { useSocketListener } from "../../Hooks/useSocketListener";
import { TSession } from "../../Models/Agenda/@types";
import { TChatConfig } from "../../Models/Chat";
import { TChat, TChatType, TMessage } from "../../Models/Chat/@types";
import { TEvent, TEventSetting } from "../../Models/Events/@types";
import { TUser } from "../../Models/User/@types";
import { useStoreActions, useStoreState } from "../../Stores";
import { COOKIES_ACCESS_TOKEN } from "../../Utils";
export const CHAT_WINDOW_WIDTH = 500;
export const SESSION_WINDOW_WIDTH = 400;
export const NETWORK_CHAT_WIDTH = 500;

const useChat = (config: TChatConfig) => {
    const {
        startChat: startChatMethod,
        appendMessage,
        updateMessage,
        fetchMessages,
        addUpdateSingleChat,
    } = useStoreActions(({ Chat: { startChat, appendMessage, fetchMessages, addUpdateSingleChat, updateMessage } }) => ({ startChat, appendMessage, fetchMessages, addUpdateSingleChat, updateMessage }));
    const { chatsMap = [], eventSettings, sessions, user } = useStoreState(({ Chat: { chatsMap }, Events: { eventSettings, allowedSessions: sessions }, User: { user } }) => ({ chatsMap, eventSettings, sessions, user }));
    let currentChat = ChatUtils.getChat(chatsMap, config);

    const {
        socket,
        SOCKET_EVENT_KEYS: { SEND_MESSAGE_CHAT, RECEIVED_MESSAGE_CHAT, USER_CONNECTED_CHAT, USER_DISCONNECTED_CHAT, HIDE_MESSAGE_CHAT },
    } = useSocketListener({ chatType: config.type });

    const startChat = useAsyncTask(startChatMethod);
    const fetchMessagestask = useAsyncTask(fetchMessages);
    const fetchOldMessages = async () => {
        console.log("we are fired");
        await fetchMessagestask.run({ config, chatId: currentChat?.id || "" });
    };
    const isChatActive = ChatUtils.isChatActive(
        config,
        eventSettings,
        sessions.find((i) => i.id === config.sessionId)
    );

    useEffect(() => {
        if (config.eventId) startChat.run(config);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [config.eventId]);

    useEffect(() => {
        if (currentChat?.id) {
            socket.removeListener(RECEIVED_MESSAGE_CHAT);
            socket.removeListener(USER_CONNECTED_CHAT);
            socket.removeListener(USER_DISCONNECTED_CHAT);
            socket.removeListener(HIDE_MESSAGE_CHAT);
            socket.on(RECEIVED_MESSAGE_CHAT, (message: TMessage) => {
                if (user?.id !== message.userId) {
                    appendMessage({ message, type: config.type, action: "ADD", isCurrentUser: user?.id === message.userId });
                } else if (user?.id === message.userId) {
                    updateMessage({ message, type: message.chatType || config.type, isSelf: true });
                }
            });
            socket.on(USER_CONNECTED_CHAT, (data: { userId: string; chatId: string; liveUserIds: string[] }) => {
                if (data.chatId === currentChat?.id && currentChat) {
                    addUpdateSingleChat({ type: config.type, chat: { ...currentChat, liveUserIds: data.liveUserIds }, action: "PUT" });
                }
            });
            socket.on(USER_DISCONNECTED_CHAT, (data: { userId: string; chatId: string; liveUserIds: string[] }) => {
                if (data.chatId === currentChat?.id && currentChat) {
                    addUpdateSingleChat({ type: config.type, chat: { ...currentChat, liveUserIds: data.liveUserIds }, action: "PUT" });
                }
            });
            socket.on(HIDE_MESSAGE_CHAT, (data: { chatId: string; id: string }) => {
                let message = (currentChat?.messages || []).find((i) => i.id === data.id);
                if (message) {
                    appendMessage({ type: config.type, message: { ...message, hidden: true }, action: "UPDATE" });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentChat]);
    const sendMessage = (message: Partial<TMessage>) => {
        const { parentMessage, ...rest } = message;
        socket.emit(SEND_MESSAGE_CHAT, {
            ...rest,
            chatId: currentChat?.id || "",
            accessToken: localStorage.getItem(COOKIES_ACCESS_TOKEN),
        });
        if (currentChat) {
            appendMessage({ type: config.type, message: { ...message, created: new Date().toISOString(), chatId: currentChat?.id || "", userId: user?.id || "" }, action: "ADD" });
        }
    };

    return {
        messages: currentChat?.messages || [],
        sendMessage,
        oldMsgsLoading: fetchMessagestask.status === "PROCESSING",
        loading: startChat.status === "PROCESSING", // || fetchMessagestask.status === 'PROCESSING',
        loadMore: fetchOldMessages,

        liveCount: currentChat?.liveUserIds?.length || 0,
        isChatActive,
        hasMore: currentChat?.totalMessageCount !== (currentChat?.messages || []).length,
    };
};
export const ChatUtils = {
    getChat: (chatsMap: Array<{ chats: Array<TChat>; type: TChatType }>, config: TChatConfig) => {
        let chats = chatsMap.find((i) => i.type === config.type)?.chats;
        if (!chats || chats.length === 0) return undefined;
        let chat = chats.find((elem) => {
            return (config.type === "network" && elem.type === "network") || (config.type === "session" && elem.type === "session" && config.sessionId === elem.sessionId);
        });
        return chat;
    },
    isChatActive: (config: TChatConfig, eventSettings?: TEventSetting, session?: TSession) => {
        if (!eventSettings) return false;
        if (config.type === "network") return eventSettings.networkingChatIsActive === true;
        if (config.type === "session") return session?.chatIsActive === true;
        return false;
    },
    isStaff: (user?: Partial<TUser>, event?: TEvent) => {
        if (!user || !event) return false;
        let status = false;
        user._scopedRoles?.forEach((el) => {
            if (el.role !== "AGENCY_USER" && el.role !== "UNAUTHENTICATED" && el.role !== "EVENT_CREATOR") {
                let ind = el.scopes.findIndex((i) => i.model === "Event" && i.where.id === event.id);
                if (ind > -1) status = true;
            }
            if (el.role === "AGENCY_ADMIN" || el.role === "SUPER_ADMIN") status = true;
        });
        return status;
    },
    isSameDay: (date1?: string, date2?: string) => {
        let td1 = moment(date1);
        let td2 = moment(date2);
        if (td1.day() === td2.day() && td2.year() === td1.year() && td1.month() === td2.month()) return true;
        return false;
    },
    getAttachmentIcon: (type: string) => {
        let Icon = AttachFileRoundedIcon;
        if (/text/g.test(type)) Icon = TextFieldsRoundedIcon;
        if (/video/g.test(type)) Icon = TheatersRoundedIcon;
        if (/application/g.test(type)) Icon = FileCopyRoundedIcon;
        return Icon;
    },
};
export default useChat;
