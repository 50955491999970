import { Box, Button, CircularProgress, Menu, MenuItem } from '@material-ui/core';
import { isArray } from 'lodash';
import React, { FC } from 'react';
import { useFlowPopover } from '../../Features/Popover/useFlowPopover';
import useAsyncTask from '../../Hooks/useAsyncTask';
import { useTranslation } from '../../Hooks/useTranslation';
import AgendaModel from '../../Models/Agenda';
import { TSession } from '../../Models/Agenda/@types';
import { TAsset } from '../../Models/App';
import { useStoreState } from '../../Stores';
import { size } from 'lodash';
export interface DownloadButtonProps {
    session: TSession
}

function isContentEmpty(content: TAsset | TAsset[]) {
    if (!content) return true;
    if (!size(content)) return true;
    return false
}

const DownloadButton: FC<DownloadButtonProps> = (props) => {
    const { getTranslation } = useTranslation();
    const { registrant } = useStoreState(({ Registrant: { registrant } }) => ({ registrant }));
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const useDownloadTask = useAsyncTask(AgendaModel.downloadAssets);
    const { session } = props;
    const { Component, withPopover } = useFlowPopover({
        id: 'popper', registrant
    })
    const onMenuClick = async (content: TAsset) => {
        await useDownloadTask.run({ sessionId: session.id, contentId: content.id, eventId: registrant?.id || '' });
        window.open(content.url, '_blank')
        setAnchorEl(null);
    }
    const onDownloadClick = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (true) {

            await withPopover(e.currentTarget, 'sessionInterest', async () => {
                setAnchorEl(e.currentTarget);
            })
        }
    }


    if (!session._content || isContentEmpty(session._content || []) || session.allowContentDownload === false)
        return <div />
    const content = isArray(session._content) ? session._content : [session._content]
    return (
        <>
            <Button onClick={onDownloadClick} variant="text" color="primary">
                {/* Download/s */}
                {getTranslation("agendaCard.downloadButton")}
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                PaperProps={{
                    style: {
                        borderRadius: 8
                    }
                }}
            >
                {useDownloadTask.status === 'PROCESSING' ? <Box display='flex' alignItems='center' justifyContent='center' height={'50px'} width={'50px'}><CircularProgress /></Box> : (
                    <div>{
                        content.map((item, index) => {
                            return (
                                <MenuItem key={index} onClick={() => onMenuClick(item)}>
                                    {item.name}
                                </MenuItem>
                            )
                        })
                    }
                    </div>
                )}
            </Menu>
            {Component}
        </>
    )
}



export default DownloadButton