import { createMuiTheme } from "@material-ui/core/styles";
import shadows from "@material-ui/core/styles/shadows";
import { THEME_PALETTE } from "./themeValues";

const { others, ...palette } = THEME_PALETTE;
shadows[1] = "0px 1px 2px #00000029";
shadows[2] = "0px 3px 6px #00000029";

export default createMuiTheme({
    palette: {
        ...palette,
    },
    shadows: shadows,
    typography: {
        // fontFamily: 'arial',
        fontFamily: "Custom, arial",
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightBold: 600,

        h1: {
            fontSize: 96,
            fontWeight: 600,
        },
        h2: {
            fontSize: 60,
            fontWeight: 600,
        },
        h3: {
            fontSize: 48,
            fontWeight: 600,
        },
        h4: {
            fontSize: 34,
            fontWeight: 600,
        },
        h5: {
            fontSize: 24,
        },
        h6: {
            fontSize: 20,
            lineHeight: 1.4,
        },
        subtitle1: {
            fontSize: 16,
            fontWeight: 500,
        },
        subtitle2: {
            fontSize: 14,
            fontWeight: 500,
        },
        body1: {
            fontSize: 16,
        },
        body2: {
            fontSize: 14,
        },
        caption: {
            fontSize: 12,
            fontWeight: 500,
        },
        overline: {
            fontSize: 10,
            lineHeight: 1,
        },
    },
    overrides: {
        MuiPaper: {
            rounded: {
                borderRadius: 4,
            },
        },
        MuiChip: {
            outlined: {
                color: THEME_PALETTE.text.secondary,
                "& .MuiChip-avatar": {
                    marginLeft: 0,
                },
            },

            outlinedSecondary: {
                borderColor: THEME_PALETTE.text.secondary,
            },

            root: {
                height: "auto",
                minHeight: "26px",
            },
        },
        MuiListItemIcon: {
            root: {
                minWidth: 42,
            },
        },
        MuiTab: {
            root: {
                padding: 0,
                "@media (min-width: 600px)": {
                    minWidth: 0,
                },
            },
        },
        MuiBackdrop: {
            root: {
                backgroundColor: "rgba(0, 0, 0, 0.8)",
            },
        },
        MuiTooltip: {
            popper: {},

            tooltipPlacementLeft: {
                color: "#fff",
                backgroundColor: "rgba(0, 0, 0, 0.54)",
            },
            tooltipPlacementTop: {
                color: "#fff",
                backgroundColor: "rgba(0, 0, 0, 0.54)",
                // fontSize: 14,
                // fontWeight: 'normal'
            },
        },
        MuiAppBar: {
            colorDefault: {
                backgroundColor: THEME_PALETTE.common.white,
            },
        },
        MuiButton: {
            root: {
                textTransform: "unset",
            },
            containedPrimary: {
                "&:hover": {
                    backgroundColor: THEME_PALETTE.secondary.main,
                },
            },
        },
        MuiTypography: {
            root: {
                whiteSpace: "pre-wrap",
            },
        },
        MuiCheckbox: {
            root: {
                padding: 4,
                paddingTop: 0,
            },
        },
        MuiRadio: {
            root: {
                padding: 4,
            },
        },
        MuiFormControlLabel: {
            root: {
                marginLeft: -6,
            },
        },
        MuiSnackbarContent: {
            root: {
                display: "flex",
                alignItems: "flex-start",
            },
            message: {
                maxWidth: "90%",
                "& > span": {
                    alignItems: "flex-start",
                },
            },
        },
    },
});
