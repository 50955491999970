import { Box } from '@material-ui/core';
import { Formik } from 'formik';
import React, { FC } from 'react';
import { attachField, IReactFormProps, MLFormContent } from 'react-forms';
import SubmitButton from '../../Components/SubmitButton';
import Typo from '../../Components/Typo';
import useAsyncTask from '../../Hooks/useAsyncTask';
import { useTranslation } from '../../Hooks/useTranslation';
import { FeedbackValue, TSessionRequest } from '../../Models/Agenda/@types';
import RequestItems from './RequestItems';
import * as Yup from 'yup';
export const SESSION_REQUEST_FORM_KEYS: string[] = [
    'requestedDemo',
    'requestedDemoDuration',
    'requestedOneToOneMeet',
    'requestedOneToOneMeetDuration',
    'requestedWorkshop',
    'requestedWorkshopDuration',
    'text',
];

attachField('request', <RequestItems />);
export interface SessionRequestProps {
    onSubmit?: (values: TSessionRequest) => Promise<void>;
    sessionRequest?: TSessionRequest;
}
const validationSchema = Yup.object({
    text: Yup.string()
        .trim()
        .test({
            name: 'texttest',
            message: 'Empty text is not allowed',
            test: function (value: string) {
                if (value && value.trim().length === 0) return false;
                return true;
            },
        }),
});
const SessionRequest: FC<SessionRequestProps> = (props) => {
    const { getTranslation } = useTranslation();
    const { getFeedbackValues } = FeedbackValue();
    const FEEDBACK_VALUES = getFeedbackValues();
    const config: IReactFormProps['config'] = FEEDBACK_VALUES.map((i) => ({
        valueKey: i.value,
        type: 'request',
        fieldProps: {
            label: i.name,
            formControlLabelProps: {
                style: { alignItems: 'flex-start' },
            },
            color: 'secondary',
        },
    }));
    const commentConfig: IReactFormProps['config'] = [
        {
            valueKey: 'text',
            type: 'text',
            fieldProps: {
                label: getTranslation('meetingRequestForSessionForm.commentInputLabel'),
                multiline: true,
                rows: 3,
                fullWidth: true,
                variant: 'outlined',
            },
        },
    ];
    const onSubmit = useAsyncTask(async (values: TSessionRequest) => {
        await props.onSubmit?.(values);
    });

    const allowSubmit = (values: TSessionRequest) => {
        return !isFormEmpty(values);
    };

    return (
        <Box width="100%">
            <Formik validationSchema={validationSchema} initialValues={{ ...props.sessionRequest }} onSubmit={onSubmit.run}>
                {(formikProps) => (
                    <>
                        <Typo style={{ marginBottom: 12 }} weight="medium" variant="body2">
                            {getTranslation('meetingRequestForSessionForm.formTitle')}
                        </Typo>
                        <MLFormContent schema={[...config, ...commentConfig]} formikProps={formikProps} formId="session-feedback" />
                        <SubmitButton
                            fullWidth
                            disabled={!allowSubmit(formikProps.values)}
                            loading={onSubmit.status === 'PROCESSING'}
                            color="primary"
                            type={'submit'}
                            variant="contained"
                            onClick={() => formikProps.handleSubmit()}
                        >
                            {getTranslation('meetingRequestForSessionForm.submitButtonLabel')}
                        </SubmitButton>
                    </>
                )}
            </Formik>
        </Box>
    );
};

const isFormEmpty = (form: TSessionRequest) => {
    return SESSION_REQUEST_FORM_KEYS.filter((k) => !!form[k]).length === 0;
};

export default SessionRequest;
