import dayjs from "dayjs";
import { useCallback } from "react";
import { useNavMenu } from "../Features/AppHeader/useNavMenu";
import { TSession } from "../Models/Agenda/@types";
import { useStoreActions, useStoreState } from "../Stores";

export const useJoinSession = () => {
    const { registrant } = useStoreState(({ Registrant: { registrant } }) => ({ registrant }));
    const { setRegistrant } = useStoreActions(({ Registrant: { setRegistrant } }) => ({ setRegistrant }))
    const { origin, BASE_URL } = useNavMenu();
    const handleJoin = useCallback(async (item: TSession) => {
        if (item.type === 'externalConferenceCall' && item.externalConferenceUrl && dayjs().isBefore(item.endTime)) {
            window.open(
                item.externalConferenceUrl,
                'External Url'
            );
        } else {
            //  await onSessionJoin(item.id);
            let checkInIds = registrant?.checkedInSessionIds || [];
            if (checkInIds.indexOf(item.id) === -1)
                checkInIds.push(item.id);
            registrant && setRegistrant({ ...registrant, checkedInSessionIds: checkInIds });
            window.open(
                `${origin + BASE_URL}/agenda/fullscreen/${item.id}`,
                'details',
                `height=${window.innerHeight},width=${window.innerWidth},toolbar=0,menubar=0,scrollbars=0,resizable=0,location=0,directories=0,status=0`
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [registrant, registrant?.checkedInSessionIds?.length]);
    return { handleJoin }
}