import { Box, Grid, GridProps } from '@material-ui/core';
import React, { FC } from 'react';
import FullScreenLoader from '../../Components/FullScreenLoader';
import { TAgencyContent } from '../../Models/Agency/@types';
import AgencyContentCard, { AgencyContentCardProps } from './AgencyContentCard';

export interface AgencyContentGridListProps extends Omit<AgencyContentCardProps, 'item'> {
    gridContainerProps?: GridProps,
    items: TAgencyContent[]
    gridItemprops?: GridProps
    loading?: boolean
}

const AgencyContentGridList: FC<AgencyContentGridListProps> = (props) => {
    const { loading = false, gridContainerProps, gridItemprops, items, ...assetCardProps } = props;
    const gridSizing = getGridSizing(assetCardProps.variant) as GridProps;


    return loading ? <Box height='80vh'><FullScreenLoader /></Box> : (
        <Grid spacing={4} {...gridContainerProps} container>
            {items.map((item, i) => (
                <Grid {...gridSizing} {...gridItemprops} item key={i}>
                    <AgencyContentCard item={item} {...assetCardProps} />
                </Grid>
            ))}
        </Grid>
    )
}

function getGridSizing(variant: AgencyContentCardProps['variant']) {
    if (variant === 'list')
        return { md: 12, sm: 12, xs: 12 }
    return { xl: 2, lg: 3, md: 4, sm: 12, xs: 12 }
}



export default AgencyContentGridList;