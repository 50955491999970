import { Box, Paper } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React, { FC, useState } from "react";
import Typo from "../../Components/Typo";
import EmailVerificationForm, { EmailVerificationFormData } from "../../Forms/EmailVerificationForm";
import useAsyncTask from "../../Hooks/useAsyncTask";
import EntityRegistrationsModel from "../../Models/EntityRegistrations";
import { TEntity } from "../../Models/EntityRegistrations/@types";
import { useStoreActions } from "../../Stores";
import { useAuth } from "../Auth/useAuth";
import { useRegistrantHandler } from "../PrivateRoute/useRegistrantHandler";
import GuestLoginForm from "Forms/GuestLoginForm";

export interface SignInCardProps {
    entity: TEntity;
}

const SignInCard: FC<SignInCardProps> = (props) => {
    const classes = useStyles();
    const { entity } = props;
    const { doGuestLogin } = useStoreActions(({ User: { doGuestLogin } }) => ({ doGuestLogin }));
    const type = EntityRegistrationsModel.getEntityType(entity);
    const { isRegistered: checkEmail } = useAuth();
    const handleGuestSignUp = useAsyncTask(async (data: Record<string, string>) => {
        await doGuestLogin({
            userData: { ...data, emailOptIn: !!entity.emailOptInText && entity.isPreviewResource },
            agencyId: entity.agencyId,
            resourceType: type,
            resourceId: entity.id,
        });
    });
    const { AuthComponent, openGuestDialog, openLoginDialog, openSignupDialog } = useRegistrantHandler({
        initRegistrant: true,
        baseUrl: window.location.pathname,
        handleGuestSignUp: handleGuestSignUp.run,
    });
    const [show, setShow] = useState<"guest" | "auth">();
    const [email, setEmail] = useState("");
    const handleCheck = useAsyncTask(async (data: EmailVerificationFormData) => {
        // e.preventDefault();
        try {
            const isRegistered = await checkEmail(data.email || "");
            setEmail(data.email);
            if (isRegistered) {
                setShow("auth");
                openLoginDialog(data.email);
            } else {
                if (entity.isPreviewResource) {
                    //  openGuestDialog(data.email);
                    setShow("guest");
                } else {
                    setShow("auth");
                    openSignupDialog(data.email);
                }
            }
            // if (isRegistered) setAuthType('login');
            // else {
            //     allowGuest ? setAuthType('guest') : setAuthType('signup');
            // }
        } catch (error) {}
    });

    return (
        <Paper elevation={2} className={classes.root}>
            <Box width="100%" display="flex" flexDirection="column">
                <Typo gutterBottom variant="h6" weight="medium">
                    {"Enter your email to view complete content"}
                </Typo>
                {show === "guest" ? (
                    <GuestLoginForm onBack={() => setShow(undefined)} onSubmit={handleGuestSignUp.run} isSubmitting={handleGuestSignUp.status === "PROCESSING"} initialData={{ email }} />
                ) : (
                    <EmailVerificationForm emailOptInText={entity.emailOptInText} onSubmit={handleCheck.run} isSubmitting={handleCheck.status === "PROCESSING"} initialData={{ email }} />
                )}
            </Box>
            {show === "auth" && <>{AuthComponent}</>}
        </Paper>
    );
};

const useStyles = makeStyles<Theme>((theme) => {
    return createStyles({
        root: {
            // zIndex: theme.zIndex.snackbar + 1,
            width: "100%",
            padding: theme.spacing(2),
            boxSizing: "border-box",
            position: "sticky",
            top: 100,
        },
    });
});

export default SignInCard;
