import StrategicPartnersBase from "../../BaseModels/StrategicPartners";
import { TStrategicPartner } from "./@types";

class StrategicPartnerModel extends StrategicPartnersBase {


    static isUserFromStrategicPartner = async (email: string, partners: TStrategicPartner[]) => {
        if (!email)
            return { isStrategicPartnerUser: false, strategicPartnerSlug: undefined };
        const domain = email.split('@')?.[1];
        if (!domain)
            return { isStrategicPartnerUser: false, strategicPartnerSlug: undefined };
        try {
            if (!partners.length)
                return { isStrategicPartnerUser: false, strategicPartnerSlug: undefined };
            else {
                let len = partners.length;
                for (var i = 0; i < len; i++) {
                    if ((partners[i].emailDomains || []).includes(domain))
                        return { isStrategicPartnerUser: true, strategicPartnerSlug: partners[i].id }
                }
            }
        } catch (err) {

        }
        return { isStrategicPartnerUser: false, strategicPartnerSlug: undefined };
    }
}

export default StrategicPartnerModel;