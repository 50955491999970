import { Box, Button, CircularProgress } from '@material-ui/core';
import Mustache from 'mustache';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import CloseButton from '../../Components/CloseButton';
import Typo from '../../Components/Typo';
import { useAuthDialog } from '../../Dialogs/Auth/useAuthDialog';
import useToastMessage from '../../Hooks/useToastMessage';
import { useTranslation } from '../../Hooks/useTranslation';
import { TRegistrant } from '../../Models/Registrant/@types';
import UserModel from '../../Models/User';
import { useStoreState } from '../../Stores/index';
import { useNavMenu } from '../AppHeader/useNavMenu';
import { useAuth } from '../Auth/useAuth';
import { useCheckIn } from '../CheckIn';
import Permissions from '../Permissions';
import useAutoRegister from '../Registration/useAutoRegister';
import FlowPopover from './FlowPopover';

export type useFlowPopoverConfig = {
    id: string;
    onClose?: () => void;
    registrant?: TRegistrant;
};

export type PopperFor = 'sessionInterest' | 'join' | 'oneOnOneMeeting' | 'checkin';
export type PopperAction = 'register' | 'signin' | 'checkin' | 'sendVerificationLink';
export type PopperContentType = 'register' | 'signin' | 'checkin' | 'closed' | 'cancelled' | 'notApproved' | 'verifyEmail';

export const useFlowPopover = (config: useFlowPopoverConfig) => {
    const { getTranslation } = useTranslation();
    const { eventDetails, eventSettings } = useStoreState((state) => state.Events);
    const { register: doAutoRegister } = useAutoRegister();
    const CONTENT: Record<PopperContentType, { text: string; action?: { actionLabel: string; action: PopperAction } }> = {
        signin: {
            text: getTranslation('mainBanner.notLoggedIn'),
            action: {
                action: 'signin',
                actionLabel: getTranslation('mainBanner.signInLabel'),
            },
        },
        register: {
            text: `Please register to perform this action.`,
            action: {
                action: 'register',
                actionLabel: getTranslation('mainBanner.registerLabel'),
            },
        },
        checkin: {
            text: `Please checkin to perform this action.`,
            action: {
                action: 'checkin',
                actionLabel: getTranslation('mainBanner.checkinLabel'),
            },
        },
        verifyEmail: {
            text: getTranslation('mainBanner.emailNotVerified'),
            action: {
                action: 'sendVerificationLink',
                actionLabel: getTranslation('mainBanner.resendLink'),
            },
        },

        cancelled: {
            text: getTranslation('mainBanner.rejectedRegistration'),
        },
        closed: {
            text: getTranslation('mainBanner.closedRegistration'),
        },
        notApproved: {
            text: getTranslation('mainBanner.notApproved'),
        },
    };

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [contentType, setContentType] = useState<PopperContentType>('signin');
    const [loading, setLoading] = useState<boolean>(false);
    const { isAuthenticated, user } = useStoreState((state) => state.User);
    const history = useHistory();
    const { BASE_URL } = useNavMenu();
    const { handleCheckInClick, GdprDialog } = useCheckIn();
    const { isRegistered, forgotPassword, userLogin, userSignup } = useAuth();
    const { AuthComponent, openAuthDialog } = useAuthDialog({
        onLogin: userLogin,
        onSignup: userSignup,
        handleCheck: isRegistered,
        onForgotPassword: forgotPassword,
    });

    const withToast = useToastMessage();

    const withPopover = async (target: HTMLElement, flow: PopperFor, action: () => any) => {
        if (!eventDetails) return;
        if (!isAuthenticated) {
            setContentType('signin');
            setAnchorEl(target);
            return;
        }

        if (!Permissions.isEmailVerified(eventDetails, user)) {
            setContentType('verifyEmail');
            setAnchorEl(target);
            return;
        }

        if (flow === 'checkin') {
            return await action();
        }

        if (flow === 'sessionInterest' || flow === 'oneOnOneMeeting') {
            if (Permissions.sessionInterest(config.registrant)) {
                return await action();
            } else {
                if (config.registrant?.id) {
                    if (config.registrant?.status === 'approved') setContentType('checkin');
                    else if (config?.registrant?.status === 'registered' && eventSettings?.approvalRequired) setContentType('notApproved');
                    else if (config.registrant?.status === 'canceled' || config.registrant?.status === 'rejected') setContentType('cancelled');
                } else {
                    if (eventDetails?.registrationOpen) setContentType('register');
                    else setContentType('closed');
                }
                // if (config.registrant?.status === 'rejected') {
                //     setContentType('cancelled');
                // } else if (eventSettings?.approvalRequired && config.registrant?.status !== 'approved') setContentType('notApproved');
                // else if (eventDetails.registrationOpen) setContentType('register');
                // else if (!eventDetails.registrationOpen) setContentType('closed');
                setAnchorEl(target);
            }
            return;
        }
        if (flow === 'join') {
            if (Permissions.sessionJoin(config.registrant)) {
                return await action();
            } else {
                if (config.registrant?.id) {
                    if (config.registrant?.status === 'approved') setContentType('checkin');
                    else if (config.registrant?.status === 'registered' && eventSettings?.approvalRequired) setContentType('notApproved');
                    else if (config.registrant?.status === 'canceled' || config.registrant?.status === 'rejected') setContentType('cancelled');
                } else {
                    if (eventDetails?.registrationOpen) setContentType('register');
                    else setContentType('closed');
                }

                setAnchorEl(target);
            }
            return;
        }
    };

    const handleClose = () => {
        config.onClose?.();
        setAnchorEl(null);
    };

    const sendVerificationLink = async () => {
        if (!user || !eventDetails) return;
        try {
            await withToast(
                async () => {
                    await UserModel.sendVerificationMail(user.email, eventDetails.slug, eventDetails.agencyId);
                },
                {
                    successToastMessage: Mustache.render(getTranslation('feedbackMessages.verificationEMailSent') as string, {
                        userEmail: user.email,
                    }),
                }
            );
        } catch (error) {}
    };

    const handleAction = (action: PopperAction) => async () => {
        if (!eventDetails) return;
        switch (action) {
            case 'checkin': {
                try {
                    setLoading(true);
                    await handleCheckInClick(eventDetails.id);
                    setLoading(false);
                } catch (_e) {
                    setLoading(false);
                }
                handleClose();
                break;
            }
            case 'register': {
                if (eventDetails.autoRegister) {
                    doAutoRegister.run({}).then(() => handleClose());
                } else {
                    history.push(`${BASE_URL}/register`);
                    handleClose();
                }

                break;
            }
            case 'signin': {
                openAuthDialog();
                handleClose();
                break;
            }
            case 'sendVerificationLink': {
                sendVerificationLink();
                handleClose();
                break;
            }
        }
    };

    const content = CONTENT[contentType];

    return {
        withPopover,
        isOpen : !!anchorEl,
        Component: (
            <>
                {anchorEl ? (
                    <FlowPopover anchorEl={anchorEl} id={config.id} onClose={handleClose}>
                        <Box width="320px" p={2} boxSizing="border-box">
                            <Box display="flex" mb={1} mt={-1} justifyContent="flex-end">
                                <CloseButton variant="filledLight" size="small" onClick={handleClose} />
                            </Box>
                            <Typo>{content.text}</Typo>
                            {content.action ? (
                                <Box mt={1} display="flex" justifyContent="center">
                                    {loading ? (
                                        <CircularProgress color="primary" />
                                    ) : (
                                        <Button fullWidth color="primary" variant="contained" onClick={handleAction(content.action.action)}>
                                            {content.action.actionLabel}
                                        </Button>
                                    )}
                                </Box>
                            ) : null}
                        </Box>
                    </FlowPopover>
                ) : null}
                {AuthComponent}
                {GdprDialog}
            </>
        ),
    };
};
