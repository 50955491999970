import { AppBar, Box, Toolbar } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React, { FC } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { IS_IN_IFRAME } from '../..';
import LOGO from '../../Assets/Logo/default_logo.svg';
import { useMediaQuery } from '../../Hooks/useMediaQuery';
import { useStoreState } from '../../Stores';
import { HEADER_HEIGHT } from '../AppHeader';
import UserMenu from '../AppHeader/UserMenu';


export interface AgencyHeaderProps {

}

const AgencyHeader: FC<AgencyHeaderProps> = (props) => {
    const classes = useStyles({});
    const history = useHistory()
    const { isDeviceSm } = useMediaQuery();
    const { agency, } = useStoreState(({ User: { user }, Agency: { agency, query } }) => ({ user, agency, query }));

    const logo = agency?._logo?.url || LOGO;

    const onLogoClick = () => {
        
        history.replace({search : '' , pathname : '/calendar'})
    }

    return (
      <AppBar position="sticky" className={classes.appbar}>
        <Toolbar classes={{ gutters: classes.gutter }} className={classes.shrunk}>
          {!isDeviceSm && <Box width={'20px'} />}
          {/* <Link rel="noopener" to={'#'}> */}
          <img className={classes.logo} onClick={onLogoClick} src={logo} height="50px" alt="logo" />
          {/* </Link> */}
          <Box flex={1} />
        
          {IS_IN_IFRAME ? null : !isDeviceSm && <UserMenu />}
        </Toolbar>
      </AppBar>
    );
}

const useStyles = makeStyles<Theme, any>((theme) => ({
    logo:{
        cursor : 'pointer'
    },
    appbar: {
        backgroundColor: theme.palette.common.white,
        transition: '200ms ease-in',
        boxShadow: '0px 1px 2px #00000029',
        '& img': {
            transition: '200ms ease-out'
        }
    },
    shrunk: {
        height: HEADER_HEIGHT,
        transition: '200ms ease-in',
        '& img': {
            height: 36
        }
    },
}));

export default AgencyHeader;