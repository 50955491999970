import React, { FC, useContext } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, Button, CircularProgress } from '@material-ui/core';
import Typo from '../../Components/Typo';
import { useStoreState } from '../../Stores';
import useAsyncTask from '../../Hooks/useAsyncTask';
import useToastMessage from '../../Hooks/useToastMessage';
import UserModel from '../../Models/User';
import { ContrastText } from '../../Components';
import { useTranslation } from '../../Hooks/useTranslation'
import { TranslationContext } from '../../Utils/TranslationProvider';
import Mustache from 'mustache';
import Permissions from '../Permissions';

export interface VerificationStatusProps {

}

const VerificationStatus: FC<VerificationStatusProps> = (props) => {
    const classes = useStyles({});
    const { user, eventDetails } = useStoreState(({ User: { user }, Events: { eventDetails } }) => ({ user, eventDetails }));

    const withToast = useToastMessage();
    const { getTranslation } = useTranslation();
    const { language } = useContext(TranslationContext);


    const sendVerificationLink = async () => {
        if (!user || !eventDetails) return;
        try {
            await withToast(async () => {
                await UserModel.sendVerificationMail(user.email, eventDetails.slug, eventDetails.agencyId);
            }, { successToastMessage: Mustache.render(getTranslation("feedbackMessages.verificationEMailSent") as string, { userEmail: user.email }) });
        } catch (error) {

        }
    }

    const verificationClickHandler = useAsyncTask(sendVerificationLink);



    if (!user) return <div />;
    if (Permissions.isEmailVerified(eventDetails, user)) return <div />

    return (
        <Box className={classes.root} display="flex" alignItems="flex-start" flexDirection="column" px={2} py={1}>
            <ContrastText>{getTranslation("mainBanner.emailNotVerified")}</ContrastText>
            <Button variant="text" onClick={verificationClickHandler.run}>
                <ContrastText underline>
                    {verificationClickHandler.status === 'PROCESSING' ? <CircularProgress /> : getTranslation("mainBanner.resendLink")}
                </ContrastText>
            </Button>
        </Box>
    )
}

const useStyles = makeStyles<Theme, any>((theme) => ({
    root: {
        position: 'fixed',
        bottom: 20,
        left: 20,
        zIndex: theme.zIndex.appBar + 1,
        backgroundColor: '#b90202',
        boxShadow: theme.shadows[1],
        borderRadius: 4,
        '& button': {
            paddingBottom: 0,
            paddingTop: 0,
            textDecoration: 'underline',
            marginLeft: -4
        }
    }
}));

export default VerificationStatus;