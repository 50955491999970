export const SALUTATIONS = [
    'Herr',
    'Frau',
    'Herr Dr',
    'Frau Dr',
    'Herr Prof',
    'Frau Prof',
    'Herr Prof. Dr.',
    'Frau Prof. Dr.',
    'Mr',
    'Mrs',
    'Miss',

]