import { Box } from '@material-ui/core';
import React, { FC, useMemo } from 'react';
import Typo from '../../Components/Typo';
import { TEvent } from '../../Models/Events/@types';
import { useStoreState } from '../../Stores';

export interface DomainAllowMessageProps {}

const WHITELIST_MESSAGE = 'Only the following domains are allowed:';
const BLACKLIST_MESSAGE = 'Following domains are restricted:';

const DomainAllowMessage: FC<DomainAllowMessageProps> = (props) => {
    const { eventDetails = {} as TEvent } = useStoreState(({ Events: { eventDetails } }) => ({ eventDetails }));

    const { domainRestrictionType = 'open', restrictedEmailDomains = [], restrictedStrategicPartners = [] } = eventDetails;
    // const { allowed } = DomainUtils.checkEmailDomains(restrictedStrategicPartners || [],
    //     {
    //         domainRestrictionType: domainRestrictionType,
    //         restrictedEmailDomains: restrictedEmailDomains,
    //         restrictedStrategicPartnerIds: restrictedStrategicPartnerIds
    //     });

    const domains = useMemo(() => {
        let emailToCheck = restrictedEmailDomains;
        restrictedStrategicPartners.forEach((elem) => {
            emailToCheck = [...emailToCheck, ...elem.emailDomains];
        });
        return emailToCheck;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [restrictedEmailDomains.length, restrictedStrategicPartners.length]);

    if (domainRestrictionType === 'open') return null;
    const message = domainRestrictionType === 'deny' ? BLACKLIST_MESSAGE : WHITELIST_MESSAGE;
    // const domaiList = [restrictedEmailDomains, restrictedStrategicPartners.map(i => i)]
    // const domaiList = eventDetails.domainRestrictionType === 'deny' ? eventDetails.blacklistedDomains || [] : eventDetails.whitelistedDomains || [];
    if (!eventDetails) return null;

    return (
        <Box>
            <Typo variant="caption">{message}</Typo>
            <br />
            <Typo variant="caption">{domains.join(', ')}</Typo>
        </Box>
    );
};

export default DomainAllowMessage;
