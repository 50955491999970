import { useContext, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import AppContext from "../Context/AppContext";
import { useStoreState } from "../Stores";
import { useSocketListener } from "./useSocketListener";

const origin = window.origin;
const usePathChangeListener = () => {
    const [sourcePath, setSourcePath] = useState<string>("");
    const { pathname } = useLocation();
    const { initialized } = useContext(AppContext);
    const { user, zineId, assetId, activeTab } = useStoreState(({ User: { user }, Zine: { zineId }, AgencyAsset: { assetId }, Agency: { activeTab } }) => ({ user, zineId, assetId, activeTab }));
    const { TrackCustomEvent } = useSocketListener();

    const shouldFire = useMemo(() => {
        if (pathname.includes("/zine")) {
            if (zineId) return true;
            else {
                return false;
            }
        }
        if (pathname.includes("/asset")) {
            if (assetId) return true;
            else {
                return false;
            }
        }
        if (initialized) return true;
        else if (activeTab) return true;
        return false;
    }, [pathname, initialized, zineId, assetId, activeTab]);

    useMemo(() => {
        if (shouldFire) {
            const fullPath = origin + pathname;
            TrackCustomEvent.emit("PATH_CHANGE", {
                sourcePath: sourcePath,
                destinationPath: fullPath,
            });
            setSourcePath(fullPath);
        }
    }, [pathname, origin, user?.id, shouldFire]);
};
export default usePathChangeListener;
