import { TAgencyState } from ".";
import qs from 'qs';

class Query {
    filter: TAgencyState['appliedFilter'];

    constructor(filter?: TAgencyState['appliedFilter']) {
        this.filter = {
            regions: filter?.regions,
            focusAreaIds: filter?.focusAreaIds ?? [],
            languages: filter?.languages ?? [],
            type: filter?.type ?? [],
            status: filter?.status
        }
    }

    get toQueryString() {
        const _filter: Record<string, string[] | string> = {};
        if (this.filter.regions)
            _filter['regions'] = this.filter.regions;
        if (this.filter.focusAreaIds.length)
            _filter['focusAreaIds'] = this.filter.focusAreaIds;
        if (this.filter.languages.length)
            _filter['languages'] = this.filter.languages;
        if (this.filter.type.length)
            _filter['type'] = this.filter.type;
        if (this.filter.status)
            _filter['status'] = this.filter.status;
        return qs.stringify(Object.keys(_filter).length ? { filter: qs.stringify(_filter) } : {});
    }

    static fromQueryString(query: string) {
        const q = qs.parse(query, { ignoreQueryPrefix: true }) as any;
        const filter = (q.filter ? qs.parse(q.filter) : { focusAreaIds: [], languages: [], type: [] }) as TAgencyState['appliedFilter'];
        return new Query(filter);
    }

    static fromUrl() {
        const q = qs.parse(window.location.search, { ignoreQueryPrefix: true }) as any;
        const filter = (q.filter ? qs.parse(q.filter) : { focusAreaIds: [], languages: [], type: [], }) as TAgencyState['appliedFilter'];
        return new Query(filter)
    }

    static getQueryFromObject = (obj: Object, options?: qs.IStringifyOptions | undefined) => {
        return qs.stringify(obj, options);
    }

}

export default Query;