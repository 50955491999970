import { Box } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';
import { StarBorderSharp, StarSharp } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import React, { FC, useState } from 'react';
import Typo from '../Typo';

export interface indexProps {
    count?: number
    onChange?: (rating: number) => any
    description?: string
    rating?: number
}
const Rating: FC<indexProps> = (props) => {

    const { count = 5, description = '', rating = -1 } = props;
    const classes = useStyles();
    const [baseRating, setBaseRating] = useState<number>(rating)
    const handleClick = (index: number) => {
        setBaseRating(index)
        if (props.onChange)
            props.onChange(index + 1)
    }
    return (
        <Box className={classes.ratingRoot}>
            <Typo weight='medium' variant='body2'>{description}</Typo>
            <Box display='flex' mt='10px'>
                {
                    Array.from({ length: count }).map((_, i) => {
                        if (i <= baseRating)
                            return <StarSharp key={i} className={classes.star} onClick={() => handleClick(i)} />
                        else
                            return <StarBorderSharp key={i} className={classes.star} onClick={() => handleClick(i)} />
                    })
                }
            </Box>
        </Box>
    )
}

const useStyles = makeStyles<Theme>((theme) => {
    return (createStyles({
        ratingRoot: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        star: {

            margin: '0 5px',
            fontSize: '40px',
            color: '#FFAF2F',
            transition: '200ms linear',
            '&:hover': {
                cursor: 'pointer',
                // fontSize: '45px'
                transform: 'scale(1.3)'
            }
        }
    }))
})

export default Rating