import React, { FC } from 'react';
import { attachField, ReactForm, setDefaultProps } from 'react-forms';
import { useMediaQuery } from '../../Hooks/useMediaQuery';
import { TAgency } from '../../Models/Agency/@types';
import { TEntity, TEntityRegistration } from '../../Models/EntityRegistrations/@types';
import ErrorText from '../ErrorText';
import { formActionConfig, getFormConfig, getFormValidator } from './config';
export interface EntityRegistrationFormProps {
    entity: TEntity;
    agency: TAgency;
    formData: Partial<TEntityRegistration>;
    onSubmit: (formData: Partial<TEntityRegistration>) => void;
    disabledKeys?: (keyof TEntityRegistration)[];
}
setDefaultProps('text', { autoComplete: 'off', fullWidth: true });

attachField('errorText', <ErrorText errorMessage="Some mandatory fields are missing/incorrect" />);
const EntityRegistrationForm: FC<EntityRegistrationFormProps> = (props) => {
    const { entity, formData, onSubmit, disabledKeys = [], agency } = props;
    const { isDeviceSm } = useMediaQuery();
    const config = getFormConfig(entity, disabledKeys, isDeviceSm, agency);
    const formValidator = getFormValidator(entity, disabledKeys, isDeviceSm, agency);
    return (
        <div>
            <ReactForm
                initialValues={{
                    receiveUpdates: false,
                    agreedNDA: false,
                    focusAreaIds: [],
                    phone: '',
                    entityRelation: formData.eventRelation,
                    ...formData,
                }}
                // initialValues={{ salutation: 'mr', email: formData.email || '', firstName: formData.firstName || '' }}
                onSubmit={onSubmit}
                config={config}
                formId={'userForm'}
                actionConfig={formActionConfig}
                formSettings={{
                    verticalSpacing: 40,
                    horizontalSpacing: 40,
                }}
                validationSchema={formValidator}
            />
        </div>
    );
};

export default EntityRegistrationForm;
