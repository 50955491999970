import React, { createContext, useState } from 'react';
import { Theme } from '@material-ui/core/styles';
import DEFAULT_THEME from '../../Theme';


export const ThemeContext = createContext<ThemeContextProviderValue>({
    theme: DEFAULT_THEME,
    setTheme: () => { }
})


const ThemeContextProvider: React.FC = (props) => {
    const [theme, setTheme] = useState<Theme>(DEFAULT_THEME)

    const value: ThemeContextProviderValue = {
        theme,
        setTheme,
    }

    return (
        <ThemeContext.Provider value={value}>
            {props.children}
        </ThemeContext.Provider>
    )
}


export interface ThemeContextProviderValue {
    theme: Theme
    setTheme: React.Dispatch<React.SetStateAction<Theme>>
}

export default ThemeContextProvider;