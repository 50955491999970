import React from 'react'
import { IFieldProps } from 'react-forms'
import ErrorMessage from '../Components/ErrorText'

interface ErrorTextProps extends IFieldProps {
    errorMessage: string
}

const ErrorText: React.FC<ErrorTextProps> = (props) => {

    const hasError = Object.keys(props.formikProps?.errors || {}).length > 0;
    if (!hasError) return <div />

    return (
        <ErrorMessage>
            {props.errorMessage}
        </ErrorMessage>
    )
}



export default ErrorText