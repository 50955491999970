import { Box, Button, Icon, IconButton, TextField } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { FC, useEffect, useState } from 'react';
import Typo from '../../Components/Typo';
import { useGlobalStyle } from '../../Constants/globalStyle';
import { TSessionQuestions } from '../../Models/Agenda/@types';

export interface SessionQuestionsProps {
    onSubmit: (questions: Partial<TSessionQuestions>) => Promise<void>;
    disabled?: boolean;
    questions?: TSessionQuestions;
}

const SessionQuestions: FC<SessionQuestionsProps> = ({ questions, onSubmit }) => {
    const [value, setValue] = useState('');
    const [items, setItems] = useState<string[]>([]);
    const globalClasses = useGlobalStyle();
    const classes = useStyles();
    useEffect(() => {
        setItems(questions?.questions || []);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [questions?.questions.length]);
    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setValue(e.target.value);
    };
    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter' && value) {
            setItems((i) => [value, ...i]);
            setValue('');
        }
    };
    const handleSubmit = () => {
        let list = [...items];
        if (value) {
            list.push(value);
        }
        onSubmit({ questions: list });
    };
    const handleRemove = (index: number) => {
        setItems((i) => i.filter((_i, ii) => ii !== index));
    };
    return (
        <Box width="100%" display="flex" flexDirection="column">
            <Box display="flex" flexDirection="column" flex={1} minHeight="150px" maxHeight="150px" overflow="auto">
                <Box display="flex" mb={1} className={classes.divider}>
                    <Typo variant="body2" weight="bold">
                        {'List your questions here :'}
                    </Typo>
                </Box>
                <Box width="100%" display="flex" flexDirection="column" height="100%" overflow="auto">
                    {items.map((item, index) => {
                        return (
                            <Box display="flex" justifyContent="space-between" width="100%" alignItems="center" my={0.5} key={index}>
                                <Typo variant="body2">{item}</Typo>
                                <IconButton className={classes.icons} onClick={() => handleRemove(index)}>
                                    <Icon className={globalClasses.iconButtonSmall}>close</Icon>
                                </IconButton>
                            </Box>
                        );
                    })}
                </Box>
            </Box>
            <Box width="100%" my={2}>
                <TextField
                    fullWidth
                    onChange={handleChange}
                    onKeyPress={handleKeyDown}
                    value={value}
                    placeholder="Please click on Submit to save your question."
                    variant="outlined"
                />
            </Box>
            <Button disabled={!items.length} variant="contained" color="primary" fullWidth onClick={handleSubmit}>
                Submit
            </Button>
        </Box>
    );
};

export default SessionQuestions;
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        icons: {
            '&:hover': {
                backgroundColor: '#fff',
            },
            padding: 0,
        },
        divider: {
            borderBottom: `1px solid ${theme.palette.grey['600']}`,
        },
    })
);
