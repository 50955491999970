import { Box } from '@material-ui/core';
import EventModel from 'Models/Events';
import React from 'react';
import { Link } from 'react-router-dom';
import { RoundedButton } from '../../../Components';
import Typo from '../../../Components/Typo';
import { TBannerConfig } from '../../../Context/BannerContext/@types';
import { useAuthDialog } from '../../../Dialogs/Auth/useAuthDialog';
import { useMediaQuery } from '../../../Hooks/useMediaQuery';
import { useTranslation } from '../../../Hooks/useTranslation';
import { useStoreState } from '../../../Stores';
import { useNavMenu } from '../../AppHeader/useNavMenu';
import { useAuth } from '../../Auth/useAuth';
import useAutoRegister from '../../Registration/useAutoRegister';
import { getBannerMessages } from './GenericMessage';
interface PostEventBannerProps {
    loggedIn?: boolean;
    registered?: boolean;
    registrationOpen?: boolean;
    registrantionStatus?: 'cancelled' | 'closed' | 'unregistered' | 'approved' | 'rejected' | 'checkedIn';
}


const PostEventBanner: React.FC<PostEventBannerProps> = (props) => {
    const { loggedIn = false, registered = false, registrantionStatus = 'unregistered', registrationOpen = false } = props;
    const { isDeviceSm } = useMediaQuery();
    const { BASE_URL } = useNavMenu();
    const { eventSettings, eventDetails } = useStoreState(({ Events: { eventDetails, eventSettings } }) => ({ eventSettings, eventDetails }));

    const { userLogin, userSignup, isRegistered, forgotPassword } = useAuth();
    const { RoundedRegisterButton } = useAutoRegister();
    const { AuthComponent, openAuthDialog } = useAuthDialog({
        onLogin: userLogin,
        onSignup: userSignup,
        handleCheck: isRegistered,
        onForgotPassword: forgotPassword,
    });
    const { getTranslation } = useTranslation();
    const requiresApproval = eventSettings?.approvalRequired ?? false;
    const { autoRegister , eventType = 'default'} = eventDetails || {};

    const eventOverText = `${EventModel.getEventLabel(true)} is over.`

    if (!loggedIn)
        return (
            <Box
                display="flex"
                mt={isDeviceSm ? 1 : 0}
                alignItems={isDeviceSm ? 'flex-start' : 'center'}
                width={isDeviceSm ? '100%' : undefined}
                flexDirection={isDeviceSm ? 'column' : 'row'}
            >
                <Box mt={isDeviceSm ? 1 : 0}>
                    <Typo variant={'subtitle2'} weight="medium">
                        {getBannerMessages('notLoggedIn', eventType,autoRegister)}
                    </Typo>
                </Box>
                {isDeviceSm ? (
                    <Box width="100%" display="flex">
                        <Box flex={1} />
                        <RoundedButton onClick={openAuthDialog} variant="contained" color="secondary">
                            {getTranslation('navbar.signIn')}
                        </RoundedButton>
                    </Box>
                ) : null}
                {AuthComponent}
            </Box>
        );
    return (
      <Box display="flex" mt={isDeviceSm ? 1 : 0} alignItems={isDeviceSm ? 'flex-start' : 'center'} width={isDeviceSm ? '100%' : undefined} flexDirection={isDeviceSm ? 'column' : 'row'}>
        {!registered && registrantionStatus === 'unregistered' && (
          <>
            <Box mx={isDeviceSm ? 0 : 3}>
              <Typo variant={'subtitle2'} weight="medium">
                {eventOverText}&nbsp;{registrationOpen ? getBannerMessages('registrationOpen', eventType, autoRegister) : getBannerMessages('registrationClosed', eventType, autoRegister)}
              </Typo>
            </Box>
            {registrationOpen && (
              <Box width={isDeviceSm ? '100%' : undefined} display="flex">
                <Box flex={1} />
                {autoRegister ? (
                  RoundedRegisterButton
                ) : (
                  <Link rel="noopener" to={`${BASE_URL}/register`}>
                    <RoundedButton borderColor="white" variant="contained" color="secondary">
                      {getTranslation('mainBanner.registerButton')}
                    </RoundedButton>
                  </Link>
                )}
              </Box>
            )}
          </>
        )}
        {registered && registrantionStatus === 'cancelled' && (
          <>
            <Box mx={isDeviceSm ? 0 : 3}>
              <Typo variant={'subtitle2'} weight="medium">
                {eventOverText}&nbsp;{getBannerMessages('cancelled', eventType, autoRegister)}
              </Typo>
            </Box>

            {registrationOpen && (
              <Box width={isDeviceSm ? '100%' : undefined} display="flex">
                <Box flex={1} />
                {autoRegister ? (
                  RoundedRegisterButton
                ) : (
                  <Link rel="noopener" to={`${BASE_URL}/register`}>
                    <RoundedButton borderColor="white" variant="contained" color="secondary">
                      {getTranslation('mainBanner.registerButton')}
                    </RoundedButton>
                  </Link>
                )}
              </Box>
            )}
          </>
        )}

        {registered && registrantionStatus === 'rejected' && (
          <Box>
            <Typo variant={'subtitle2'} weight="medium">
              {getBannerMessages('cancelled', eventType, autoRegister)}
            </Typo>
          </Box>
        )}

        {registered && registrantionStatus !== 'cancelled' && registrantionStatus !== 'rejected' && (
          <Box>
            <Typo variant={'subtitle2'} weight="medium">
              {!requiresApproval || registrantionStatus === 'approved' || registrantionStatus === 'checkedIn' ? getBannerMessages('registered', eventType, autoRegister) : getBannerMessages('notApproved', eventType, autoRegister)}
            </Typo>
          </Box>
        )}
      </Box>
    );
};

export const getPostEventBannerConfig = (config: PostEventBannerProps): TBannerConfig => ({
    content: { right: <PostEventBanner {...config} /> },
    size: 'large',
});

export default PostEventBanner;
