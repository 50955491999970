import { Box } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import React, { FC, useMemo, useState } from 'react';
import MainDialog from '../../Components/MainDialog';
import { TAsset } from '../../Models/App';
import { useStoreActions } from '../../Stores';
export interface PdfDialogProps {
    pdf: TAsset;
    open: boolean;
    afterClose?: Function;
}

const PdfDialog: FC<PdfDialogProps> = (props) => {
    const { pdf, open } = props;
    const classes = useStyles();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [loader, setLoader] = useState<boolean>(true);
    const { closePdfDialog } = useStoreActions(({ Dialogs: { closePdfDialog } }) => ({ closePdfDialog }));
    const onClose = () => {
        props.afterClose?.();
        closePdfDialog();
    };
    const onIframeLoad = (e: React.SyntheticEvent<HTMLIFrameElement, Event>) => {
        setLoader(false);
        const elem = document.getElementById('end');
        console.log(elem);
    };
    const isPdf = useMemo(() => {
        return pdf.mimetype === 'application/pdf';
    }, [pdf]);
    return (
        <MainDialog
            open={open}
            mini={true}
            onClose={() => onClose()}
            PaperProps={{
                className: classes.gradientBg,
            }}
            containerClassname={classes.padding}
        >
            <Box className={clsx(classes.root)}>
                <Box className={classes.container}>
                    {/* {loader ? <FullScreenLoader /> : null} */}
                    <iframe
                        className={classes.iframe}
                        title="pdf-dialog"
                        onLoad={(e) => onIframeLoad(e)}
                        // src={pdf.url}
                        // src={`http://view.officeapps.live.com/op/view.aspx?src=${pdf.url}`}
                        src={isPdf ? `${pdf.url}` : `https://view.officeapps.live.com/op/embed.aspx?src=${pdf.url}`}
                    ></iframe>
                    {/* <DocViewer config={{ header: { disableHeader: true } }} className={classes.iframe} pluginRenderers={DocViewerRenderers} documents={[{ uri: pdf.url }]} /> */}
                </Box>
            </Box>
        </MainDialog>
    );
};
/**
 * unselectable="on"
 * class="cui-toolbar-buttondock alignright
 * id="ChromelessStatusBar.RightDock"
 */
const useStyles = makeStyles<Theme>((theme) => {
    return createStyles({
        root: {
            overflow: 'auto',
            display: 'flex',
        },
        gradientBg: {
            // background: `${getGradientFromTheme(theme)} !important`
        },
        iframe: {
            width: 990,
            minHeight: `calc(100vh - 66px)`,
            border: '1px solid black',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                height: `calc(100vh - 66px)`,
            },
            // '& .cui-toolbar-buttondock alignright': {
            //     display: 'none'
            // }
        },
        container: {
            width: 1000,
            margin: 'auto',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
        },
        padding: {
            padding: 0,
            width: 1000,
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
        },
        cover: {
            backgroundColor: '#fff',
            opacity: 0.2,
        },
    });
});

export default PdfDialog;
