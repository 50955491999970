import { Popover, PopoverProps } from '@material-ui/core';
import React from 'react';

interface FlowPopoverProps {
    id: string
    anchorEl: HTMLElement | null
    onClose: () => void,
    PaperProps?: PopoverProps['PaperProps']
}

const FlowPopover: React.FC<FlowPopoverProps> = (props) => {
    const { anchorEl, id, PaperProps } = props;
    const open = Boolean(anchorEl);

    const handleClose = () => {
        props.onClose();
    }

    return (<Popover
        elevation={1}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}

        PaperProps={{
            ...PaperProps,
            style: {
                borderRadius: 8,
                ...PaperProps?.style
            }
        }}
    >
        {props.children}
    </Popover>
    )
}

export default FlowPopover