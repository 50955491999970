import { Box, Checkbox, CheckboxProps, Chip, FormControlLabel, FormControlLabelProps } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { FormikValues } from 'formik';
import { get } from 'lodash';
import React, { FC } from 'react';
import { FormConfig, IFieldProps } from 'react-forms';
import Typo from '../../Components/Typo';
import { useMediaQuery } from '../../Hooks/useMediaQuery';
import { useTranslation } from '../../Hooks/useTranslation';
export interface ItemProps extends CheckboxProps {
    label?: string;
    formControlLabelProps?: FormControlLabelProps;
}

export interface RequestItemsProps extends IFieldProps {
    fieldProps?: ItemProps;
}

const RequestItems: FC<RequestItemsProps> = (props) => {
    const { getTranslation } = useTranslation();
    const REQUEST_DURATIONS = [
        getTranslation('meetingRequestForSessionForm.meetingTimeInDaysInput'),
        getTranslation('meetingRequestForSessionForm.meetingTimeInWeeksInput'),
        getTranslation('meetingRequestForSessionForm.meetingTimeInMonthsInput'),
    ];
    const classes = useStyles();
    const { fieldProps = {} as ItemProps, formikProps = {} as FormikValues, fieldConfig = {} as FormConfig } = props;
    const { isDeviceSm } = useMediaQuery();
    const { label = '', formControlLabelProps = {} as FormControlLabelProps, ...checkboxProps } = fieldProps;
    const value = (get(formikProps, `values.${fieldConfig.valueKey}`) as boolean) || false;
    const duration = (get(formikProps, `values.${fieldConfig.valueKey}Duration`) as string) || '';
    const handleClick = (item: string) => {
        formikProps.setFieldValue(`${fieldConfig.valueKey}Duration`, duration === item ? '' : item);
    };
    const onChange = () => {
        formikProps.setFieldValue(`${fieldConfig.valueKey}`, !value);
        if (value) formikProps.setFieldValue(`${fieldConfig.valueKey}Duration`, '');
    };
    return (
        <Box>
            <FormControlLabel
                label={
                    <Typo variant="body2" weight="regular">
                        {label}
                    </Typo>
                }
                {...formControlLabelProps}
                control={<Checkbox checked={value} onChange={onChange} {...checkboxProps}></Checkbox>}
            />
            {value === true && (
                <Box display="flex" justifyContent={isDeviceSm ? 'flex-start' : 'center'} flexWrap="wrap">
                    {REQUEST_DURATIONS.map((i, index) => {
                        return (
                            <Chip
                                label={
                                    <Typo className={clsx({ [classes.text]: duration === i })} weight="regular" variant="body2">
                                        {i}
                                    </Typo>
                                }
                                key={index}
                                color="secondary"
                                variant={duration === i ? 'default' : 'outlined'}
                                className={clsx(classes.chips)}
                                onClick={() => handleClick(i)}
                            />
                        );
                    })}
                </Box>
            )}
        </Box>
    );
};

const useStyles = makeStyles<Theme>((theme) => {
    return createStyles({
        chips: {
            margin: 5,
        },
        text: {
            color: '#fff',
        },
    });
});

export default RequestItems;
