import { Dialog, DialogProps, Slide, Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';
import DialogToolbar from './DialogToolbar';

export interface MainDialogProps extends Omit<DialogProps, 'onClose' | 'onSubmit'> {
    onClose?: () => void;
    containerClassname?: string;
    mini?: boolean;
    dialogTitle?: string;
}

const MainDialog: React.FC<MainDialogProps> = (props) => {
    const { onClose = () => {}, mini = false, PaperProps, containerClassname, dialogTitle, ...dialogProps } = props;
    const classes = useStyles();

    return (
        <Dialog
            onClose={() => onClose()}
            PaperProps={{ ...PaperProps, className: clsx(classes.paperRoot, PaperProps?.className) }}
            fullScreen
            {...dialogProps}
            TransitionComponent={Slide}
        >
            <DialogToolbar mini={mini} onClose={onClose} title={dialogTitle} />
            <div className={clsx(classes.content, containerClassname)}>{props.children}</div>
        </Dialog>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paperRoot: {
            background: theme.palette.background.default,
        },
        content: {
            [theme.breakpoints.up('sm')]: {
                padding: '34px 0px 80px 0px',
                width: 800,
                margin: '0 auto',
            },
        },
    })
);

// function Transition(props: any) {
//     return <Slide direction="down" {...props} />;
// }

export default MainDialog;
