import { LinearProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useStoreState } from '../../Stores';
import { useNavMenu } from '../AppHeader/useNavMenu';
import { useRegistrantHandler } from './useRegistrantHandler';


interface PrivateRouteProps extends RouteProps {
    initRegistrant?: boolean
    allowUnregisteredUser?: boolean
}


const PrivateRoute: React.FC<PrivateRouteProps> = (props) => {
    const { initRegistrant, allowUnregisteredUser = true, ...routeProps } = props;
    const [loading, setLoading] = useState(true);
    const { isAuthenticated, AuthComponent } = useRegistrantHandler({ initRegistrant: !!initRegistrant })
    const { registrant } = useStoreState(state => state.Registrant);
    const { BASE_URL } = useNavMenu()




    useEffect(() => {
        if (registrant) {
            setLoading(false);
        }
    }, [registrant])
    const getComponent = () => {
        if (registrant && (registrant.status === 'approved' || registrant.status === 'checkedIn')) return <Route {...routeProps} />
        else if (!!registrant?.status) return <Redirect to={BASE_URL} />
        else return <Redirect to={`${BASE_URL}/register`} />
    }




    return !isAuthenticated ? AuthComponent : (
        allowUnregisteredUser ? <Route {...routeProps} /> : (
            loading ? <LinearProgress /> : (
                getComponent()
            )
        )
    )
}



export default PrivateRoute