import { TEvent } from "../../Models/Events/@types";
import { TRegistrant } from "../../Models/Registrant/@types";
import { TUser } from "../../Models/User/@types";


const Permissions = {
    registration(event?: TEvent, registrant?: TRegistrant) {
        if (!event) return false;
        if (!registrant) return false;
        if (!event.registrationOpen) return false;
        if (registrant.status === 'approved') return false;
        if (registrant.status === 'rejected') return false;
        if (registrant.status === 'registered') return false;
        if (registrant.status === 'checkedIn') return false;
        return true
    },
    isEmailVerified(event?: TEvent, user?: TUser) {
        if (!event) return false;
        if (!event._settings.emailVerificationRequired)
            return true;
        return user && user.emailVerified;
    },
    isDomainRestricted(event?: TEvent, user?: TUser) {
        if (!event || !user) return true;
        let domainEmails = event.restrictedEmailDomains || [];
        event.restrictedStrategicPartners?.forEach(i => { domainEmails = [...domainEmails, ...(i.emailDomains || [])] })
        switch (event.domainRestrictionType) {
            case 'open': return false;
            case 'allow': return !isDomainInList(domainEmails || [], user.email);
            case 'deny': return isDomainInList(domainEmails || [], user.email);
            default: return true;
        }
    },
    sessionRequest(registrant?: TRegistrant) {
        if (!registrant) return false;
        if (registrant.status === 'approved') return true;
        if (registrant.status === 'checkedIn') return true;
        if (registrant.status === 'registered') return true;
        return false;
    },
    sessionInterest(registrant?: TRegistrant) {
        if (!registrant) return false;
        if (registrant.status === 'approved') return true;
        if (registrant.status === 'checkedIn') return true;
        return false;
    },
    sessionJoin(registrant?: TRegistrant) {
        if (!registrant) return false;
        if (registrant.status === 'checkedIn') return true;
        return false;
    },
    eventFeedback(registrant?: TRegistrant) {
        if (!registrant) return false;
        if (registrant.status === 'checkedIn' && !registrant.reviewedEvent) return true;
        return false;
    },
    oneOnOneMeeting(event?: TEvent, registrant?: TRegistrant) {
        if (!event) return false;
        if (!registrant || !registrant?.status) return;
        if (event._settings.meeting) return true
        return false;
    }
}


/**
 * Checks if a email is in given list of domains
 * @param list list of domains to check
 * @param email email to check from the list
 */
export const isDomainInList = (list: string[], email: string) => list.reduce((prevValue, curEle) => {
    const regex = new RegExp(curEle + '$', 'ig');
    return prevValue || regex.test(email)
}, false)


export type TUserAction = keyof typeof Permissions;
export default Permissions;