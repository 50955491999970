import React, { FC, useCallback, useEffect } from "react";
import { useHistory } from "react-router";
import { useSocketListener } from "../../Hooks/useSocketListener";
import { TAgencyAsset } from "../../Models/AgencyAssets/@types";
import { useStoreActions, useStoreState } from "../../Stores";

export interface AgencyAssetProps {}

const AgencyAsset: FC<AgencyAssetProps> = (props) => {
    const { asset, guestInfo } = useStoreState(({ AgencyAsset: { asset }, User: { guestInfo } }) => ({ asset, guestInfo }));
    const { openVideoDialog, openPdfDialog } = useStoreActions(({ Dialogs: { openVideoDialog, openPdfDialog } }) => ({
        openVideoDialog,
        openPdfDialog,
    }));
    const { push: historyPush } = useHistory();
    const { TrackCustomEvent } = useSocketListener();
    const onClose = useCallback(() => {
        TrackCustomEvent.emit("EXIT_AGENCY_ASSET", { assetPreviewId: guestInfo?.id, email: guestInfo?.userData?.email });
        historyPush("/resources");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [asset?.id, guestInfo?.email]);
    const showAssetContent = (asset: TAgencyAsset) => {
        if (!asset._file) return;
        TrackCustomEvent.emit("JOIN_AGENCY_ASSET", { assetPreviewId: guestInfo?.id, email: guestInfo?.userData?.email });
        if (asset.mimeType === "video") openVideoDialog({ videos: [asset._file], afterClose: onClose });
        else openPdfDialog({ pdf: asset._file, afterClose: onClose });
    };
    useEffect(() => {
        if (asset?.id) {
            showAssetContent(asset);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [asset?.id]);
    return <div />;
};

export default AgencyAsset;
