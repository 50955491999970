import { Avatar, Box } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment-timezone'
import React, { FC } from 'react';
import Typo from '../../Components/Typo';
import { TSession } from '../../Models/Agenda/@types';
import { TTimeZone } from '../../Models/Events/@types';
import utilities from '../../Resources/Utils';

export interface CurrentSessionCardProps {
    session?: TSession
    timeZone?: TTimeZone
    focusAreas?: string[]
}

const CurrentSessionCard: FC<CurrentSessionCardProps> = (props) => {
    const classes = useStyles();
    const { session = {} as TSession, timeZone, focusAreas = [] } = props;

    return (
        <Box className={classes.root}>
            <Box display='flex' flexDirection='column' width='100%'>
                <Typo variant='body1' weight='regular'>
                    {`${moment(session.startTime).format('DD/MM/YYYY')} ${moment(session.startTime).format('HH:mm')} - ${moment(session.endTime).format('HH:mm')} ${utilities.getAbbreviation(timeZone)} (${timeZone?.offset || 'UTC 00:00'})`}
                </Typo>
                <Box>
                    <Typo weight='medium' variant='h6' style={{ lineHeight: '25px' }}>{session.title}</Typo>
                </Box>
                <Box>
                    <Typo weight='medium' variant='subtitle2'>{focusAreas.join(',')}</Typo>
                </Box>
                <Box display='flex' flexDirection='column'>
                    {
                        (session.speakers || []).map((item, index) => {
                            return <Box mt='10px' display='flex' key={index} className={index > 0 ? classes.speakerItem : ''}>
                                <Avatar
                                    src={item._profileImage?.url || ''}
                                    className={classes.logo}
                                />
                                <Box display='flex' flexDirection='column' ml='10px' mt='5px'>
                                    <Typo weight='medium' variant='body2'>{item.firstName + " " + item.lastName}</Typo>
                                    <Typo weight='regular' variant='caption'>{item.designation}</Typo>
                                </Box>
                            </Box>
                        })

                    }
                </Box>

            </Box>
        </Box>
    )
}

const useStyles = makeStyles<Theme>((theme) => {
    const { palette: { grey } } = theme
    return (createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start'
        },
        live: {
            display: 'flex',
            alignItems: 'center',
            background: '#38B468',
            color: '#ffff',
            padding: '0 5px',
            marginRight: '5px'
        },
        logo: {
            width: 50,
            height: 50,
        },
        speakerItem: {
            backgroundImage: `linear-gradient(to right, ${grey[600]} 33%, rgba(255,255,255,0) 0%)`,
            backgroundPosition: 'top',
            backgroundSize: '5px 1px',
            backgroundRepeat: 'repeat-x',
            paddingTop: 15
        }
    }))
})

export default CurrentSessionCard