import { Box, Button, Link, Paper, Theme } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { BookmarksOutlined } from "@material-ui/icons";
import clsx from "clsx";
import moment from "moment-timezone";
import React, { useCallback, useMemo } from "react";
import breakLottie from "../../Assets/Lottie/Break.json";
import { AddToCalendar, ContrastText, LiveBadge, RoundedButton } from "../../Components";
import BadgeTag from "../../Components/Badge/BadgeTag";
import DownloadButton from "../../Components/Buttons/DownloadButton";
import LottieImage from "../../Components/LottieImage";
import Poppers from "../../Components/Poppers";
import ReadMore from "../../Components/ReadMore";
import Typo from "../../Components/Typo";
import { useJoinSession } from "../../Hooks/useJoinSession";
import { useMediaQuery } from "../../Hooks/useMediaQuery";
import { useTranslation } from "../../Hooks/useTranslation";
import { TSession } from "../../Models/Agenda/@types";
import { TSpeaker } from "../../Models/EventPerson/@types";
import utilities from "../../Resources/Utils";
import { useFullScreenSession } from "../../Screens/SessionDetail/useFullScreenSession";
import { useStoreState } from "../../Stores/index";
import { THEME_PALETTE } from "../../Theme/themeValues";
import { useNavMenu } from "../AppHeader/useNavMenu";
import PlayVideo from "../PlayVideo";
import { useFlowPopover } from "../Popover/useFlowPopover";
import SpeakerChiplist from "../Speaker/SpeakerChiplist";
import SessionTimerDisplay from "./SessionTimerDisplay";
export interface SessionCardProps {
    session: TSession;
    eventAgendaUrl?: string;
    isActive?: boolean;
    onSpeakerClick?: (speaker: TSpeaker) => void;
    disableActions?: boolean;
    isRegistered?: boolean;
    handleSessionInterested?: (id: string) => void;
    multiple?: boolean;
    withDrawer?: boolean;
    withTimer?: boolean;
}

const SessionCard: React.FC<SessionCardProps> = (props) => {
    const { session, eventAgendaUrl, multiple = false, withDrawer = false } = props;
    const { isActive = false, disableActions = false } = props;
    const { registrant } = useStoreState(({ Registrant: { registrant } }) => ({ registrant }));
    const { eventDetails } = useStoreState(({ Events: { eventDetails } }) => ({ eventDetails }));
    const classes = useStyles(withDrawer)();
    const { isDeviceSm } = useMediaQuery();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const pastSession = useMemo(() => session.status === "closed", [session.status]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const interestedSessionIds = useMemo(() => registrant?.interestedSessionIds || [], [registrant?.interestedSessionIds?.length, registrant?.id]);
    // eslint-disable-next-line react-hooks/exhaustive-deps

    const bookmarked = !!interestedSessionIds.find((i) => i === session.id);
    const FOCUS_AREAS = useMemo(
        () =>
            (eventDetails?.focusAreas || [])
                .filter((fa) => session.focusAreaIds.indexOf(fa.id) > -1)
                .map((fa) => fa.name)
                .join(", "),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [(eventDetails?.focusAreas || []).length]
    );
    const isPartnerEnabled = useMemo(
        () => eventDetails?._settings.partners || eventDetails?._settings.sponsers,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [eventDetails?._settings.partners, eventDetails?._settings.sponsers]
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const partnerNames = useMemo(
        () => eventDetails?._partners.filter((i) => (session.partnerIds || [])?.includes(i.id)).map((i) => ({ name: i.name, value: i.slug || i.id, isSponsor: i.isSponsor })),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [session.partnerIds?.length, eventDetails?._partners?.length]
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const isBreak = useMemo(() => session.type === "break", [session.type]);

    const { getTranslation } = useTranslation();
    const { Component, withPopover } = useFlowPopover({
        id: "popper",
        registrant,
    });
    const { eventSlug, origin } = useNavMenu();
    const { handleJoin } = useJoinSession();
    const { popperNodes, onButtonClick, anchorEl, popperName, onPopperClose } = useFullScreenSession(session, session.id);

    const handleSessionJoin = (item: TSession) => (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        withPopover(e.currentTarget, "join", () => {
            handleJoin(item);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    };

    const handleSessionInterested = useCallback(
        (id: string) => (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            withPopover(e.currentTarget, "sessionInterest", () => {
                if (props.handleSessionInterested) props.handleSessionInterested(id);
            });
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props.handleSessionInterested]
    );

    const SessionInterestedComponent = useMemo(
        () =>
            isBreak ? (
                <div className={classes.breakIcon}>
                    <LottieImage lotteJSON={breakLottie} loop={true} dimension={{ height: "80px", width: "80px" }} style={{ transform: "translate(-18%,-18%)" }} />
                </div>
            ) : null,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isBreak, interestedSessionIds.length, session.id, classes, handleSessionInterested]
    );

    const onFeedbackClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, name: "feedback" | "request" | "questions") => {
        withPopover(e.currentTarget, name === "feedback" ? "join" : "sessionInterest", () => onButtonClick(e, name));
    };

    const FeedBackComponent = useMemo(
        () =>
            disableActions || isBreak ? null : (
                <>
                    {pastSession && (
                        <Button color="primary" variant="text" onClick={(e) => onFeedbackClick(e, "feedback")}>
                            {getTranslation("sessionFeedbackForm.feedBackButton")}
                        </Button>
                    )}
                    <Button color="primary" variant="text" onClick={(e) => onFeedbackClick(e, "request")}>
                        {getTranslation("agendaCard.request")}
                    </Button>
                    <Button color="primary" variant="text" onClick={(e) => onFeedbackClick(e, "questions")}>
                        {"Ask questions"}
                    </Button>
                </>
                // eslint-disable-next-line react-hooks/exhaustive-deps
            ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [disableActions, session.type, registrant, onFeedbackClick]
    );
    const DownloadComponent = <DownloadButton session={session} />;

    const ActionComponent = useMemo(
        () =>
            isBreak ? null : isActive ? (
                <>
                    {DownloadComponent}
                    {session.type === "noStream" ? null : (
                        <RoundedButton color="primary" variant="contained" onClick={handleSessionJoin(session)}>
                            {getTranslation("agendaCard.joinSessionButton")}
                        </RoundedButton>
                    )}
                </>
            ) : pastSession ? (
                <>
                    {DownloadComponent}
                    <PlayVideo session={session} onClick={handleSessionJoin(session)} />
                </>
            ) : (
                <>
                    {DownloadComponent}

                    <AddToCalendar open={session.startTime} close={session.endTime} description={`${session.description || ""}\n\n${eventAgendaUrl}`} title={session.title} />
                    {disableActions ? null : (
                        <RoundedButton color="primary" startIcon={<BookmarksOutlined fontSize="inherit" />} variant={bookmarked ? "contained" : "outlined"} onClick={handleSessionInterested(session.id)}>
                            Interested
                        </RoundedButton>
                    )}
                </>
            ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isBreak, pastSession, session.id, isActive, handleSessionJoin, disableActions, interestedSessionIds]
    );
    //.join(' , '))

    return (
        <Paper
            className={clsx(classes.root, {
                [classes.activeSession]: isActive,
                [classes.highlight]: !!session.isHighlighted,
                [classes.break]: isBreak,
                [classes.multiple]: multiple,
                [classes.withDrawer]: withDrawer,
            })}
        >
            {Component}

            {disableActions ? null : SessionInterestedComponent}
            <Box display={"flex"} alignItems="flex-start" width={"100%"} boxSizing="border-box" flexWrap={"wrap"}>
                <Box py={"2px"} px={"5px"} pl={disableActions || !SessionInterestedComponent ? "16px" : "32px"} className={classes.time}>
                    <ContrastText weight="bold" variant="subtitle2">
                        {`${moment(session.startTime).format("HH:mm")} - ${moment(session.endTime).format("HH:mm")} ${utilities.getAbbreviation(eventDetails?.timeZone)}`}
                    </ContrastText>
                </Box>

                {session.recommended ? <BadgeTag typoProps={{ weight: "bold", variant: "subtitle2" }} className={classes.recommendBadge} bgColor={THEME_PALETTE.others.recommendedGreen} text="Recommended" textColor="#fff" /> : null}
                {session.venue ? (
                    <Box py={"2px"} px={"5px"}>
                        <Typo weight="bold" variant="subtitle2">
                            {session.venue}
                        </Typo>
                    </Box>
                ) : null}
                {props.withTimer ? <SessionTimerDisplay session={session} /> : null}
                {isActive ? (
                    <div className={classes.badge}>
                        <LiveBadge type="rounded" />
                    </div>
                ) : null}
            </Box>

            <Box p={2} pt={1} flex={1} display="flex" flexDirection="column" alignItems="flex-start" className={classes.mainContent}>
                <Typo align="left" weight="bold" variant="h6" style={{ lineHeight: 1.3 }}>
                    {session.title}
                </Typo>
                {isBreak ? (
                    <ReadMore text={session.description} key={session.id} boxProps={{ my: 1 }} />
                ) : (
                    <>
                        {session.subtitle && (
                            <Typo align="left" variant="body1" weight="bold">
                                {session.subtitle}
                            </Typo>
                        )}
                        {FOCUS_AREAS && (
                            <Typo align="left" variant="body1" weight="regular" gutterBottom>
                                {FOCUS_AREAS}
                            </Typo>
                        )}
                        <ReadMore text={session.description} key={session.id} boxProps={{ my: 2 }} />
                        <Box className={classes.cardFooter} width="100%">
                            <Box flex={1} display="flex" flexWrap={"wrap"}>
                                <SpeakerChiplist onClick={disableActions ? undefined : props.onSpeakerClick} speakers={session.speakers || []} />
                            </Box>
                            <Box display="flex" width="100%" alignItems={isDeviceSm ? "flex-start" : "center"} justifyContent="space-between" flexDirection={isDeviceSm ? "column" : "row"}>
                                <Box display="flex" ml={!isDeviceSm && isActive ? 6 : 0}>
                                    {partnerNames?.length && isPartnerEnabled ? (
                                        <>
                                            <Typo variant="body2">Session partners:&nbsp;</Typo>
                                            {partnerNames.map((item, index) => (
                                                <Box display="flex" key={item.value}>
                                                    <Link variant="body2" color="secondary" underline="none" href={`${origin}/${eventSlug}/${item.isSponsor ? "sponsors" : "partners"}/${item.value}`} target="_blank">
                                                        {item.name}
                                                    </Link>
                                                    {index < partnerNames.length - 1 ? (
                                                        <Typo variant="body2" color="secondary">
                                                            &nbsp;,&nbsp;
                                                        </Typo>
                                                    ) : null}
                                                </Box>
                                            ))}
                                            {/* <Typo variant='body2' color='secondary'>{`${partnerNames}`}</Typo> */}
                                        </>
                                    ) : null}
                                </Box>
                                <Box display="flex" mt={isDeviceSm ? 1.5 : 0} flexDirection={isDeviceSm ? "column" : "row"} justifyContent="flex-end" alignSelf={isDeviceSm ? "flex-end" : undefined} alignItems={isDeviceSm ? "flex-end" : "center"}>
                                    {FeedBackComponent}
                                    {/* {!disableActions ? ActionComponent : null} */}
                                    {ActionComponent}
                                </Box>
                            </Box>
                        </Box>
                    </>
                )}
            </Box>
            <Poppers onClose={onPopperClose} anchorEl={anchorEl}>
                {popperNodes[popperName]}
            </Poppers>
        </Paper>
    );
};

const useStyles = (withDrawer: boolean) =>
    makeStyles((theme: Theme) => {
        return createStyles({
            root: {
                width: 800,
                position: "relative",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                [theme.breakpoints.down("sm")]: {
                    width: "100%",
                },
                borderTopLeftRadius: 4,
            },
            time: {
                backgroundColor: "#4D4D4D",
                borderTopLeftRadius: 4,
            },
            recommendBadge: {
                padding: "2px 5px 2px 5px",
            },
            multiple: {
                width: 500,
                //margin: '0 20px',
                [theme.breakpoints.down("sm")]: {
                    width: "100%",
                    margin: "0",
                },
            },
            withDrawer: {
                width: "100%",
                [theme.breakpoints.down("sm")]: {
                    width: "80%",
                    marginLeft: 0,
                },
            },
            cardFooter: {
                display: "flex",
                marginTop: 10,
                flexDirection: "column",
                alignItems: "flex-start",
                [theme.breakpoints.down("sm")]: {
                    alignItems: "flex-start",
                },
            },
            activeSession: {
                borderLeft: `3px solid ${THEME_PALETTE.others.activeGreen}`,
            },
            highlight: {
                borderLeft: `3px solid ${theme.palette.secondary.main}`,
            },
            badge: {
                position: "absolute",
                left: 0,
                bottom: 20,
                [theme.breakpoints.down("sm")]: {
                    bottom: 8,
                },
            },
            mainContent: {
                width: "100%",
                boxSizing: "border-box",
                [theme.breakpoints.down("sm")]: {
                    paddingTop: 0,
                },
                // [theme.breakpoints.up('sm')]: {
                //     backgroundImage: `linear-gradient(${grey[600]} 33%, rgba(255,255,255,0) 0%)`,
                //     backgroundPosition: 'left',
                //     backgroundSize: '1px 5px',
                //     backgroundRepeat: 'repeat-y',
                // }
            },
            actionButton: {
                [theme.breakpoints.down("sm")]: {
                    marginTop: 8,
                    width: "100%",
                    justifyContent: "flex-end",
                    display: "flex",
                },
            },
            break: {
                width: withDrawer ? "100%" : 600,
                marginLeft: withDrawer ? 0 : "-200px",
                "& > div:nth-child(2)": {
                    // height: 85,
                },
                [theme.breakpoints.down("sm")]: {
                    width: "100%",
                    marginLeft: 0,
                },
            },
            interestedIcon: {
                position: "absolute",
                left: -17,
                top: -11,
            },
            breakIcon: {
                position: "absolute",
                left: -15,
                top: -15,
                width: 45,
                padding: 0,
                height: 45,
                color: "white",
                // border: '1px solid #F2F2F2',
                transition: "200ms ease-in",
                background: `#fff !important`,
                boxShadow: theme.shadows[1],
                "&:hover": {
                    background: `#fff !important`,
                },
                borderRadius: "50%",
            },
        });
    });

export default SessionCard;
