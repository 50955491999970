import { Checkbox, Divider, Paper, Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { useContext, useState } from 'react';
import SubmitButton from '../../Components/SubmitButton';
import Typo from '../../Components/Typo';
import { useGlobalStyle } from '../../Constants/globalStyle';
import useConfirmationDialog from '../../Hooks/useConfirmationDialog';
import { useTranslation } from '../../Hooks/useTranslation';
import { TRegistrant } from '../../Models/Registrant/@types';
import { ToastContext } from '../../Utils/AppToast';
import GDPR_CONF from './GDPR_CONF.json';

interface GdprProps {
    onSubmit: (formData: Record<string, boolean>) => void;
    loading?: boolean;
    registrant: Partial<TRegistrant>;
    rootClass?: string;
    containerClass?: string;
}

const FORM_KEYS = GDPR_CONF.filter((c) => c.type === 'checkbox').map((v) => v.value);
const defaultForm: Record<string, any> = {};
const errorMessage = '*Mandatory';
FORM_KEYS.forEach((k) => (k ? (defaultForm[k] = false) : null));

const Gdpr: React.FC<GdprProps> = (props) => {
    const { loading = false, onSubmit, registrant, rootClass, containerClass } = props;
    const [form, setForm] = useState<Record<string, boolean>>({ ...defaultForm });
    const globalClasses = useGlobalStyle();
    const withConfirmation = useConfirmationDialog();
    const classes = useStyles();
    const NAME = registrant.firstName + ' ' + registrant.lastName;
    const JOB = [registrant.designation, registrant.company].filter((j) => j).join(', ');
    const ADDRESS = [registrant.street, registrant.city, registrant.zipCode].filter((j) => j).join(', ');
    const EMAIL = registrant.email;
    const { showToast } = useContext(ToastContext);
    const { getTranslation } = useTranslation();
    const CONFIRMATION_MESSAGE = getTranslation('confirmationDialog.noGdprSelectedBody');
    const handleCheck = (value: string) => (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        if (value === CodeWarriorGDPRKeyName) {
            if (!checked) setError(errorMessage);
            else setError('');
        }
        setForm({ ...form, [value]: checked });
    };
    const [error, setError] = useState<string>('');
    const shouldShowCodeWarriorElem = !!CodeWarriorEventIds.find((i) => i === registrant.eventId);
    const handleSubmit = () => {
        if (shouldShowCodeWarriorElem && !form[CodeWarriorGDPRKeyName]) {
            setError(errorMessage);
            showToast('Some mandatory fields are missing', { autoHideDuration: 15000, variant: 'error' });
            return;
        }
        if (shouldShowConfirmation(form)) {
            withConfirmation(() => submit(form), {
                message: getTranslation('confirmationDialog.noGdprSelectedTitle'),
                body: CONFIRMATION_MESSAGE,
                agreeText: getTranslation('meetingRequestForSessionForm.submitButtonLabel'),
                cancelText: getTranslation('networkingUserCard.editButton'),
            });
        } else {
            submit(form);
        }
    };
    const submit = (form: Record<string, boolean>) => {
        console.log(form);
        onSubmit(form);
        setForm({});
    };

    return (
        <div className={clsx(classes.root, globalClasses.pageBottomPadding, rootClass)}>
            <Paper className={clsx(classes.container, containerClass)}>
                <Typo weight="medium">
                    Welcome! <br />
                    {NAME} <br />
                    {JOB} <br />
                    {ADDRESS} <br />
                    {EMAIL} <br />
                </Typo>
                <br />
                {GDPR_CONF.map((conf, i) => { 
                if (conf.type === 'html')
                  return (
                    <Typo dangerouslySetInnerHTML={{ __html: conf.value || ''}} variant="body2" key={i} weight={conf.bold ? 'medium' : 'regular'}/>
                    
                   
                  );
                    if (conf.type === 'text')
                        return (
                            <Typo variant="body2" key={i} weight={conf.bold ? 'medium' : 'regular'}>
                                {conf.value}
                            </Typo>
                        );
                    else if (conf.type === 'break') return <br key={i} />;
                    else if (conf.type === 'checkbox') {
                        if (conf.value === CodeWarriorGDPRKeyName && !shouldShowCodeWarriorElem) return <div key={i} />;
                        return (
                            <div key={i} className={classes.row}>
                                <Checkbox onChange={handleCheck(conf.value || '')} />
                                {conf.value === CodeWarriorGDPRKeyName ? (
                                    <div>
                                        <Typo variant="body2" className={classes.specialElemDiv} dangerouslySetInnerHTML={{ __html: SECURE_CODE_WARRIOR_GDPR }} />
                                        {error.length ? (
                                            <Typo style={{ textTransform: 'none' }} variant="body2" color="error">
                                                {error}
                                            </Typo>
                                        ) : null}
                                    </div>
                                ) : (
                                    <Typo variant="body2">{conf.label}</Typo>
                                )}
                            </div>
                        );
                    } else if (conf.type === 'divider') return <Divider key={i} />;
                    return <div />;
                })}
                {/*
                
                <Typo gutterBottom>
                    <b>Anhang</b><br />
                    Attachmate Group Germany GmbH, Fraunhoferstr. 7, D-85737 Ismaning;
                    Borland GmbH, Fraunhoferstr. 7, D-85737 Ismaning; Entco Deutschland GmbH,
                    Herrenberger Str. 140, D-71034 Böblingen; Gwava EMEA GmbH, von-Braun-Str. 38 a,
                    D-48683 Ahaus; Micro Focus GmbH, Fraunhoferstr. 7, D-85737 Ismaning ;
                    Serena Software GmbH, Nördlicher Zubringer 9-11, D-40470 Düsseldorf.
                </Typo>
                <br />  */}
                <div className={classes.row} style={{ justifyContent: 'flex-end' }}>
                    <SubmitButton variant="contained" color="primary" onClick={handleSubmit} loading={loading}>
                        {getTranslation('meetingRequestForSessionForm.submitButtonLabel')}
                    </SubmitButton>
                </div>
            </Paper>
        </div>
    );
};

const shouldShowConfirmation = (form: Record<string, boolean>) => {
    return !Object.keys(form).reduce<boolean>((prev, cur) => prev || form[cur], false);
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            // background: theme.colors.background,
            paddingTop: 40,
        },
        container: {
            position: 'relative',
            // top: 120,
            background: 'white',
            width: 800,
            margin: '0 auto',
            boxSizing: 'border-box',
            padding: '60px 80px',
            borderRadius: 4,
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                padding: '30px 16px',
            },
        },
        button: {
            color: 'white',
            width: 235,
            // background: theme.colors.button1,
            '&:hover': {
                background: theme.palette.secondary.main,
            },
        },

        content: {
            maxWidth: 1080,
            margin: '0 auto',
            display: 'flex',
            flex: 1,
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            padding: '0 20px',
            boxSizing: 'border-box',
        },
        navContainer: {
            display: 'flex',
            alignItems: 'center',
            '& > p': {
                width: '100%',
                marginRight: 20,
            },
        },
        logo: {
            width: 170,
            height: 42,
        },
        row: {
            display: 'flex',
            // alignItems: 'center',
            '& span': {
                padding: 0,
                height: 'fit-content',
            },
        },
        checkinButton: {
            marginTop: 20,
            height: 60,
            width: 360,
        },
        specialElemDiv: {
            '& *': {
                fontSize: 14,
            },
        },
    })
);

export default Gdpr;

const CodeWarriorEventIds = ['60c06fac0fc35144146921a3', '608945b73f4b9b68f4aaa446'];
export const CodeWarriorGDPRKeyName = 'canSecureCodeWarriorContact';
const SECURE_CODE_WARRIOR_GDPR = `<span >Micro Focus International plc. und Secure Code Warrior Ltd. sind unabhängige und eigenständige Organisatoren der Veranstaltung. Mit der Anmeldung zu dieser Veranstaltung stimmen Sie der Verarbeitung und derIhrer Kontaktdaten gemäß der <a href="https://go.microfocus.com/e/239772/about-legal--privacy/2rs2s28/514098151?h=UR87Q_02QjSTq6wc7tl7-MVd2qoyfVZpfWMs8bl_XfQ" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://go.microfocus.com/e/239772/about-legal--privacy/2rs2s28/514098151?h%3DUR87Q_02QjSTq6wc7tl7-MVd2qoyfVZpfWMs8bl_XfQ&amp;source=gmail&amp;ust=1623310820628000&amp;usg=AFQjCNH3JkudUrOs47CAHR5mo3po2LNEtw">Micro Focus Privacy Policy</a>und der
<a href="https://go.microfocus.com/e/239772/legal-privacy-policy/2rs2s2b/514098151?h=UR87Q_02QjSTq6wc7tl7-MVd2qoyfVZpfWMs8bl_XfQ" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://go.microfocus.com/e/239772/legal-privacy-policy/2rs2s2b/514098151?h%3DUR87Q_02QjSTq6wc7tl7-MVd2qoyfVZpfWMs8bl_XfQ&amp;source=gmail&amp;ust=1623310820628000&amp;usg=AFQjCNHgzxd6b4Z9_MT6ZogDyHxmI83yqw">Secure Code Warrior Privacy Policy</a> zu, sowie der Kontaktaufnahme durch beide Unternehmen per E-Mail und Telefon im Zusammenhang dieser Veranstaltung. Sie können Ihre Einwilligung gegenüber jedem der Unternehmen jederzeit widerrufen, indem Sie Ihre Einstellungen unter
<a href="https://go.microfocus.com/e/239772/en-us-subscription/2rs2s2d/514098151?h=UR87Q_02QjSTq6wc7tl7-MVd2qoyfVZpfWMs8bl_XfQ" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://go.microfocus.com/e/239772/en-us-subscription/2rs2s2d/514098151?h%3DUR87Q_02QjSTq6wc7tl7-MVd2qoyfVZpfWMs8bl_XfQ&amp;source=gmail&amp;ust=1623310820628000&amp;usg=AFQjCNE_X7IsbsISLTb84ByvqYdlTXV1CQ">Micro Focus Abonnements</a></b> und <a href="https://go.microfocus.com/e/239772/-client-subscription-agreement/2rs2s2g/514098151?h=UR87Q_02QjSTq6wc7tl7-MVd2qoyfVZpfWMs8bl_XfQ" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://go.microfocus.com/e/239772/-client-subscription-agreement/2rs2s2g/514098151?h%3DUR87Q_02QjSTq6wc7tl7-MVd2qoyfVZpfWMs8bl_XfQ&amp;source=gmail&amp;ust=1623310820628000&amp;usg=AFQjCNFRcs8yaIK4KwkBby94eGLKydNuxw">Secure Code Warrior Abonnements</a></b> ändern.<u></u><u></u></span>`;
