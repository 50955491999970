import { Action, action, Thunk, thunk, thunkOn, ThunkOn } from "easy-peasy";
import { Injections, TRootStore } from "..";
import { TFeedBack, TSessionQuestions, TSessionRequest } from "../../Models/Agenda/@types";
import { TNetwork, TRecommendNetworkResponse } from "../../Models/Network";
import { TRegistrant } from "../../Models/Registrant/@types";


export interface TCredit {
    points: number
    rank: number
}
export interface TRegistrantState {
    registrant: TRegistrant | undefined;
    credit: TCredit
    formData: Partial<TRegistrant>
    registrantList: TRegistrant[]
    sentConnections: Array<TNetwork>
    receivedConnections: Array<TNetwork>
    feedback: TFeedBack | undefined,
    sessionRequest: TSessionRequest | undefined
    sessionQuestions: TSessionQuestions | undefined
    setRegistrantFormData: Action<TRegistrantState, Partial<TRegistrant>>
    setCredit: Action<TRegistrantState, Partial<TCredit>>
    setRegistrant: Action<TRegistrantState, TRegistrant | undefined>;
    setSentConnections: Action<TRegistrantState, TNetwork[]>
    setReceivedConnections: Action<TRegistrantState, TNetwork[]>
    registerSingleRegistrant: Thunk<TRegistrantState, { eventId: string, utm?: Record<string, any>, registrant: Partial<TRegistrant>, }, Injections>
    setRegistrantList: Action<TRegistrantState, TRegistrant[]>;
    fetchNetworkRegistrantList: Thunk<TRegistrantState, string, Injections>;
    updateRegistrant: Thunk<TRegistrantState, { eventId: string, registrant: Partial<TRegistrant> }, Injections>;
    cancelRegistration: Thunk<TRegistrantState, string, Injections>;
    setFeedback: Action<TRegistrantState, TFeedBack | undefined>;
    setSessionRequest: Action<TRegistrantState, TSessionRequest | undefined>;
    setSessionQuestions: Action<TRegistrantState, TSessionQuestions | undefined>;
    submitEventFeedback: Thunk<TRegistrantState, { eventId: string, feedback: any }, Injections>
    onUserLogOut: ThunkOn<TRegistrantState, Injections, TRootStore>
}


const State: TRegistrantState = {
    sessionRequest: undefined,
    registrant: undefined,
    sessionQuestions: undefined,
    formData: {},
    credit: {
        points: 0,
        rank: 0
    },
    sentConnections: [],
    receivedConnections: [],
    registrantList: [],
    feedback: undefined,
    setCredit: action((state, payload) => {
        state.credit = { ...state.credit, ...payload };
    }),
    setFeedback: action((state, payload) => {
        state.feedback = payload;
    }),
    setSessionRequest: action((state, payload) => {
        state.sessionRequest = payload;
    }),
    setSessionQuestions: action((state, payload) => {
        state.sessionQuestions = payload;
    }),
    setRegistrant: action((state, payload) => {
        state.registrant = payload;
    }),
    setSentConnections: action((state, payload) => {
        state.sentConnections = payload;
    }),
    setReceivedConnections: action((state, payload) => {
        state.receivedConnections = payload;
    }),
    setRegistrantFormData: action((state, payload) => {
        state.formData = payload;
    }),
    registerSingleRegistrant: thunk(async (actions, payload, { injections }) => {
        try {

            const res = await injections.RegistrantModel.registerUser(payload)
            // const res: Partial<TRegistrant> = { agencyId: 'asd', city: 'asdas' }
            actions.setRegistrant(res);
            return res
        } catch (err) {
            actions.setRegistrant(undefined);
            throw err;
        }
    }),
    setRegistrantList: action((state, payload) => {
        state.registrantList = [...payload];
    }),

    cancelRegistration: thunk(async (actions, payload, { injections, getState }) => {
        await injections.RegistrantModel.cancelRegistration(payload);
        const state = getState();
        if (!state.registrant) return;
        actions.setRegistrant({ ...state.registrant, status: 'canceled' })
    }),

    updateRegistrant: thunk(async (actions, payload, { injections }) => {
        const registrant = await injections.RegistrantModel.updateRegistrant(payload.eventId, payload.registrant);
        actions.setRegistrant(registrant)
        return registrant;
    }),

    fetchNetworkRegistrantList: thunk(async (actions, payload, { injections }) => {
        try {
            let res: TRecommendNetworkResponse = await injections.NetworkModel.getNetworkingUser(payload);
            actions.setRegistrantList([...res.hits]);
            actions.setReceivedConnections([...res.receivedConnections])
            actions.setSentConnections([...res.sentConnections])
        } catch (err) {
            actions.setRegistrantList([]);
            actions.setReceivedConnections([]);
            actions.setSentConnections([]);
            throw err;
        }
    }),

    submitEventFeedback: thunk(async (actions, payload, { injections, getState }) => {
        try {
            await injections.EventModel.sendFeedback(payload.eventId, payload.feedback);
            const state = getState();
            actions.setRegistrant({ ...state.registrant, reviewedEvent: true } as TRegistrant)
        } catch (error) {
            throw error;
        }

    }),
    onUserLogOut: thunkOn(
        (_actions, storeActions) => storeActions.User.logout,
        async (_state, _target, { getState }) => {
            getState().feedback = undefined;
            getState().sessionQuestions = undefined;
            getState().sessionRequest = undefined;
        }

    )
}
export default State