import { Box, Button, CircularProgress, Dialog, Icon, IconButton, Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { useEffect, useMemo, useState } from 'react';
import { animated, useSpring } from 'react-spring';
import { MainDialogProps } from '../../Components';
import ErrorText from '../../Components/ErrorText';
import Typo from '../../Components/Typo';
import { useGlobalStyle } from '../../Constants/globalStyle';
import EmailVerificationForm, { EmailVerificationFormData } from '../../Forms/EmailVerificationForm';
import GuestLoginForm from '../../Forms/GuestLoginForm';
import LoginForm from '../../Forms/LoginForm/';
import SignupForm from '../../Forms/SignupForm';
import useAsyncTask from '../../Hooks/useAsyncTask';
import { useMediaQuery } from '../../Hooks/useMediaQuery';
import { useTranslation } from '../../Hooks/useTranslation';

export type AuthType = 'login' | 'signup' | 'check' | 'guest';

export interface AuthDialogProps extends MainDialogProps {
    authType: AuthType;
    onSubmit: Function;
    handleCheck: (value: string) => Promise<boolean>;
    onTypeChange?: (type: AuthType) => void;
    onForgotPassword?: (email: string) => void;
    errorMessage?: string;
    loading?: boolean;
    guestEmail?: string;
}

const AuthDialog: React.FC<AuthDialogProps> = (props) => {
    const { authType, loading = false, handleCheck: _handleCheck, onForgotPassword, onSubmit, errorMessage, guestEmail, onTypeChange, ...dialogProps } = props;
    // const [type, setType] = useState<AuthType>(authType)
    const { isDeviceSm } = useMediaQuery();
    const [val, setVal] = useState('');
    const globalClasses = useGlobalStyle();
    const { getTranslation } = useTranslation();
    useEffect(() => {
        if (guestEmail) {
            setVal(guestEmail);
        }
    }, [guestEmail]);
    const classes = useStyles();
    const TITLE = useMemo(() => {
        if (authType === 'login') return getTranslation('loginForm.formTitle') + '\n' + getTranslation('loginForm.formSubtitle');
        if (authType === 'check') return getTranslation('emailInputForm.formTitle');
        if (authType === 'guest') return 'Enter your details';
        return getTranslation('signUpForm.formTitle');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authType]);

    const setAuthType = (type: AuthType) => {
        // setType(type)
        if (onTypeChange) onTypeChange(type);
    };

    const handleCheck = async (data: EmailVerificationFormData) => {
        // e.preventDefault();
        setVal(data.email);
        try {
            const isRegistered = await props.handleCheck(data.email || '');
            if (isRegistered) setAuthType('login');
            else {
                guestEmail ? setAuthType('guest') : setAuthType('signup');
            }
        } catch (error) {}
    };
    const checkHandler = useAsyncTask(handleCheck);

    // const handleClose = () => {
    //     setAuthType('check');
    //     if (dialogProps.onClose)
    //         dialogProps.onClose()
    // }

    const closeDialog = () => {
        restartFlow();
        if (dialogProps.onClose) dialogProps.onClose();
    };

    const restartFlow = () => {
        setAuthType('check');
    };

    const handleForgotPassword = () => {
        if (!val || !onForgotPassword) return;
        onForgotPassword(val);
    };

    const spring = useSpring({
        background: 'white',
        borderRadius: loading ? 100 : 0,
        transform: loading ? 'scale(0.6)' : 'scale(1)',
        // width: loading ? '500px' : '100%'
    });

    return (
        // <MainDialog {...dialogProps}>
        <Dialog {...dialogProps} PaperProps={{ className: classes.paper }} fullScreen={isDeviceSm} disableBackdropClick scroll="body">
            <animated.div style={spring} className={classes.container}>
                {loading && !isDeviceSm ? (
                    <CircularProgress />
                ) : (
                    <Box width={isDeviceSm ? '100%' : '500px'} height={isDeviceSm ? '100%' : undefined} mx="auto" position="relative">
                        <IconButton size="small" className={clsx(globalClasses.outlinedIconButton, classes.closeButton)} onClick={closeDialog}>
                            <Icon>close</Icon>
                        </IconButton>
                        <Box p={6} className={classes.content}>
                            <Typo color={authType === 'check' ? 'textPrimary' : 'secondary'} gutterBottom variant="h6" weight="medium">
                                {TITLE}
                            </Typo>
                            <ErrorText>{errorMessage}</ErrorText>
                            {authType === 'login' && val ? (
                                <>
                                    <LoginForm onBack={restartFlow} initialData={{ email: val }} onSubmit={onSubmit} />
                                    <Box display="flex" mt={2} justifyContent="space-between">
                                        <Box flex={1} />
                                        <Button type="button" onClick={handleForgotPassword}>
                                            {getTranslation('loginForm.resetPasswordButtonLabel')}
                                        </Button>
                                    </Box>
                                </>
                            ) : null}

                            {authType === 'check' ? (
                                // <form onSubmit={checkHandler.run}>
                                //     <TextField required fullWidth label="Email" value={val} onChange={e => setVal(e.target.value)} />
                                //     <Box mt={3}>
                                //         <SubmitButton loading={checkHandler.status === 'PROCESSING'} fullWidth type="submit" variant="contained" color="primary">NEXT</SubmitButton>
                                //     </Box>
                                // </form>
                                <>
                                    <EmailVerificationForm onSubmit={checkHandler.run} isSubmitting={checkHandler.status === 'PROCESSING'} initialData={{ email: val }} />
                                </>
                            ) : null}

                            {authType === 'signup' && val ? (
                                <>
                                    <SignupForm onBack={restartFlow} initialData={{ email: val }} onSubmit={onSubmit} />
                                    <Box display="flex" mt={2} justifyContent="space-between">
                                        <Box />
                                    </Box>
                                </>
                            ) : null}
                            {authType === 'guest' && val ? (
                                <>
                                    <GuestLoginForm onBack={restartFlow} initialData={{ email: val }} onSubmit={onSubmit} />
                                    <Box display="flex" mt={2} justifyContent="space-between">
                                        <Box />
                                    </Box>
                                </>
                            ) : null}
                        </Box>
                    </Box>
                )}
            </animated.div>
        </Dialog>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            background: 'transparent',
            boxShadow: 'none',
            [theme.breakpoints.down('sm')]: {
                height: '100%',
                // height: `calc(100vh - ${HEADER_HEIGHT_SMALL}px)`
            },
        },
        container: {
            [theme.breakpoints.down('sm')]: {
                height: '100vh',
            },
        },
        content: {
            [theme.breakpoints.down('sm')]: {
                top: '45%',
                position: 'relative',
                transform: 'translateY(-50%)',
            },
        },
        closeButton: {
            position: 'absolute',
            top: 16,
            right: 16,
        },
    })
);

export default AuthDialog;
