import EntityRegistrationsBase from "../../BaseModels/EntityRegistrations";
import { TAgencyAsset } from "../AgencyAssets/@types";
import { TZine } from "../Zine/@types";
import { TEntity } from "./@types";

class EntityRegistrationsModel extends EntityRegistrationsBase {
    static isZine(item: TEntity): item is TZine {
        return !!(item as TZine).uploadStatus || (item as TZine).doc_type === 'Zine'
    }
    static isAgencyAsset(item: TEntity): item is TAgencyAsset {
        return !!(item as TAgencyAsset).mimeType
    }
    static getEntityType = (entity: TEntity): 'Zine' | 'AgencyAsset' => {
        if (EntityRegistrationsModel.isAgencyAsset(entity))
            return 'AgencyAsset';
        return 'Zine'
    }

}
export default EntityRegistrationsModel