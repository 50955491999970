import { Link } from '@material-ui/core';
import React, { FC } from 'react';
import Typo from '../../Components/Typo';

export interface TermsProps { }
//https://www.microfocus.com/about/legal/
const Terms: FC<TermsProps> = (props) => {
    return (
        <Typo variant='body2'>
            I agree to the&nbsp;
            <Link variant='body2' underline='none' href={'https://www.microfocus.com/about/legal/'} color='primary' target='_blank'>
                Terms & Conditions
            </Link> and <Link variant='body2' underline='none' href={'https://www.microfocus.com/about/legal/'} color='primary' target='_blank'>
                Privacy Policy
            </Link>
        </Typo>
    )
}


export default Terms