import { action, Action, thunk, Thunk } from "easy-peasy";
import { Injections } from "..";
import { TFilter } from "../../Models";
import { TChatConfig } from "../../Models/Chat";
import { TChat, TChatType, TMessage } from "../../Models/Chat/@types";
import utilities from "../../Resources/Utils";

export interface TChatState {
    // chats: Array<TChat>;
    chatsMap: Array<{ type: TChatType, chats: Array<TChat> }>
    addUpdateSingleChat: Action<TChatState, { type: TChatType, chat: TChat, action: 'ADD' | 'PUT' | 'UPDATE' | "DELETE" }>;
    startChat: Thunk<TChatState, TChatConfig, Injections>;
    updateMessage: Action<TChatState, { type: TChatType, message: TMessage, sessionId?: string, isSelf?: boolean }>;
    appendMessage: Action<TChatState, { type: TChatType, message: TMessage, action?: 'ADD' | 'PUT' | 'UPDATE' | "DELETE", isCurrentUser?: boolean }>
    fetchMessages: Thunk<TChatState, { config: TChatConfig, chatId: string, filter?: TFilter }, Injections>
}
const State: TChatState = {
    chatsMap: [],
    addUpdateSingleChat: action((state, payload) => {
        let cIndex = state.chatsMap.findIndex(i => i.type === payload.type);
        if (cIndex > -1) {
            let chats = utilities.updateItemList([...state.chatsMap[cIndex].chats || []], payload.chat, payload.action);
            state.chatsMap[cIndex].chats = chats
        } else {
            state.chatsMap.push({
                type: payload.type,
                chats: [payload.chat]
            })
        }
    }),
    updateMessage: action((state, payload) => {
        let cIndex = state.chatsMap.findIndex(i => i.type === payload.type);
        if (cIndex > -1) {
            let chats = [...(state.chatsMap[cIndex].chats || [])];
            let chatToUpdate = chats.find(c => c.id === payload.message.chatId);
            if (chatToUpdate) {
                if (payload.isSelf) {
                    let updateMessage = payload.message;
                    let msgtoUpdate = chatToUpdate.messages.findIndex(i => {
                        return (!i.id && updateMessage.userId === i.userId && (updateMessage.text === i.text || updateMessage.attachment?.id === i.attachment?.id))
                    });
                    if (msgtoUpdate > -1)
                        chatToUpdate.messages[msgtoUpdate] = updateMessage
                }
                chats = utilities.updateItemList(chats, chatToUpdate, 'UPDATE');
                state.chatsMap[cIndex].chats = chats;
            }
        }
    }),
    startChat: thunk(async (actions, params, { injections }) => {
        try {
            const chat = await injections.ChatModel.startChat(params)
            if (chat) {
                const messages = await injections.ChatModel.getMessages({ id: chat.id, filter: { "include": [{ "relation": "user" }, { 'relation': 'parentMessage', scope: { include: 'user' } }], } })
                actions.addUpdateSingleChat({ chat: { ...chat, messages: messages.reverse() }, action: 'PUT', type: params.type })
            }
        } catch (er) {

        }

    }),
    appendMessage: action((state, payload) => {
        let cIndex = state.chatsMap.findIndex(i => i.type === payload.type);
        if (cIndex > -1) {
            let chat = (state.chatsMap[cIndex].chats || []).find(i => i.id === payload.message.chatId);
            if (chat) {
                if (payload.isCurrentUser === true) {
                    let userId = payload.message.userId;
                    let text = Boolean(payload.message.text);
                    let att = Boolean(payload.message.attachment);
                    let index = chat.messages.findIndex(i => i.userId === userId
                        && ((text && payload.message.text === i.text) || (att && payload.message.attachment?.id === i.attachment?.id)))
                    if (index > -1)
                        chat.messages.splice(index, 1, payload.message);
                    else
                        chat.messages.push(payload.message)
                } else {
                    if (payload.action === 'ADD')
                        chat.messages.push(payload.message)
                    else
                        chat.messages = utilities.updateItemList(chat.messages || [], payload.message, payload.action || 'ADD',);
                }
                chat.newMessageCount = (chat.newMessageCount || 0) + 1;
                let chats = utilities.updateItemList([...state.chatsMap[cIndex].chats || []], chat, 'PUT', 'id');
                state.chatsMap[cIndex].chats = chats

            }
        }
    }),
    fetchMessages: thunk(async (_actions, params, { getState, injections }) => {
        let state = getState();
        let cIndex = state.chatsMap.findIndex(i => i.type === params.config.type);
        if (cIndex > -1) {
            let chat = (state.chatsMap[cIndex].chats || []).find(i => i.id === params.chatId);
            if (!chat || chat.messages.length === chat.totalMessageCount + (chat.newMessageCount || 0))
                return;
            else if (chat) {
                let skip = chat.messages.length;
                let limit = 20
                const messages = await injections.ChatModel.getMessages({ id: params.chatId, filter: { include: [{ "relation": "user" }, { "relation": "parentMessage", scope: { include: 'user' } }], skip, limit } });
                chat.messages = [...messages].concat([...chat.messages]);
                let chats = utilities.updateItemList([...state.chatsMap[cIndex].chats || []], chat, 'PUT');
                state.chatsMap[cIndex].chats = chats
            }
        }
    })
};
export default State;