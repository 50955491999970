import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { getGradientFromTheme, getRevGradientFromTheme } from '../Features/Theme/themeUtils';

export const useGlobalStyle = makeStyles((theme: Theme) =>
  createStyles({
    outlinedIconButton: {
      border: `1px solid ${theme.palette.text.primary}`,
    },
    gradientBg: {
      background: getGradientFromTheme(theme),
    },
    revGradientBg: {
      background: getRevGradientFromTheme(theme),
    },
    gradientBorder: {},
    iconButtonSmall: {
      borderRadius: 10,
      padding: 4,
      background: theme.palette.grey['200'],
      color: theme.palette.common.white,
      fontSize: 12,
    },
    pageBottomPadding: {
      paddingBottom: 100,
    },
    description: {
      lineHeight: 1.5,
    },
  })
);
