export const INTERCOM_APP_ID = 'k2ka87jg';

export const intercomUtil = {
    shutIntercom: () => {
        var w = window;
        var ic = w.Intercom;
        if (typeof ic === 'function') ic('shutdown');
    },
    hideIntercom: () => {
        window.intercomSettings = {
            hide_default_launcher: true,
        };
        var w = window;
        var ic = w.Intercom;
        if (typeof ic === 'function') ic('update', w.intercomSettings);
    },
    updateIntercomPadding: (horizontal_padding = 20, vertical_padding = 20) => {
        console.log(horizontal_padding);
        window.intercomSettings = {
            horizontal_padding,
            vertical_padding,
        };
        var w = window;
        var ic = w.Intercom;
        if (typeof ic === 'function') {
            ic('reattach_activator');
            ic('update', w.intercomSettings);
        }
    },
    initIntercom: (user, event, APP_ID, baseUrl, theme) => {
        window.intercomSettings = {
            app_id: APP_ID,
            name: user ? `${user?.firstName} ${user?.lastName}` : `Guest user`,
            user_id: user?.id || 'guest-user',
            eventName: event.name || 'event-name',
            eventId: event.id || 'event-id',
            eventUrl: `${baseUrl}`,

            action_color: theme ? theme.palette.secondary.main : undefined,
            background_color: theme ? theme.palette.primary.main : undefined,
        };
        // : (window.intercomSettings = {
        //       app_id: APP_ID,
        //       action_color: theme ? theme.palette.secondary.main : undefined,
        //       background_color: theme ? theme.palette.primary.main : undefined,
        //       horizontal_padding: 520,

        //       // name: 'User',
        //       // email: null
        //   });
        var w = window;
        var ic = w.Intercom;
        if (typeof ic === 'function') {
            ic('reattach_activator');
            ic('update', w.intercomSettings);
        } else {
            var d = document;
            var i = function () {
                i.c(arguments);
            };
            i.q = [];
            i.c = function (args) {
                i.q.push(args);
            };
            w.Intercom = i;
            var l = function () {
                var s = d.createElement('script');
                s.type = 'text/javascript';
                s.async = true;
                s.src = 'https://widget.intercom.io/widget/' + APP_ID;
                var x = d.getElementsByTagName('script')[0];
                x.parentNode.insertBefore(s, x);
            };
            if (document.readyState === 'complete') {
                l();
            } else if (w.attachEvent) {
                w.attachEvent('onload', l);
            } else {
                w.addEventListener('load', l, false);
            }
        }
    },
};

window.intercomSettings = {
    app_id: INTERCOM_APP_ID,
};
