import { CircularProgress } from '@material-ui/core';
import { get } from 'lodash';
import Mustache from 'mustache';
import React, { FC, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { MainDialog, RoundedButton } from '../../Components';
import useAsyncTask from '../../Hooks/useAsyncTask';
import useToastMessage from '../../Hooks/useToastMessage';
import { useTranslation } from '../../Hooks/useTranslation';
import EventModel from '../../Models/Events';
import { TEvent } from '../../Models/Events/@types';
import RegistrantModel from '../../Models/Registrant';
import { TRegistrant } from '../../Models/Registrant/@types';
import { useStoreActions, useStoreState } from '../../Stores';
import { ToastContext } from '../../Utils/AppToast';
import Gdpr from '../Agreements/Gdpr';
import { useNavMenu } from '../AppHeader/useNavMenu';
import { useFlowPopover } from '../Popover/useFlowPopover';

export interface CheckInProps {}

const CheckIn: FC<CheckInProps> = (props) => {
    const { registrant, eventDetails = {} as TEvent } = useStoreState(({ Registrant: { registrant }, Events: { eventDetails } }) => ({
        registrant,
        eventDetails,
    }));
    const { handleCheckInClick, GdprDialog } = useCheckIn();
    const { getTranslation } = useTranslation();
    const { Component: PopperComponent, withPopover } = useFlowPopover({
        id: 'popper-checkin',
        registrant,
    });

    const task = useAsyncTask(handleCheckInClick);

    const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        withPopover(e.currentTarget, 'checkin', () => {
            task.run(eventDetails.id);
        });
    };

    return (
        <>
            {task.status === 'PROCESSING' ? (
                <CircularProgress />
            ) : (
                <RoundedButton borderColor="white" variant="contained" color="secondary" onClick={handleClick}>
                    {getTranslation('mainBanner.checkinLabel')}
                </RoundedButton>
            )}
            {PopperComponent}
            {GdprDialog}
        </>
    );
};

export const useCheckIn = () => {
    const withToast = useToastMessage();
    const history = useHistory();
    const [dialogOpen, setDialogOpen] = useState(false);
    const { eventDetails = {} as TEvent, eventSettings } = useStoreState(({ Events: { eventDetails, eventSettings } }) => ({
        eventDetails,
        eventSettings,
    }));
    const { BASE_URL } = useNavMenu();
    const { showToast } = useContext(ToastContext);
    const { registrant } = useStoreState(({ Registrant: { registrant } }) => ({ registrant }));
    const { setRegistrant } = useStoreActions(({ Registrant: { setRegistrant } }) => ({ setRegistrant }));
    const { getTranslation } = useTranslation();
    const doCheckIn = async (eventId: string, _registrant?: TRegistrant) => {
        await withToast(
            async () => {
                await EventModel.checkin(eventId);
                _registrant && setRegistrant({ ..._registrant, status: 'checkedIn' });
            },
            {
                successToastMessage: Mustache.render(getTranslation('feedbackMessages.checkedInSuccess') as string, {
                    firstName: registrant?.firstName,
                    lastName: registrant?.lastName,
                }),
            }
        );
    };

    const handleCheckInClick = async (eventId: string) => {
        if (eventSettings?.onsiteGDPR === true && !(registrant?.gdprSet === true)) {
            setDialogOpen(true);
        } else {
            await doCheckIn(eventId, registrant);
            history.push(`${BASE_URL}/agenda`);
        }
    };

    const submitHandler = useAsyncTask(async (gdpr: Record<string, any>) => {
        if (!eventDetails || !registrant) return;
        try {
            const res = await RegistrantModel.submitGDPR(registrant.id, gdpr);
            setRegistrant({ ...res });
            await doCheckIn(eventDetails.id, res);
        } catch (err) {
            let errorCode = get(err, 'response.data.error.statusCode') || 0;
            if (errorCode === 409) {
                try {
                    await doCheckIn(eventDetails.id, registrant);
                } catch (err) {
                    showToast(getTranslation('feedbackMessages.generalErrorMessage'), { variant: 'error' });
                }
            }
            return;
        }
        setDialogOpen(false);
        setTimeout(() => {
            showToast(
                Mustache.render(getTranslation('feedbackMessages.checkedInSuccess') as string, {
                    firstName: registrant?.firstName,
                    lastName: registrant?.lastName,
                }),
                { variant: 'success' }
            );
            history.push(`${BASE_URL}/agenda`);
        }, 500);
    });

    return {
        handleCheckInClick,
        GdprDialog: registrant ? (
            <MainDialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
                <Gdpr loading={submitHandler.status === 'PROCESSING'} onSubmit={submitHandler.run} registrant={registrant} />
            </MainDialog>
        ) : null,
    };
};

export default CheckIn;
