import { Box } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import React, { FC, useContext } from "react";
import FullScreenLoader from "../Components/FullScreenLoader";
import AppContext from "../Context/AppContext";
import usePathChangeListener from "../Hooks/usePathChangeListener";
import AgencyNavigation from "../Routes/AgencyNavigation";
import Page404 from "./Page404";

export interface AgencyLayoutProps {}

const AgencyLayout: FC<AgencyLayoutProps> = (props) => {
    const classes = useStyles({});
    const { initialized, invalidAgency } = useContext(AppContext);
    usePathChangeListener();
    return invalidAgency ? (
        <Page404 />
    ) : !initialized ? (
        <FullScreenLoader />
    ) : (
        <Box className={classes.root}>
            <AgencyNavigation />
        </Box>
    );
};

const useStyles = makeStyles<Theme, any>((theme) => ({
    root: {
        backgroundColor: theme.palette.background.default,
        minHeight: "100vh",
    },
}));

export default AgencyLayout;
