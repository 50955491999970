import { each, isString } from "lodash";
declare global {
    interface Window {
        _linkedin_partner_id: string;
        _linkedin_data_partner_ids: string[];
        analytics: any;
        lintrk: any;
    }
}

const createTrackImage = (url: string) => {
    const imageElement = document.createElement("img");
    imageElement.width = 1;
    imageElement.height = 1;
    imageElement.setAttribute("style", "display:none;");
    imageElement.src = url;
    return imageElement;
};
const noScriptImageTag = (url: string) => {
    const imageElement = createTrackImage(url);
    const noScript = document.createElement("noscript");
    noScript.appendChild(imageElement);
    return noScript;
};

export type EventTracking = "registerRequested" | "registerNext" | "registerSubmit";
type EventTrackingMap<T> = { [tracking in EventTracking]?: T };

type AnalyticsType = "facebook" | "linkedIn" | "segment" | "google";

export interface IAnalyticsConfig extends EventTrackingMap<(data: any, config: IAnalyticsCustomConfig) => void> {
    script: string | ((config: IAnalyticsCustomConfig) => string);
    beforeLoad?: (config: IAnalyticsCustomConfig) => void;
    onLoad?: (config: IAnalyticsCustomConfig) => void;
    registerRequested?: (data: any, config: IAnalyticsCustomConfig) => void;
    registerNext?: (data: any, config: IAnalyticsCustomConfig) => void;
    registerSubmit?: (data: any, config: IAnalyticsCustomConfig) => void;
}

export interface IAnalyticsCustomConfig extends Omit<IAnalyticsConfig, "script"> {
    type: AnalyticsType;
    appId: string;
}

interface IAnalyticsConfMap {
    [key: string]: IAnalyticsConfig;
}

interface IAnalyticsEventConfMap {
    [key: string]: IAnalyticsCustomConfig[];
}

export const ANALYTICS_CONFIG: IAnalyticsConfMap = {
    google: {
        beforeLoad: (config) => {
            document.body.appendChild(noScriptImageTag(`https://www.googletagmanager.com/gtag/js?id=${config.appId}`));
        },
        script: (config) => ` window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', '${config.appId}');`,
    },
    segment: {
        script: `
        !function(){
            var analytics=window.analytics=window.analytics||[];
            if(!analytics.initialize)
                if(analytics.invoked)
                    window.console&&console.error&&console.error("Segment snippet included twice.");
                else{
                    analytics.invoked=!0;
                    analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];
                    analytics.factory=function(e){
                        return function(){
                            var t=Array.prototype.slice.call(arguments);
                            t.unshift(e);analytics.push(t);
                            return analytics
                        }
                    };
                    for(var e=0;e<analytics.methods.length;e++){
                        var key=analytics.methods[e];
                        analytics[key]=analytics.factory(key)
                    }
                    analytics.load=function(key,e){
                        var t=document.createElement("script");
                        t.type="text/javascript";
                        t.async=!0;
                        t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";
                        var n=document.getElementsByTagName("script")[0];
                        n.parentNode.insertBefore(t,n);
                        analytics._loadOptions=e};
                        analytics.SNIPPET_VERSION="4.13.1";
                    }
            }();
        `,
        onLoad: (config) => {
            if (window.analytics) {
                window.analytics?.load(config.appId);
                window.analytics?.page();
            }
        },
    },
    facebook: {
        script: `!function (f, b, e, v, n, t, s) {
      if (f.fbq) return; n = f.fbq = function () {
        n.callMethod ?
          n.callMethod.apply(n, arguments) : n.queue.push(arguments)
      };
      if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
      n.queue = []; t = b.createElement(e); t.async = !0;
      t.src = v; s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s)
    }(window, document, 'script',
      'https://connect.facebook.net/en_US/fbevents.js');`,
        onLoad: (config: IAnalyticsCustomConfig) => {
            if (window.fbq) {
                window.fbq("init", config.appId);
                window.fbq("track", "PageView");
            }
            document.body.appendChild(noScriptImageTag(`https://www.facebook.com/tr?id=${config.appId}&ev=PageView&noscript=1`));
        },
    },

    linkedIn: {
        script: `(function () {
      var s = document.getElementsByTagName("script")[0];
      var b = document.createElement("script");
      b.type = "text/javascript"; b.async = true;
      b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
      s.parentNode.insertBefore(b, s);
    })();`,
        beforeLoad: (config: IAnalyticsCustomConfig) => {
            window._linkedin_partner_id = config.appId;
            window._linkedin_data_partner_ids = [];
            window._linkedin_data_partner_ids.push(window._linkedin_partner_id);
        },
        onLoad: (config: IAnalyticsCustomConfig) => {
            document.body.appendChild(noScriptImageTag(`https://px.ads.linkedin.com/collect/?pid=${config.appId}=&fmt=gif`));
        },
    },
};

export const ANALYTICS_EVENTS_CONFIG: IAnalyticsEventConfMap = {
    default: [
        {
            type: "segment",
            appId: "bNOON3Ull5FDgpry44Eb69BGBqqdY672",
            registerSubmit: (data, config) => {
                console.log("registration success", { data, config });
            },
        },
        {
            type: "facebook",
            appId: "3034890236527952",
            registerSubmit: () => {
                if (window.fbq) {
                    window.fbq("track", "SubmitApplication");
                }
            },
        },
        {
            type: "linkedIn",
            appId: "1418801",
            registerNext: (data: any, config: IAnalyticsCustomConfig) => {
                document.body.appendChild(createTrackImage("https://px.ads.linkedin.com/collect/?pid=1418801&conversionId=2919025&fmt=gif"));
            },
            registerSubmit: (data: any, config: IAnalyticsCustomConfig) => {
                document.body.appendChild(createTrackImage("https://px.ads.linkedin.com/collect/?pid=1418801&conversionId=2919033&fmt=gif"));
            },
        },
    ],
    "realize-2020": [
        {
            type: "linkedIn",
            appId: "2550209",
            registerRequested: (data: any, config: IAnalyticsCustomConfig) => {
                document.body.appendChild(createTrackImage("https://px.ads.linkedin.com/collect/?pid=2550209&conversionId=3046193&fmt=gif"));
            },
            registerNext: (data: any, config: IAnalyticsCustomConfig) => {
                document.body.appendChild(createTrackImage("https://px.ads.linkedin.com/collect/?pid=2550209&conversionId=2929249&fmt=gif"));
            },
            registerSubmit: (data: any, config: IAnalyticsCustomConfig) => {
                document.body.appendChild(createTrackImage("https://px.ads.linkedin.com/collect/?pid=2550209&conversionId=2929185&fmt=gif"));
            },
        },
    ],
    "virtual-alm-octane-day": [
        {
            type: "linkedIn",
            appId: "2626777",
            registerRequested: (data: any, config: IAnalyticsCustomConfig) => {
                document.body.appendChild(createTrackImage("https://px.ads.linkedin.com/collect/?pid=2626777&conversionId=3021289&fmt=gif"));
            },
            registerNext: (data: any, config: IAnalyticsCustomConfig) => {
                document.body.appendChild(createTrackImage("https://px.ads.linkedin.com/collect/?pid=2626777&conversionId=3021297&fmt=gif"));
            },
            registerSubmit: (data: any, config: IAnalyticsCustomConfig) => {
                document.body.appendChild(createTrackImage("https://px.ads.linkedin.com/collect/?pid=2626777&conversionId=3021305&fmt=gif"));
            },
        },
    ],
    "test-event-08": [
        {
            type: "linkedIn",
            appId: "abcd",
            registerRequested: (data: any, config: IAnalyticsCustomConfig) => {
                console.log("Analytics: Register requested");
                // document.body.appendChild(createTrackImage('https://px.ads.linkedin.com/collect/?pid=2626777&conversionId=3021289&fmt=gif'));
            },
            registerNext: (data: any, config: IAnalyticsCustomConfig) => {
                console.log("Analytics: Register Next button clicked");
                // document.body.appendChild(createTrackImage('https://px.ads.linkedin.com/collect/?pid=2626777&conversionId=3021297&fmt=gif'));
            },
            registerSubmit: (data: any, config: IAnalyticsCustomConfig) => {
                console.log("Analytics: Register submitted");
                // document.body.appendChild(createTrackImage('https://px.ads.linkedin.com/collect/?pid=2626777&conversionId=3021305&fmt=gif'));
            },
        },
    ],
};

let currentEventConfig: IAnalyticsCustomConfig[];

export const setCurrentAnalyticsConfig = (analyticsConfig: IAnalyticsCustomConfig[]) => {
    currentEventConfig = analyticsConfig;
};

export const TrackEvent = {
    log: (eventType: EventTracking, data?: any) => {
        console.log("Analytics: Logging Event", eventType, data, currentEventConfig);
        each(currentEventConfig, (config) => {
            const analyticsGeneralConfig = ANALYTICS_CONFIG[config.type];
            if (!analyticsGeneralConfig) return true;
            const eventMethod = config[eventType] || analyticsGeneralConfig[eventType];
            if (!eventMethod) return true;
            eventMethod(data || {}, config);
        });
    },
};

export const setAnalytics = (analyticsConfig: IAnalyticsCustomConfig[]) => {
    setCurrentAnalyticsConfig(analyticsConfig);

    each(analyticsConfig, (config) => {
        const analyticsGeneralConfig = ANALYTICS_CONFIG[config.type];
        if (!analyticsGeneralConfig) return true;
        const beforeLoad = config.beforeLoad || analyticsGeneralConfig.beforeLoad;
        const onLoad = config.onLoad || analyticsGeneralConfig.onLoad;

        if (beforeLoad) {
            beforeLoad(config);
        }
        let scriptElement = document.createElement("script");
        scriptElement.text = isString(analyticsGeneralConfig.script) ? analyticsGeneralConfig.script : analyticsGeneralConfig.script(config);
        scriptElement.id = `analytics-script-${config.type}`;
        document.body.appendChild(scriptElement);
        if (onLoad) onLoad(config);
    });
};
