import chalk from "chalk";
import moment from "moment-timezone";
import { useMemo } from "react";
import io from "socket.io-client";
import config from "../Config";
import { useNotification } from "../Features/Notification/useNotification";
import { TSession } from "../Models/Agenda/@types";
import { TGamificationScore } from "../Models/App";
import DomainUtils from "../Models/Domain";
import { TEventSetting } from "../Models/Events/@types";
import { TUser } from "../Models/User/@types";
import utilities from "../Resources/Utils";
import { useStoreActions, useStoreState } from "../Stores";
import { COOKIES_ACCESS_TOKEN } from "../Utils";
import { BUFFER_TIME, VIDEO_BUFFER_TIME } from "./useScheduler";
export const SOCKET_CLIENT_ID_KEY = "socketClientId";
export const SOCKET_EVENT_KEYS = {
    AUTHENTICATE: "authenticate",
    ON_CONNECTED: "on_connected",
    SESSION_STARTED: "Session.started",
    SESSION_ENDED: "Session.ended",
    SESSION_RECORDING_URL: "Session.recordingCompleted",
    TRACK_NEW_RANK: "Track.newRank",
    TRACK_SCORED: "Track.scored",
    JOIN: "join",
    JOIN_EVENT: "joinEvent",
    JOINED: "joined",
    SEND_MESSAGE_CHAT: "sendMessage",
    RECEIVED_MESSAGE_CHAT: "receiveMessage",
    HIDE_MESSAGE_CHAT: "hideMessage",
    MSG_SENT: "messageSent",
    USER_CONNECTED_CHAT: "userConnected",
    USER_DISCONNECTED_CHAT: "userDisconnected",
    REGISTRATION_CANCELLED: "registrationStatusChange",
    EXIT_SESSION: "exitSession",
    JOIN_SESSION: "joinSession",
    SESSION_UPDATED: "upsertSession",
    EVENT_SETTINGS_UPDATED: "updateSettings",
    SESSION_DELETED: "deleteSession",
    JOIN_ZINE: "joinZine",
    EXIT_ZINE: "exitZine",
    PATH_CHANGE: "pathChange",
    JOIN_AGENCY_ASSET: "joinAgencyAsset",
    EXIT_AGENCY_ASSET: "exitAgencyAsset",
    /**
     * SOCKET EVENTS TO BE HANDLED. IF HANDLED THEN REMOVE THEM FROM THE LIST. :)
     * eventDeleted
     * upsertAgenda
     * deleteAgenda
     * upsertEventPerson
     * deleteEventPerson
     * feedback
     * updateEvent
     * updateSettings
     * updateWebImage
     * updateEventSlug
     * reorderEventPersons
     * checkedIn
     */
};
export type TSocketMessage = Record<string, any>;
let curs: Array<TSession> = [];
let sess: Array<TSession> = [];
let rank: number;
let user: TUser | undefined = undefined;
export const socket = io.connect(config.get("BASE_URL"), { autoConnect: false });
export const useSocketListener = (config?: Record<string, any>) => {
    const { notify } = useNotification();
    const path = useMemo(() => {
        return window.location.href;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.location.href]);
    const {
        currentSessions,
        allSessions,
        agency,
        zineId,
        assetId,
        credit,
        eventDetails,
        eventSettings,
        user: appUser,
        strategicPartners,
        registrant,
    } = useStoreState(({ Agency: { agency }, Events: { currentSessions, allSessions, eventDetails, eventSettings }, Zine: { zineId }, AgencyAsset: { assetId }, Registrant: { credit, registrant }, User: { user }, Agency: { strategicPartners } }) => ({
        assetId,
        currentSessions,
        zineId,
        strategicPartners,
        allSessions,
        agency,
        credit,
        eventDetails,
        user,
        eventSettings,
        registrant,
    }));
    const { setCurrentSessions, removeSession, setAllSession, setEventSetting } = useStoreActions(({ Events: { removeSession, setCurrentSessions, setAllSession, setEventSetting } }) => ({
        removeSession,
        setCurrentSessions,
        setAllSession,
        setEventSetting,
    }));
    const { setCredit } = useStoreActions(({ Registrant: { setCredit } }) => ({ setCredit }));
    curs = currentSessions;
    sess = allSessions;
    rank = credit.rank;
    user = appUser;
    const { ON_CONNECTED, SESSION_STARTED, SESSION_RECORDING_URL, SESSION_ENDED, TRACK_NEW_RANK, TRACK_SCORED, JOIN, JOIN_EVENT, SESSION_UPDATED, EVENT_SETTINGS_UPDATED, EXIT_SESSION, SESSION_DELETED } = SOCKET_EVENT_KEYS;
    const disconnectSocket = () => {
        utilities.clearItemFromCookies(SOCKET_CLIENT_ID_KEY);
        if (socket.connected) {
            socket.removeAllListeners();
            socket.disconnect();
        }
    };
    const connectEventSocket = (eventId: string) => {
        socket.emit(
            JOIN_EVENT,
            {
                accessToken: localStorage.getItem(COOKIES_ACCESS_TOKEN),
                eventId: eventId,
            },
            (data: any) => {
                console.log(JOIN_EVENT, data);
            }
        );
    };
    const socketInit = () => {
        disconnectSocket();
        try {
            socket.removeAllListeners();
            console.log("socket connection requested", localStorage.getItem(COOKIES_ACCESS_TOKEN));
            socket.connect();
            socket.on("connect", () => {
                console.log("socket connection successful");
                socket.emit(
                    JOIN,
                    {
                        accessToken: localStorage.getItem(COOKIES_ACCESS_TOKEN),
                    },
                    (data: { clientId?: string }) => {
                        utilities.clearItemFromCookies(SOCKET_CLIENT_ID_KEY);
                        utilities.addItemInCookies(SOCKET_CLIENT_ID_KEY, data.clientId);
                        console.log(JOIN, data);
                    }
                );
            });

            socket.on(SESSION_DELETED, ({ agendaId, sessionId }: { agendaId: string; eventId: string; sessionId: string }) => {
                if (sessionId) removeSession({ agendaId, sessionId });
            });

            socket.on(SESSION_UPDATED, (data: { eventId: string; agendaId: string; session: TSession }) => {
                console.log({ [SESSION_UPDATED]: data });
                let elem = sess.find((i) => i.id === data.session.id);
                let list = utilities.updateItemList(sess, { ...elem, ...data.session }, "PUT");
                setAllSession([...list]);
            });

            // id : eventId
            socket.on(EVENT_SETTINGS_UPDATED, (data: { id: string; _settings: TEventSetting }) => {
                setEventSetting(data._settings);
            });

            socket.on(ON_CONNECTED, (data: any) => {
                // console.log(data);
            });

            socket.on("on_error", (data: any) => {
                // console.log(data);
            });

            socket.on(SESSION_STARTED, (data: { id: string }) => {
                //console.log(SESSION_STARTED);
                let elem = sess.find((i) => i.id === data.id);
                if (!elem) return;
                if (moment().isSameOrAfter(moment(elem.startTime).subtract(elem.type === "video" ? VIDEO_BUFFER_TIME : BUFFER_TIME, "minute"))) {
                    const { allowed, recommended } = DomainUtils.checkEmailDomains(
                        strategicPartners,
                        { domainRestrictionType: elem.domainRestrictionType, restrictedEmailDomains: elem.restrictedEmailDomains || [], restrictedStrategicPartnerIds: elem.restrictedStrategicPartnerIds },
                        user?.id
                    );
                    if (allowed) setCurrentSessions([...utilities.updateItemList(curs, { ...elem, status: "live", isSessionLive: true, recommended, allowed }, "PUT")]);
                }
                let list = utilities.updateItemList(sess, { ...elem, status: "live", isSessionLive: true }, "PUT");
                setAllSession([...list]);
            });
            socket.on(SESSION_ENDED, (data: { id: string }) => {
                // console.log(SESSION_ENDED);
                let elem = sess.find((i) => i.id === data.id);
                if (!elem) return;
                socket.emit(EXIT_SESSION, {
                    accessToken: localStorage.getItem(COOKIES_ACCESS_TOKEN),
                    sessionId: data.id,
                });
                setCurrentSessions([...utilities.updateItemList(curs, elem, "DELETE")]);
                let list = utilities.updateItemList(sess, { ...elem, status: "closed" }, "UPDATE");
                setAllSession([...list]);
            });

            socket.on(TRACK_NEW_RANK, (data: { rank: number; eventId: string }) => {
                const _rank = data?.rank;

                let allowNotification = eventSettings?.gamification || false;
                if (data?.eventId !== eventDetails?.id || allowNotification === false) return;
                if (typeof rank === "number" && rank !== _rank) {
                    setCredit({ rank: _rank });
                    // notify({
                    //     message: `Your Rank: ${_rank}`,
                    //     title: `Rank update`,
                    //     subtitle: 'To view details go to Rewards tab'
                    // })
                }
            });

            socket.on(TRACK_SCORED, (data: TGamificationScore) => {
                let allowNotification = eventSettings?.gamification || false;
                if (data.eventId !== eventDetails?.id || allowNotification === false) return;
                const points = data.credits_remaining;
                const rank = data.rank;
                const credit = data.score > 0;
                setCredit({ points, rank });
                notify({
                    message: `Your Points: ${points}\nYour Rank: ${rank}`,
                    title: credit ? "You just earned some points!" : "You just lost some points",
                    subtitle: "To view details go to Rewards tab",
                });
            });

            socket.on(SESSION_RECORDING_URL, (data: { id: string; recordingUrl: string }) => {
                let elem = sess.find((i) => i.id === data.id);
                if (!elem) return;
                let list = utilities.updateItemList(sess, { ...elem, recordingCompleted: true }, "UPDATE");
                setAllSession([...list]);
            });

            socket.on("connect_failed", (error: any) => console.log("Error", error));
        } catch (err) {
            console.log("Socket error : ", err);
        }
    };

    const TrackCustomEvent = useMemo(() => {
        return {
            emit: (event: keyof typeof SOCKET_EVENT_KEYS, data: object, callback: (resp: any) => any = () => {}) => {
                /**
                 * @author Adnan Husain
                 *
                 *  A generalized socket function that emits the desired event on server.
                 *
                 * @param eventName : event you want to fire.
                 * @param data : data you want to send.
                 * @param callback : {optional} callback fired if there is any callback that is fired in response to the event from server.
                 */
                const date = new Date();
                data = {
                    ...data,
                    eventId: eventDetails?.id,
                    registrationId: registrant?.id,
                    zineId,
                    agencyAssetId: assetId,
                    agencyId: agency?.id,
                };

                console.log(chalk.bgGreen("TRACK CUSTOM EVENT => "), { userId: user?.id, page: path, event: SOCKET_EVENT_KEYS[event], sentTime: date.toString(), data });
                socket.emit(
                    "createEvent",
                    {
                        name: SOCKET_EVENT_KEYS[event],
                        page: path,
                        sentTime: date,
                        data,
                        userId: user?.id,
                    },
                    callback
                );
            },
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user?.id, path, eventDetails?.id, registrant?.id, zineId, agency?.id, assetId]);
    return { socket, socketInit, SOCKET_EVENT_KEYS, disconnectSocket, connectEventSocket, TrackCustomEvent };
};
