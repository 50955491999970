import { Box } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import moment from "moment";
import React, { FC, useMemo, useState } from "react";
import Timer from "react-compound-timer";
import Typo from "../../Components/Typo";
import { BUFFER_TIME, VIDEO_BUFFER_TIME } from "../../Hooks/useScheduler";
import { TSession } from "../../Models/Agenda/@types";
export interface SessionTimerDisplayProps {
    session: TSession;
}

const SessionTimerDisplay: FC<SessionTimerDisplayProps> = ({ session }) => {
    const classes = useStyles();
    const [timer, setTimer] = useState<number>(0);
    const [showTimer, setShowTimer] = useState<boolean>(false);
    const isUpcoming = useMemo(() => {
        if (!session.startTime) return false;
        if (session.isSessionLive) return false;
        let { startTime, type } = session;
        let toCheckLive = moment(startTime)
            .clone()
            .subtract(type === "video" ? VIDEO_BUFFER_TIME : BUFFER_TIME - 1, "minute");
        if (toCheckLive.isSameOrBefore(moment())) return false;
        let diff = toCheckLive.diff(moment(), "millisecond");
        if (diff >= 0 && diff <= 600000) {
            setTimer(diff);
            setShowTimer(true);
            return true;
        }
        return false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [session.startTime]);
    if (!showTimer || !isUpcoming) return null;
    const format = (value: number) => {
        return value < 10 ? `0${value}` : `${value}`;
    };
    return (
        <Timer checkpoints={[{ time: 0, callback: () => setShowTimer(false) }]} initialTime={timer} direction={"backward"}>
            <Box ml={2} p="2px 5px 2px 0px" display="flex" alignItems="flex-start">
                <Box className={classes.timerPart}>
                    <Typo weight="bold" variant="subtitle2">
                        <Timer.Minutes formatValue={format} />M
                    </Typo>
                    {/* <Typo variant={Subtitle} align='center'>MINS</Typo> */}
                </Box>

                <Typo weight="bold" variant="subtitle2">
                    &nbsp;:&nbsp;
                </Typo>
                <Box className={classes.timerPart} margin="0px 4px">
                    <Typo weight="bold" variant="subtitle2">
                        <Timer.Seconds formatValue={format} />S
                    </Typo>
                    {/* <Typo variant={Subtitle} align='center'>SECS</Typo> */}
                </Box>
            </Box>
        </Timer>
    );
};

const useStyles = makeStyles<Theme>((theme) => {
    return createStyles({});
});

export default SessionTimerDisplay;
