import { Box, Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import moment from 'moment-timezone';
import React from 'react';
import { Fade } from 'react-reveal';
import Slider, { Settings } from 'react-slick';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import ArrowButton from '../../Components/ArrowButton';
import Typo from '../../Components/Typo';
import { useMediaQuery } from '../../Hooks/useMediaQuery';
import { getUpdatedSessions } from '../../Hooks/useScheduler';
import { useTranslation } from '../../Hooks/useTranslation';
import { TSession } from '../../Models/Agenda/@types';
import utilities from '../../Resources/Utils';
import '../../Styles/slider.css';
import SessionCard, { SessionCardProps } from './SessionCard';
export interface SessionListProps extends Omit<SessionCardProps, 'session' | 'isActive'> {
    sessions: TSession[]
    currentSessions?: Array<TSession>
}

const SessionList: React.FC<SessionListProps> = (props) => {
    const { sessions = [], currentSessions = [], withDrawer = false, ...sessionCardProps } = props;
    const allSessions = getUpdatedSessions(sessions);
    const { isDeviceSm } = useMediaQuery();
    const classes = useStyles();
    const { getTranslation } = useTranslation();
    let sessionMap: { time: string, sessions: TSession[], isBreak?: boolean }[] = [];
    allSessions.forEach((elem) => {
        let isBreak = elem.type === 'break';
        let time = moment(elem.startTime).startOf('minute').format();
        let index = sessionMap.findIndex(i => (i.time === time && i.isBreak === isBreak));
        if (index === -1) {
            sessionMap.push({ time: time, sessions: [elem], isBreak });
        } else {
            sessionMap[index].sessions.push(elem);
        }
    });
    return (
        <Box display='flex' flexDirection='column' width='100%' alignItems='center' position='relative'>
            {
                sessionMap.map((elem, i) => {
                    let multiple = elem.sessions.length > 1;
                    let alignStart = multiple && !isDeviceSm && !withDrawer && window.innerWidth <= elem.sessions.length * MultipleSessionCardWidth;
                    let elem1 = document.getElementById(elem.time);
                    return (
                        <Box key={elem.time + i} width='100%'>
                            <Fade bottom >
                                <Box
                                    width='100%'
                                    className={clsx({
                                        [classes.background]: multiple
                                    })}
                                    key={elem.time + i}
                                >
                                    {multiple && <Box className={clsx({
                                        [classes.heading]: alignStart && !withDrawer,
                                        [classes.headingMultiple]: !alignStart && !withDrawer,
                                        [classes.margin]: isDeviceSm || withDrawer
                                    })}
                                        //  mt={8}
                                        width={(elem1?.clientWidth || 800) - 20}
                                    ><Typo variant='h6' weight='bold'>{getTranslation('agendaScreen.parallelSessionsHeading')}</Typo></Box>}
                                    <Box
                                        className={clsx(classes.container, {
                                            [classes.root]: alignStart,
                                            [classes.drawerContainer]: withDrawer
                                            // [classes.background]: multiple
                                        })}
                                        // mb={multiple ? 8 : 0}
                                        alignSelf={(alignStart) ? 'flex-start' : 'center'}
                                    >

                                        {
                                            alignStart ?
                                                <Box width='100%' position='relative' my={1}>
                                                    <Fade bottom >
                                                        <Slider
                                                            {...settings}
                                                        >
                                                            {elem.sessions.map((s, i) => {
                                                                const currentSession = currentSessions.find(i => i.id === s.id);
                                                                return (
                                                                    <Fade key={s.id} bottom delay={200 + (i * 200)}>
                                                                        <Box mt={2} mb={'5px'} key={s.id} className={classes.outline}>
                                                                            <SessionCard withDrawer={withDrawer} multiple={multiple} isActive={currentSession?.id === s.id && s.type !== 'break'} session={s} {...sessionCardProps} />
                                                                        </Box>
                                                                    </Fade>
                                                                )
                                                            })}
                                                        </Slider>
                                                    </Fade>
                                                </Box>
                                                :
                                                <Box

                                                    className={clsx({

                                                        [classes.mobileMultiple]: multiple,
                                                        [classes.withDrawer]: (withDrawer || (isDeviceSm))
                                                    })}
                                                    id={elem.time}
                                                >
                                                    {elem.sessions.map((s, i) => {
                                                        const currentSession = currentSessions.find(i => i.id === s.id);
                                                        return (
                                                            <Fade bottom key={s.id} delay={200 + (200 * i)}>
                                                                <Box mt={s.type === 'break' ? 4 : 2} mb={multiple ? 0 : (s.type === 'break' ? 4 : 2)} mx={(multiple && !isDeviceSm && !withDrawer) ? 1.5 : 0}>
                                                                    <SessionCard withDrawer={withDrawer} multiple={multiple} isActive={currentSession?.id === s.id && s.type !== 'break'} session={s} {...sessionCardProps} />
                                                                </Box>
                                                            </Fade>
                                                        )
                                                    })}
                                                </Box>
                                        }
                                    </Box>
                                </Box>
                            </Fade>
                        </Box>
                    )
                })
            }
        </Box>

    )

}


export default SessionList
const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        overflow: 'auto',
        width: '100%',
        justifyContent: 'flex-start',
    },
    background: {
        background: `transparent linear-gradient(108deg, ${utilities.hexToRgb(theme.palette.primary.main)} 0%, ${utilities.hexToRgb(theme.palette.secondary.main)} 100%) 0% 0% no-repeat padding-box`,

        margin: '24px 0',
        paddingTop: 40,
        paddingBottom: 40
    },
    margin: {
        paddingLeft: 24
    },
    heading: {
        marginLeft: 42,
        boxSizing: 'border-box'
    },
    headingMultiple: {
        boxSizing: 'border-box',
        margin: '0 auto',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    mobileMultiple: {
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            width: '100%',
        },
    },
    withDrawer: {
        flexDirection: 'column',
        width: '100%',
    },
    container: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        boxSizing: 'border-box',
        [theme.breakpoints.down('sm')]: {
            padding: '0 24px'
        }
    },
    drawerContainer: {
        padding: '0 24px'
    },
    outline: {
        '&:focus': {
            outline: 'none !important'
        }
    }
}))
const MultipleSessionCardWidth = 524;
const count = parseInt((window.innerWidth / MultipleSessionCardWidth).toString());

export const settings: Settings = {
    infinite: false,
    speed: 500,
    slidesToShow: count < 1 ? 1 : count,
    slidesToScroll: 2,
    swipeToSlide: true,
    nextArrow: <ArrowButton role='next' />,
    prevArrow: <ArrowButton role='previous' />,

}

