import React, { useState } from "react";
import { SOCKET_EVENT_KEYS, useSocketListener } from "../../Hooks/useSocketListener";
import { useSessionFeedback } from "../../Screens/SessionDetail/useSessionFeedback";
import { useStoreState } from "../../Stores";
import FeedbackDialog from "./FeedbackDialog";

type DIALOG_ACTIONS = "meeting-end" | "video-end";

export const useSessionFeedbackDialog = (sessionId: string) => {
    const [open, setOpen] = useState<boolean>(false);
    const { feedback, sessionRequest, currentSessions } = useStoreState(({ Registrant: { feedback, sessionRequest }, Events: { currentSessions } }) => ({ feedback, sessionRequest, currentSessions }));
    const [showRejoin, setShowRejoin] = useState<boolean>(false);

    const toggleShowRejoin = (flag: boolean) => {
        setShowRejoin(flag);
    };

    const { handleFeedbackSubmit, handleRequestSubmit, getSessionFeedback, getSessionRequest } = useSessionFeedback(sessionId);
    const toggleDialog = async () => {
        if (!open) {
            await getSessionFeedback();
            await getSessionRequest();
        }
        setOpen(!open);
    };
    const FeedbackDialogComponent = (
        <FeedbackDialog
            feedback={feedback}
            showRejoin={showRejoin && currentSessions.find((i) => i.id === sessionId)?.status === "live"}
            sessionRequest={sessionRequest}
            onFeedbackSubmit={handleFeedbackSubmit}
            onSessionRequestSubmit={handleRequestSubmit}
            isSessionLive={currentSessions.find((i) => i.id === sessionId)?.isSessionLive}
        />
    );

    const { socket } = useSocketListener();
    const { SESSION_ENDED } = SOCKET_EVENT_KEYS;

    const initListner = () => {
        socket.on(SESSION_ENDED, async (data: { id: string }) => {
            if (!open && data.id === sessionId) {
                await toggleDialog();
            }
        });
    };
    return {
        showDialog: open,
        toggleDialog,
        FeedbackDialogComponent,
        initListner,
        toggleShowRejoin,
    };
};
