import { FormControlLabel, Paper, Radio, RadioGroup } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React, { FC } from 'react';
import { HEADER_HEIGHT } from '../../../Components';
import { useStoreActions, useStoreState } from '../../../Stores';

export interface RecommendFilterProps {

}


const FILTER_VALUE = {
    ONLY_RECOMMENDED: 'onlyRecommended',
    ALL: 'all'
}

const RecommendFilter: FC<RecommendFilterProps> = (props) => {
    const classes = useStyles({});
    const { showRecommended, } = useStoreState(({ Agency: { showRecommended } }) => ({ showRecommended }))
    const { setShowRecommended } = useStoreActions(({ Agency: { setShowRecommended } }) => ({ setShowRecommended }))
    const showRecommendedLabel = `Show only recommended`;

    return (
        <Paper square className={classes.root}>
            <RadioGroup classes={{ root: classes.formContainer }} aria-label="showRecommended" name="showRecommended" value={showRecommended === false ? FILTER_VALUE.ALL : FILTER_VALUE.ONLY_RECOMMENDED} onChange={(_, v) => setShowRecommended(v === FILTER_VALUE.ONLY_RECOMMENDED)}>
                <FormControlLabel classes={{ label: classes.label }} value={FILTER_VALUE.ONLY_RECOMMENDED} control={<Radio color='primary' />} label={showRecommendedLabel} />
                <FormControlLabel classes={{ label: classes.label }} value={FILTER_VALUE.ALL} control={<Radio color='primary' />} label="Show All" />
            </RadioGroup>
        </Paper>
    )
}

const useStyles = makeStyles<Theme, any>((theme) => ({
    root: {
        width: 'fit-content',
        padding: theme.spacing(1),
        position: 'sticky',
        top: HEADER_HEIGHT + 80,
        zIndex: theme.zIndex.appBar,
        borderRadius: theme.shape.borderRadius,
        margin: theme.spacing(-8, 0, 4, 0),
        [theme.breakpoints.down('sm')]: {
            top: HEADER_HEIGHT + 50,
        },
    },
    formContainer: {
        flexDirection: 'row'
    },
    label: {
        fontWeight: theme.typography.fontWeightBold,
        fontSize: theme.typography.body2.fontSize,
        // color: theme.palette.primary.main
    }
}));

export default RecommendFilter;