import { Box, Button } from "@material-ui/core";
import moment from "moment-timezone";
import qs from "query-string";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Config from "../../../src/Config";
import { ContrastText } from "../../Components";
import { useNavMenu } from "../../Features/AppHeader/useNavMenu";
import SessionVideo from "../../Features/Session/SessionVideo";
import { useMediaQuery } from "../../Hooks/useMediaQuery";
import { useSocketListener } from "../../Hooks/useSocketListener";
import { TSession } from "../../Models/Agenda/@types";
import { TAsset } from "../../Models/App";
import { useStoreState } from "../../Stores/index";
import { COOKIES_ACCESS_TOKEN } from "../../Utils";
import { getZoomConfig } from "../ZoomClient/service";
interface TMediaConfig {
    session?: TSession;
    eventId?: string;
    onVideoEnd?: () => void;
    onMeetingLeft?: (flag: boolean) => void;
}
//stackrs.com:/LiveApp/play.html?name=streams/679363370953169827621945.mp4&autoplay=true&token=991117227012551032706597
let isLive = false;
let startTime: undefined | number = 0;
const defaultStreamId = "1105689";
//(session?.status === 'closed' && session.type === 'conferenceCall') ? getVODUrl(streamUrl || '') : s
export const useSessionMedia = (config?: TMediaConfig) => {
    const [watchStream, setWatchStream] = useState(true);
    const { origin, BASE_URL } = useNavMenu();
    const [streamUrl, setStreamUrl] = useState<string>();
    const [streamId, setStreamId] = useState<string>();
    const { ZoomConfig } = getZoomConfig(origin + BASE_URL);
    const { referenceNumber = "", currentSessions } = useStoreState(({ User: { referenceNumber }, Events: { currentSessions } }) => ({
        referenceNumber,
        currentSessions,
    }));
    const { isDeviceSm } = useMediaQuery();
    const [sessionJoined, setSessionJoined] = useState<boolean>(false);
    const [videoUrl, setVideoUrl] = useState<string>();
    //const ROOT_URL = origin + BASE_URL
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const session = useMemo(() => config?.session, [config?.session?.id]);

    const {
        socket,
        SOCKET_EVENT_KEYS: { JOIN_SESSION, EXIT_SESSION, SESSION_ENDED },
        TrackCustomEvent,
    } = useSocketListener();
    const handleIFrameLoad = (event: React.SyntheticEvent<HTMLIFrameElement, Event>) => {
        if (!watchStream && event.currentTarget.contentWindow?.location.href === ZoomConfig.ZOOM_LEAVE_URL) {
            handleSessionEnd("conference");
        }
    };
    const fireExitSessionSockets = useCallback((sessionId?: string) => {
        TrackCustomEvent.emit("EXIT_SESSION", { sessionId });
        socket.emit(EXIT_SESSION, {
            accessToken: localStorage.getItem(COOKIES_ACCESS_TOKEN),
            sessionId,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSessionEnd = (type: "conference" | "video") => {
        fireExitSessionSockets(session?.id);
        if (type === "conference") config?.onMeetingLeft?.(true);
        config?.onVideoEnd?.();
    };
    useEffect(() => {
        if (session?.id) {
            socket.on(SESSION_ENDED, (data: { id: string }) => {
                if (session?.id === data.id) fireExitSessionSockets(data.id);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [session?.id]);
    useEffect(() => {
        if (session && !sessionJoined) {
            onSessionJoin(session.id, true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [session, sessionJoined]);
    const query = qs.stringify({ referenceNumber: referenceNumber, sessionId: session?.id });
    const isExternalVideo = (session?.type === "externalConferenceCall" || session?.type === "noStream") && session.allowPlayback && session._conferenceRecording;
    const isVideo = session?.type === "video" && session?._video;
    const isConferenceVideo = session?.type === "conferenceCall" && session?.allowPlayback === true && (session._conferenceRecording || session.recordingCompleted);
    const isVimeoStream = watchStream && session?.type === "conferenceCall" && session.streamProvider === "vimeo";
    const isStreamUrl = session?.type === "streamingUrl" && session.streamingUrl;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const getVideo = (video?: TAsset) => {
        if (!video) return undefined;
        if (video.isVODCreated === true && streamUrl) return streamUrl;
        else return video.url;
    };
    const getVimeoStreamUrl = () => {
        return `https://vimeo.com/event/${streamId || defaultStreamId || ""}/embed/`;
    };
    useEffect(() => {
        /**
         * Track join session and exit session for generalized analytics.
         */
        if (session?.id && socket.connected) {
            TrackCustomEvent.emit("JOIN_SESSION", { sessionId: session.id }, (e: any) => console.log(e));
            return () => {
                TrackCustomEvent.emit("EXIT_SESSION", { sessionId: session.id });
            };
        }
    }, [TrackCustomEvent, session, socket.connected]);

    const updateVideo = useCallback(() => {
        let conferenceVideo: string | undefined = undefined;
        if (isStreamUrl) conferenceVideo = session?.streamingUrl || "";
        else if (isExternalVideo) conferenceVideo = getVideo(session?._conferenceRecording);
        else if (session?.allowPlayback) conferenceVideo = getVideo(session?._conferenceRecording);
        const recordingUrl = session?.recordingCompleted ? `${Config.get("API_URL")}events/${config?.eventId}/sessions/${session.id}/recording?access_token=${localStorage.getItem(COOKIES_ACCESS_TOKEN)}` : undefined;

        const video = session?.type === "video" ? getVideo(session?._video) : conferenceVideo || recordingUrl;
        setVideoUrl(video);
    }, [isStreamUrl, isExternalVideo, getVideo, session, config]);

    useEffect(() => {
        updateVideo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [streamUrl, isStreamUrl]);
    const onSessionJoin = async (id: string, requiresStreamUrl: boolean = false) => {
        try {
            console.log(JOIN_SESSION, "emitted for session => ", id);
            socket.emit(
                JOIN_SESSION,
                {
                    accessToken: localStorage.getItem(COOKIES_ACCESS_TOKEN),
                    sessionId: id,
                    // requiresStreamUrl
                },
                (data: { playUrl?: string; streamId?: string }) => {
                    console.log(JOIN_SESSION, data);
                    setSessionJoined(true);
                    if (requiresStreamUrl) {
                        setStreamUrl(data.playUrl || "");
                    }
                    if (data.streamId) {
                        setStreamId(data.streamId);
                    }
                }
            );
        } catch (err) {
            console.log("error", err);
        }
    };
    const toggleStream = async () => {
        if (!watchStream) {
            await onSessionJoin(session?.id || "", true);
        } else {
            console.log("change stream to zoom => ", session?.id);
            await onSessionJoin(session?.id || "");
            setStreamUrl("");
        }
        setWatchStream(!watchStream);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const StreamButton = (
        <Box mx={"5px"}>
            <Button variant="text" color="inherit" onClick={toggleStream}>
                <ContrastText variant="body2" weight="medium">
                    {watchStream ? "Participate" : "Stream 1"}
                </ContrastText>
            </Button>
        </Box>
    );
    const SwitchToSdkButton = watchStream ? (
        <Box mx={"5px"}>
            <Button title="Switching to this stream may reduce quality of audio/video." variant="text" color="inherit" onClick={toggleStream}>
                <ContrastText variant="body2" weight="medium">
                    {"Audio/Video issue?"}
                </ContrastText>
            </Button>
        </Box>
    ) : null;

    useEffect(() => {
        isLive = !!currentSessions.find((i) => i.id === session?.id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentSessions.length, session?.id]);
    useEffect(() => {
        const sessionStartTime = moment(session?.startTime);
        const diff = moment().diff(sessionStartTime, "second");
        startTime = session && isLive && session?.type === "video" ? diff : 0;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLive, session?.id]);

    return {
        Component: session?.id ? (
            <>
                {!isVideo && !isExternalVideo && !isConferenceVideo && !isStreamUrl ? (
                    <iframe
                        onLoad={handleIFrameLoad}
                        title="session-vide"
                        src={watchStream ? (isVimeoStream ? getVimeoStreamUrl() : streamUrl) : `${BASE_URL}/zoom-client?${query}`}
                        frameBorder="0"
                        width="100%"
                        height="100%"
                        allow="microphone; camera; fullscreen"
                    ></iframe>
                ) : (
                    <SessionVideo disableSeek={isLive} startTime={startTime} subtitles={session?._subtitles} width={isDeviceSm ? "100%" : "100%"} margin="0 auto" height="100%" url={videoUrl} onVideoEnd={() => handleSessionEnd("video")} />
                )}
            </>
        ) : null,
        watchStream,
        StreamButton,
        SwitchToSdkButton,
    };
};
