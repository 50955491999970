import { Action, action, thunk, Thunk, thunkOn, ThunkOn } from "easy-peasy";
import { TRootStore } from "..";
import StrategicPartnersBase from "../../BaseModels/StrategicPartners";
import AgencyModel from "../../Models/Agency";
import { TAgency, TAgencyCalendarResponse, TAgencyContent } from "../../Models/Agency/@types";
import StrategicPartnerModel from "../../Models/StrategicPartner";
import { TStrategicPartner } from "../../Models/StrategicPartner/@types";
import utils from "../../Utils";
import { buildSearchFilter, getFieldFilter } from "./helpers";
import Query from "./Query";

const isInIFrame = utils.inIframe();
const domain = utils.getDomain();

export interface TAgencyState {
    agency?: TAgency
    activeTab?: 'Calendar' | 'Resources'
    setActiveTab: Action<TAgencyState, 'Calendar' | 'Resources' | undefined>
    setAgency: Action<TAgencyState, TAgency>
    contentList: TAgencyContent[]
    query: Query,
    strategicPartner?: TStrategicPartner
    facets: TAgencyCalendarResponse['facets']
    resourceFacets: TAgencyCalendarResponse['facets'] & { total: number }
    isFetching: boolean
    showRecommended: boolean
    hasRegionFilter: boolean
    pageNumber: number
    contentCount: number
    setQuery: Action<TAgencyState, Query>
    setFacets: Action<TAgencyState, TAgencyCalendarResponse['facets']>
    setResourceFacets: Action<TAgencyState, TAgencyCalendarResponse['facets'] & { total: number }>
    setShowRecommended: Action<TAgencyState, boolean>
    setContentCount: Action<TAgencyState, number>
    setPageNumber: Action<TAgencyState, number>
    setIsFetching: Action<TAgencyState, boolean>
    setStrategicPartner: Action<TAgencyState, TStrategicPartner | undefined>
    fetchStrategicPartner: Thunk<TAgencyState, string>
    setContentList: Action<TAgencyState, TAgencyContent[]>
    fetchAgencyContents: Thunk<TAgencyState, { pageNumber?: number, filter?: TAgencyState['appliedFilter'], recommended?: boolean, activeTab?: 'Calendar' | 'Resource' }>
    appliedFilter: {
        regions?: string
        focusAreaIds: string[]
        languages: string[]
        type: string[]
        status?: 'upcoming' | 'past'
    }
    setAppliedFilter: Action<TAgencyState, TAgencyState['appliedFilter']>
    onFilterChange: ThunkOn<TAgencyState, {}, TRootStore>
    onPageChange: ThunkOn<TAgencyState, {}, TRootStore>
    onRecommendationToggle: ThunkOn<TAgencyState, {}, TRootStore>
    onUserChange: ThunkOn<TAgencyState, {}, TRootStore>
    resetFilter: Action<TAgencyState, TAgencyState['appliedFilter']>
    strategicPartners: TStrategicPartner[];
    fetchStrategicPartners: Thunk<TAgencyState, string>
}


let initialFetch = true;

export const AGENCY_EVENT_FILTER = (status?: 'upcoming' | 'past') => ({
    doc_type: ['Event', 'External'],
    bool: status === 'past' ? {
        must: [
            {
                range: {
                    endTime: {
                        lt: "now"
                    }
                }
            }
        ]
    } : {
        must: [
            {
                range: {
                    endTime: {
                        gt: "now"
                    }
                }
            }
        ]
    }
})

export const AGENCY_RESOURCE_FILTER = {
    doc_type: ['Zine', 'AgencyAsset']
}
export const AGENCY_CONTENT_FETCH_SIZE = 30;
const State: TAgencyState = {
    contentList: [],
    hasRegionFilter: false,
    facets: {},
    resourceFacets: { total: 0 },
    contentCount: 100,
    query: Query.fromUrl(),
    showRecommended: isInIFrame,
    pageNumber: 1,
    isFetching: false,
    appliedFilter: {
        focusAreaIds: [],
        languages: [],
        type: [],
    },
    strategicPartners: [],
    setActiveTab: action((state, payload) => {
        state.activeTab = payload
    }),
    setFacets: action((state, payload) => {
        state.facets = payload;
    }),
    setResourceFacets: action((state, payload) => {
        state.resourceFacets = payload;
    }),
    setQuery: action((state, payload) => {
        state.hasRegionFilter = !!payload.filter.regions
        state.query = payload;
    }),
    setShowRecommended: action((state, payload) => {
        state.showRecommended = payload;
    }),
    setStrategicPartner: action((state, payload) => {
        state.strategicPartner = payload;
    }),
    setContentCount: action((state, payload) => {
        state.contentCount = payload;
    }),
    setPageNumber: action((state, payload) => {
        state.pageNumber = payload;
    }),
    setAppliedFilter: action((state, payload) => {
        state.appliedFilter = payload;
    }),
    resetFilter: action((state, payload) => {
        state.appliedFilter = payload;
    }),
    setIsFetching: action((state, payload) => {
        state.isFetching = payload;
    }),
    setAgency: action((state, payload) => {
        state.agency = payload
    }),
    setContentList: action((state, payload) => {
        state.contentList = payload;
    }),
    fetchStrategicPartner: thunk(async (actions, payload,) => {
        const { data } = await StrategicPartnerModel.get_StrategicPartners_id<TStrategicPartner>(payload);
        actions.setStrategicPartner(data);

    }),
    fetchAgencyContents: thunk(async (actions, payload, { getState }) => {
        const { filter, pageNumber = 1, recommended, } = payload;

        let { agency, activeTab } = getState();
        if (!agency) return;
        actions.setIsFetching(true);
        const isResource = activeTab === 'Resources';
        const { where, sort } = buildSearchFilter(filter)
        const WhereFilter = isResource ? {
            ...AGENCY_RESOURCE_FILTER
        } : {
            ...AGENCY_EVENT_FILTER(),
            bool: { ...AGENCY_EVENT_FILTER(filter?.status).bool, ...(where.bool || {}) }
        }
        const { data } = await AgencyModel.get_Agencies_calender<TAgencyCalendarResponse>(undefined, {
            limit: AGENCY_CONTENT_FETCH_SIZE,
            skip: (pageNumber - 1) * AGENCY_CONTENT_FETCH_SIZE,
            ...getFieldFilter(isResource ? 'resource' : 'calendar'),
            sort: isResource ? { "updated": { "order": "desc" } } : {
                ...sort
            },
            where: {
                agencyId: agency.id,
                isPublished: true,
                ...where,
                ...WhereFilter
            },

        }, recommended, isInIFrame ? domain : undefined);
        initialFetch = false;
        // actions.setFacets(data.facets);
        actions.setContentCount(data.total);
        actions.setContentList(data.hits.map(d => d.hit));
        actions.setIsFetching(false);
    }),
    onFilterChange: thunkOn(
        (actions) => actions.setAppliedFilter,
        async (actions, { payload }) => {
            actions.setQuery(new Query(payload))
            // await actions.fetchAgencyContents({ filter: payload })
        }
    ),
    onPageChange: thunkOn(
        (actions) => actions.setPageNumber,
        async (actions, { payload }, { getState }) => {
            const { appliedFilter, showRecommended } = getState();
            await actions.fetchAgencyContents({ filter: appliedFilter, pageNumber: payload, recommended: showRecommended })
        }
    ),
    onRecommendationToggle: thunkOn(
        (actions) => actions.setShowRecommended,
        async (actions, { payload }, { getState }) => {
            const { appliedFilter, showRecommended } = getState();
            await actions.fetchAgencyContents({ filter: appliedFilter, recommended: showRecommended })
        }
    ),
    onUserChange: thunkOn(
        (actions, storeActions) => storeActions.User.setIsAuthenticated,
        // (actions, storeActions) => actions.setShowRecommended,

        async (actions, { payload }, { getState }) => {
            const { appliedFilter, } = getState();
            if (!initialFetch) {
                actions.setShowRecommended(false);
                await actions.fetchAgencyContents({ filter: appliedFilter });
            }
            // actions.setAppliedFilter(appliedFilter)
        }
    ),
    fetchStrategicPartners: thunk(async (_a, agencyId, { getState }) => {
        try {
            const { data: strategicPartners } = await StrategicPartnersBase.get_StrategicPartners<TStrategicPartner[]>({ where: { agencyId } });
            getState().strategicPartners = strategicPartners;
        } catch (er) {

        }

    })

};
export default State