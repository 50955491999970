import { TEvent } from "../Events/@types";
import { TSession } from "../Agenda/@types";
import { TSpeaker, TEventPerson } from "./@types";
import { Tag } from "../Agency/@types";
import { find } from 'lodash';

export default class SpeakerModel {

    static getSpeakerSession(event: TEvent | undefined, speakerId: string) {
        if (!event) return []
        const list: TSession[] = [];
        event._sessions.forEach(session => {
            const index = session.speakerIds.findIndex(i => i === speakerId)
            if (index > -1)
                list.push(session);
        })
        return list
    }
    static getSpeaker(event: TEvent | undefined, speakerIds: string[]) {
        if (!event) return []
        const list: TSpeaker[] = [];
        event.speakers?.forEach(speaker => {
            const index = speakerIds.findIndex(i => i === speaker.id)
            if (index > -1)
                list.push(speaker)
        })
        return list;
    }

    static attachExpertiseIdResolver(event: TEvent) {
        event._eventPersons = event._eventPersons.map(e => ({ ...e, resolveExpertiseIds: (tags: Tag[]) => SpeakerModel.resolveExpertiseIds(e, tags) }));
        return event
    }

    static resolveExpertiseIds(eventPerson: TEventPerson, tags: Tag[]) {
        return (eventPerson.expertiseIds || []).map(id => find(tags, { id }) ?? null).filter(x => x) as Tag[];
    }
}