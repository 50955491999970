
import axios, {AxiosRequestConfig} from 'axios';
// THIS IS A GENERATED FILE; PLEASE DO NOT MAKE CHANGES HERE

class TagsBase {

/** 
 * Find all instances of the model matched by filter from the data source.
 */ 
    static get_Tags<T = any>(filter?: any,config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/Tags`,
            method: 'get',
            params: {
                filter
            },
            
        })
    }

/** 
 * Find first instance of the model matched by filter from the data source.
 */ 
    static get_Tags_findOne<T = any>(filter?: any,config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/Tags/findOne`,
            method: 'get',
            params: {
                filter
            },
            
        })
    }

/** 
 * edit
 */ 
    static patch_Tags_id_edit<T = any>(name: any,id: string,accessUserId?: any,config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/Tags/${id}/edit`,
            method: 'patch',
            
            data: {
                name, accessUserId
            },
        })
    }

/** 
 * create
 */ 
    static post_Tags_create<T = any>(tagTree?: any,accessUserId?: any,config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/Tags/create`,
            method: 'post',
            
            data: {
                ...tagTree, accessUserId
            },
        })
    }

/** 
 * treeSearch
 */ 
    static get_Tags_tree_search<T = any>(term?: any,filter?: any,accessUserId?: any,config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/Tags/tree-search`,
            method: 'get',
            params: {
                term, filter, accessUserId
            },
            
        })
    }

/** 
 * shiftTag
 */ 
    static patch_Tags_id_shift<T = any>(id: string,newParentId?: any,newIndex?: any,accessUserId?: any,config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/Tags/${id}/shift`,
            method: 'patch',
            
            data: {
                newParentId, newIndex, accessUserId
            },
        })
    }

/** 
 * deleteTags
 */ 
    static delete_Tags_delete<T = any>(ids: any,adjustChildrenInParent?: any,backup?: any,accessUserId?: any,config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/Tags/delete`,
            method: 'delete',
            
            data: {
                ids, adjustChildrenInParent, backup, accessUserId
            },
        })
    }

/** 
 * expandTree
 */ 
    static get_Tags_expand<T = any>(ids: any,accessUserId?: any,config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/Tags/expand`,
            method: 'get',
            params: {
                ids, accessUserId
            },
            
        })
    }

}

export default TagsBase;
    