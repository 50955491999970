import _ from 'lodash';
import { useContext, useEffect } from 'react';
import TranslationJson from '../Hooks/translations.json';
import TranslationModel from '../Models/Translations';
import { StaticTranslationNode, TranslationLocale } from '../Models/Translations/@types';
import { useStoreState } from '../Stores/index';
import { TranslationContext } from '../Utils/TranslationProvider';

export type SystemSupportedLanguages = 'en' | 'fr' | 'de'
export interface TNode extends Record<SystemSupportedLanguages | string, string | TNode> {
    label: string
}

let TranslationTree: StaticTranslationNode = TranslationJson;

export const useTranslation = () => {

    const { agency } = useStoreState(state => state.Agency);

    let { language } = useContext(TranslationContext);

    useEffect(() => {
        TranslationTree = TranslationModel.mergeTrees(TranslationJson, agency?.staticTranslations);
    }, [agency])

    const getTranslation = (path: string, header: TranslationLocale = language): string => {
        return _.get(TranslationTree, `${path}.${header}`) as string || _.get(TranslationTree, `${path}.en`) as string || '';
    }



    return { getTranslation }
}