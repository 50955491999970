import { Box, Theme, TypographyProps } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import Timer, { Checkpoint } from 'react-compound-timer';
import { useMediaQuery } from '../Hooks/useMediaQuery';
import Typo from './Typo';

export interface CountdownTimerProps {
    initialTimer: number;
    checkpoints?: Checkpoint[];
}
let minute = 60000;
const CountdownTimer: React.FC<CountdownTimerProps> = (props) => {
    const { isDeviceSm } = useMediaQuery();
    const classes = useStyles();
    const { initialTimer } = props;
    const [showSeconds, setShowSeconds] = useState<boolean>(false);
    useEffect(() => {
        if (initialTimer <= minute) setShowSeconds(true);
    }, [initialTimer]);
    const format = (value: number) => {
        return value < 10 ? `0${value}` : `${value}`;
    };

    const Time: TypographyProps['variant'] = isDeviceSm ? 'subtitle2' : 'subtitle2';

    return (
        <Timer
            checkpoints={[...(props.checkpoints || []), { callback: () => setShowSeconds(true), time: minute }]}
            initialTime={props.initialTimer}
            direction={'backward'}
        >
            <Box my={isDeviceSm ? 0 : 1} display="flex" alignItems="flex-start">
                <Typo variant={Time} weight="medium">{`Time left `}</Typo>
                {!showSeconds ? (
                    <>
                        {' '}
                        <Box className={classes.timerPart}>
                            <Typo variant={Time} weight="medium">
                                <Timer.Days formatValue={format} />D
                            </Typo>
                            {/* <Typo variant={Subtitle} align='center' >DAYS</Typo> */}
                        </Box>
                        <Typo variant={Time} weight="medium">
                            &nbsp;:&nbsp;
                        </Typo>
                        <Box className={classes.timerPart}>
                            <Typo variant={Time} weight="medium">
                                <Timer.Hours formatValue={format} />H
                            </Typo>
                            {/* <Typo variant={Subtitle} align='center' >HRS</Typo> */}
                        </Box>
                        <Typo variant={Time} weight="medium">
                            &nbsp;:&nbsp;
                        </Typo>
                        <Box className={classes.timerPart}>
                            <Typo variant={Time} weight="medium">
                                <Timer.Minutes formatValue={format} />M
                            </Typo>
                            {/* <Typo variant={Subtitle} align='center'>MINS</Typo> */}
                        </Box>
                    </>
                ) : (
                    <>
                        <Typo variant={Time}>&nbsp;:&nbsp;</Typo>
                        <Box className={classes.timerPart} margin="0px 4px">
                            <Typo variant={Time} weight="medium">
                                <Timer.Seconds formatValue={format} />S
                            </Typo>
                            {/* <Typo variant={Subtitle} align='center'>SECS</Typo> */}
                        </Box>
                    </>
                )}
            </Box>
        </Timer>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        timerPart: {
            display: 'flex',
        },
    })
);

export default CountdownTimer;
