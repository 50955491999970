import React, { FC } from "react";
import { Box, Typography, TypographyProps } from "@material-ui/core";
import { createStyles, Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
interface IHTMLTextProps extends TypographyProps {
    htmlText: string;
}

const HTMLText: FC<IHTMLTextProps> = (props) => {
    const classes = useStyles();
    return <Typography {...props} className={clsx(classes.description, props.className)} dangerouslySetInnerHTML={{ __html: props.htmlText }} />;
};

const useStyles = makeStyles<Theme>((theme) => {
    return createStyles({
        paper: {
            width: "800px",
            margin: "32px auto",
            height: "auto",
            padding: 40,
            boxSizing: "border-box",
            whiteSpace: "pre-wrap",

            [theme.breakpoints.down("sm")]: {
                width: "100%",
                padding: 16,
            },
        },
        description: {
            "& img": {
                width: "100%",
            },
            "& p": {
                margin: 0,
            },
        },
    });
});
export default HTMLText;
