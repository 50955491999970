import React, { createContext } from 'react';
import { AppContextProviderValue, defaultValue } from './@types';
import { useAppInit } from './useAppInit';
import { useEventSlugHandler } from './useEventSlugHandler';

const AppContext = createContext<AppContextProviderValue>(defaultValue)


export const AppContextProvider: React.FC = (props) => {

    const { eventSlug: slug } = useEventSlugHandler()
    const { initialized, setTokenAndUser, statusAndPermissions, invalidEvent, previewMode, eventSlug, eventId, invalidAgency } = useAppInit({ eventSlug: slug })

    // useEffect(() => {

    //     console.log("params", params)
    // }, [params])
    const providerValue: AppContextProviderValue = {
        initialized,
        invalidEvent,
        previewMode,
        eventSlug,
        eventId,
        statusAndPermissions,
        setTokenAndUser,
        invalidAgency
    }

    //console.log("app context", providerValue)
    return (
        <AppContext.Provider value={providerValue}>
            {props.children}
        </AppContext.Provider>
    )
}




export default AppContext;