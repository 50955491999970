
import axios, {AxiosRequestConfig} from 'axios';
// THIS IS A GENERATED FILE; PLEASE DO NOT MAKE CHANGES HERE

class AgencyAssetsBase {

/** 
 * Find all instances of the model matched by filter from the data source.
 */ 
    static get_AgencyAssets<T = any>(filter?: any,config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/AgencyAssets`,
            method: 'get',
            params: {
                filter
            },
            
        })
    }

/** 
 * Create a new instance of the model and persist it into the data source.
 */ 
    static post_AgencyAssets<T = any>(data?: any,config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/AgencyAssets`,
            method: 'post',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Find a model instance by {{id}} from the data source.
 */ 
    static get_AgencyAssets_id<T = any>(id: string,filter?: any,config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/AgencyAssets/${id}`,
            method: 'get',
            params: {
                filter
            },
            
        })
    }

/** 
 * Patch attributes for a model instance and persist it into the data source.
 */ 
    static patch_AgencyAssets_id<T = any>(id: string,data?: any,config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/AgencyAssets/${id}`,
            method: 'patch',
            
            data: {
                ...data
            },
        })
    }

/** 
 * moveToTrash
 */ 
    static delete_AgencyAssets_trash<T = any>(ids: any,config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/AgencyAssets/trash`,
            method: 'delete',
            
            data: {
                ids
            },
        })
    }

/** 
 * Publish entity.
 */ 
    static post_AgencyAssets_id_publish<T = any>(publish: any,id: string,config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/AgencyAssets/${id}/publish`,
            method: 'post',
            
            data: {
                publish
            },
        })
    }

/** 
 * stats
 */ 
    static get_AgencyAssets_id_analytics_stats<T = any>(id: string,accessUserId?: any,config?: AxiosRequestConfig) {
        return axios.request<T>({
            ...config,
            url: `/AgencyAssets/${id}/analytics/stats`,
            method: 'get',
            params: {
                accessUserId
            },
            
        })
    }

}

export default AgencyAssetsBase;
    