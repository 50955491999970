import { createStyles, Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import React, { FC } from "react";
import { attachField, FormConfig, IFormActionProps, ReactForm } from "react-forms";
import * as Yup from "yup";
import ConsentMessage from "../../Components/ConsentMessage";
import { useTranslation } from "../../Hooks/useTranslation";
import EmailField from "../EmailField";

attachField("emailField", <EmailField />);
attachField("consent", <ConsentMessage />);

export interface GuestLoginFormData {
    email: string;
    firstName: string;
    lastName: string;
}

interface IProps {
    onSubmit: Function;
    isSubmitting?: boolean;
    onBack?: (email: string) => void;
    initialData?: Partial<GuestLoginFormData>;
}

const useFormActionConfig = () => {
    const { getTranslation } = useTranslation();
    const classes = useStyles();
    const config: IFormActionProps = {
        submitButtonText: getTranslation("emailInputForm.nextButtonLabel"),
        submitButtonLayout: "fullWidth",
        submitButtonProps: {
            size: "large",
        },
        containerClassNames: classes.buttonContainer,
    };
    return config;
};

export const GuestLoginForm: FC<IProps> = (props) => {
    const { getTranslation } = useTranslation();
    const CONFIG: Array<Array<FormConfig> | FormConfig> = [
        {
            type: "emailField",
            valueKey: "email",
            fieldProps: {
                onBack: props.onBack,
                disabled: true,
            },
        },
        {
            type: "text",
            valueKey: "firstName",
            fieldProps: {
                label: getTranslation("signUpForm.firstNameInputLabel"),
                fullWidth: true,
            },
        },
        {
            type: "text",
            valueKey: "lastName",
            fieldProps: {
                label: getTranslation("signUpForm.lastNameInputLabel"),
                fullWidth: true,
            },
        },
    ];
    const formValidation = Yup.object({
        firstName: Yup.string().trim().required("First Name is required"),
        email: Yup.string().trim().email(getTranslation("loginForm.invalidEmailLabel")).required(getTranslation("loginForm.emailRequiredLabel")),
    });

    const formActionConfig = useFormActionConfig();
    return (
        <ReactForm
            formId="login-form"
            config={CONFIG}
            actionConfig={formActionConfig}
            initialValues={props.initialData}
            onSubmit={props.onSubmit}
            validationSchema={formValidation}
            formSettings={{
                verticalSpacing: 20,
            }}
            isInProgress={props.isSubmitting}
            enableReinitialize
        ></ReactForm>
    );
};

export default GuestLoginForm;

const useStyles = makeStyles<Theme>((theme) =>
    createStyles({
        buttonContainer: {
            marginTop: 40,
        },
    })
);
