import { action, Action, Thunk, thunk } from "easy-peasy";
import moment from "moment-timezone";
import { Injections, TRootStore } from "..";
import { eventSponsors } from "../../Features/AppHeader/useNavMenu";
import { TEventStatus, TSession } from "../../Models/Agenda/@types";
import DomainUtils from "../../Models/Domain";
import { TEventPerson, TSpeaker } from "../../Models/EventPerson/@types";
import { TEvent, TEventSetting } from "../../Models/Events/@types";
import SessionGroupModel from "../../Models/SessionGroup";
import { TSessionGroup } from "../../Models/SessionGroup/@types";
import { ANALYTICS_EVENTS_CONFIG, IAnalyticsCustomConfig, setAnalytics } from "analytics.config";
export type TEmptyField = keyof TEvent | "rewards" | "registrant" | "feedback" | "meetings" | "sponsors";

const getAnalyticsConfig = (eventSlug: string = "default"): IAnalyticsCustomConfig[] => {
    let analyticsConfig: IAnalyticsCustomConfig[] = ANALYTICS_EVENTS_CONFIG[eventSlug];
    if (!analyticsConfig) analyticsConfig = ANALYTICS_EVENTS_CONFIG["default"];
    return analyticsConfig;
};

export interface TEventState {
    eventDetails: TEvent | undefined;
    eventPersons: TEventPerson[];
    eventSettings: TEventSetting | undefined;
    sessionDetails: TSession | undefined;
    isLive: boolean;
    allowedDomains: string[];
    showRecommended: boolean;
    recommendedSessionCount: number;
    /**
     * is event open for registration for all the user's domain
     */
    isRegistrationAllowed: boolean;
    /**
     * check email and update registration restriction flag
     */
    setRegistrationAllowed: Action<TEventState, string | undefined>;
    /**
     * allSessions => list of all sessions.
     */
    allSessions: TSession[];
    /**
     * sessions => list of all sessions which are allowed for the current logged in user's email domain.
     * if user logged out , then only those sessions are allowed which are open to all.
     */
    allowedSessions: TSession[];
    eventStatus: TEventStatus;
    setAllSession: Thunk<TEventState, Array<TSession>, Injections, TRootStore>;
    setAllowedSessions: Action<TEventState, Array<TSession>>;
    removeSession: Action<TEventState, { agendaId: string; sessionId: string }>;
    emptyFields: TEmptyField[];
    currentSession: TSession | undefined;
    currentSessions: Array<TSession>;
    resetState: Action<TEventState, void>;
    setEventSetting: Action<TEventState, TEventSetting>;
    setEventStatus: Action<TEventState, TEventStatus>;
    setCurrentSessions: Action<TEventState, Array<TSession>>;
    setEmptyFields: Action<TEventState, TEmptyField[]>;
    setLiveStatus: Action<TEventState, boolean>;
    setCurrentSession: Action<TEventState, undefined | TSession>;
    setSingleEvent: Action<TEventState, TEvent | undefined>;
    setSingleSession: Action<TEventState, TSession | undefined>;
    fetchSingleEvent: Thunk<TEventState, string, Injections, TRootStore>;

    sessionGroup: TSessionGroup[];
    groupSessions: Action<TEventState, Array<TSession>>;
    setShowRecommended: Action<TEventState, boolean>;
}

const State: TEventState = {
    eventDetails: undefined,
    eventSettings: undefined,
    currentSessions: [],
    allSessions: [],
    showRecommended: false,
    allowedSessions: [],
    recommendedSessionCount: 0,
    eventStatus: "Upcoming",
    currentSession: undefined,
    sessionDetails: undefined,
    emptyFields: [],
    isLive: false,
    sessionGroup: [],
    eventPersons: [],
    allowedDomains: [],
    isRegistrationAllowed: false,
    setRegistrationAllowed: action((state, email) => {
        const event = state.eventDetails;
        if (!event || !email) state.isRegistrationAllowed = false;
        else {
            const { domainRestrictionType, restrictedEmailDomains, restrictedStrategicPartnerIds } = event;
            const { allowed } = DomainUtils.checkEmailDomains(event.restrictedStrategicPartners || [], { domainRestrictionType, restrictedEmailDomains, restrictedStrategicPartnerIds }, email);
            state.isRegistrationAllowed = allowed;
        }
    }),
    setAllSession: thunk((actions, payload, { getState, getStoreState }) => {
        const {
            User: { user },
            Agency: { strategicPartners },
        } = getStoreState();
        payload = payload.map((i) => ({ ...i, isSessionLive: !(i.type === "conferenceCall" && i.status === "upcoming") }));
        getState().allSessions = payload;
        let sessions: TSession[] = [];
        payload.forEach((elem) => {
            let speakerIds = elem.eventPersonIds;
            let tSpeaker = getState().eventPersons.filter((i) => (speakerIds || []).indexOf(i.id) > -1);
            elem.speakerIds = elem.eventPersonIds || [];
            let { allowed, recommended } = DomainUtils.checkEmailDomains(
                strategicPartners,
                { domainRestrictionType: elem.domainRestrictionType, restrictedEmailDomains: elem.restrictedEmailDomains, restrictedStrategicPartnerIds: elem.restrictedStrategicPartnerIds },
                user?.email
            );
            if (allowed) sessions.push({ ...elem, allowed, recommended, speakers: tSpeaker, speakerIds: elem.speakerIds || [], isSessionLive: !(elem.type === "conferenceCall" && elem.status === "upcoming") });
        });
        const state = getState();
        state.allowedSessions = [...sessions];
        state.showRecommended = false;
        state.recommendedSessionCount = sessions.filter((i) => i.recommended).length;
    }),
    setShowRecommended: action((state, payload) => {
        state.showRecommended = payload;
    }),
    resetState: action((state, payload) => {
        state.eventDetails = undefined;
        state.eventSettings = undefined;
        state.currentSessions = [];
        state.allowedSessions = [];
        state.sessionDetails = undefined;
        state.isLive = false;
        state.emptyFields = [];
    }),
    setEventSetting: action((state, payload) => {
        state.eventSettings = payload;
        let event = state.eventDetails;
        if (event) {
            const emptyFields: TEventState["emptyFields"] = [];
            emptyFields.push("feedback");
            if (event.eventType === "webinar" || (event._sessions || []).length === 0 || !payload.agendas) emptyFields.push("_sessions");
            if ((event._partners || []).length === 0 || !payload.partners) emptyFields.push("_partners");
            if (!event._settings.sponsers || (eventSponsors(event).length === 0 && !event._settings.displaySponsorshipPackage && !event._sponsorshipPackage)) {
                emptyFields.push("sponsors");
            }
            if ((event._speakers || []).length === 0 || !payload.speakers) emptyFields.push("_speakers");
            if (!payload.exhibition || (event.scenes || []).length === 0 || ((event.scenes || []).length > 0 && event.scenes[0]._rooms.length === 0)) emptyFields.push("scenes");
            if (!payload.gamification) emptyFields.push("rewards");
            if (!(event._eventPersons || []).filter((s) => s.isAvailableForMeeting).length || !payload.meeting) emptyFields.push("meetings");
            if (!payload.covidGuideline) {
                emptyFields.push("covidGuideline");
            }
            if (!payload.goodToKnow) {
                emptyFields.push("goodToKnow");
            }
            state.emptyFields = emptyFields;
        }
    }),

    setAllowedSessions: action((state, payload) => {
        state.allowedSessions = payload;
    }),
    removeSession: action((state, { sessionId }) => {
        state.allSessions = state.allSessions.filter((i) => i.id !== sessionId);
        state.allowedSessions = state.allowedSessions.filter((i) => i.id !== sessionId);
        state.currentSessions = state.currentSessions.filter((i) => i.id !== sessionId);
    }),
    setEventStatus: action((state, payload) => {
        state.eventStatus = payload;
    }),
    setEmptyFields: action((state, payload) => {
        state.emptyFields = payload;
    }),
    setLiveStatus: action((state, payload) => {
        state.isLive = payload;
    }),
    setSingleEvent: action((state, payload) => {
        state.eventDetails = payload;
    }),
    setCurrentSessions: action((state, payload) => {
        state.currentSessions = [...payload];
    }),
    setCurrentSession: action((state, payload) => {
        state.currentSession = payload;
    }),
    setSingleSession: action((state, payload) => {
        state.sessionDetails = payload;
    }),
    fetchSingleEvent: thunk(async (actions, eventId, { injections, getState }) => {
        let event = await injections.EventModel.fetchSingleEvent(eventId).catch((err) => {
            actions.setSingleEvent(undefined);
            throw err;
        });
        event = injections.EventPersonModel.attachExpertiseIdResolver(event);
        event._speakers = event.speakers = injections.EventModel.getSpeakersFromEvent(event);
        actions.setEventSetting(event._settings);
        const state = getState();
        const emptyFields: TEventState["emptyFields"] = [];
        const speakers: TSpeaker[] = event.speakers || [];
        state.eventPersons = speakers;
        let sessions: TSession[] = [];

        let env = process.env.REACT_APP_NODE_ENV?.trim();
        if (env === "production") {
            setAnalytics(getAnalyticsConfig(event.slug));
        }

        localStorage.setItem("eventType", event.eventType);

        if (event?.timeZone) moment.tz.setDefault(event.timeZone.timezone);
        event._sessions.forEach((s) => {
            let speakerIds = s.eventPersonIds;
            let tSpeaker = speakers.filter((i) => (speakerIds || []).indexOf(i.id) > -1);
            s.speakerIds = s.eventPersonIds || [];
            sessions.push({ ...s, speakers: tSpeaker, speakerIds: s.speakerIds || [], isSessionLive: !(s.type === "conferenceCall" && s.status === "upcoming") });
        });

        actions.setAllSession([...sessions]);

        actions.setSingleEvent({ ...event, _sessions: sessions });

        emptyFields.push("feedback");
        if (event.eventType === "webinar" || (event._sessions || []).length === 0 || !event._settings.agendas) emptyFields.push("_sessions");
        if ((event._partners || []).length === 0 || !event._settings.partners) emptyFields.push("_partners");
        if (!event._settings.sponsers || (eventSponsors(event).length === 0 && !event._settings.displaySponsorshipPackage && !event._sponsorshipPackage)) {
            emptyFields.push("sponsors");
        }
        // if ((eventSponsors(event).length === 0 || !event._settings.sponsers) && (!event._settings.displaySponsorshipPackage || !event._sponsorshipPackage))
        //     emptyFields.push('_partners')
        if ((event._speakers || []).length === 0 || !event._settings.speakers) emptyFields.push("_speakers");
        if (!event._settings.exhibition || (event.scenes || []).length === 0 || ((event.scenes || []).length > 0 && event.scenes[0]._rooms.length === 0)) emptyFields.push("scenes");
        if (!event._settings.gamification) emptyFields.push("rewards");
        if (!(event._eventPersons || []).filter((s) => s.isAvailableForMeeting).length || !event._settings.meeting) emptyFields.push("meetings");
        if (!event._settings.covidGuideline) {
            emptyFields.push("covidGuideline");
        }
        if (!event._settings.goodToKnow) {
            emptyFields.push("goodToKnow");
        }

        actions.setEmptyFields(emptyFields);
        return event;
    }),
    groupSessions: action((state, payload) => {
        let group = SessionGroupModel.groupSessionByDay(payload);
        state.sessionGroup = [...group];
    }),
};

export default State;
