import React, { FC, useState } from "react";
import { SnackbarProps } from "@material-ui/core";
import { ToastProps, ToastMessage } from "mui-wrapper-utils";

interface ContextProps {
    open: boolean;
    message: string;
    variant: ToastProps["variant"];
    showToast: (message: string, options?: Partial<IToastContext>) => void;
    hideToast: () => void;
}

export const ToastContext = React.createContext<ContextProps>({
    open: false,
    message: "",
    variant: "success",
    showToast: (message: string, options?: Partial<IToastContext>) => {},
    hideToast: () => {},
});

export interface IToastContext extends SnackbarProps {
    message?: string;
    variant?: ToastProps["variant"];
}

export const useAppToast = (initialState: IToastContext) => {
    const [toastState, setToastState] = useState({ open: false, message: "", ...initialState });

    const showToast = (message: string, options?: Partial<IToastContext>) => {
        setToastState((toastState) => ({
            ...toastState,
            message,
            open: true,
            autoHideDuration: options?.autoHideDuration || 6000,
            ...options,
        }));
    };
    const hideToast = () => {
        setToastState((toastState) => ({
            ...toastState,
            open: false,
        }));
    };
    return { autoHideDuration: toastState.autoHideDuration, showToast, hideToast, message: toastState.message || "", open: toastState.open, variant: toastState.variant || "success" };
};

const AppToastProvider: FC = (props) => {
    const { autoHideDuration = 6000, showToast, hideToast, message, open, variant } = useAppToast({ open: false });
    return (
        <ToastContext.Provider
            value={{
                message,
                open,
                showToast,
                hideToast,
                variant,
            }}
        >
            {props.children}
            <ToastMessage ContentProps={{ style: { fontSize: 16, maxWidth: 600 } }} autoHideDuration={autoHideDuration} message={message} open={open} variant={variant} onClose={hideToast} />
        </ToastContext.Provider>
    );
};

export default AppToastProvider;
